import React from "react";
import "./AppointmentSection.css";
import appointmentMobile from "../../../Images/phone-appointment.png";
import appointmentSchedule from "../../../Images/appointment-scheduled.png";
import bookAppointment from "../../../Images/book-appointment.png";
import { useNavigate } from "react-router-dom";

const AppointmentSection = () => {
  const navigate = useNavigate(null);
  return (
    <div className="appointment-sec">
      <div className="container">
        <div className="row align-center">
          <div className="col-md-5 second-text">
            <h2>Scheduled Appointments in few seconds</h2>
            <h5>
              The VmyCards has features such as calendar integration, which
              makes it easy to schedule an appointment. Professionalism at its
              finest.
            </h5>
            <div
              style={{ cursor: "pointer" }}
              onClick={() => navigate("/register")}
              className="blue-button"
            >
              Choose Your Time
            </div>
          </div>
          <div className="col-md-7 relative2">
            <img src={appointmentMobile} />
            <div className="appointment-schedule">
              {" "}
              <img src={appointmentSchedule} />{" "}
            </div>
            <div className="book-appointment">
              {" "}
              <img src={bookAppointment} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppointmentSection;
