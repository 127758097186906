import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Register.css";
import axios from "axios";
import "./Register.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Formik, Form, Field, useFormik } from "formik";
import { changePasswordValidation } from "../common/changePasswordValidation";
import { environmentVariables } from "../config/env.config";
function ChangePassword() {
  const baseUrl = environmentVariables?.apiUrl;
  const navigate = useNavigate();
  const [password, setPassword] = useState(null);
  const [isAuth, setIsAuth] = useState(false);
  const [authCode, setAuthCode] = useState(null);
  useEffect(() => {
    const url = new URL(window.location.href);
    const auth = url.searchParams.get("auth");
    setAuthCode(auth);
    if (auth == null || auth == undefined) {
      setIsAuth(false);
      toast.error("Auth Code not found", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
      setTimeout(() => {
        navigate("/");
      }, 6000);
    } else {
      setIsAuth(true);
    }
  }, []);
  const initialValues = {
    password: "",
    cpassword: "",
  };
  let formik = useFormik({
    initialValues: initialValues,
    validationSchema: changePasswordValidation,
    onSubmit: async () => {
      if (Object.keys(formik.errors).length == 0) {
        setIsAuth(false);

        let data = JSON.stringify(formik.values);

        let config = {
          method: "post",
          maxBodyLength: Infinity,
          url: `${baseUrl}api/v1/user/resetPassword`,
          headers: {
            _token: authCode,
            "Content-Type": "application/json",
          },
          withCredentials:true,
          data: data,
        };

        axios
          .request(config)
          .then((response) => {
            toast.success("Password changed successfully", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 1000,
            });

            setTimeout(() => {
              navigate("/login");
            }, 4000);
          })
          .catch((error) => {
            toast.error(error?.response?.data?.message || error?.message, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 1000,
            });
            setTimeout(() => {
              navigate("/");
            }, 3000);
          });
      }
    },
  });
  const { values, errors, handleSubmit } = formik;

  return (
    <section className="py-5">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-lg-4">
            <div className="loginform">
              <h3>Change Password</h3>
              <form className="row g-3">
                <div className="col-md-12">
                  <label for="exampleInputEmail1" className="form-label">
                    Password{" "}
                  </label>
                  <input
                    type="password"
                    className="form-control clear_string"
                    placeholder="Enter New Password"
                    name="password"
                    value={formik.values.password}
                    required=""
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.password && formik.errors.password ? (
                    <div id="error_msg">{errors.password}</div>
                  ) : null}
                </div>
                <div className="col-md-12">
                  <label for="exampleInputEmail1" className="form-label">
                    Confirm Password{" "}
                  </label>
                  <input
                    type="password"
                    className="form-control clear_string"
                    placeholder="Re-Enter Password"
                    name="cpassword"
                    value={formik.values.cpassword}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    required=""
                  />
                  {formik.touched.cpassword && formik.errors.cpassword ? (
                    <div id="error_msg">{errors.cpassword}</div>
                  ) : null}
                </div>

                <div className="col-12">
                  <button
                    disabled={!isAuth}
                    onClick={handleSubmit}
                    type="button"
                    className="btn try_theme_btn "
                  >
                    Change Password
                  </button>
                </div>
              </form>
            </div>
            <ToastContainer />
          </div>
        </div>
      </div>
    </section>
  );
}

export default ChangePassword;
