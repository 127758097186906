import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { environmentVariables } from "../../../config/env.config";
import React, { useContext, useEffect, useState } from "react";
import { userContext } from "../../../context/userContext";
import { businessObjContext } from "../../../context/businessObjContext";

function SeoPage({ businessObj }) {
  const [metaKeyword, setMetaKeyword] = useState("");
  const [metaDescription, setMetaDescription] = useState("");
  const [googleAnalytics, setGoogleAnalytics] = useState("");
  const [fbPixelCode, setFbPixelCode] = useState("");
  const [metaImage, setMetaImage] = useState("");
  const [metaImageShow, setMetaImageShow] = useState("");
  const { userData, setUserData } = useContext(userContext);
  const { businessObjData, setBusinessObjData } =
    useContext(businessObjContext);
  const baseUrl = environmentVariables?.apiUrl;

  const handleFetchBusinessData = async () => {
    try {
      let { data: res } = await axios.get(
        `${baseUrl}api/v1/business/fetchBusinessById?id=${businessObj?.id}`,
        {
          headers: {
            _token: userData?.token,
          },
          // withCredentials: true,
        }
      );
      let getBusinessData = res?.data;
      // console.log(getBusinessData, "gtBusinessData");
      localStorage.setItem("businessObj", JSON.stringify(getBusinessData));
      if (getBusinessData && getBusinessData.id) {
        setMetaKeyword(getBusinessData?.meta_keyword || "");
        setMetaDescription(getBusinessData?.meta_description || "");
        setGoogleAnalytics(getBusinessData?.google_analytic || "");
        setFbPixelCode(getBusinessData?.fbpixel_code || "");
        setMetaImage(getBusinessData?.meta_image || "");
        setMetaImageShow(getBusinessData?.meta_image || "");
      } else {
        setMetaKeyword("");
        setMetaDescription("");
        setGoogleAnalytics("");
        setFbPixelCode("");
        setMetaImage("");
        setMetaImageShow("");
      }
    } catch (err) {
      // toast.error(err?.response?.data?.message, {
      //   position: toast.POSITION.TOP_RIGHT,
      // });
    }
  };

  useEffect(() => {
    handleFetchBusinessData();
  }, [businessObj]);

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      // if (metaKeyword == "" || metaKeyword?.trim() == "") {
      //   toast.error("Meta keyword is mandatory", {
      //     position: toast.POSITION.TOP_RIGHT,
      //   });
      //   return
      // } else if (metaDescription == "" || metaDescription?.trim() == "") {
      //   toast.error("Meta Description is mandatory", {
      //     position: toast.POSITION.TOP_RIGHT,
      //   });
      //   return
      // }

      const formData = new FormData();
      // console.log(metaImage, "metaImage242");
      formData.append("myFile", metaImage);
      formData.append("business_id", businessObj?.id);
      formData.append("google_analytic", googleAnalytics);
      formData.append("meta_keyword", metaKeyword);
      formData.append("meta_description", metaDescription);
      formData.append("fbpixel_code", fbPixelCode);

      axios
        .post(`${baseUrl}api/v1/business/businessSeo`, formData, {
          headers: {
            _token: userData?.token,
          },
          // withCredentials: true,
        })
        .then((res) => {
          // console.log(res, "2321313:Res");
          toast.success(res?.data?.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          handleFetchBusinessData();
        })
        .catch((error) => {
          // console.log(error, "ERwerwewe");
        });

      // if(getResult&&getResult.success==false){
      //   return toast.error(getResult?.err?.message, {
      //     position: toast.POSITION.TOP_RIGHT,
      //   });
      // }
    } catch (err) {
      toast.error(err?.response?.data?.message, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    }
  };

  const imageUpload = (e) => {
    setMetaImage(e.target.files[0]);
    const file = e.target.files[0];

    const reader = new FileReader();
    reader.onload = () => {
      setMetaImageShow(reader.result);
    };
    reader.readAsDataURL(file);
  };

  return (
    <>
      <div className="row">
        <div className="col-12 col-lg-12">
          <div className="">
            <form>
              <div className="boxbanner">
                {" "}
                <label class="form-label">Banner:</label>
                <div className="bannerbox">
                  <img
                    // src={metaImageShow  }
                    src={
                      metaImageShow?.includes("base64")
                        ? metaImageShow
                        : `${baseUrl}uploads/${metaImageShow}`
                    }
                    className="mx-auto d-block img-fluid"
                  ></img>
                </div>
                <input
                  className="form-control"
                  type="file"
                  name="metaImage" // Set the name to match the Multer field name
                  onChange={(event) => imageUpload(event)}
                />
              </div>
              <div class="mb-3">
                <label class="form-label" for="exampleInputEmail1">
                  Meta Keywords
                </label>
                <input
                  class="form-control"
                  id="exampleInputEmail1"
                  type="text"
                  aria-describedby="emailHelp"
                  placeholder="Enter Meta Keyword"
                  value={metaKeyword}
                  onChange={(e) => setMetaKeyword(e.target.value)}
                />
              </div>
              <div class="mb-3">
                <label class="form-label" for="exampleInputEmail1">
                  Meta Description
                </label>
                <input
                  class="form-control"
                  id="exampleInputEmail1"
                  type="text"
                  aria-describedby="emailHelp"
                  placeholder="Enter Meta Description"
                  value={metaDescription}
                  onChange={(e) => setMetaDescription(e.target.value)}
                />
              </div>
              <div class="mb-3">
                <label class="form-label" for="exampleInputEmail1">
                  Google Analytic
                </label>
                <input
                  class="form-control"
                  id="exampleInputEmail1"
                  type="text"
                  aria-describedby="emailHelp"
                  placeholder="Enter Google Analytic"
                  value={googleAnalytics}
                  onChange={(e) => setGoogleAnalytics(e.target.value)}
                />
              </div>
              <div class="mb-3">
                <label class="form-label" for="exampleInputEmail1">
                  Fb pixel Code
                </label>
                <input
                  class="form-control"
                  id="exampleInputEmail1"
                  type="text"
                  aria-describedby="emailHelp"
                  placeholder="Enter Fb pixel Code"
                  value={fbPixelCode}
                  onChange={(e) => setFbPixelCode(e.target.value)}
                />
              </div>
              <div class="mb-3">
                <button
                  type="Submit"
                  class="btn btn-cu"
                  onClick={(e) => handleSubmit(e)}
                >
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default SeoPage;
