import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import "./LandingPage.css";
import Appointments from "../Images/Appointments.png";
import sliderImg from "../Images/slider-img.png";
import { environmentVariables } from "../../src/config/env.config";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CircularLoader from "../../src/Backend/Components/CircularLoader/CircularLoader";
import { Link } from "react-router-dom";
import { userContext } from "../context/userContext";

function LandingPage() {
  const [userCreateData, setUserCreateData] = useState({
    name: "",
    lastname: "",
    email: "",
    contactNumber: "",
    textareaValue: "",
  });
  const baseUrl = environmentVariables?.apiUrl;
  const { userData, setUserData } = useContext(userContext);

  const [errorMsgName, setErrorMsgName] = useState("");
  const [errorMsgEmail, setErrorMsgEmail] = useState("");
  const [errorMsgNo, setErrorMsgNo] = useState("");
  const [errorMsgText, setErrorMsgText] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);
  const [counts, setCounts] = useState({
    count_users: 0,
    count_cards: 0,
    count_business: 0,
    count_countries: 0,
  });
  const [settingData, setSettingData] = useState([]);

  function isValidPhoneNumber(phoneNumber) {
    const phonePattern = /^\d{10}$/;
    return phonePattern.test(phoneNumber);
  }

  const isOnlyNumber = (phoneNumber) => {
    const phonePattern = /^\d+$/;
    return phonePattern.test(phoneNumber);
  };

  const isValidEmail = (email) => {
    const emailRegex = /^[A-Za-z0-9._%-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/;
    return emailRegex.test(email);
  };
  const isValidName = (name) => {
    const nameRegex = /^[A-Za-z -]+$/;
    return nameRegex.test(name);
  };

  const handleSubmits = async (e) => {
    e.preventDefault();
    setErrorMsgName("");
    setErrorMsgEmail("");
    setErrorMsgNo("");
    setErrorMsgText("");
    if (!userCreateData.name||userCreateData?.name?.trim()?.length==0) {
      setErrorMsgName("Name is mandatory");
      return;
    }
    else if (!isValidName(userCreateData.name)) {
      setErrorMsgName("Only alphabets required");
      return;
    }
    if (!userCreateData.email) {
      setErrorMsgEmail("Email is mandatory");
      return;
    } else if (!isValidEmail(userCreateData.email)) {
      setErrorMsgEmail("Invalid email format");
      return;
    }
    if (!userCreateData.contactNumber) {
      setErrorMsgNo("Contact Number is mandatory");
      return;
    } else if (!isOnlyNumber(userCreateData.contactNumber)) {
      setErrorMsgNo("Only Number required");
      return;
    }

    if (!userCreateData.textareaValue||userCreateData?.textareaValue?.trim()?.length==0) {
      setErrorMsgText("Message is mandatory");
      return;
    }


    try {
      setIsSubmit(true);
      const response = await axios.post(
        `${baseUrl}api/v1/info/saveContactInfo`,
        userCreateData
      );

      if (response.status === 201) {
        setIsSubmit(false);
        toast.success(
          "Thanks for your enquiry! Our team will get back to you soon.",
          {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          }
        );
        setUserCreateData({
          name: "",
          lastname: "",
          email: "",
          contactNumber: "",
          textareaValue: "",
        });
      } else {
        setIsSubmit(false);
        toast.error(
          "Failed to submit the enquiry. Please check your details and try again later.",
          {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          }
        );
      }
      // console.log("response", response);
    } catch (error) {
      setIsSubmit(false);
      console.error("Error:", error);
      toast.error("Failed to send the enquiry. Please try again later.", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    }
  };



  

  async function fetchConfig() {
    try {
      const response = await axios.get(
        `${baseUrl}api/v1/admin/setting/fetchConfig`,
        {
          params: {
            cnf_type: "web",
            name:['Landing_Countries','Landing_AppUsers','Landing_CardUsers','Landing_BusinessUsers']
          },
        }
      );
      if (response.status === 200) {
        // console.log(response?.data?.result, "responsedata");
        setSettingData(response?.data?.result);
      }
    } catch (error) {
      console.error({ "Error:": error, msg: "ErrorLanding" });
    }
  }

  useEffect(() => {
    fetchConfig();
  }, []);

  return (
    <div>
      <section className="Slider spy pb-0">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-6">
              <div className="slidercaption">
                <div className="blog-heading">
                  <h3>
                    Empowering Connections
                    <br /> <span> Next-Gen Digital Cards </span>
                  </h3>
                  <p className="text_gray">
                    Experience a 30-day <strong>FREE</strong> Trial that helps
                    you connect with people easily and build important
                    relationships.
                  </p>
                  <div class="btns_main">
                    <Link to="/register">
                      <button class="theme-bg try_theme_btn">
                        <span class="text-white">Start Free Trial</span>
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-6">
              <div className="sliderimg">
                <img src={sliderImg} className="mx-auto d-block img-fluid" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="counterslider spy">
        <div className="container">
          <div className="row rowstyle">
            <div className="col-12 col-sm-5 col-lg-5 col-xl-5">
              <div className="row">
                <div className="col-12 col-sm-4 col-lg-4 col-xl-4">
                  <ul className="imgsmgroup">
                    <li>
                      <img
                        src=" https://vmycards.com/landing/assets/img/a1.png"
                        className="smimg"
                      />
                    </li>
                    <li>
                      <img
                        src=" https://vmycards.com/landing/assets/img/a2.png"
                        className="smimg"
                      />
                    </li>
                    <li>
                      <img
                        src=" https://vmycards.com/landing/assets/img/a3.png"
                        className="smimg"
                      />
                    </li>
                    <li>
                      <img
                        src=" https://vmycards.com/landing/assets/img/a4.png"
                        className="smimg"
                      />
                    </li>
                  </ul>
                </div>
                <div className="col-12 col-sm-8 col-lg-8 col-xl-8 exabox">
                  <div className="countbox">
                    <h6>{settingData?.Landing_Countries}</h6>
                    <p>Countries Worldwide Shipping</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-7 col-lg-7">
              <div className="row">
                <div className="col-12 col-lg-4">
                  <div className="countbox">
                    <h6> {settingData.Landing_AppUsers}</h6>
                    <p>App Users</p>
                  </div>
                </div>
                <div className="col-12 col-lg-4">
                  <div className="countbox">
                    <h6> {settingData.Landing_CardUsers}</h6>
                    <p>Card Users</p>
                  </div>
                </div>
                <div className="col-12 col-lg-4">
                  <div className="countbox">
                    <h6> {settingData.Landing_BusinessUsers}</h6>
                    <p>Business Users</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="appoitmnetsec spy">
        <div className="container">
          <div className="blog-heading text-center">
            <h3>
              ULTIMATE <br /> <span> NFC Business Card </span>
            </h3>
            <p className="section_paragragh">
              Experience the future of networking with multiple payment gateway
              links that set us apart from competitors.
            </p>
          </div>

          <div className="row">
            <div className="col-12 col-lg-12">
              <img src={Appointments} className="mx-auto d-block img-fluid" />
            </div>
          </div>
        </div>
      </section>

      <section className="blog spy">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12 respo-order-2">
              <div className="blog-section">
                <div className="blog-heading">
                  <h3>
                    The future of digital cards is here.
                  </h3>
                  <p>
                    With features like calendar integration, you can get
                    notified if someone book an appointment with you. Truly
                    professional.
                  </p>
                  <div class="btns_main">
                    <Link to="/register" class="btn theme-bg try_theme_btn">
                      {" "}
                      <span class="text-white">Sync Your Calendar</span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <div className="blog-image text-center">
                <img
                  src="https://vmycards.com/landing/assets/img/blog1.png"
                  alt="blog"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="blog">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12">
              <div className="blog-image">
                <img
                  src="https://vmycards.com/landing/assets/img/card-img.png"
                  alt="hero"
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="col-lg-6 col-md-12 respo-order-2">
              <div className="blog-section">
                <div className="blog-heading">
                  <h3>
                    Diverse Styles & Templates
                  </h3>
                  <p>
                    We believe in the power of connection and innovation to
                    empower businesses with cutting-edge networking solutions.
                  </p>
                  <div class="btns_main">
                    <Link to="/register" class="btn theme-bg try_theme_btn">
                      {" "}
                      <span class="text-white">Select your Style</span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="blog spy">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-lg-6 col-md-12 respo-order-2">
              <div class="blog-section">
                <div class="blog-heading">
                  <h3>
                    How VmyCards works?
                  </h3>
                  <p>
                    Get started by signing up and setting up your account. Fill
                    in your essential business information that you'd like
                    others to share. Choose a design that suits your style. Add
                    a payment link and upload your company logo. We'll customize
                    your QR code to blend perfectly with your brand's colors and
                    background.
                  </p>
                  <div class="btns_main">
                    <Link to="/register" class="btn theme-bg try_theme_btn">
                      {" "}
                      <span class="text-white">Explore Now</span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-md-12">
              <div class="blog-image text-center">
              {/*   <video width="100%" height={300} autoplay>
                  <source
                    src="https://iframe.mediadelivery.net/play/167280/3281efd7-3a96-4049-8184-f9ef340028c6"
                    type="video/mp4"
                  />
                  <source
                    src="https://iframe.mediadelivery.net/play/167280/3281efd7-3a96-4049-8184-f9ef340028c6"
                    type="video/ogg"
                  />
                  Your browser does not support the video tag.
                </video>

                */}

                   <iframe
                  width="100%"
                  height="300"
                  src="https://www.youtube.com/embed/qGZg_v4heTU"
                  frameborder="0"
                  allowfullscreen
                ></iframe>


              </div> 
            </div>
          </div>
        </div>
      </section>

      <section className="contactus spy" id="contact">
        <div className="container">
          <div className="blog-heading text-center">
            <h3>
              Contact Us <br /> <span> Get your NFC business card </span>
            </h3>
            <p class="blog_heading_Updated">
              Just share your details and our team will get back to you shortly.
            </p>
          </div>

          <div className="row  justify-content-center">
            <div className="col-12 col-lg-6">
              <form
                method="POST"
                // action="https://vmycards.com/submit-query"
                accept-charset="UTF-8"
                class="row g-3 contacthomepage-a pb-0"
                onSubmit={handleSubmits}
              >
                <div className="col-md-6">
                  <input
                    type="text"
                    className="form-control clear_string"
                    placeholder="First Name"
                    name="first_name"
                    required=""
                    value={userCreateData.name}
                    onChange={(e) =>
                      setUserCreateData({
                        ...userCreateData,
                        name: e.target.value,
                      })
                    }
                  />
                  <div
                    style={{
                      fontSize: "14px",
                      color: "red",

                      bottom: "70px",
                    }}
                  >
                    {errorMsgName}
                  </div>
                </div>
                <div className="col-md-6">
                  <input
                    type="text"
                    className="form-control clear_string"
                    placeholder="Last Name"
                    name="last_name"
                    value={userCreateData.lastname}
                    onChange={(e) =>
                      setUserCreateData({
                        ...userCreateData,
                        lastname: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="col-12 col-md-6">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Email"
                    name="email"
                    required=""
                    value={userCreateData.email}
                    onChange={(e) =>
                      setUserCreateData({
                        ...userCreateData,
                        email: e.target.value,
                      })
                    }
                  />
                  <div
                    style={{
                      fontSize: "14px",
                      color: "red",

                      bottom: "70px",
                    }}
                  >
                    {errorMsgEmail}
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <input
                    type="text"
                    className="form-control clear_string"
                    maxlength="20"
                    minlength="10"
                    placeholder="Mobile Number"
                    name="mobile_number"
                    value={userCreateData.contactNumber}
                    onChange={(e) =>
                      setUserCreateData({
                        ...userCreateData,
                        contactNumber: e.target.value,
                      })
                    }
                  />
                  <div
                    style={{
                      fontSize: "14px",
                      color: "red",

                      bottom: "70px",
                    }}
                  >
                    {errorMsgNo}
                  </div>
                </div>
                <div className="col-12">
                  <textarea
                    className="form-control clear_string"
                    rows="6"
                    placeholder="Message"
                    name="message"
                    value={userCreateData.textareaValue}
                    onChange={(e) =>
                      setUserCreateData({
                        ...userCreateData,
                        textareaValue: e.target.value,
                      })
                    }
                  ></textarea>
                  <div
                    style={{
                      fontSize: "14px",
                      color: "red",

                      bottom: "70px",
                    }}
                  >
                    {errorMsgText}
                  </div>
                </div>
                <div className="col-12 mt-4">
                  <button
                    type="submit"
                    className="btn try_theme_btn mx-auto d-block"
                    disabled={isSubmit}
                  >
                    {isSubmit ? <CircularLoader size={20} /> : "Submit"}
                  </button>
                </div>
                <ToastContainer />{" "}
              </form>
            </div>
            {/* <div className="row justify-content-center">
              <div className="col-12 col-lg-8">
                <div className="row">
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </section>
    </div>
  );
}
export default LandingPage;
//1112