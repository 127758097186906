import React, { useContext, useEffect, useRef, useState } from "react";
import { useCallback,useMemo } from "react";
import PageName from "../Utils/PageName";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import axios from "axios";
import { userContext } from "../../context/userContext";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { environmentVariables } from "../../config/env.config";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import ReactDOM from "react-dom";
import DataTable from "react-data-table-component";
import SortIcon from "@material-ui/icons/ArrowDownward";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import CircularLoader from "../Components/CircularLoader/CircularLoader";
import styled from "styled-components";
import "./UpdateRequestcardstyle.css";
import Modal from "@mui/material/Modal";
import { Box } from "@mui/material";
import DatePicker from "react-datepicker";
import FilterIcon from "../../../src/Images/FilterIcon.png";
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import { v4 as uuidv4 } from 'uuid';

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
`;

const AddUserText = styled.div`
@media(max-width: 420px){
  display:none !important;
}
`;

const Boxstyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  zIndex: 999,
  borderRadius: "15px",
  border: "none",
  outline: "none",
  focus: "none",
  boxShadow:
    " rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;",
  padding: "3rem 5rem",
  "@media (max-width: 650px)": {
    width: "425px",
    padding: "2rem",
  },
  "@media (max-width: 430px)": {
    width: "380px",
    padding: "2rem",
  },
  "@media (max-width: 390px)": {
    width: "350px",
    padding: "2rem",
  },
};

const customStyles = {
  dataTableExtensions: {
    backgroundColor: "lightgray",
    padding: "10px",
  },
  headRow: {
    style: {
      backgroundColor: "#e9e9e9",
      borderRadius: "2px 2px 0 0",
      paddingLeft: "25px",
      border: "none",
      textTransform: "capitalize",
      position: "sticky",
      left: 0,
      top: 0,
      zIndex: 1,
      boxShadow: "0 2px 2px -1px rgba(0, 0, 0, 0.1)",
      overflow: "hidden",
      whiteSpace: "nowrap",
    },
  },
  headCells: {
    style: {
      color: "#525252",
      fontSize: "16px",
      minHeight: "52px",
    },
  },
  rows: {
    style: {
      padding: "6px 0px 6px 25px",
      fontSize: "14px",
      textTransform: "capitalize",
      minHeight: "58px !important",
    },
  },
  UserNameColumn: {
    position: "sticky",
    left: "0",
    zIndex: "1",
    backgroundColor: "#fff",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
};


function UserUploadList() {
  const navigate = useNavigate();
  const { userData } = useContext(userContext);
  const [businesslistData, setBusinesslistData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [activeBusinessStatus, setBusinessStatus] = useState("");
  const [leadStatus, setLeadStatus] = useState("");
  const [isLoader, setIsloader] = useState(true);
  // const [currentPage, setCurrentPage] = useState(0);
  const [currentRowsPerPage, setCurrentRowsPerPage] = useState(2);
  const [searchQuery, setSearchQuery] = useState(null);
  const [selectedDateFrom, setSelectedDateFrom] = useState("");
  const [selectedDateTo, setSelectedDateTo] = useState("");
  const [showFilter, setShowFilter] = useState(false);
  const [paginationTotalRows, setPaginationTotalRows] = useState(0);
  const [paginationPerPage, setPaginationPerPage] = useState(10);

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(10);
  const [action] = useState({ fromUser: false }); //this is a way to have an instant-changing state
  const [rowsPerPage, setRowsPerPage] = useState(10); //change to 10 after you remove paginationRowsPerPageOptions
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedRowsPerPage, setSelectedRowsPerPage] = useState([]);
  

  const tableExtensions = {
    export: false,
    print: false,
  };
 
  const [tempData, setTempData] = useState([]);

   const exportToExcel = () => {
    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    // Check if businesslistData is defined before using it
    if (!businesslistData) {
      console.error("businesslistData is not defined");
      return;
    }

    const formattedData = businesslistData.map((item) => ({
      Name: item?.name,
      Email: item?.email,
      Mobile: item?.mobile_number,
      City: item?.city,
      State: item?.state,
      Pincode: item?.pincode,
      Web_URL: item?.web_address,
      Address: item?.address,
      Other_Details: item?.other_details,
      Send_Email_Status: item?.send_email_status === 1 ? 'Sent' : 'Not Sent',
     
    }));
    const uid = uuidv4(); // Generate a unique identifier
    const timestamp = new Date().toISOString().replace(/:/g, '-').split('.')[0]; // Format timestamp
    const ws = XLSX.utils.json_to_sheet(formattedData);
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    const fileName = `USERSDATA_${uid}_${timestamp}${fileExtension}`;
    saveAs(data, fileName);
  };
  
  // https://codesandbox.io/p/sandbox/flamboyant-tesla-22fbq7?file=%2Fsrc%2FApp.js%3A16%2C22-16%2C27
  // reference url

  const getUsersData  = async (page = 1, rowsPerPage) => {
    // console.log("FFFFFFFFFFff",pageSize)
    // setPaginationPerPage(pageSize)
    let filter={
      send_email_status: activeBusinessStatus === 'All Status' ? '' : activeBusinessStatus,
      startDate:selectedDateFrom,
      endDate:selectedDateTo
    }

    let data={
      limit:rowsPerPage,
      page,
      filter
    }

 
    let config =  {
      method: "post",
      maxBodyLength: Infinity,
      url: `${environmentVariables?.apiUrl}api/v1/user/getUsersUploadList`,
      headers: {
        _token: userData?.token,
      },
      data:data
      // withCredentials: true,
    };
    await axios
      .request(config)
      .then((response) => {
        setIsloader(false);
        let temp3 = response?.data?.result;
        temp3 = temp3.sort(
          (x, y) => new Date(y?.createdAt) - new Date(x?.createdAt)
        );
        setBusinesslistData(response?.data?.result);
        setTempData(response?.data?.result);
        setPaginationTotalRows(response?.data?.totalCount);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

 
  const handleOnSelectedRowsChange = useCallback(
    ({ selectedRows }) => {
      if (!action.fromUser) return; //the component always trigger this with 0 selected rows when it renders a page, what would clear the selection

      selectedRowsPerPage[currentPage] = selectedRows; //there is no way to tell if a row was DEselected, so I had to control the selected rows per page,
      //the array would get an index to control each page
      console.log(JSON.stringify(selectedRowsPerPage));
    },
    [currentPage, selectedRowsPerPage, action.fromUser]
  );

  const handlePageChange = (page) => {
    getUsersData(page, rowsPerPage);
    setCurrentPage(page);
  };

  const handleRowsPerPageChange = async (newRowsPerPage) => {
    if (!businesslistData.length) return; //when the table is rendered for the first time, this would trigger, and we don't need to call fetchUsers again
    getUsersData(1, newRowsPerPage);
    setRowsPerPage(newRowsPerPage);
    setCurrentPage(1);
    setSelectedRowsPerPage([]);
  };

  const handleReset = () => {
    setBusinessStatus("All Status");
    // setBusinesslistData(tempData);
    setSelectedDateFrom("");
    setSelectedDateTo("");
    // getUsersData(1, 10);
  };

  const handleviewClick = (slug) => {
    if (window != undefined) {
      window.open(`/${slug}`, "_blank");
    }
  };


  const loadNextPage = (page,direction,size) => {
    const nextPage = page //currentPage + 1;
    getUsersData(nextPage);
    setCurrentPage(nextPage);
    // console.log(size,"Fffffsxxxxxxx")
    // setPaginationPerPage(size);
  }
  
  const loadPageFilter = () => {
    const nextPage = 1
    setCurrentPage(nextPage);
    getUsersData(nextPage)
  };


  const handleSendMail = (_id) => {
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${environmentVariables?.apiUrl}api/v1/admin/sendMailSingle`,
      headers: {
        _token: userData?.token,
      },
      data: {
        _id:_id
      },
    };
    axios
      .request(config)
      .then((response) => {
        toast.success("Mail has been  sent successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        setIsSubmit(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleToggleStatus = (userId, currentStatus, event) => {
    // const newStatus = currentStatus == "0" ? "1" : "0";
    const newStatus = event.target.checked ? "1" : "0";

    axios
      .put(
        `${environmentVariables?.apiUrl}api/v1/user/updateStatusOfUser/${userId}`,
        { status: newStatus },
        {
          headers: {
            _token: userData?.token,
          },
        }
      )
      .then((response) => {
        toast.success(response?.data?.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        getUsersData();
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        console.error("Error updating user status:", error);
      });
  };

  useEffect(() => {
    getUsersData();
  }, []);

  const columns = [ 
    {
      name: "Type",
      selector: (row) => row?.lead_type,
      sortable: true,
      cell: (row) => <div>{row?.lead_type === "USER" ? `User ${row?.users_id}` : row?.lead_type}</div>,
    }, 
    {
      name: "Name",
      selector: (row) => row?.name,
      sortable: true,
      cell: (d) => {
        const capitalizedName =
        d?.name?.charAt(0)?.toUpperCase() + d?.name?.slice(1);
        return capitalizedName;
      },
      color: "red",
      fixed:"left",
    },
    {
      name: "Mobile / Email",
      selector: (row) => row?.email,
      sortable: true,
      cell: (row) => <div >{row?.mobile_number}<br/>{row?.email}</div>,
    },
    {
      name: "City / State",
      selector: (row) => row?.city,
      sortable: true,
      cell: (row) => <div >{  row?.city   }<br/> {row?.state}<br/>{row?.pincode}</div>,
    },
    {
      name: "Web URL",
      selector: (row) => row?.web_address,
      sortable: true,
      cell: (row) => (
        <div>
          {row?.web_address && (
            <a href={row.web_address} target="_blank" rel="noopener noreferrer">
              {row.web_address}
            </a>
          )}
        </div>
      ),
    },
    {
      name: "Address",
      selector: (row) => row?.address,
      sortable: true,
      cell: (row) => <div >{row?.address}</div>,
    },
    {
      name: "Other Details",
      selector: (row) => row?.other_details,
      sortable: true,
      cell: (row) => <div >{row?.other_details}</div>,
    },
    {
      name: "Send Email Status",
      selector: (row) => row?.send_email_status,
      sortable: true,
      cell: (row) => <div >{row?.send_email_status === 1 ? 'Sent' : 'Not Sent'}</div>,
    },
    {
      name: "Lead Status",
      selector: (row) => row?.leadstatus,
      sortable: true,
      // cell: (row) => <div >{row?.send_email_status === 1 ? 'Sent' : 'Not Sent'}</div>,
    },
    {
      name: "Date",
      selector: (row) => row?.updatedAt,
      sortable: true,
      cell: (row) => {
        if (row?.updatedAt) {
          const timestamp = parseInt(row.updatedAt, 10);
          const date = new Date(timestamp);
          const formattedDate = date.toLocaleDateString('en-GB', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
          });
      
          return <div>{formattedDate}</div>;
        } else {
          return <div></div>; // Handle the case when updatedAt is not available
        }
      },
    },
    {
      name: "Action",
      sortable: false,
      cell: (d) => (
        <div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
        <div style={{
          backgroundColor:"#32AAF7",
          width:"25px",
          height:"25px",
          display:"flex",
          justifyContent:"center", 
          alignItems:"center",
          borderRadius:"5px",
          position: "relative",
          cursor: "pointer", 
        }}>
        <i
        style={{color:"white"}}
            key={d._id}
            onClick={() => handleSendMail(d._id)}
            className="fa-regular fa-envelope"
            title="Resend Mail" 
          ></i>
        </div>
        <div style={{
          backgroundColor:"#20a630",
          width:"25px",
          height:"25px",
          display:"flex",
          justifyContent:"center", 
          alignItems:"center",
          borderRadius:"5px",
          position: "relative", 
          marginLeft: "5px",
          cursor: "pointer",
        }}>
        <i
        style={{color:"white"}}
            key={d.slug}
            onClick={() => handleviewClick(d.slug)}
            className="fa-regular fa-eye"
            title="View Business" 
          ></i>
        </div>


        </div>
       ),
      },
      {
        name: "Active",
        sortable: false,
        cell: (d) => (
                <div className="form-check form-switch">
                   <input
                    className="form-check-input"
                    type="checkbox"
                    defaultChecked={d?.usr_dtl?.isDeleted === 0 ? false : true}
                    id={`flexSwitchCheckDefault_${d.id}`}
                    onChange={(event) => handleToggleStatus(d.id, d?.usr_dtl?.isDeleted, event)}
                    style={{ marginLeft: "-10px" }}
                  />
                </div>          
         ),
        },
  ];

  const tableData = {
    columns,
    data: businesslistData,
  };

  const handleFilterOpen = () => {
    setShowFilter(!showFilter);
  };
  const handleFilterClose = () => {
    setShowFilter(false);
  };

 
  
  const handleSearch = () => {
    loadPageFilter()
    setShowFilter(false);
  };

  useEffect(() => {
    if (selectedDateTo != "" && selectedDateFrom != "") {
      if (selectedDateTo < selectedDateFrom) {
        toast.warning("To Date should be equal to or greater then From Date", {
          position: toast.POSITION.TOP_RIGHT,
        });
        return;
      }
    }
    if (
      activeBusinessStatus == "All Status" &&
      !selectedDateFrom &&
      !selectedDateTo
    ) {
      // setBusinesslistData(tempData);
    } else {
      let filteredData = tempData;
      if (activeBusinessStatus != "" && activeBusinessStatus != "All Status") {
        filteredData = filteredData.filter(
          (el) => el?.send_email_status === (activeBusinessStatus === 'active' ? 1 : 0)
        );
      }
      if (selectedDateFrom && selectedDateTo) {
        filteredData = filteredData.filter((el) => {
          const date = new Date(el.createdAt);
          return date >= selectedDateFrom && date <= selectedDateTo;
        });
      }
      // setTempData(filteredData);
      // setBusinesslistData(filteredData);
    }
  }, [activeBusinessStatus, selectedDateFrom, selectedDateTo]);


  const fromDate = useRef(null);
  const toDate = useRef(null);
  const handleChangeBusinessStatus = (e) => {
    if (e.target.value == "active") {
      setBusinessStatus("active");
    } else if (e.target.value == "inactive") {
      setBusinessStatus("inactive");
    } else if (e.target.value == "All Status") {
      setBusinessStatus("All Status");
    }
    // loadPageFilter(e.target.value)
  };
  const handleLeadStatus = (e) => {
      setLeadStatus(e.target.value);
      getUsersData(1, 1);
  };

 
 
  const ButtonFitler=()=>{
  return (  
  <>
    <div className="filter-button-wrapper requestcardfilterbuttonWrapper">
      <span class="FilterIconTextWrapper" onClick={handleFilterOpen}>
        <div className="FilterIcon">
          {" "}
          <img src={FilterIcon} width={28} />
          <div className="filtertext">Filters</div>
        </div>
      </span>
      <button
        className="btn btn-primary requestcardfilterbuttonstyle"
        onClick={handleFilterOpen}
        id="FilterIconText"
      >
        Filter
      </button>
      <button
        className="btn btn-primary requestcardfilterbuttonstyle"
        onClick={exportToExcel}
      >
        Export to Excel
      </button>
    </div>
    <Modal
      open={showFilter}
      onClose={handleFilterClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={Boxstyle}>
        {showFilter && (
          <div class="Contact_filter_fieldbutton_wrapper ModalPopupContainer">
            <div class="Contact_filter_field_wrapper ModalPopupWrapper">
              <div
                class="col-md-2 Contact_filter_Inputfield"
                id="ModalSelectfield"
              >
                 <select
                    value={activeBusinessStatus}
                    onChange={(e) => handleChangeBusinessStatus(e)}
                    class="form-select"
                  >
                    <option value={"All Status"}>Send Mail Status</option>
                    <option value="active">Already Send </option>
                    <option value="inactive">Not Send</option>
                  </select>
                <div
                  style={{
                    fontSize: "14px",
                    color: "red",
                    bottom: "70px",
                  }}
                ></div>
              </div>
              <div class="col-md-2 Contact_filter_Inputfield" id="ModalSelectfield">
                <div className="dateiconmain">
                  <DatePicker
                    placeholderText="From Date"
                    selected={selectedDateFrom ? new Date(selectedDateFrom) : null}
                    onChange={(date) => {
                      if (date) {
                      //   const fselectedDate = date.toISOString().split('T')[0];
                      //   setSelectedDateFrom(fselectedDate);

                      const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
                      const formattedDate = date.toLocaleDateString('en-US', options);
                      setSelectedDateFrom(formattedDate);
                       
                      }
                    }}
                    className="form-control"
                    ref={fromDate}
                    dateFormat="dd/MM/yyyy"
                    popperPlacement="bottom-end"
                  />
                  <div
                    class="contact_filter_toDate_calander_icon"
                    onClick={() => fromDate.current.setOpen(true)}
                  >
                    <i class="fa-regular fa-calendar"></i>
                  </div>
                </div>
              </div>


              <div class="col-md-2 Contact_filter_Inputfield" id="ModalSelectfield">
                <div className="dateiconmain">
                  <DatePicker
                    placeholderText="To Date"
                    selected={selectedDateTo ? new Date(selectedDateTo) : null}
                    onChange={(date) => {
                      if (date) {
                        // const tselectedDate = date.toISOString().split('T')[0];
                        // setSelectedDateTo(tselectedDate);

                        const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
                        const formattedDate = date.toLocaleDateString('en-US', options);
                        setSelectedDateTo(formattedDate);
                      
                      }
                    }}
                    className="form-control"
                    ref={toDate}
                    dateFormat="dd/MM/yyyy"
                    popperPlacement="bottom-end"
                  />
                  <div
                    class="contact_filter_toDate_calander_icon"
                    onClick={() => toDate.current.setOpen(true)}
                  >
                    <i class="fa-regular fa-calendar"></i>
                  </div>
                </div>
              </div>

              <div class="contact_filter_buttonwrapper">
                <button
                  class="contact_filter_buttonstyle"
                  onClick={handleReset}
                >
                  Reset
                </button>
                <button
                  class="contact_filter_buttonstyle"
                  onClick={() => handleSearch()}
                >
                  Search 
                </button>
              </div>
            </div>
            <div className="close-button-wrapper">
             <CloseButton onClick={handleFilterClose}>
                <i className="fa-solid fa-times"></i>
              </CloseButton>
        </div>
          </div>
        )}
      </Box>
    </Modal>
 

  </>
 );
  }

  return (
    <>
      <PageName PageName="Users" />
      <article>
        <div className="row">
          <div class="col-md-12">
            <p className="addusermainright">
            <ButtonFitler></ButtonFitler>
            </p>
          </div>
        </div>
        {isLoader ? (
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "55%",
              display: "flex",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <CircularLoader size={40} />
          </div>
        ) : (
          <div className="main">
            {isLoading ? (
              <div
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "55%",
                  display: "flex",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                <CircularLoader size={40} />
              </div>
            ) : (
              <>
               <div className="scrollable-columns">
                    <div className="sticky-header-container">


                <DataTableExtensions
                  {...tableData}
                  {...tableExtensions}
                  customStyles={customStyles}
                  filterPlaceholder="Search User"
                >
                  <DataTable
                    columns={columns}
                    data={tableData.data}
                    noHeader
                    defaultSortField="id"
                    sortIcon={<SortIcon />}
                    defaultSortAsc={true}
                    pagination
                    highlightOnHover
                    paginationServer
                    paginationTotalRows={paginationTotalRows}
                    paginationPerPage={paginationPerPage}
                    paginationRowsPerPageOptions={[10,20,50,100,300,1000,10000]} // Customize as needed
                    onChangePage={handlePageChange}
                    onChangeRowsPerPage={handleRowsPerPageChange}
                    onSelectedRowsChange={handleOnSelectedRowsChange}
                    dense
                    customStyles={customStyles}
                  />
                </DataTableExtensions>
                </div>
                  </div>
                  </>
            )}
          </div>
        )}
      </article>
    </>
  );
}
export default UserUploadList;