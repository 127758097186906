import React, { useContext, useEffect, useState, useRef } from "react";
import PageName from "../Utils/PageName";
import { environmentVariables } from "../../config/env.config";
import axios from "axios";
import { userContext } from "../../context/userContext";
import { ToastContainer, toast } from "react-toastify";
import DataTable from "react-data-table-component";
import SortIcon from "@material-ui/icons/ArrowDownward";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import CircularLoader from "../Components/CircularLoader/CircularLoader";
import Swal from 'sweetalert2';

function Currency_admin() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const baseUrl = environmentVariables?.apiUrl;
  const { userData, setUserData } = useContext(userContext);

  const [isCurrency, setIsCurrency] = useState(false);
  const [currencyName, setCurrencyName] = useState("");
  const [shortform, setShortform] = useState("");
  const [mobilecode, setMobileCode] = useState("");
  const [countryiso, setCountryIso] = useState("");
  const [isStatus, setIsStatus] = useState(false);
  const [symbol, setSymbol] = useState("");
  const [currencyValue, setCurrencyValue] = useState("");
  const [activate, setActivate] = useState(false);
  const createCurrencyModalRef = useRef(null);

  const [isUpdate, setIsUpdate] = useState(false);
  const [currencyId, setCurrencyId] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const fetchCurrency = async () => {
    try {
      axios
        .get(`${baseUrl}api/v1/currency`, {
          headers: {
            _token: userData?.token,
          },
          withCredentials: true,
        })
        .then((response) => {
          // console.log(response?.data?.data ,"@@@@@@@@@@CCCCCCCC");
          setData(response?.data?.data);
          setIsLoading(false)

        })
        .catch((error) => {
          console.log(error);
          setIsLoading(false)

        });
    } catch (err) {}
  };

  useEffect(() => {
    fetchCurrency();
    return () => {
      // Remove the event listener when the component unmounts
      $(createCurrencyModalRef.current).off("hidden.bs.modal");
    };
  }, []);

  const handleClearState = () => {
    try {
      // e.preventDefault();
      setCurrencyName("");
      setSymbol("");
      setCurrencyValue("");
      setActivate(false);
      setIsUpdate(false);
      setCurrencyId("");
      setIsCurrency(false);
      setShortform("");
      setMobileCode("");
      setCountryIso("");
    } catch (err) {
      console.log(err, "erororo");
    }
  };

  const handleEdit = (el) => {
    try {
      setIsUpdate(true);
      setCurrencyId(el?.id);
      setCurrencyName(el?.name);
      setSymbol(el?.symbol);
      setCurrencyValue(el?.current_value);
      setActivate(el?.status == true ? true : false);
      setIsCurrency(true);
      setIsStatus(false);
      setShortform(el?.shortform)
      setMobileCode(el?.mobile_code);
      setCountryIso(el?.country_iso);
      $(createCurrencyModalRef.current).modal("show");
    } catch (err) {
      console.log(err, "Errorr ");
    }
  };

  const handleDelete = async (d) => {
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: `You won't be able to revert this!`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      });
  
      // Check if the user confirmed the deletion
      if (result.isConfirmed) {
      let { data: res } = await axios.delete(
        `${baseUrl}api/v1/currency/${d?.id}`,
        {
          headers: {
            _token: userData?.token,
          },
        }
      );
      // console.log(res?.message, "Resssssss22222ssssss");
      if (res && res?.success) {
        toast.success(res?.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      }
    }
      fetchCurrency();
    } catch (err) {
      console.log(err, "erorororor");
    }
  };
  const columns = [
    {
      name: "NAME",
      selector: (row) => row?.name,
      sortable: true,
    },
    {
      name: "SYMBOL",
      selector: (row) => row?.symbol,
      sortable: true,
    },
    {
      name: "ShortForm",
      selector: (row) => row?.shortform,
      sortable: true,
    },
    {
      name: "CountryISo",
      selector: (row) => row?.country_iso,
      sortable: true,
    },
    {
      name: "Mobile Code",
      selector: (row) => row?.mobile_code,
      sortable: true,
    },
    {
      name: "Value in INR",
      selector: (row) => row?.current_value,
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => (row?.status == 1 ? "Active" : "Not Active"),
      sortable: true,
    },
    {
      name: "Action",
      sortable: false,
      cell: (d) => [
        <>
          <i
            key={`trash-${d.id}`}
            className="fas fa-trash-alt"
            onClick={() => handleDelete(d)}
          ></i>{" "}
          &nbsp;&nbsp; &nbsp;{" "}
          <i
            key={`edit-${d.id}`}
            className="fas fa-edit"
            onClick={() => {
              handleEdit(d);
            }}
            style={{cursor:"pointer",fontSize:"22px", fontWeight:"100"}}
          ></i>
          ,
        </>,
      ],
    },
  ];

  const customStyles = {
    
    headRow: {
      style: {
        display: "flex",
        alignItems: "stretch",
        width: "100%",
        backgroundColor: "#E9E9E9",
        minHeight: "52px",
        borderRadius: "2px 2px 0px 0px",
        padding: "8px 25px",
        border: "none",
       
      },
    },
    headCells: {
      style: {
        color: "#525252",
        fontSize: "16px",
        minHeight: "52px",
        fontSize:"15px",
      
      },
    },
    rows: {
      style: {
        padding: "20px 25px",
        fontSize: "14px",
        textTransform: "capitalize",
        minHeight: "48px ",
      },
    },
  };


  const tableData = {
    columns,
    data,
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      // console.log(isUpdate,"isUpdate@!@!@@@",currencyId ,"currencyId ")
      if (isUpdate == false && currencyId == "") {
        if (currencyName == "" || currencyName?.trim() == "") {
          toast.error("Currency name is mandatory", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          return;
        } else if (symbol == "" || symbol?.trim() == "") {
          toast.error("Currency symbol is mandatory", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          return;
        } else if (currencyValue == "" || currencyValue?.trim() == "") {
          toast.error("Currency Value is mandatory", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          return;
        }
      }

      let obj = {
        name: currencyName,
        symbol: symbol,
        current_value: currencyValue,
        status: activate,
        shortform:shortform,
        mobile_code:mobilecode,
        country_iso:countryiso
      };
      // console.log(obj,"objectt",activate)
      let res;
      // setIsStatus(true);
      if (isUpdate == true && currencyId != "") {
        obj.currencyId = currencyId;
        // console.log(isUpdate, "isUe", currencyId, "isUpde",obj);
        res = await axios.put(`${baseUrl}api/v1/currency/${currencyId}`, obj, {
          headers: {
            _token: userData?.token,
          },
        });
      } else {
        // console.log(isUpdate, "iste", currencyId, "isisUpdate111");
        res = await axios.post(`${baseUrl}api/v1/currency`, obj, {
          headers: {
            _token: userData?.token,
          },
        });
      }
      // console.log(res,"ressssssssssss@@@@@@@")
      if (res && res?.data && res?.data?.success) {
        fetchCurrency();
        handleClearState();
        toast.success(res?.data?.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        $(createCurrencyModalRef.current).modal("hide");
      }
    } catch (err) {
      // console.log(err?.response?.data,"Errr")
      toast.error(
        err?.response?.data?.message?.message || err?.response?.data?.message,
        {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        }
      );
    }
  };
  return (
    <>
      <PageName PageName="Manage Currency" />
      <article>
        <div className="main">
          {/* <li data-bs-toggle="modal" data-bs-target="#createCurrencyModal">
            <span
              className="app-nav__item btnhead"
              onClick={(e) => setIsCurrency(!isCurrency)}
            >
              + Create Currency{" "}
            </span>
          </li> */}
          <div data-bs-toggle="modal" data-bs-target="#createCurrencyModal">
            <button
            id="Currency_admin_button"
              type="button"
              className="save-cta"
              onClick={(e) => setIsCurrency(!isCurrency)}
            >
             + Create Currency{" "}
            </button>
            </div>
          {isLoading ? (
            <CircularLoader size={40} />
          ) : (
          <DataTableExtensions 
          {...tableData} 
          filterPlaceholder="Search Currency"
          customStyles={customStyles}
          >
            <DataTable
              columns={columns}
              data={data}
              noHeader
              defaultSortField="id"
              sortIcon={<SortIcon />}
              defaultSortAsc={true}
              pagination
              highlightOnHover
              dense
              customStyles={customStyles}
            />
          </DataTableExtensions>
           )}
        </div>
        {/* {console.log(isCurrency,"isc333333")} */}
        <div
          className={`modal fade ${isCurrency ? "show" : ""}`}
          id="createCurrencyModal" // Match this with data-bs-target
          tabIndex="-1"
          ref={createCurrencyModalRef}
        >
          <div class="modal-dialog">
            <div class="modal-content" style={{width:"100%"}}>
              <div class="modal-header">
                <h5 class="modal-title">
                  {" "}
                  {isUpdate && currencyId != "" ? "Edit" : "Create New"}{" "}
                  Currency 
                </h5>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={(e) => handleClearState(e)}
                ></button>
              </div>
              <div className="modal-body" id="Create_newCurrency_Container">
                <div className="container">
                  <div className="row" id="create_new_currency_form">
                    <div className="col-md-6" id="create_new_currency_Inputfield">
                      <div className="mb-2">
                        {/* Name */}
                        <input
                          type="text"
                          placeholder="Name"
                          value={currencyName}
                          className="form-control"
                          onChange={(e) => setCurrencyName(e.target.value)}
                        />
                      </div>
                    </div>

                    <div className="col-md-6" id="create_new_currency_Inputfield">
                      <div className="mb-2">
                        {/* Symbol */}
                        <input
                          type="text"
                          placeholder="Symbol"
                          className="form-control"
                          value={symbol}
                          onChange={(e) => setSymbol(e.target.value)}
                        />
                      </div>
                    </div>

                    <div className="col-md-6" id="create_new_currency_Inputfield">
                      <div className="mb-2">
                        <input
                          type="text"
                          placeholder="shortform"
                          className="form-control"
                          value={shortform}
                          onChange={(e) => setShortform(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-md-6" id="create_new_currency_Inputfield">
                      <div className="mb-2">
                        <input
                          type="text"
                          placeholder="mobile_code"
                          className="form-control"
                          value={mobilecode}
                          onChange={(e) => setMobileCode(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-md-6" id="create_new_currency_Inputfield">
                      <div className="mb-2">
                        <input
                          type="text"
                          placeholder="country_iso"
                          className="form-control"
                          value={countryiso}
                          onChange={(e) => setCountryIso(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="modal-body" id="Create_newCurrency_Container">
                <div className="container">
                  <div className="row" id="create_new_currency_form">
                    <div className="col-md-6" id="create_new_currency_Inputfield">
                      <div className="mb-2">
                        {/* Current value  */}
                        <input
                          type="number"
                          placeholder="Current value "
                          className="form-control"
                          value={currencyValue}
                          onChange={(e) => setCurrencyValue(e.target.value)}
                        />
                        <div class="currency_admin_rupees_para"> 1 indian rupee = current value </div>
                      </div>
                    </div>
                    {/* </div>

    <div className="row"> */}
                    <div className="col-md-6">
                      <div className="mb-2" id="Currency_Activate_Name">
                        Activate
                        <div id="Currency_Radio_wrapper">
                          <label>
                            <input
                              type="radio"
                              checked={activate === true}
                              onChange={() => setActivate(true)}
                            />{" "}
                            Yes
                          </label>
                          <label id="Currency_radio_No">
                            <input
                              type="radio"
                              checked={activate === false}
                              onChange={() => setActivate(false)}
                            />{" "}
                            No
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="modal-footer" id="Updatee_config_Button_Wrapper">
                <button
                id="Update_config_Button"
                  type="button"
                  class="btn btn-primary"
                  onClick={(e) => handleSubmit(e)}
                >
                  {isStatus ? (
                    <CircularLoader size={20} />
                  ) : isUpdate && currencyId != "" ? (
                    "Update"
                  ) : (
                    "Create"
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </article>
    </>
  );
}

export default Currency_admin;
