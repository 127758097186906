import React from "react";

function SubSocialMedia(props) {
  const rs = props.rs;
  let socialMediaData = [];
  // console.log(rs, "h123");
  const getSocialIcon = (social) => {
    // val?.nameOfSocial.toLowerCase() == "gmail"
    //               ? "google"
    //               : val?.nameOfSocial.toLowerCase()
    if (social == "gmail") {
      return "google";
    } else if (social == "facebook") {
      return "facebook-f";
    } else if (social == "twitter") {
      return "twitter";
    } else {
      return social;
    }
  };
  const getLink = (social, link) => {
    if (social == "whatsapp") {
      return `https://wa.me/${link}`;
    } else if (social == "gmail") {
      return `mailto:${link}`;
} else if (social == "telegram") {
      return `https://t.me/${link}`;
    } else if (social == "pinterest") {
      return `https://in.pinterest.com/${link}`;
    } else {
      if (!link.startsWith("http://") && !link.startsWith("https://")) {
          link = "https://" + link;
      }
      link = link.replace("www.", "");
      return link;
    }
  };
  try {
    socialMediaData = JSON.parse(rs.SocialMediaObj.content);
    if (socialMediaData.length) {
      socialMediaData = JSON.parse(socialMediaData);
    }
    // console.log(socialMediaData, "socialMediaData");
  } catch (error) {
    console.error("Error parsing socialMediaData:", error);
  }

  return (
    <article className="social-links-sec sec">
      <h3 className="sectitle social">Social Media</h3>
     
           
      
       <ul className="socil-link">
  {socialMediaData.map((val, index) => {
    const socialIcon = getSocialIcon(val?.nameOfSocial?.toLowerCase());
    const link = getLink(val?.nameOfSocial?.toLowerCase(), val?.link);

    if (val?.nameOfSocial && val?.link) {
      return (
        <div className="social-link-container" key={index}>
          <li>
            <a href={link} target="_blank">
              <i class={`fa-brands fa-${socialIcon}`}></i>
            </a>
            {/* <div className="social-name">{val?.nameOfSocial.toLowerCase()}</div> */}
          </li>
        </div>
      );
    }

    return null; 
  })}
</ul>
    </article>
  );
}

export default SubSocialMedia;
