import React, { useContext, useEffect } from "react";
import PageName from "../../Utils/PageName";
import "./Card.css";
import ViewCard from "./ViewCard";
import { useState } from "react";
import ThumbnailCard from "./ThumbnailCard";
import axios from "axios";
import { environmentVariables } from "../../../config/env.config";
import { userContext } from "../../../context/userContext";
import { Link, useNavigate } from "react-router-dom";
import CircularLoader from "../../Components/CircularLoader/CircularLoader";
import { businessContext } from "../../../context/businessContext";
import { Box, Modal, Typography, colors } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../Pages/OrderNowUpdatedStyle.css";

const CreateCard = () => {
  const [activeTab, setActiveTab] = useState(null);
  const [physicalCards, setPhysicalCards] = useState(null);
  const [physicalCardsOnPage, setPhysicalCardsOnPage] = useState(null);
  const [physicalCardLoading, setPhysicalCardLoading] = useState(false);
  const [businessArr, setBusinessArr] = useState([]);
  const [businessNameObj, setBusinessNameObj] = useState({});
  const [activeBusinessId, setActiveBusinessId] = useState("");

  const { businessActive, setBusinessActice } = useContext(businessContext);
  const [noBusinessAvilable, setNoBusinessAvilable] = useState();
  const navigation = useNavigate();
  const navigate = useNavigate();
  const [activeMsg, setActiveMsg] = useState(false);
  const [activeCardData, setActiveCardData] = useState(null);
  // console.log(activeCardData,"activeCardDatasssss")
  const [pageNo, setPageNo] = useState(1);
  const [popUp, setPopUp] = useState(false);
  const [totalPages, setTotalPages] = useState(null);
  const { userData } = useContext(userContext);
  // console.log(userData.id,"KKKKK>>>>>>><<<<<")

  const [isLoadingPhyCards, setIsLoadingPhyCards] = useState(false);
  const pageNumbers = Array.from({ length: totalPages }, (_, i) => i + 1);
  const handleClose = () => setPopUp(false);
  let get = localStorage.getItem("PhysicalCardBusinessObj");
  const [selectedImage, setSelectedImage] = useState(null);
  const [logoUrl, setLogoUrl] = useState(null);
  
  const imageUrl = logoUrl;
  

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 420,
    height: 200,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    borderRadius: "10px",
    border: "none",
    outline: "none",
  };

  useEffect(() => {
    if (physicalCards && physicalCards.length > 0) {
      setPhysicalCardsOnPage(physicalCards.slice(pageNo * 4 - 4, pageNo * 4));
    }
  }, [pageNo, totalPages, physicalCards]);

  const handleOrderNowClick = () => {
   

    if (businessArr.length === 0) {
      setPopUp(true);
      setActiveMsg(true);
    } else {
      if (businessNameObj && businessNameObj?.sub_title) {
        localStorage.setItem(
          "PhysicalCardBusinessObj",
          JSON.stringify(businessNameObj)
        );
        navigation(`/ordernow?card=${activeTab}&title=${businessNameObj?.sub_title}&desig=${businessNameObj?.designation}&businessId=${activeBusinessId}&logoimg=${imageUrl}&frontimg=${activeCardData?.front_image}&cardname=${activeCardData?.card_name}&cardid=${activeCardData?.id}&islogo=${activeCardData?.is_logo}`);
      } else {
        toast.warning("Choose any business", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      }
    }
  };


  useEffect(() => {
    if (get && get.length) {
      get = JSON.parse(get);
      setTimeout(() => {
        setBusinessNameObj(get);
        localStorage.removeItem("PhysicalCardBusinessObj");
      })
    }
  }, [get]);
  useEffect(() => {
    setIsLoadingPhyCards(true);
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${environmentVariables?.apiUrl}api/v1/phy_card/getPhysicalCard`,
      headers: {
        _token: userData?.token,
      },
    };
    axios
      .request(config)
      .then((response) => {
        setPhysicalCards(response?.data?.data);
        setTotalPages(Math.ceil(response?.data?.data.length / 4));
        setIsLoadingPhyCards(false);
      })
      .catch((error) => {
        setIsLoadingPhyCards(false);
      });
  }, []);

  const fetchUserBusiness = () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${environmentVariables?.apiUrl}api/v1/business/fetchbusinessNameAndDesignation`,
      headers: {
        _token: userData?.token,
      },
    };

    axios
      .request(config)
      .then((response) => {
        setBusinessArr(response?.data?.data);
        setActiveBusinessId(response?.data?.data[0]?.id);
        if (get && get.length) {
          localStorage.removeItem("PhysicalCardBusinessObj")
        } else {
          console.error("get first time")
        }
        if (response?.data?.data?.length == 0) {
          setPopUp(true);
          setActiveMsg(true);
        }
      })
      .catch((error) => {
        setIsLoadingPhyCards(false);
      });
  };
  useEffect(() => {
    fetchUserBusiness();
  }, []);
  const handleChangeBusinessData = (e) => {
    let findObj = businessArr.find((el) => el.title == e?.target?.value);
    setActiveBusinessId(findObj?.id);
    setBusinessNameObj(findObj);
  };
  // console.log(selectedImage,"selectedImage@@@###@@@###@")
  const handleFileChange1 = (event) => {
    const file = event.target.files[0];
    setSelectedImage(file);
  };




  const fetchLogoUrl = async (cardId, userId, businessId) => {
    try {
      const response = await axios.get(`${environmentVariables.apiUrl}api/v1/phy_card/getLogoUrl`, {
        params: {
          card_id: cardId,
          user_id: userId,
          business_id: businessId,
        },
      });
  
      const fetchedLogoUrl = response.data.logoUrl;
      return fetchedLogoUrl;
    } catch (error) {
      console.error(error);
      throw error; // You might want to handle this error accordingly
    }
  };
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const fetchedLogoUrl = await fetchLogoUrl(activeTab, userData.id, activeBusinessId);
        setLogoUrl(fetchedLogoUrl);
        // console.log(fetchedLogoUrl, "fetchedLogoUrl");
      } catch (error) {
        console.error(error);
      }
    };
  
    fetchData();
  }, [activeTab, userData.id, activeBusinessId]);
  
  
  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    setSelectedImage(file);
  
    const formData = new FormData();
    formData.append('logo_image_phy', file);
    formData.append('card_id', activeTab);
    formData.append('user_id', userData.id);
    formData.append('business_id', activeBusinessId);
  
    try {
      const response = await axios.post(`${environmentVariables?.apiUrl}api/v1/phy_card/logoUpload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
  
      const fetchedLogoUrl = await fetchLogoUrl(activeTab, userData.id, activeBusinessId);
        setLogoUrl(fetchedLogoUrl);
  
      console.log(response.data);
    } catch (error) {
      console.error(error);
    }
  };
  
  // console.log(logoUrl, "logoUrl");
  // console.log(selectedImage, "selectedImage");

  return (
    <>
      <PageName PageName="Physical Card" />
      {popUp && (
        <>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <div
                style={{
                  fontSize: "17px",
                  display: "flex",
                  justifyContent: "center",
                  textAlign: "center",
                  alignItems: "center",
                  marginBottom: "30px",
                  outline: "none",
                  border: "none",
                }}
                id="modal-modal-title"
                variant="h6"
                component="h2"
              >
                <div
                  style={{
                    position: "absolute",
                    top: "2px",
                    right: "5px",
                    cursor: "pointer",
                  }}
                  onClick={() => setPopUp(false)}
                >
                  <CloseIcon />
                </div>
                No business found, Please create your business to order physical
                card.
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <button
                  style={{
                    display: "block",
                    width: "100%",
                    textAlign: "center",
                    color: "rgb(255, 255, 255)",
                    marginBottom: "13px",
                    padding: "15px 40px",
                    fontWeight: "bold",
                    fontSize: "16px",
                    borderRadius: "50px",
                    background:
                      "linear-gradient(to right, rgb(1, 195, 204), rgb(125, 42, 232))",
                    border: "0px",
                    boxShadow: "rgba(191, 173, 223, 0.61) 0px 8px 24px",
                  }}
                  onClick={() => navigation("/business?create=1")}
                >
                  Create Business
                </button>
              </div>
            </Box>
          </Modal>
        </>
      )}
      <div>
        <div class="ordernow_physicalcard_wrapper" id="OrderNowPhysicalcardwrapper">
          <div className="selectfield_wrapper">
            <select
              value={businessNameObj?.title || ""}
              onChange={(e) => handleChangeBusinessData(e)}
              class="form-select"
              style={{ minWidth: "215px" }}
            >
              <option value="select business">Select Business </option>
              {businessArr &&
                businessArr?.map((val) => (
                  <option value={val?.title}>{val?.title} </option>
                ))}
            </select>
            <div
              style={{
                fontSize: "14px",
                color: "red",
                bottom: "70px",
              }}
            ></div>
            <div>
              <marquee style={{ color: "red", fontSize: "14px" }}>
                {activeMsg && <>you dont have any business*</>}
              </marquee>
            </div>
            
          </div>

          <div className="Physicalcardchooselogo">
      {activeCardData?.is_logo === 1 ? (
        <>
          <input
            className="form-control"
            type="file"
            name="banner"
            onChange={handleFileChange}
          />
        </>
      ) : null}
          </div>

          <div className="PhysicalcardOrdernowWrapper">
            <button
              type="button"
              class="PhysicalcardOrdernow"
              onClick={handleOrderNowClick}
            >
              Order Now
            </button>
          </div>
        </div>
      </div>
      {activeCardData && (
        <ViewCard
          physicalCardLoading={physicalCardLoading}
          setPhysicalCardLoading={setPhysicalCardLoading}
          card_id={activeTab}
          activeCardData={activeCardData}
          name={businessNameObj?.sub_title || "Your Name"}
          degination={businessNameObj?.designation || "Your Designation"}
          weburl="www.xyz.com"
          logoimg={imageUrl}
          islogo={activeCardData?.is_logo}
          logo="https://vmycards.com/storage/card_logo/logo_16969236651843057845.png"
          slug= {businessNameObj?.slug}
        />
        
      )}
      
      {isLoadingPhyCards ? (
        <div
          style={{
            position: "absolute",
            top: "50px",
            left: "55px",
            display: "flex",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <CircularLoader size={40} />
        </div>
      ) : (
        <>
          {physicalCardsOnPage && (
            <div className="row mt-5 ThumbViewMarginIssu">
              <ThumbnailCard
                physicalCardLoading={physicalCardLoading}
                setPhysicalCardLoading={setPhysicalCardLoading}
                pageNo={pageNo}
                physicalCards={physicalCards}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                setActiveCardData={setActiveCardData}
              />
            </div>
          )}
        </>
      )}
    </>
  );
};
export default CreateCard;
//412 10:40
