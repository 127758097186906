import React, { useContext, useEffect, useState, useRef } from "react";
import PageName from "../Utils/PageName.js";
import { Link } from "react-router-dom";
import { environmentVariables } from "../../config/env.config.js";
import axios from "axios";
import Swal from "sweetalert2";
import CircularLoader from "../Components/CircularLoader/CircularLoader.js";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import { currencyContext } from "../../context/currencyContext.js";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import styled from "styled-components";
import { userContext } from "../../context/userContext";
import "./../Pages/Updatedcss/UpdatedPlanStyle.css";
import PhoneInput from "react-phone-input-2";

function Plans() {
  const [dataArr, setDataArr] = useState([]);
  const [contactSalesPlanType, setContactSalesPlanType] = useState("");
  const [dataArrYear, setDataArrYear] = useState([]);
  const [dataArrMonth, setDataArrMonth] = useState([]);
  const [dataArrLifetime, setDataArrLifetime] = useState([]);
  const { userData, setUserData } = useContext(userContext);
  const [activeUserPlan, setActiveUserPlan] = useState({});
  const [loading, setLoading] = useState(true);
  const baseUrl = environmentVariables?.apiUrl;
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const currentDate = new Date();
  const formattedDate = currentDate.toISOString();
  const [price, setPrice] = useState("");
  const selectRef = useRef(null);
  const [maxUsers, setMaxUsers] = useState("");
  const [maxBusiness, setMaxBusiness] = useState("");
  const [numberOfpvc, setnumberOfpvc] = useState("");
  const [numberOfMetal, setnumberOfMetal] = useState("");
  const [storageLimit, setStorageLimit] = useState("");
  const [enableDomain, setEnableDomain] = useState(false);
  const [enableSubDomain, setenableSubDomain] = useState(false);
  const [enableBranding, setenableBranding] = useState(false);
  const [progressiveWebApp, setprogressiveWebApp] = useState(false);
  const [enableQRCode, setenableQRCode] = useState(false);
  const [enableChatgpt, setenableChatgpt] = useState(false);
  const [enablePVCCard, setenablePVCCard] = useState(false);
  const [enableMetalCard, setenableMetalCard] = useState(false);
  const [textareaValue, setTextareaValue] = useState("");
  const user_type = userData?.user_type;
  const [paymentMode, setPaymentMode] = useState("-1");
  const [isPaymentBtnClicked, setIsPaymentBtnClicked] = useState(false);
  const navigate = useNavigate();
  const { currencyObjData, setCurrencyObjData } = useContext(currencyContext);
  const [countryCode, setCountryCode] = useState(userData.country_code);
  const [currencyCode, setCurrencyCode] = useState();
  console.log(currencyCode,currencyObjData?.name,"cur44444444444currencyCode")

  useEffect(()=>{
    setCurrencyCode(currencyObjData?.name)
    console.log(currencyCode,currencyObjData?.name,"currencyC2222odecurrencyCode")
  },[])


  const CenteredTabList = styled.div`
    display: flex;
    justify-content: center;
  `;
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const selectedValue = selectRef.current.value;
      const response = await axios.post(
        `${baseUrl}api/v1/plan/createPlan`,
        {
          name,
          price,
          duration: selectedValue,
          max_users: maxUsers,
          business: maxBusiness,
          storage_limit: storageLimit,
          enable_custdomain: enableDomain ? "on" : "off",
          enable_custsubdomain: enableSubDomain ? "on" : "off",
          enable_branding: enableBranding ? "on" : "off",
          pwa_business: progressiveWebApp ? "on" : "off",
          enable_qr_code: enableQRCode ? "on" : "off",
          enable_chatgpt: enableChatgpt ? "on" : "off",
          enable_physical_pvc: enablePVCCard ? "on" : "off",
          enable_physical_metal: enableMetalCard ? "on" : "off",
          description: textareaValue,
          no_of_pvc_card: numberOfpvc,
          no_of_metal_card: numberOfMetal,
          themes: "Theme1, Theme2",
        },
        {
          headers: {
            _token: userData?.token,
          },
          withCredentials: true,
        }
      );
      if (response?.data?.success) {
        Swal.fire("Plan saved");
        setTimeout(() => {
          Swal(null);
        }, 3000);

        window.location.reload();
      }
    } catch (error) {
      Swal.fire(error?.response?.data?.message);
      console.error("Error:", error?.response?.data?.message);
    }
  };

  const [data, setData] = useState({});
  const [editedData, setEditedData] = useState({});
  const [planId, setPlanId] = useState("");
  const [tempKeyForLoader, setTempKeyForLoader] = useState("");
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [firstName, setFirstName] = useState("");
  const [contactEmail, setContactEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [contactLastName, setContactLastName] = useState("");
  const [message, setMessage] = useState("");
  const [isSubmitContactSales, setIsSubmitContactSales] = useState(false);
  const handleSubmitContactSales = () => {
    if (firstName == "") {
      toast.error("Firstname is required!");
      return;
    } else if (contactLastName == "") {
      toast.error("Lastname is required!");
      return;
    } else if (contactEmail == "") {
      toast.error("Email is required!");
      return;
    } else if (mobile == "") {
      toast.error("Mobile Number is required!");
      return;
    }
    setIsSubmitContactSales(true);
    let data = {
      firstname: firstName,
      lastname: contactLastName,
      email: contactEmail,
      mobile: mobile,
      message: message,
      plantype: contactSalesPlanType,
    };
    let config = {
      method: "post",
      url: `${baseUrl}api/v1/user/UserEnterprise`,
      headers: {
        _token: userData?.token,
        "Content-Type": "application/json",
      },
      withCredentials: true,
      data: data,
    };
    axios
      .request(config)
      .then((response) => {
        toast.success(
          "Thanks for contacting, someone from our team will get back to you soon!"
        );
        setFirstName('')
        setContactEmail('')
        setMobile("")
        setContactLastName('')
        setMessage('')
        setIsSubmitContactSales(false);
      })
      
      .catch((err) => {
        toast.error(err?.response?.data?.message || err?.message);
    setIsSubmitContactSales(false);

      });
  };
  const handleTabSelect = (index) => {
    setSelectedTabIndex(index);
  };

  const fetchData = async (id) => {
    try {
      setPlanId(id);
      axios
        .get(`${baseUrl}api/v1/plan/fetchPlanById`, {
          params: { planId: id },
          headers: {
            _token: userData?.token,
          },
          withCredentials: true,
        })
        .then((response) => {
          setData(response.data);
          setEditedData(response?.data?.result);
        })
        .catch((error) => {
          console.error("Error:", error?.response?.data?.message);
        });
    } catch (error) {
      console.error("API Error:", error?.response?.data?.message);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedData({ ...editedData, [name]: value });
  };
  useEffect(() => {}, [editedData]);

  const handleSave = async () => {
    try {
      let data = JSON.stringify(editedData);

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${baseUrl}api/v1/plan/editPlan?planId=${planId}`,
        headers: {
          _token: userData?.token,
          "Content-Type": "application/json",
        },
        withCredentials: true,
        data: data,
      };

      axios
        .request(config)
        .then((response) => {})
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.error("API Error:", error);
    }
  };

  const fetchPlanData = async () => {
    const encodedCountryCode = encodeURIComponent(userData.country_code);
    let apiEnd = "";
    if (user_type === "super_admin") {
      apiEnd = "/fatchPlans";
    } else {
      apiEnd = `/fatchPlansUsers_withcountry?country=${encodedCountryCode}`;
    }

    try {
      setLoading(true);
     await axios
        .get(`${baseUrl}api/v1/plan${apiEnd}`, {
          headers: {
            _token: userData?.token,
          },
        })
        .then((response) => {
        
          const filteredDataYear =
            response.data?.result.filter(
              (item) => item.duration === "Per Year" 
            ) || [];
          const filteredDataMonth =
            response.data?.result.filter(
              (item) => item.duration === "Per Month"
            ) || [];
            const filteredDataLifetime =
            response.data?.result.filter(
              (item) => item.duration === "Lifetime"
            ) || [];
          setDataArr(response.data?.result);
          setDataArrYear(filteredDataYear);
          setDataArrMonth(filteredDataMonth);
          setDataArrLifetime(filteredDataLifetime);
          setLoading(false);
        })     
    } catch (error) {
      console.error("API Error:", error?.response?.data?.message);
      setLoading(false);
    }
  };

  const fetchSpecificUserActivePlan = () => {
    let apiEnd = "";
    // console.log("userrrrrrrrrrrrr");

    try {
      axios
        .get(`${baseUrl}api/v1/plan/fetchSpecificUserActivePlan`, {
          headers: {
            _token: userData?.token,
          },
        })
        .then((response) => {
          // console.log(response?.data?.findPlanArr[0], "ArrMonth responseresponse user");
          if (response?.data?.findPlanArr && response?.data?.findPlanArr[0]) {
            setUserData({
              ...userData,
              plan: response?.data?.findPlanArr[0]?.plan_id,
              findPlanArr: [
                {
                  buy_date: response?.data?.findPlanArr[0]?.buy_date,
                  expiry_date: response?.data?.findPlanArr[0]?.expiry_date,
                  plan_id: response?.data?.findPlanArr[0]?.plan_id,
                  start_date: response?.data?.findPlanArr[0]?.start_date,
                  user_id: response?.data?.findPlanArr[0]?.user_id,
                  price: response?.data?.findPlanArr[0]?.price,
                },
              ],
            });
          }
        })
        .catch((error) => {
          console.error("Error:", error?.response?.data?.message);
        });
    } catch (error) {
      console.error("API Error:", error?.response?.data?.message);
    }
  };
  const fetchActivePlan = () => {
    // dataArrYear
    // dataArrMonth
    // userData
    // activeUserPlan,
    if (dataArrYear?.length && dataArrMonth?.length) {
      let activePlan = dataArrMonth?.find(
        (el) => el?.id == userData?.findPlanArr[0]?.plan_id
      );
      if (activePlan && activePlan?.id) {
        setActiveUserPlan(activePlan);
      } else {
        let activePlan = dataArrMonth?.find(
          (el) => el?.id == userData?.findPlanArr[0]?.plan_id
        );
        setActiveUserPlan(activePlan);
      }
    }
  };
  useEffect(() => {
    fetchPlanData();
    fetchSpecificUserActivePlan();
  }, []);
  useEffect(() => {
    fetchActivePlan();
  }, [dataArrYear, dataArrMonth]);

  const handleMonthlySubscribe = async (planId, planPrice) => {
    try {
      setTempKeyForLoader(planId);
      setIsPaymentBtnClicked(true);
      let formDataObject = {
        plan_id: planId,
        currency: currencyObjData?.shortform,
      };
      const config = {
        headers: {
          _token: userData?.token,
          "Content-Type": "application/json",
        },
      };

      const response = await axios.post(
        `${baseUrl}api/v1/pg/StripeCreateCheckoutSession`,
        formDataObject,
        config
      );
      if (response.data.status === "success") {
        const checkoutUrl = response.data.data.url;
        // console.log(checkoutUrl, "checkoutUrl!@!@@#");
        window.location.href = checkoutUrl;
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleProceedNext = (planId, planPrice) => {
    let cardAmountAfterDiscount = planPrice;
    setIsPaymentBtnClicked(true);
    setTempKeyForLoader(planId);
    const currencySymbol = currencyObjData?.shortform;
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${environmentVariables?.apiUrl}api/v1/user/create-payment-intent-ForPlan`,
      data: {
        amount: parseInt(
          cardAmountAfterDiscount
        ),
        currency: currencySymbol,
      },
    };

    axios
      .request(config)
      .then((response) => {
        if (response?.data?.clientSecret) {
          navigate("/payment-plan", {
            state: {
              data: response?.data?.clientSecret,
              amount: cardAmountAfterDiscount,
              // cardId: cardId,
              planId: planId,
            },
          });
          setIsPaymentBtnClicked(false);
          setTempKeyForLoader("");
        }
      })
      .catch((err) => {
        setIsPaymentBtnClicked(false);
        setTempKeyForLoader("");
        console.log(err, "Erroroorro");
        navigate("/plans");
      });
  };

  const handleProceedNextDPO =async(planId, planPrice) => {
    try{
    // console.log(planId,planPrice,currencyObjData?.name,"handleProcee222222222dNextDPO")
    // return
    let cardAmountAfterDiscount = planPrice;
    setIsPaymentBtnClicked(true);
    setTempKeyForLoader(planId);
    const currencySymbol = currencyObjData?.shortform;
    const response = await fetch(`${environmentVariables?.apiUrl}api/v1/payment/getTransTokenPlan`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        // Provide any necessary data for the transaction
        amount: planPrice,
        currency: currencyObjData?.name,
        planId:planId
      }),
    });

    const data = await response.json();

    // Handle the response from the backend
    if (response.ok) {
      // Payment initiated successfully
      console.log('Payment initiated:', data);
      if (data.success && data.result && data.result.API3G && data.result.API3G.TransToken && data.result.API3G.TransRef) {
        // Redirect to the payment URL with the received token
        window.location.href = `https://secure.3gdirectpay.com/payv3.php?ID=${data.result.API3G.TransToken._text}`;
      setIsPaymentBtnClicked(false);
      } else {
        console.error('Missing data for redirect');
      setIsPaymentBtnClicked(false);
      }
    } else {
      // Payment initiation failed
      console.error('Error initiating payment:', data.message);
      setIsPaymentBtnClicked(false);
    }
  } catch (error) {
    console.error('Error initiating payment:', error);
    setIsPaymentBtnClicked(false);
  }
}

    


  const handleContactSales = (planType) => {
    if (window !== undefined) {
      window.scrollTo({
        top: document.body.scrollHeight,
        behavior: "smooth", // You can use 'auto' or 'smooth' for smooth scrolling
      });
    }
    setContactSalesPlanType(planType);
  };

  const [planObj, setPlanObj] = useState({});
  const getPlanName = () => {
    axios
      .get(`${baseUrl}api/v1/plan/fetchPlanByIdForUser`, {
        params: { planId: userData?.plan },
        headers: {
          _token: userData?.token,
        },
        // withCredentials: true,
      })
      .then((res) => {
        console.log(res?.data?.result, "planNamep");
        setPlanObj(res?.data?.result);
      })
      .catch((err) => {
        console.log({ err: err });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // useEffect(() => {
  //   console.log(userData, "userData@ ArrMonth #@$#");
  // }, [userData]);
  useEffect(() => {
    getPlanName();
  }, [userData]);

  return (
    <>
      <PageName PageName="Plans" />
      {loading ? (
        <CircularLoader size={40} />
      ) : (
        <>
      <CenteredTabList>
        <Tabs selectedIndex={selectedTabIndex} onSelect={handleTabSelect}>
          

          <TabPanel>
            <>
              <section id="price" className="ptable">
                <div className="demo">
                  <div className="container">
                    <div className="col-12 col-lg-12">
                      <div className="tile-a">
                        <div className="tile-title-w-btn mb-0">
                          {user_type === "super_admin" && (
                            <p>
                              <button
                                className="btn btn-primary icon-btn"
                                data-bs-toggle="modal"
                                data-bs-target="#planselect"
                              >
                                Add Item{" "}
                              </button>
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row Mobileplangrid">
                      {/* Monthly */}

                      {dataArrMonth?.map((item, index) => (
                        <div className="col-12 col-md-4 col-sm-6  plan_Wrapper">
                          <div
                            className="pricingTable green planpadissueres"
                            id="planwrapper_height"
                          >
                            <div className="pricingTable-header">
                              <h3 className="title">
                                {dataArrMonth[index]?.name}
                              </h3>
                            </div>
                            {dataArrMonth[index]?.name != "Free Plan" ? (
                              <div className={"price-value"}>
                                {/* {`${currencyObjData?.symbol}
                                ${(
                                  dataArrMonth[index]?.price *
                                  currencyObjData?.current_value
                                ).toFixed(2)}`} */}
                                {`${currencyObjData?.symbol}
                                ${( dataArrMonth[index]?.price).toFixed(2)}`}
                              </div>
                            ) : (
                              <div className="price-value">
                                {currencyObjData?.symbol} {(0).toFixed(2)}
                              </div>
                            )}

                            {planObj?.is_enterprise == true ? (
                              <></>
                            ) : (
                              <span className="L729">
                                {userData?.findPlanArr.length > 0 ? (
                                  <>
                                    {userData?.findPlanArr.length > 0 &&
                                      userData?.findPlanArr?.some(
                                        (el) => el?.plan_id == item?.id
                                      ) && (
                                        <div className="subscribed">
                                          Subscribed
                                        </div>
                                      )}

                                    {userData?.findPlanArr?.some(
                                      (el) => el?.plan_id == item?.id
                                    ) && (
                                      <div className="desline">
                                        Plan expires at :
                                        {userData?.findPlanArr
                                          ? (() => {
                                              const filteredPlans =
                                                userData?.findPlanArr?.filter(
                                                  (el) =>
                                                    el?.plan_id === item?.id
                                                );
                                              const foundPlan =
                                                filteredPlans?.length > 0
                                                  ? filteredPlans[0]
                                                  : null;
                                              return foundPlan
                                                ? new Date(
                                                    foundPlan?.expiry_date
                                                  )?.toDateString()
                                                : "N/A";
                                            })()
                                          : "N/A"}
                                      </div>
                                    )}

                                    {activeUserPlan?.duration === "Per Month" &&
                                    item?.price >
                                      userData?.findPlanArr[0]?.price &&
                                    ((activeUserPlan?.name === "Free Plan" &&
                                      isPaymentBtnClicked &&
                                      tempKeyForLoader ===
                                        dataArrMonth[index]?.id) ||
                                      (activeUserPlan?.name !== "Free Plan" &&
                                        isPaymentBtnClicked &&
                                        tempKeyForLoader ===
                                          dataArrMonth[index]?.id)) ? (
                                      <CircularLoader size={25} />
                                    ) : (activeUserPlan?.name === "Free Plan" &&
                                        item?.price >
                                          userData?.findPlanArr[0]?.price) ||
                                      (activeUserPlan?.name !== "Free Plan" &&
                                        activeUserPlan?.duration ==
                                          "Per Month" &&
                                        item?.price >
                                          userData?.findPlanArr[0]?.price) ? (
                                            <>
                                      <button
                                        className="upgrade_plan"
                                        onClick={() => {
                                          if (item?.name == "Enterprise Plan") {
                                            handleContactSales("month");
                                          } else {
                                            handleProceedNext(
                                              dataArrMonth[index]?.id,
                                              dataArrMonth[index]?.price
                                            );
                                          }
                                        }}
                                      >
                                        Upgrade
                                      </button>
                                      <button
                                            className="upgrade_plan"
                                            onClick={() => {
                                              if (
                                                item?.name == "Enterprise Plan"
                                              ) {
                                                handleContactSales("month");
                                              } else {
                                                handleProceedNextDPO(
                                                  dataArrYear[index]?.id,
                                                  dataArrYear[index]?.price
                                                );
                                              }
                                            }}
                                          >
                                            {"DPO5"}
                                          </button>

                                      </>
                                    ) : null}
                                  </>
                                ) : (
                                  item.name != "Free Plan" &&
                                  (isPaymentBtnClicked &&
                                  tempKeyForLoader ==
                                    dataArrMonth[index]?.id ? (
                                    <CircularLoader size={25} />
                                  ) : (
                                    <>
                                      <button
                                        className="upgrade_plan"
                                        onClick={() => {
                                          if (item?.name == "Enterprise Plan") {
                                            handleContactSales("month");
                                          } else {
                                            handleProceedNext(
                                              dataArrMonth[index]?.id,
                                              dataArrMonth[index]?.price
                                            );
                                          }
                                        }}
                                      >
                                        Upgrade
                                      </button>
                                      <button
                                            className="upgrade_plan"
                                            onClick={() => {
                                              if (
                                                item?.name == "Enterprise Plan"
                                              ) {
                                                handleContactSales("month");
                                              } else {
                                                handleProceedNextDPO(
                                                  dataArrYear[index]?.id,
                                                  dataArrYear[index]?.price
                                                );
                                              }
                                            }}
                                          >
                                            {"DPO1"}
                                          </button>
                                    </>
                                  ))
                                )}
                              </span>
                            )}

                            <ul className="pricing-content planmarissueres">
                              <li className="on">
                                {dataArrMonth[index]?.themes}
                                Theme
                              </li>
                              <li className="on">
                                {`${dataArrMonth[index]?.business} Business`}
                              </li>
                              <li className="on">
                                {`${dataArrMonth[index]?.appoinments} Appoitments`}
                              </li>
                              <li className="on">
                                {`${dataArrMonth[index]?.contacts} Contact`}
                              </li>
                              <li className="on">
                                {dataArrMonth[index]?.name == "Enterprise Plan"}
                              </li>
                              <li
                                className={
                                  dataArrMonth[index]?.enable_custdomain ===
                                  "on"
                                    ? "on d-none"
                                    : "disable d-none"
                                }
                              >
                                Custom Domain
                              </li>

                              <li
                                className={
                                  dataArrMonth[index]?.enable_custsubdomain ===
                                  "on"
                                    ? "on d-none"
                                    : "disable d-none"
                                }
                              >
                                {" "}
                                Sub Domain
                              </li>

                              <li
                                className={
                                  dataArrMonth[index]?.enable_qr_code === "on"
                                    ? "on"
                                    : "disable"
                                }
                              >
                                {"QR Code"}
                              </li>

                              <li
                                className={
                                  dataArrMonth[index]?.enable_physical_pvc ===
                                  "on"
                                    ? "on"
                                    : "disable"
                                }
                              >
                                {`${dataArrMonth[index]?.no_of_pvc_card} PVC Card`}
                              </li>

                              <li
                                className={
                                  dataArrMonth[index]?.enable_physical_metal ===
                                  "on"
                                    ? "on"
                                    : "disable"
                                }
                              >
                                {`${dataArrMonth[index]?.no_of_metal_card} Metal
                                Card`}
                              </li>
                              <li>
                                {`${dataArrMonth[index]?.storage_limit} MB Storage
                                Limit`}
                              </li>
                            </ul>
                          </div>
                        </div>
                      ))}

                      {/* Yearly */}

                    {dataArrYear?.map((item, index) => (
                        <div className="col-12 col-md-4 col-sm-6 plan_Wrapper">
                          <div
                            className="pricingTable green"
                            id="planwrapper_height"
                          >
                            <div className="pricingTable-header">
                              <h3 className="title">
                                {dataArrYear[index]?.name}
                              </h3>
                            </div>
                            {/* price Section */}
                            {dataArrYear[index]?.name != "Free Plan" ? (
                              <div className={"price-value"}>
                                {`${currencyObjData?.symbol}
                                ${(
                                  dataArrYear[index]?.price 
                                ).toFixed(2)}`}
                              </div>
                            ) : (
                              <div className="price-value">
                                {currencyObjData?.symbol} {(0).toFixed(2)}
                              </div>
                            )}

                           

                            {planObj?.is_enterprise == true ? (
                              <></>
                            ) : (
                              <span className="L729">
                                {userData?.findPlanArr.length > 0 ? (
                                  <>
                                    {userData?.findPlanArr.length > 0 &&
                                      userData?.findPlanArr?.some(
                                        (el) => el?.plan_id === item?.id
                                      ) && (
                                        <div className="subscribed">
                                          Subscribed
                                        </div>
                                      )}

                                    {/* {item?.id == userData?.plan && ( */}
                                    {userData?.findPlanArr?.some(
                                      (el) => el?.plan_id == item?.id
                                    ) && (
                                      <div className="desline">
                                        Plan expires at :
                                        {userData?.findPlanArr
                                          ? (() => {
                                              const filteredPlans =
                                                userData?.findPlanArr?.filter(
                                                  (el) =>
                                                    el?.plan_id ===
                                                    userData?.plan
                                                );
                                              const foundPlan =
                                                filteredPlans.length > 0
                                                  ? filteredPlans[0]
                                                  : null;
                                              return foundPlan
                                                ? new Date(
                                                    foundPlan?.expiry_date
                                                  )?.toDateString()
                                                : "N/A";
                                            })()
                                          : "N/A"}
                                      </div>
                                    )}
                                    {activeUserPlan?.duration == "Per Month" ? (
                                      isPaymentBtnClicked &&
                                      tempKeyForLoader ==
                                        dataArrYear[index]?.id ? (
                                        <CircularLoader size={25} />
                                      ) : (
                                        item.name != "Free Plan" && (
                                         <>
                                          {countryCode === '+91' && (
                                            <button
                                              className="upgrade_plan"
                                              onClick={() => {
                                                if (item?.name === "Enterprise Plan") {
                                                  handleContactSales("year");
                                                } else {
                                                  handleProceedNextDPO(
                                                    dataArrYear[index]?.id,
                                                    dataArrYear[index]?.price
                                                  );
                                                }
                                              }}
                                            >
                                              Upgrade
                                            </button>
                                          )}

                                          {countryCode !== '+91' && (
                                            <button
                                              className="upgrade_plan"
                                              onClick={() => {
                                                if (item?.name === "Enterprise Plan") {
                                                  handleContactSales("year");
                                                } else {
                                                  handleProceedNextDPO(
                                                    dataArrYear[index]?.id,
                                                    dataArrYear[index]?.price
                                                  );
                                                }
                                              }}
                                            >
                                              Upgrade.
                                            </button>
                                          )}
                                         </>
                                        )
                                      )
                                    ) : (
                                      item?.price >
                                        userData?.findPlanArr[0]?.price &&
                                      (isPaymentBtnClicked &&
                                      tempKeyForLoader ==
                                        dataArrYear[index]?.id ? (
                                        <CircularLoader size={25} />
                                      ) : (
                                        item.name != "Free Plan" && (
                                          <>
                                          {countryCode === '+91' && (
                                            <button
                                              className="upgrade_plan"
                                              onClick={() => {
                                                if (item?.name === "Enterprise Plan") {
                                                  handleContactSales("year");
                                                } else {
                                                  handleProceedNextDPO(
                                                    dataArrYear[index]?.id,
                                                    dataArrYear[index]?.price
                                                  );
                                                }
                                              }}
                                            >
                                              Upgrade
                                            </button>
                                          )}

                                          {countryCode !== '+91' && (
                                            <button
                                              className="upgrade_plan"
                                              onClick={() => {
                                                if (item?.name === "Enterprise Plan") {
                                                  handleContactSales("year");
                                                } else {
                                                  handleProceedNextDPO(
                                                    dataArrYear[index]?.id,
                                                    dataArrYear[index]?.price
                                                  );
                                                }
                                              }}
                                            >
                                              Upgrade.
                                            </button>
                                          )}
                                         </>
                                        )
                                      ))
                                    )}
                                  </>
                                ) : (
                                  item.name != "Free Plan" &&
                                  (isPaymentBtnClicked &&
                                  tempKeyForLoader == dataArrYear[index]?.id ? (
                                    <CircularLoader size={25} />
                                  ) : (
                                    <>
                                    {countryCode === '+91' && (
                                      <button
                                        className="upgrade_plan"
                                        onClick={() => {
                                          if (item?.name === "Enterprise Plan") {
                                            handleContactSales("year");
                                          } else {
                                            handleProceedNextDPO(
                                              dataArrYear[index]?.id,
                                              dataArrYear[index]?.price
                                            );
                                          }
                                        }}
                                      >
                                        Upgrade
                                      </button>
                                    )}

                                    {countryCode !== '+91' && (
                                      <button
                                        className="upgrade_plan"
                                        onClick={() => {
                                          if (item?.name === "Enterprise Plan") {
                                            handleContactSales("year");
                                          } else {
                                            handleProceedNextDPO(
                                              dataArrYear[index]?.id,
                                              dataArrYear[index]?.price
                                            );
                                          }
                                        }}
                                      >
                                        Upgrade.
                                      </button>
                                    )}
                                   </>
                                  ))
                                )}
                              </span>
                            )}

                            <>
                              <ul className="pricing-content planmarissueres">
                                <li className="on">
                                  {dataArrYear[index]?.themes} Theme
                                </li>
                                <li className="on">
                                  {dataArrYear[index]?.business} Business
                                </li>
                                <li className="on">
                                  {dataArrYear[index]?.appoinments} Appoitments
                                </li>
                                <li className="on">
                                  {dataArrYear[index]?.contacts} Contact
                                </li>
                                <li className="on">
                                  {dataArrYear[index]?.max_users} Users
                                </li>
                                <li
                                  className={
                                    dataArrYear[index]?.enable_custdomain ===
                                    "on"
                                      ? "on d-none"
                                      : "disable d-none"
                                  }
                                >
                                  Custom Domain
                                </li>
                                <li
                                  className={
                                    dataArrYear[index]?.enable_custsubdomain ===
                                    "on"
                                      ? "on d-none"
                                      : "disable d-none"
                                  }
                                >
                                  {" "}
                                  Sub Domain
                                </li>

                                
                                <li
                                  className={
                                    dataArrYear[index]?.enable_qr_code === "on"
                                      ? "on"
                                      : "disable"
                                  }
                                >
                                  QR Code
                                </li>
                                
                                <li
                                  className={
                                    dataArrYear[index]?.enable_physical_pvc ===
                                    "on"
                                      ? "on"
                                      : "disable"
                                  }
                                >
                                  {`${dataArrYear[index]?.no_of_pvc_card} PVC Card`}
                                </li>
                                <li
                                  className={
                                    dataArrYear[index]
                                      ?.enable_physical_metal === "on"
                                      ? "on"
                                      : "disable"
                                  }
                                >
                                {`${dataArrYear[index]?.no_of_metal_card} Metal Card`}
                                </li>
                                <li className="">
                                  {dataArrYear[index]?.storage_limit} MB Storage
                                  Limit
                                </li>
                              </ul>
                            </>
                          </div>
                        </div>
                      ))}

                      {planObj?.is_enterprise == true ? (
                        <>
                          {" "}
                          <>
                            {" "}
                            <div className="col-12 col-md-4 col-sm-6  plan_Wrapper">
                              <div
                                className="pricingTable green planpadissueres"
                                id="planwrapper_height"
                              >
                                <div className="pricingTable-header">
                                  <h3 className="title">{planObj?.name}</h3>
                                </div>
                                <span className="L729">
                                  <>
                                    <div className="subscribed">Subscribed</div>

                                    {userData?.findPlanArr?.some(
                                      (el) => el?.plan_id == planObj?.id
                                    ) && (
                                      <div className="desline">
                                        Plan expires at :
                                        {userData.findPlanArr[0]?.expiry_date}
                                      </div>
                                    )}
                                  </>
                                </span>
                                {planObj?.name != "Free Plan" ? (
                                  <div className={"price-value"}>
                                    {`${currencyObjData?.symbol}
                                ${(
                                  planObj?.price 
                                ).toFixed(2)}`}
                                  </div>
                                ) : (
                                  <div className="price-value">
                                    {currencyObjData?.symbol} {(0).toFixed(2)}
                                  </div>
                                )}

                                <>
                                  <ul className="pricing-content planmarissueres">
                                    <li className="on">
                                      {planObj?.themes}
                                      Theme
                                    </li>
                                    <li className="on">
                                      {`${planObj?.business} Business`}
                                    </li>
                                    <li className="on">
                                      {`${planObj?.appoinments} Appoitments`}
                                    </li>
                                    <li className="on">
                                      {`${planObj?.contacts} Contact`}
                                    </li>
                                    <li className="on">
                                      {planObj?.name == "Enterprise Plan"}
                                    </li>
                                    <li
                                      className={
                                        planObj?.enable_custdomain === "on"
                                          ? "on d-none"
                                          : "disable d-none"
                                      }
                                    >
                                      Custom Domain
                                    </li>

                                    <li
                                      className={
                                        planObj?.enable_custsubdomain === "on"
                                          ? "on d-none"
                                          : "disable d-none"
                                      }
                                    >
                                      {" "}
                                      Sub Domain
                                    </li>

                                    <li className={"on"}>{"QR Code"}</li>

                                    <li
                                      className={
                                        planObj?.enable_physical_pvc === "on"
                                          ? "on"
                                          : "disable"
                                      }
                                    >
                                      {`${planObj?.no_of_pvc_card} PVC Card`}
                                    </li>

                                    <li
                                      className={
                                        planObj?.enable_physical_metal === "on"
                                          ? "on"
                                          : "disable"
                                      }
                                    >
                                      {`${planObj?.no_of_metal_card} Metal
                                Card`}
                                    </li>

                                    <li>
                                      {`${planObj?.storage_limit} MB Storage
                                Limit`}
                                    </li>
                                  </ul>
                                </>
                              </div>
                            </div>
                          </>
                        </>
                      ) : (
                        <>
                          <div className="col-12 col-md-4 col-sm-6  plan_Wrapper">
                            <div
                              className="pricingTable green planpadissueres"
                              id="planwrapper_height"
                            >
                              <div className="pricingTable-header">
                                <h3 className="title">Enterprise Plan</h3>
                              </div>

                              <div className={"price-value-enterprise"}>
                                {
                                  "For Customized Plans, Contact our Sales Team."
                                }
                              </div>

                              {planObj?.is_enterprise ? (
                                <></>
                              ) : (
                                <span className="L729">
                                  <button
                                    className="upgrade_plan"
                                    onClick={() => {
                                      handleContactSales("month");
                                    }}
                                  >
                                    {"Contact Sales"}
                                  </button>
                                </span>
                              )}
                              <ul className="pricing-content planmarissueres">
                                <li className="on">Customized Theme</li>
                                <li className="on">
                                  {"Personalized Physical Cards"}
                                </li>

                                <li className={"on"}>{"QR Code"}</li>
                                <li className={"on"}>{"Appoitments"}</li>
                              </ul>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </section>
            </>
          </TabPanel>
        </Tabs>
      </CenteredTabList>
      </>
      )}
      <div
        className="modal fade"
        id="planselect"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content ">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Create New Plan
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleSubmit}>
                <div className="row g-3 mb-3">
                  <div className="col">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Plan Name"
                      aria-label="Name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>
                  <div className="col">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Plan Price"
                      aria-label="Price"
                      value={price}
                      onChange={(e) => setPrice(e.target.value)}
                    />
                  </div>
                </div>

                <div className="row g-3  mb-3">
                  <div className="col">
                    <select className="form-select" ref={selectRef}>
                      <option value="One Month">One Month</option>
                      <option value="Lifetime">Lifetime</option>
                      <option value="Per Month">Per Month</option>
                      <option value="Per Year">Per Year</option>
                    </select>
                  </div>
                  <div className="col">
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Enter Max User Create Limit"
                      aria-label="Max User"
                      value={maxUsers}
                      onChange={(e) => setMaxUsers(e.target.value)}
                    />
                  </div>
                </div>
                <div className="row g-3  mb-3">
                  <div className="col">
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Enter Max User Create Limit"
                      aria-label="Max Business"
                      value={maxBusiness}
                      onChange={(e) => setMaxBusiness(e.target.value)}
                    />
                  </div>
                  <div className="col">
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Storage limit"
                      aria-label="Storage limit"
                      value={storageLimit}
                      onChange={(e) => setStorageLimit(e.target.value)}
                    />
                  </div>
                </div>

                <div className="row g-3  mb-3">
                  <div className="col d-none">
                    <div className="form-check form-switch">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="flexSwitchCheckDefault"
                        checked={enableDomain}
                        onChange={() => setEnableDomain(!enableDomain)}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="flexSwitchCheckDefault"
                      >
                        Enable Domain
                      </label>
                    </div>
                  </div>
                  <div className="col">
                    <div className="form-check form-switch">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="flexSwitchCheckDefault"
                        checked={enableSubDomain}
                        onChange={() => setenableSubDomain(!enableSubDomain)}
                      />
                      <label
                        className="form-check-label"
                        for="flexSwitchCheckDefault"
                      >
                        Enable Sub Domain
                      </label>
                    </div>
                  </div>
                </div>
                <div className="row g-3  mb-3">
                  <div className="col">
                    <div className="form-check form-switch">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="flexSwitchCheckDefault"
                        checked={enableBranding}
                        onChange={() => setenableBranding(!enableBranding)}
                      />
                      <label
                        className="form-check-label"
                        for="flexSwitchCheckDefault"
                      >
                        Enable Branding
                      </label>
                    </div>
                  </div>
                  <div className="col">
                    <div className="form-check form-switch">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="flexSwitchCheckDefault"
                        checked={progressiveWebApp}
                        onChange={() =>
                          setprogressiveWebApp(!progressiveWebApp)
                        }
                      />
                      <label
                        className="form-check-label"
                        for="flexSwitchCheckDefault"
                      >
                        Progressive Web App (PWA)
                      </label>
                    </div>
                  </div>
                </div>
                <div className="row g-3  mb-3">
                  <div className="col">
                    <div className="form-check form-switch">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="flexSwitchCheckDefault"
                        checked={enableQRCode}
                        onChange={() => setenableQRCode(!enableQRCode)}
                      />
                      <label
                        className="form-check-label"
                        for="flexSwitchCheckDefault"
                      >
                        Enable QR Code
                      </label>
                    </div>
                  </div>
                  <div className="col">
                    <div className="form-check form-switch">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="flexSwitchCheckDefault"
                        checked={enableChatgpt}
                        onChange={() => setenableChatgpt(!enableChatgpt)}
                      />
                      <label
                        className="form-check-label"
                        for="flexSwitchCheckDefault"
                      >
                        Enable Chatgpt
                      </label>
                    </div>
                  </div>
                </div>

                <div className="box">
                  <h3>Physical card</h3>
                  <div className="row g-3  mb-3">
                    <div className="col">
                      <div className="form-check form-switch">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="flexSwitchCheckDefault"
                          checked={enablePVCCard}
                          onChange={() => setenablePVCCard(!enablePVCCard)}
                        />
                        <label
                          className="form-check-label"
                          for="flexSwitchCheckDefault"
                        >
                          Enable PVC Card
                        </label>
                      </div>
                    </div>
                    <div className="col">
                      <input
                        type="number"
                        className="form-control"
                        placeholder="0"
                        aria-label="No of card"
                        value={numberOfpvc}
                        onChange={(e) => setnumberOfpvc(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="row g-3  mb-3">
                    <div className="col">
                      <div className="form-check form-switch">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="flexSwitchCheckDefault"
                          checked={enableMetalCard}
                          onChange={() => setenableMetalCard(!enableMetalCard)}
                        />
                        <label
                          className="form-check-label"
                          for="flexSwitchCheckDefault"
                        >
                          Enable Metal Card
                        </label>
                      </div>
                    </div>
                    <div className="col">
                      <input
                        type="number"
                        className="form-control"
                        placeholder="0"
                        aria-label="No of card"
                        value={numberOfMetal}
                        onChange={(e) => setnumberOfMetal(e.target.value)}
                      />
                    </div>
                  </div>
                </div>

                <div className="row g-3  mt-3">
                  <div className="col">
                    <textarea
                      className="form-control"
                      placeholder=""
                      id="floatingTextarea2"
                      rows="5"
                      value={textareaValue}
                      onChange={(e) => setTextareaValue(e.target.value)}
                    />
                  </div>
                </div>
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
                <button type="submit" className="btn btn-primary">
                  Save changes
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="planselectedit"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content ">
            <div className="modal-header">
              <h5 className="modal-title L508" id="exampleModalLabel">
                Edit Plan
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleSave}>
                <div className="row g-3 mb-3">
                  <div className="col">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Plan Name"
                      aria-label="Name"
                      name="name"
                      value={editedData.name}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="col">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Plan Price"
                      aria-label="Price"
                      name="price"
                      value={editedData.price}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>

                <div className="row g-3  mb-3">
                  <div className="col">
                    <select
                      className="form-select"
                      name="duration"
                      value={editedData.duration}
                      onChange={handleInputChange}
                    >
                      <option value="One Month">One Month</option>
                      <option value="Lifetime">Lifetime</option>
                      <option value="Per Month">Per Month</option>
                      <option value="Per Year">Per Year</option>
                    </select>
                  </div>

                  <div className="col">
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Enter Max User Create Limit"
                      aria-label="Max User"
                      name="max_users"
                      value={editedData?.max_users}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div className="row g-3  mb-3">
                  <div className="col">
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Enter Max User Create Limit"
                      aria-label="Max Business"
                      name="business"
                      value={editedData?.business}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="col">
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Storage limit"
                      aria-label="Storage limit"
                      value={editedData.storage_limit}
                      name="storage_limit"
                      onChange={handleInputChange}
                    />
                  </div>
                </div>

                <div className="row g-3  mb-3">
                  <div className="col">
                    <div className="form-check form-switch">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="flexSwitchCheckDefault"
                        checked={editedData.enable_custdomain === "on"}
                        onChange={(e) => {
                          const isChecked = e.target.checked;
                          setEditedData({
                            ...editedData,
                            enable_custdomain: isChecked ? "on" : "off",
                          });
                        }}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="flexSwitchCheckDefault"
                      >
                        Enable Domain
                      </label>
                    </div>
                  </div>
                  <div className="col">
                    <div className="form-check form-switch">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="flexSwitchCheckDefault"
                        checked={editedData.enable_custsubdomain === "on"}
                        onChange={(e) => {
                          const isChecked = e.target.checked;
                          setEditedData({
                            ...editedData,
                            enable_custsubdomain: isChecked ? "on" : "off",
                          });
                        }}
                      />
                      <label
                        className="form-check-label"
                        for="flexSwitchCheckDefault"
                      >
                        Enable Sub Domain
                      </label>
                    </div>
                  </div>
                </div>
                <div className="row g-3  mb-3">
                  <div className="col">
                    <div className="form-check form-switch">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="flexSwitchCheckDefault"
                        checked={editedData.enable_branding === "on"}
                        onChange={(e) => {
                          const isChecked = e.target.checked;
                          setEditedData({
                            ...editedData,
                            enable_branding: isChecked ? "on" : "off",
                          });
                        }}
                      />
                      <label
                        className="form-check-label"
                        for="flexSwitchCheckDefault"
                      >
                        Enable Branding
                      </label>
                    </div>
                  </div>
                  <div className="col">
                    <div className="form-check form-switch">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="flexSwitchCheckDefault"
                        checked={editedData.pwa_business === "on"}
                        onChange={(e) => {
                          const isChecked = e.target.checked;
                          setEditedData({
                            ...editedData,
                            pwa_business: isChecked ? "on" : "off",
                          });
                        }}
                      />
                      <label
                        className="form-check-label"
                        for="flexSwitchCheckDefault"
                      >
                        Progressive Web App (PWA)
                      </label>
                    </div>
                  </div>
                </div>
                <div className="row g-3  mb-3">
                  <div className="col">
                    <div className="form-check form-switch">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="flexSwitchCheckDefault"
                        checked={editedData.enable_qr_code === "on"}
                        onChange={(e) => {
                          const isChecked = e.target.checked;
                          setEditedData({
                            ...editedData,
                            enable_qr_code: isChecked ? "on" : "off",
                          });
                        }}
                      />
                      <label
                        className="form-check-label"
                        for="flexSwitchCheckDefault"
                      >
                        Enable QR Code
                      </label>
                    </div>
                  </div>
                  <div className="col">
                    <div className="form-check form-switch">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="flexSwitchCheckDefault"
                        checked={editedData.enable_chatgpt === "on"}
                        onChange={(e) => {
                          const isChecked = e.target.checked;
                          setEditedData({
                            ...editedData,
                            enable_chatgpt: isChecked ? "on" : "off",
                          });
                        }}
                      />
                      <label
                        className="form-check-label"
                        for="flexSwitchCheckDefault"
                      >
                        Enable Chatgpt
                      </label>
                    </div>
                  </div>
                </div>

                <div className="box">
                  <h3>Physical card</h3>
                  <div className="row g-3  mb-3">
                    <div className="col">
                      <div className="form-check form-switch">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="flexSwitchCheckDefault"
                          checked={editedData.enable_physical_pvc === "on"}
                          onChange={(e) => {
                            const isChecked = e.target.checked;
                            setEditedData({
                              ...editedData,
                              enable_physical_pvc: isChecked ? "on" : "off",
                            });
                          }}
                        />
                        <label
                          className="form-check-label"
                          for="flexSwitchCheckDefault"
                        >
                          Enable PVC Card
                        </label>
                      </div>
                    </div>
                    <div className="col">
                      <input
                        type="number"
                        className="form-control"
                        placeholder="0"
                        aria-label="No of card"
                        value={editedData.no_of_pvc_card}
                        name="no_of_pvc_card"
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  <div className="row g-3  mb-3">
                    <div className="col">
                      <div className="form-check form-switch">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="flexSwitchCheckDefault"
                          checked={editedData.enable_physical_metal === "on"}
                          onChange={(e) => {
                            const isChecked = e.target.checked;
                            setEditedData({
                              ...editedData,
                              enable_physical_metal: isChecked ? "on" : "off",
                            });
                          }}
                        />
                        <label
                          className="form-check-label"
                          for="flexSwitchCheckDefault"
                        >
                          Enable Metal Card
                        </label>
                      </div>
                    </div>
                    <div className="col">
                      <input
                        type="number"
                        className="form-control"
                        placeholder="0"
                        aria-label="No of card"
                        value={editedData.no_of_metal_card}
                        name="no_of_metal_card"
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                </div>

                <div className="row g-3  mt-3">
                  <div className="col">
                    <textarea
                      className="form-control"
                      placeholder=""
                      id="floatingTextarea2"
                      rows="5"
                      value={editedData.description}
                      name="description"
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
                <button type="submit" className="btn btn-primary">
                  Save changes
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="contact-sales-container">
        <div className="contact-sales-heading-wrapper">
          <div className="contact-sales-heading">
            Get in touch with our sales team
          </div>
          <div className="contact-sales-subheading">
            Have a general question about our product, plans, or something else?
          </div>
        </div>
        <div className="contact-sales-main">
          <div className="contact-sales-input-container">
            <div className="contact-sales-input-wrapper">
              <div className="contact-sales-label">First Name*:</div>
              <input
                // value={subTitle}
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                className="form-control"
                type="text"
                placeholder="First Name*"
              />
            </div>
            <div className="contact-sales-input-wrapper">
              {" "}
              <div className="contact-sales-label">Last Name*:</div>
              <input
                // value={subTitle}
                value={contactLastName}
                onChange={(e) => setContactLastName(e.target.value)}
                className="form-control"
                type="text"
                placeholder="Last Name*"
              />
            </div>
          </div>
          <div className="contact-sales-input-container">
            <div className="contact-sales-input-wrapper">
              {" "}
              <div className="contact-sales-label">Email*:</div>
              <input
                // value={subTitle}
                value={contactEmail}
                onChange={(e) => setContactEmail(e.target.value)}
                className="form-control"
                type="text"
                placeholder="Email*"
              />
            </div>
            <div className="contact-sales-input-wrapper">
              <div className="contact-sales-label">Mobile*:</div>
              <PhoneInput
              value={mobile}
                onChange={(value, country) => {
                  if (country.dialCode) {
                    setMobile(
                      `+${country.dialCode}-${value.substring(
                        country.dialCode.length
                      )}`
                    );
                  } else {
                    setMobile(value);
                  }
                }}
                country={"in"}
                className="DetailsInfoPhoneInput"
              />
            </div>
          </div>
          <div className="contact-sales-textare-container">
            <div className="contact-sales-textare-wrapper">
              <div className="contact-sales-label">Message (optional)</div>
              <textarea
                className="form-control"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                rows="4"
                placeholder="Enter Description"
              ></textarea>
            </div>
          </div>
          <div className="contact-sales-btn-wrapper">
            <button
              type="button"
              className="btn btn-cu save-cta Changeblock_saveandpreview_button"
              id="changeblockprenxtbtn"
              onClick={handleSubmitContactSales}
            >
              {isSubmitContactSales ? (
                <CircularLoader size={20} />
              ) : (
                "Schedule a Demo"
              )}
            </button>
          </div>
        </div>
      </div>
      
    </>
  );
}

export default Plans;
//1112
