import React from "react";
import { environmentVariables } from "../../../../config/env.config";
import theme6strip from "../../../../Images/theme6strip.png";

function SubService(props) {
  let rs = props.rs;

  // console.log(props, "rssubservice");
  return (
    <>
      <article className="servicesName sec">
        <div className="services">
          <h3 class="sectitle service">Services </h3>
          <div className="mainservicebox">
            {/* <ul className="servesbox">
            {JSON.parse(rs?.serviceObj?.content).map((val) => (
              <li className="service-list">{val?.name}</li>
            ))}{" "}
          </ul> */}
            {rs?.serviceObj?.content && (
              <ul className="servesbox">
                {JSON.parse(rs.serviceObj.content).map((val, index) => (
                  val?.name && (
                    <li key={index} className="service-list">
                      {val.name}
                    </li>
                  )
                ))}
              </ul>
            )}
          </div>
        </div>
      </article>
      {/* <img src={theme6strip} className="stripservice" /> */}
    </>
  );
}
export default SubService;
