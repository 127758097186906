import React, { useEffect, useState } from "react";
import "./Card.css";
import { environmentVariables } from "../../../config/env.config";
import CircularLoader from "../../Components/CircularLoader/CircularLoader";
import axios from "axios";
import QRCode from "qrcode.react";
import "./ViewCardStyle.css";
import "../../Pages/OrderNowUpdatedStyle.css";

const ViewCard = (props) => {
  // console.log(props.slug, "ZZZZZZZZZZZZZZZZZz");
  const [frontImage, setFrontImage] = useState(null);
  const [backImage, setBackImage] = useState(null);
  const [styleFile, setStyleFile] = useState("");
  const [fontFile, setFontFile] = useState("");
  const [fontFile2, setFontFile2] = useState("");
  const [styleContent, setStyleContent] = useState(null);
  const [isCardLoaded, setIsCardLoaded] = useState(false);
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const [businessLink, setBusinessLink] = useState("");
  const [storedData, setStoredData] = useState(
    localStorage.getItem("businessObj")
  );

  const frontImg = new Image(); // new
  const backImg = new Image(); // new
  const originUrl = window.location.origin;
  const link = (`${originUrl}/${props?.slug}`)

  console.log(link,"3454edc")

  const getFont = (cardId) => {
    axios
      .get(
        `${environmentVariables?.apiUrl}api/v1/phy_card/get_fonts/${cardId?.activeCardData?.id}`
      )
      .then((res) => {
        setFontFile(res?.data?.fontFiles[0]?.data);
        setFontFile2(res?.data?.fontFiles[1]?.data);
        // console.log(fontFile, "fontFileview");
        // console.log(fontFile2, "fontFile2");
        setTimeout(() => {
          props.setPhysicalCardLoading(false);
        }, 1000);
      })
      .catch((err) => {
        console.log(err);
        props.setPhysicalCardLoading(false);
      });
  };

  useEffect(() => {
    if (props.activeCardData) {
      setFrontImage(
        `${environmentVariables?.apiUrl}${props.activeCardData?.card_name}/${props.activeCardData?.front_image}`
      );
      setBackImage(
        `${environmentVariables?.apiUrl}${props.activeCardData?.card_name}/${props.activeCardData?.back_image}`
      );
      setStyleFile(
        `${environmentVariables?.apiUrl}${props.activeCardData?.card_name}/${props.activeCardData?.style_file}`
      );
      getFont(props);

      if (props.activeCardData?.style_content) {
        setStyleContent(props.activeCardData?.style_content);
      }
    }
  }, [props.activeCardData]);

  useEffect(() => {
    // Event handler for when both images are loaded
    const handleImagesLoaded = () => {
      setIsImageLoaded(true);
    };

    // Event handler for front image loaded
    const handleFrontImageLoad = () => {
      console.log("Front Image Loaded!");
    };

    // Event handler for back image loaded
    const handleBackImageLoad = () => {
      console.log("Back Image Loaded!");
    };

    // Set source and attach load event listeners
    frontImg.src = `${environmentVariables?.apiUrl}${props.activeCardData?.card_name}/${props.activeCardData?.front_image}`;
    frontImg.addEventListener("load", handleFrontImageLoad);

    backImg.src = `${environmentVariables?.apiUrl}${props.activeCardData?.card_name}/${props.activeCardData?.back_image}`;
    backImg.addEventListener("load", handleBackImageLoad);

    // Attach the handleImagesLoaded event once both images are loaded
    Promise.all([frontImg, backImg]).then(handleImagesLoaded);

    // Cleanup event listeners on component unmount
    return () => {
      frontImg.removeEventListener("load", handleFrontImageLoad);
      backImg.removeEventListener("load", handleBackImageLoad);
    };
  }, [props.activeCardData]);

  useEffect(() => {
    if (props.activeCardData) {
      const css = `
      @font-face {
        font-family: 'dynamic-font';
        src: url(data:application/font-ttf;base64,${fontFile}) format('truetype');
      }
    `;

      const css2 = `
    @font-face {
      font-family: 'dynamic-font2';
      src: url(data:application/font-ttf;base64,${fontFile2}) format('truetype');
    }
  `;

      const blob = new Blob([css], { type: "text/css" });
      const url = URL.createObjectURL(blob);

      const blob2 = new Blob([css2], { type: "text/css" });
      const url2 = URL.createObjectURL(blob2);

      // Create a <link> element to apply the styles
      const link = document.createElement("link");
      link.rel = "stylesheet";
      link.href = url;

      // Append the <link> element to the document's head
      document.head.appendChild(link);

      const link1 = document.createElement("link");
      link1.rel = "stylesheet";
      link1.href = url2; // Fix: use url2 here
      document.head.appendChild(link1);

      if (styleFile) {
        const link = document.createElement("link");
        link.href = styleFile;
        link.rel = "stylesheet";
        link.onload = () => {
          setIsCardLoaded(true);
        };
        const style = document.createElement("style");
        style.innerHTML = `
    @font-face {
      font-family: 'dynamic-font';
      src: url('${fontFile}') format('truetype');
    }
    `;
        document.head.appendChild(link);
        document.head.appendChild(style);

        return () => {
          document.head.removeChild(link);
        };
      } else if (styleContent) {
        const styleElement = document.createElement("style");
        styleElement.type = "text/css";
        styleElement.appendChild(document.createTextNode(styleContent));
        document.head.appendChild(styleElement);
        setIsCardLoaded(true);

        return () => {
          document.head.removeChild(styleElement);
        };
      }
    }
  }, [styleFile, fontFile]);

  useEffect(() => {
    if (storedData !== "undefined") {
      const businessData = JSON.parse(storedData);
      // const slugb = businessData?.slug;
      const originUrl = window.location.origin;
      // setBusinessLink(`${originUrl}/${slugb}`);
      setBusinessLink(`${originUrl}/${props?.slug}`);
    }
  }, [storedData]);

  console.log(
    "pppp",
    `${environmentVariables.apiUrl}uploads/LogoPhy/default1.png`
  );
  // console.log(props, "props.logoimg");
  return (
    <>
      {props.physicalCardLoading || !isImageLoaded ? (
        <article id={props.card_id}>
          <div className="row ">
            <div className="ViewCard_container">
              <div
                style={{ display: "flex", justifyContent: "center" }}
                className="cardviewiiner"
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    textAlign: "center",
                    justifyContent: "center",
                  }}
                >
                  <CircularLoader size={40} />
                </div>
              </div>
            </div>
          </div>
        </article>
      ) : (
        <>
          <article id={props.card_id}>
            <div className="row ">
              <div className="ViewCard_container">
                <div className="cardviewiiner">
                  <div className="flip-card ViewCardMobile">
                    <div className="flip-card-inner ViewMobilefInnerCard">
                      <div className="flip-card-front ViewMobilefFrontCard">
                        <div className="pos-r ">
                          <img
                            src={frontImage}
                            className="mx-auto d-block img-fluid "
                            loading="lazy"
                          />
                          <div className="caption-front">
                            <h1
                              style={{ fontFamily: "dynamic-font" }}
                              className="u-name Physical_card_one"
                            >
                              {props?.islogo === 1 ? (
                                <>
                                  {props.logoimg === null ? (
                                    <>
                                      {props.card_id === 71 ? (
                                        <img
                                          src={`${environmentVariables.apiUrl}uploads/LogoPhy/defaultLogo2.png`}
                                          className="mx-auto d-block img-fluid ViewCardLogoStyle"
                                          // style={{ width: "30px", height: "30px" }}
                                        />
                                      ) : (
                                        <img
                                          src={`${environmentVariables.apiUrl}uploads/LogoPhy/defaultLogo1.png`}
                                          className="mx-auto d-block img-fluid ViewCardLogoStyle"
                                          // style={{ width: "30px", height: "30px" }}
                                        />
                                      )}
                                    </>
                                  ) : (
                                    <img
                                      src={`${environmentVariables.apiUrl}uploads/LogoPhy/${props.logoimg}`}
                                      className="mx-auto d-block img-fluid ViewCardLogoStyle"
                                      // style={{ width: "30px", height: "30px" }}
                                    />
                                  )}
                                </>
                              ) : null}

                              {/* {
                        SelectedDesignation !=="null" && <div class="Ordernow_title">{SelectedDesignation}</div>
                      } */}
                              {props.name}
                            </h1>
                            <p
                              style={{ fontFamily: "dynamic-font2" }}
                              className="u-deg Physical_card_one_backend"
                            >
                              {props.degination}
                            </p>
                            <p
                              style={{ fontFamily: "dynamic-font" }}
                              className="u-weblink pos-ab"
                            ></p>
                          </div>
                        </div>
                      </div>
                      <div className="flip-card-back">
                        <div
                          className="pos-r"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <img src={backImage} className="img-fluid  " />
                          <div
                            className="caption-back"
                            style={{ position: "absolute", zIndex: 999999 }}
                            loading="lazy"
                          >
                            <div className="qr_scanner_wrapper">
                              <QRCode
                                style={{ height: "128px" }}
                                className="qrCodeStyle"
                                value={link}
                                bgColor="transparent"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </article>
        </>
      )}
    </>
  );
};

export default ViewCard;
