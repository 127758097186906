import React, { useEffect, useContext } from "react";
import { useState } from "react";
import SocialForm from "./SocialForm";
import { toast } from "react-toastify";
// import { businessContext } from "../../../context/businessContext";
import { businessObjContext } from "../../../context/businessObjContext";
import "./../../Pages/Updatedcss/BusinessChangeBlockUpdatedStyle.css";

function SocialCreate({ setSocialMediaData, socialMediaData }) {
  const [formList2, setFormList2] = useState([]);
  const [formCount, setFormCount] = useState(0);
  const [isAddServiceDisable, setIsAddSocialDisable] = useState(false);

  // const [isAddDisable, setIsAddDisable] = useState(false);
  const [socialData, setSocialData] = useState([]);
  const { businessObjData } = useContext(businessObjContext);

  const createForm = () => {
    setIsAddSocialDisable(true);
    const newFormCount = formCount + 1;
    setFormCount(newFormCount);
    const newForm = {
      formCount: newFormCount,
      nameOfSocial: "",
      link: "",
    };
    const updatedSocialData = [...socialData, newForm];

    setSocialData(updatedSocialData);
    // setFormList2(updatedSocialData);
  };

  useEffect(() => {
    // console.log(socialData,"sociaata22")
    setFormList2(socialData);
    setSocialMediaData(socialData);
  }, [socialData]);

  useEffect(() => {
    let getServicesData;

    // console.log(businessObjData, "servce2i bb2j");
    if (businessObjData && businessObjData?.SocialMediaObj) {
      getServicesData = JSON.parse(businessObjData?.SocialMediaObj?.content);
      if (getServicesData.length) {
        getServicesData = JSON.parse(getServicesData);
      }
    }

    // console.log(getServicesData, "service22i  Obj");
    if (getServicesData && getServicesData?.length) {
      setFormList2(getServicesData);
      setFormCount(getServicesData?.length);
      setSocialData(getServicesData);
    } else {
      setFormList2([]);
      setFormCount(0);
      setSocialData([]);
    }
  }, [businessObjData]);

  return (
    <>
      {/* {console.log(socialData,"rmList223")} */}
      {socialData ? (
        <div className="row" id="Socialformpadd">
          {socialData.map((item, index, arr) => (
            <>
              <SocialForm
                formCount={item?.formCount}
                socialData={socialData}
                setIsAddSocialDisable={setIsAddSocialDisable}
                setSocialData={setSocialData}
                index={index}
                // removeForm={removeForm}
                
              ></SocialForm>
            </>
          ))}
        </div>
      ) : null}

      <button
        disabled={isAddServiceDisable}
        type="button"
        onClick={createForm}
        className="btn btn-cu addbuttonedit"
      >
        + Add new Social Media form
      </button>
    </>
  );
}

export default SocialCreate;
