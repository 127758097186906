import React, { useEffect, useRef } from "react";

import { environmentVariables } from "../../../../config/env.config";
import TestimonialCarousel from "./TestimonialCarousel";
import { useState } from "react";

function SubTestimonial(props) {
  let rs = props.rs;

const [currentIndex, setCurrentIndex] = useState(0);
  const [testimonials, setTestimonials] = useState([]);
  const [testimonialsLength, setTestimonialsLength] = useState([]);
  const touchStartX = useRef(0);

  const handleTouchStart = (e) => {
    touchStartX.current = e.touches[0].clientX;
  };

  const handleTouchMove = (e) => {
    const touchEndX = e.touches[0].clientX;
    const deltaX = touchEndX - touchStartX.current;

    if (Math.abs(deltaX) > 50) {
      // You can adjust this threshold to control how much swipe is needed to trigger the slide
      setCurrentIndex((prev) =>
        deltaX > 0
          ? Math.max(prev - 1, 0)
          : Math.min(prev + 1, testimonials.length - 1)
      );
    }
  };

  const handleTouchEnd = () => {
    touchStartX.current = 0;
  };
  useEffect(() => {
    if (rs) {
      if (rs?.testimonialObj?.content) {
        let parsedContent = JSON.parse(rs?.testimonialObj?.content);
        if (parsedContent?.length != 0) {
          setTestimonials(parsedContent);
          setTestimonialsLength(parsedContent.length);
        }
      }
    }
  }, [rs]);
  // useEffect(() => {
  //   $(".owl-carousel").owlCarousel({
      //     items: 1,
      //     loop: true,
      //     autoplay: false,
      //     dots: false,
      //     nav: true,
      //     navText: [
        //       "<i class='fa fa-angle-left'></i>",
        //       "<i class='fa fa-angle-right'></i>",
      //     ],
    //   });
  // }, []);

  console.log(rs, "rssubservice");
  return (
    <>
{testimonials && testimonials.length > 0 && (
      <article class="testimoniyalbox sec">
        <h3 class="sectitle testimonial">Testimonial </h3>

        <div>
            <TestimonialCarousel
              testimonials={testimonials}
              currentElem={currentIndex}
              handleTouchStart={handleTouchStart}
              handleTouchMove={handleTouchMove}
              handleTouchEnd={handleTouchEnd}
            />

                  <div className="carousel-container">
                    <button
                onClick={() =>
                  currentIndex == 0
                    ? setCurrentIndex(testimonialsLength - 1)
                    : setCurrentIndex((prev) => prev - 1)
                }
                className="carousel-btn"
              >
                      <i class="fa fa-angle-left"></i>
                    </button>
                    <button
                onClick={() => {
                  currentIndex == testimonialsLength - 1
                    ? setCurrentIndex(0)
                    : setCurrentIndex((prev) => prev + 1);
                }}
                className="carousel-btn"
              >
                  <i class="fa fa-angle-right"></i>
              </button>
                  </div>
                        </div>
      </article>
)}
    </>
  );
}
export default SubTestimonial;
