import React, { useEffect, useContext } from "react";
import { useState } from "react";
import TestimonialForm from "./testimonialForm";
import { businessObjContext } from "../../../context/businessObjContext";

function NewBusinessDetilsCreate({ setTestimonialArray }) {
  const [formList, setFormList] = useState([]);
  const [formCount, setFormCount] = useState(0);
  const [isAddTestimonialDisable, setIsAddTestimonialDisable] = useState(false);
  const [isAddDisable, setIsAddDisable] = useState(false);
  const [testimonialData, setTestimonialData] = useState([]);
  const [data, setData] = useState({
    formCount: formCount,
    image: null,
    name: "",
    description: "",
    imageView: null,
  });
  const { businessObjData } = useContext(businessObjContext);

  const removeForm = (formKey) => {
    // console.log(formKey, "fmKey,formKey,", testimonialData);
    const newFormList = testimonialData?.filter(
      (val, index) => index !== formKey
    );
    // console.log(newFormList,"rem)_data")
    setTestimonialData([...newFormList]);
    // if (testimonialData?.length == 0) {
    if (formKey == testimonialData?.length - 1) {
      setIsAddTestimonialDisable(false);
    }
  };

  useEffect(() => {
    let getTestimonialData;

    if (businessObjData && businessObjData?.testimonialObj) {
      getTestimonialData = JSON.parse(businessObjData?.testimonialObj?.content);
    }
    // console.log(getTestimonialData, "testimonial  Obj!@#!@");
    if (getTestimonialData && getTestimonialData?.length) {
      const updatedTestimonialData = getTestimonialData.map((el, index) => ({
        ...el,
        formCount: index,
        isImageUpload: false,
      }));
      setFormList(updatedTestimonialData);
      setFormCount(updatedTestimonialData?.length);
      setTestimonialData(updatedTestimonialData);
    } else {
      setFormList([]);
      setFormCount(0);
      setTestimonialData([]);
    }
  }, [businessObjData]);

  const createForm = () => {
    setIsAddTestimonialDisable(true);
    const newFormCount = formCount + 1;
    setFormCount(newFormCount);
    const newForm = {
      formCount: newFormCount,
      image: null,
      name: "",
      description: "",
      imageView: null,
      isImageUpload: true,
    };
    const updatedData = [...formList, newForm];
    // setFormList(updatedData);
    setTestimonialData(updatedData);
  };

  useEffect(() => {
    // console.log(testimonialData,"testimonialDta2 1212 23@")
    setFormList(testimonialData);
    // setTestimonialData(testimonialData)
    setTestimonialArray(testimonialData);
    if (testimonialData.length == 0) {
      setIsAddTestimonialDisable(false);
    }
  }, [testimonialData]);

  return (
    <>
      <div className="row" id="AddNewStyleForm">
        {testimonialData.map((form, index) => (
          // <>{form}</>
          <TestimonialForm
            formCount={formCount}
            testimonialData={testimonialData}
            setIsAddTestimonialDisable={setIsAddTestimonialDisable}
            setTestimonialData={setTestimonialData}
            // handleAddService={handleAddService}
            removeForm={removeForm}
            // setData={setData}
            // data={data}
            index={index}
            // formData={form}
          ></TestimonialForm>
        ))}
      </div>
      <button
        disabled={isAddTestimonialDisable}
        type="button"
        onClick={createForm}
        className="btn btn-cu addbuttonedit"
      >
        + Add new Testimonial
      </button>
    </>
  );
}

export default NewBusinessDetilsCreate;
