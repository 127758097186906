import React from "react";
function SubContactInfo(props) {
  let rs = props.rs;
  let ff = rs?.contactInfo?.content;
  if (ff?.length) {
    ff = JSON?.parse(ff);
  }
  if (ff?.length) {
    ff = JSON?.parse(ff);
  }

  // console.log(ff,"adfff")
  //return {rs?.contactInfo && ( )
  return (
    <>
      <article class="contact sec">
        <h3 class="sectitle contactinfo">Contact us</h3>

        <div class="conlink">
          <div className="contact-us-grid">
            <div className="contact-info-container">
              <div className="contact-info-details">
                <div className="contact-info-icon">
                  <i className="fa fa-solid fa-phone"></i>
                </div>
                {/* <p className="contact-info-label">Phone</p> */}
                <a
                  className="contactinfolink"
                  href={
                    ff?.contactInfoPhone ? `tel:${ff?.contactInfoPhone}` : ""
                  }
                >
                  <span className="contactinfotext">
                    {ff?.contactInfoPhone || ``}
                  </span>
                </a>
              </div>
            </div>
            <div className="vertical-line"></div>

            <div className="vertical-line"></div>
            <div className="contact-info-container">
              <div className="contact-info-details">
                <div className="contact-info-icon">
                  <i className="fa fa-solid fa-envelope"></i>
                </div>
                {/* <p className="contact-info-label">Email</p> */}
                <a
                  href={
                    ff?.contactInfoEmail ? `mailto:${ff?.contactInfoEmail}` : ""
                  }
                >
                  <span className="contactinfotext">
                    {ff?.contactInfoEmail}
                  </span>
                </a>
              </div>
            </div>
            
            <div className="contact-info-container">
              <div className="contact-info-details">
                <div className="contact-info-icon">
                  <i className="fa fa-solid fa-location-dot"></i>
                </div>
                {/* <p className="contact-info-label">Address</p> */}
                <a>
                  <span className="contactinfotext">
                    {ff?.contactInfoAddress}
                  </span>
                </a>
              </div>
            </div>
          </div>
          {/* <li>
                <a href="">
                <i class="fa-solid fa-envelope"></i>
                  <span>
                   
                    {props?.contactInfo?.contactInfoWebUrl ||
                      `www.Shreyassinghal.com`}
                  </span>
                </a>
              </li> */}
        </div>
      </article>
    </>
  );
}
export default SubContactInfo;
