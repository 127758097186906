import React, { useContext, useEffect, useRef, useState } from "react";
import PageName from "../Utils/PageName";
import { Link } from "react-router-dom";
import { environmentVariables } from "../../config/env.config";
import axios from "axios";
import { userContext } from "../../context/userContext";
import { businessNameContext } from "../../context/businessContext";

import ReactDOM from "react-dom";
import DataTable from "react-data-table-component";
import SortIcon from "@material-ui/icons/ArrowDownward";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import { businessContext } from "../../context/businessContext";
import { businessObjContext } from "../../context/businessObjContext";
import CircularLoader from "../Components/CircularLoader/CircularLoader";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import "./ContactFilter.css";
import Modal from "@mui/material/Modal";
import { Box } from "@mui/material";
import FilterIcon from "../../../src/Images/FilterIcon.png";
import { useCallback,useMemo } from "react";

const Boxstyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  // height: 500,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  zIndex: 999,
  borderRadius: "15px",
  border: "none",
  outline: "none",
  focus: "none",
  boxShadow:
    " rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;",
  padding: "3rem 5rem",
  "@media (max-width: 650px)": {
    width: "425px",
    padding: "2rem",
  },
  "@media (max-width: 430px)": {
    width: "380px",
    padding: "2rem",
  },
  "@media (max-width: 390px)": {
    width: "350px",
    padding: "2rem",
  },
};

function Contacts() {
  const [data, setData] = useState([]);
  // console.log(data,"datacontact1")
  const [loading, setLoading] = useState(true);
  const baseUrl = environmentVariables?.apiUrl;
  const { userData, setUserData } = useContext(userContext);
  const { businessNameData, setBusinessNameData } =
    useContext(businessNameContext);
  // useEffect(()=>{

  //   console.log(businessNameData,"businessNameData12")
  // },[businessNameData])

  const { businessObjData, setBusinessObjData } =
    useContext(businessObjContext);

  const [isLoading, setIsLoading] = useState(true);

  const [businessData, setBusinessData] = useState([]);
  const [tempData, setTempData] = useState([]);
  const [activeBusinessName, setActiveBusinessName] = useState("");
  const fromDate = useRef(null);
  const toDate = useRef(null);
  const tableExtensions = {
    export: false,
    print: false,
  };
  const [action] = useState({ fromUser: false });
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [paginationTotalRows, setPaginationTotalRows] = useState(0);
  const [paginationPerPage, setPaginationPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [showPopUp, setShowPopUp] = useState("");
  const [selectedRowsPerPage, setSelectedRowsPerPage] = useState([]);
  const [maskedContactsExist, setMaskedContactsExist] = useState(false);

  const Boxstyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    // height: 500,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    zIndex: 999,
    borderRadius: "15px",
    border: "none",
    outline: "none",
    focus: "none",
    boxShadow:
      " rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;",
    padding: "3rem 5rem",
    "@media (max-width: 650px)": {
      width: "425px",
      padding: "2rem",
    },
    "@media (max-width: 430px)": {
      width: "380px",
      padding: "2rem",
    },
    "@media (max-width: 390px)": {
      width: "350px",
      padding: "2rem",
    },
  };

  const customStyles = {
    dataTableExtensions: {
      backgroundColor: "lightgray",
      padding: "10px",
    },
    headRow: {
      style: {
        backgroundColor: "#e9e9e9",
        borderRadius: "2px 2px 0 0",
        paddingLeft: "25px",
        border: "none",
        textTransform: "capitalize",
        position: "sticky",
        left: 0,
        top: 0,
        zIndex: 1,
        boxShadow: "0 2px 2px -1px rgba(0, 0, 0, 0.1)",
        overflow: "hidden",
        whiteSpace: "nowrap",
      },
    },
    headCells: {
      style: {
        color: "#525252",
        fontSize: "16px",
        minHeight: "52px",
      },
    },
    rows: {
      style: {
        padding: "6px 0px 6px 25px !important",
        fontSize: "14px",
        textTransform: "capitalize",
        minHeight: "58px !important",
      },
    },
    businessColumn: {
      position: "sticky",
      left: "0",
      zIndex: "1",
      backgroundColor: "#fff",
      overflow: "hidden",
      whiteSpace: "nowrap",
    },
  };

  const fetchcontact = (page) => {
    try {
      // if(businessObjData&&businessObjData.id){

      let obj = {
        business_id: businessObjData?.id,
      };
      axios
        .post(`${baseUrl}api/v1/contact/fetchContacts_withpafination?page=${page}&limit=${rowsPerPage}&showcount=${userData?.contacts}`, obj, {
          headers: {
            _token: userData?.token,
          },
          withCredentials: true,
        })
        .then((response) => {
          let arr = response?.data?.result?.records;
          setShowPopUp(response.data?.pagination?.totalCount)
          // if (arr.some(contact => contact.name === '***' || contact.email === '***' || contact.phone === '***')) {
          //   setMaskedContactsExist(true);
          // }

          // console.log(response?.data?.pagination,"showPopUp")

          if(showPopUp >= userData?.appointments){
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Your free plan contact quota has been exhausted. Upgrade now to check your upcoming Enquiry.",
            });
          }

          arr = arr?.sort(
            (x, y) => new Date(y?.created_at) - new Date(x?.created_at)
          );
          // console.log(arr, "admin 23");
          let tempArr = arr?.map((el) => el?.title);
          let tempUniqueArr = new Set(tempArr);
          tempUniqueArr = [...tempUniqueArr];
          setBusinessData(tempUniqueArr);
          // setActiveBusinessName(response?.data?.result?.records[0]?.title);
          setTempData(arr);
          setData(arr);
          setIsLoading(false);
          console.log("tolcont", response.data.pagination.totalCount)
          setPaginationTotalRows(response?.data?.pagination?.totalCount)
        })
        .catch((error) => {
          console.log(error);
          setIsLoading(false);
        });
      // }
    } catch (er) {
      console.log(er, "eroror");
    }
  };

  const handleOnSelectedRowsChange = useCallback(
    ({ selectedRows }) => {
      if (!action.fromUser) return; //the component always trigger this with 0 selected rows when it renders a page, what would clear the selection

      selectedRowsPerPage[currentPage] = selectedRows; //there is no way to tell if a row was DEselected, so I had to control the selected rows per page,
      //the array would get an index to control each page
      console.log(JSON.stringify(selectedRowsPerPage));
    },
    [currentPage, selectedRowsPerPage, action.fromUser]
  );

  const handlePageChange = (page) => {
    const nextPage = page; 
    fetchcontact(nextPage);
    console.log(page, "");
    // fetchBusinessData(page);
    setCurrentPage(page);
  };

  const handleRowsPerPageChange = async (newRowsPerPage) => {
    console.log(newRowsPerPage, "newRowsPerPagenewRowsPerPagenewRowsPerPage");
    // if (!businesslistData.length) return;
    // Update the state first
    setRowsPerPage(newRowsPerPage);
    setCurrentPage(1); // Reset current page to 1
    setSelectedRowsPerPage([]);
    // Now call the function with the updated state
    fetchcontact(1);
  };

  useEffect(() => {
    fetchcontact();
  }, [businessObjData,rowsPerPage]);

  useEffect(() => {
    fetchcontact();
  }, []);

  const handleClick = (title) => {
    // console.log(`You clicked me! ${title}`);
  };

  const getBusinessNameById = (businessId) => {
    // console.log(businessNameData, "businessNameData @@@@@@");
    const business = businessNameData.find((item) => item.id === businessId);
    return business ? business.title : "";
  };

  const handleDeleteContact = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        let data = JSON.stringify({
          contactId: id,
        });

        let config = {
          method: "delete",
          maxBodyLength: Infinity,
          url: `${environmentVariables?.apiUrl}api/v1/contact/deleteContact`,
          headers: {
            _token: userData?.token,
            "Content-Type": "application/json",
          },
          // withCredentials: true,
          data: data,
        };
        axios
          .request(config)
          .then((response) => {
            Swal.fire(
              "Deleted!",
              "Selected Contact has been deleted.",
              "success"
            );
            fetchcontact();
          })
          .catch((error) => {
            Swal.fire(
              "Error!",
              error?.response?.data?.message || error?.message,
              "error"
            );
          });
      }
    });
  };

  const customStyles2 = {
    dataTableExtensions: {
      backgroundColor: "lightgray",
      padding: "10px",
    },
    // ... other styles

    // Add your CSS styles inline
    tooltip_element: {
      position: "relative",
      display: "inline-block",
    },
    tooltip_element_span: {
      display: "none",
      position: "absolute",
      backgroundColor: "#f9f9f9",
      color: "#000",
      padding: "5px",
      border: "1px solid #ccc",
      zIndex: "1",
    },
    "tooltip_element:hover span": {
      display: "block",
    },
  };

  const columns = [
    {
      name: "Business Name",
      selector: "business_id",
      sortable: true,
      // cell: (row) => getBusinessNameById(row.business_id),
      cell: (row) => row?.title,
      fixed: "left",
      // style: customStyles.businessColumn,
    },
    {
      name: "Name",
      selector: "name",
      sortable: true,
      cell: (d) => {
        const capitalizedName =
          d?.name?.charAt(0).toUpperCase() + d?.name?.slice(1);
        return capitalizedName;
      },
    },
    {
      name: "Date",
      // selector: "date",
      selector: (row) =>
        row?.created_at ? new Date(row?.created_at).toLocaleDateString() : "",
      sortable: true,
      width: "08%",
    },
    {
      name: "Email",
      selector: "email",
      sortable: true,
    },
    {
      name: "Mobile",
      selector: "phone",
      sortable: true,
    },
    {
      name: "Message",
      selector: "message",
      sortable: true,
      cell: (d) => (
        <span style={customStyles2?.tooltip_element}>
          <span className="toolTipMessage" title={d?.message}>
            {d?.message?.slice(0, 20)} {d?.message?.length > 20 && "..."}{" "}
          </span>
        </span>
      ),
    },
    {
      name: "Action",
      sortable: false,
      cell: (d) => (
        <>
          {userData.created_by == 0 ? (
            <i
              style={{ width: "50px" }}
              onClick={() => handleDeleteContact(d?.id)}
              className="fas fa-trash-alt"
            ></i>
          ) : userData &&
            userData?.permission &&
            userData?.permission?.some((obj) =>
              obj?.permissionobj?.name?.includes("manage contact")
            ) ? (
            <i
              style={{ width: "50px" }}
              onClick={() => handleDeleteContact(d?.id)}
              className="fas fa-trash-alt"
            ></i>
          ) : (
            "--"
          )}
        </>
      ),
    },
  ];

  const handleChangeBusinessData = (e) => {
    // console.log(e.target.value,"response@#")
    setActiveBusinessName(e.target.value);
  };
  const [selectedDateFrom, setSelectedDateFrom] = useState("");
  const [selectedDateTo, setSelectedDateTo] = useState("");
  const [showFilter, setShowFilter] = useState(false);

  const handleFilterOpen = () => {
    setShowFilter(!showFilter);
  };
  const handleFilterClose = () => {
    setShowFilter(false);
  };

  useEffect(() => {
    if (selectedDateTo != "" && selectedDateFrom != "") {
      if (selectedDateTo < selectedDateFrom) {
        toast.error("Invalid Date Selected", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        return;
      }
    }
    if (
      activeBusinessName === "All Business" &&
      !selectedDateFrom &&
      !selectedDateTo
    ) {
      setData(tempData);
    } else {
      let filteredData = tempData;

      if (activeBusinessName !== "All Business") {
        filteredData = filteredData?.filter(
          (el) => el.title === activeBusinessName
        );
      }
      // console.log(filteredData,"filteredDatafilteredData ")
      if (selectedDateFrom && selectedDateTo) {
        filteredData = filteredData?.filter((el) => {
          const date = new Date(el.created_at);
          return date >= selectedDateFrom && date <= selectedDateTo;
        });
      }
      setData(filteredData);
    }
  }, [activeBusinessName, selectedDateFrom, selectedDateTo]);

  const tableData = {
    columns,
    data,
  };
  // console.log(tableData, "tableDatacontact");
  const handleReset = () => {
    setActiveBusinessName("All Business");
    setSelectedDateFrom("");
    setSelectedDateTo("");
    // console.log(tempData, "@!#!23");

    setData([...tempData]);
  };

  return (
    <>
      <PageName PageName="Contacts" />

      <article>
        <div class="row d-none">
          <div class="col-md-12">
            <div class="tile">
              <div class="tile-body">
                <form class="row">
                  <div class="mb-2 col-md-3">
                    <input
                      class="form-control"
                      type="text"
                      placeholder="Search....."
                    />
                  </div>
                  <div class="Contact_filterandsearch_wrapper">
                    <div class="mb-2 col-md-3">
                      <select class="form-select">
                        <option value="">Select an option</option>
                        <option value="option1">xxx </option>
                        <option value="option2">xxxx</option>
                      </select>
                    </div>
                    <div class="mb-2 col-md-4 align-self-end">
                      <button class="btn btn-primary me-3" type="button">
                        <i class="bi bi-check-circle-fill ms-2 "></i>Search
                      </button>
                      <button class="btn btn-danger" type="button">
                        <i class="bi bi-check-circle-fill me-2"></i>Reset
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="main">
          {isLoading ? (
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "55%",
                display: "flex",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <CircularLoader size={40} />
            </div>
          ) : (
            <>
             <div className="" style={{ position: 'relative', top: '-64px', width: '70%', left: '20px' }}>
      {/* {maskedContactsExist && (
        <div>
          <marquee style={{ color: "red", fontSize: "14px" }}>
                {maskedContactsExist && <>Your free plan contact quota has been exhausted. Upgrade now to check your upcoming Enquiry.*</>}
              </marquee>
        </div>
        
      )} */}
      {/* Your existing JSX */}
    </div>
    
              <div className="filter-button-wrapper requestcardfilterbuttonWrapper">
                <span class="FilterIconTextWrapper" onClick={handleFilterOpen}>
                  <div className="FilterIcon">
                    {" "}
                    <img src={FilterIcon} width={28} />
                    <div className="filtertext">Filters</div>
                  </div>
                </span>
                <button
                  className="btn btn-primary requestcardfilterbuttonstyle"
                  onClick={handleFilterOpen}
                  id="FilterIconText"
                >
                  Filter
                </button>
              </div>
              <Modal
                open={showFilter}
                onClose={handleFilterClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={Boxstyle}>
                  {showFilter && (
                    <div class="Contact_filter_fieldbutton_wrapper ModalPopupContainer">
                      <div class="Contact_filter_field_wrapper ModalPopupWrapper">
                        <div
                          class="col-md-2 Contact_filter_Inputfield"
                          id="ModalSelectfield"
                        >
                          {/* <h5 className="mb-3">Business Name </h5> */}
                          <select
                            value={activeBusinessName}
                            onChange={(e) => handleChangeBusinessData(e)}
                            class="form-select"
                          >
                            {" "}
                            <option value={"All Business"}>All Business</option>
                            {businessData &&
                              businessData.map((val, index) => (
                                <option value={val}>{val} </option>
                              ))}
                          </select>
                          <div
                            style={{
                              fontSize: "14px",
                              color: "red",
                              bottom: "70px",
                            }}
                          ></div>
                        </div>

                        <div
                          class="col-md-2 Contact_filter_Inputfield"
                          id="ModalSelectfield"
                        >
                          {/* <h5 className="mb-3">From Date </h5> */}
                          <div className="dateiconmain">
                            <DatePicker
                              selected={selectedDateFrom}
                              ref={fromDate}
                              placeholderText="From Date"
                              onChange={
                                (date) =>
                                  setSelectedDateFrom(
                                    date
                                  ) /* Handle date change */
                              }
                              className="form-control"
                              popperPlacement="bottom-end" // Apply appropriate styling
                            />

                            <div
                              class="contact_filter_toDate_calander_icon"
                              onClick={() => fromDate.current.setOpen(true)}
                            >
                              <i class="fa-regular fa-calendar"></i>
                            </div>
                          </div>
                        </div>

                        <div
                          class="col-md-2 Contact_filter_Inputfield"
                          id="ModalSelectfield"
                        >
                          {/* <h5 className="mb-3">To Date </h5> */}
                          <div className="dateiconmain">
                            <DatePicker
                              ref={toDate}
                              selected={selectedDateTo}
                              placeholderText="To Date"
                              onChange={
                                (date) =>
                                  setSelectedDateTo(
                                    date
                                  ) /* Handle date change */
                              }
                              className="form-control"
                              popperPlacement="bottom-end" // Apply appropriate styling
                            ></DatePicker>
                            <div
                              class="contact_filter_toDate_calander_icon"
                              onClick={() => toDate.current.setOpen(true)}
                            >
                              <i class="fa-regular fa-calendar"></i>
                              {/* <i class="fa-regular fa-calendar"></i> */}
                            </div>
                          </div>
                        </div>

                        <div class="contact_filter_buttonwrapper">
                          <button
                            class="contact_filter_buttonstyle"
                            onClick={handleFilterClose}
                          >
                            Apply
                          </button>
                          <button
                            class="contact_filter_buttonstyle"
                            onClick={() => handleReset()}
                          >
                            Reset{" "}
                          </button>
                        </div>
                      </div>
                      <div></div>
                    </div>
                  )}
                </Box>
              </Modal>

              <div className="scrollable-columns">
                <div className="sticky-header-container">
                  <DataTableExtensions
                    {...tableData}
                    {...tableExtensions}
                    customStyles={customStyles}
                    filterPlaceholder="Search Contact"
                  >
                    <DataTable
                      columns={columns}
                      data={data}
                      noHeader
                      defaultSortField="id"
                      sortIcon={<SortIcon />}
                      defaultSortAsc={true}
                      pagination
                      highlightOnHover
                      paginationServer
                      paginationTotalRows={paginationTotalRows}
                      paginationPerPage={paginationPerPage}
                      paginationRowsPerPageOptions={[10,20,50,100,300,1000,10000]} // Customize as needed
                      onChangePage={handlePageChange}
                      onChangeRowsPerPage={handleRowsPerPageChange}
                      onSelectedRowsChange={handleOnSelectedRowsChange}
                      dense
                      customStyles={customStyles}
                    />
                  </DataTableExtensions>
                </div>
              </div>
            </>
          )}
        </div>
      </article>
    </>
  );
}

export default Contacts;
