import React, { useContext, useEffect, useState } from "react";
import "./Card.css";

import { environmentVariables } from "../../../config/env.config";
import CircularLoader from "../../Components/CircularLoader/CircularLoader";
import axios from "axios";
import QRCode from "qrcode.react";
import "./ViewCardStyle.css";
import "./ViewCardDownloadStyle.css";
import { useParams, useNavigate } from 'react-router-dom';
import { userContext } from "../../../context/userContext";

const ViewCardDownload = () => {
  const { id } = useParams();
  const navigate = useNavigate()
  const [frontImage, setFrontImage] = useState(null);
  const [backImage, setBackImage] = useState(null);
  const [styleFile, setStyleFile] = useState("");
  const [fontFile, setFontFile] = useState("");
  const [fontFile2, setFontFile2] = useState("");
  const [styleContent, setStyleContent] = useState(null);
  const [isCardLoaded, setIsCardLoaded] = useState(false);
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const [businessLink, setBusinessLink] = useState("");
  const [data_props, setData_props] = useState({});
  const { userData, setUserData } = useContext(userContext);

  const handlePrint = () => {
    window.print();
  };
  const [storedData, setStoredData] = useState(
    localStorage.getItem("businessObj")
  );

  const frontImg = new Image();  // new
  const backImg = new Image();  // new

  const GetPhysicalCard = async (id) => {
    try {
      await axios.get(
        `${environmentVariables?.apiUrl}api/v1/phy_card/GetPrintPhysicalCard/${id}`,
        {
          headers: {
            _token: userData?.token,
          },
        }
      ).then((res) => {
        const orderedRes = res?.data?.data;
        setData_props({
          "physicalCardLoading": false,
          "card_id": orderedRes?.physical_card_structre?.id,
          "activeCardData": {
            "id": orderedRes?.physical_card_structre?.id,
            "front_image": orderedRes?.physical_card_structre?.front_image,
            "card_name": orderedRes?.physical_card_structre?.card_name,
            "back_image": orderedRes?.physical_card_structre?.back_image,
            "thumbnail_image": orderedRes?.physical_card_structre?.thumbnail_image,
            "font_file": orderedRes?.physical_card_structre?.font_file,
            "font_file2": orderedRes?.physical_card_structre?.font_file2,
            "style_file": orderedRes?.physical_card_structre?.style_file,
            "style_content": orderedRes?.physical_card_structre?.style_content,
            "is_logo": orderedRes?.physical_card_structre?.is_logo,
            "createdAt": orderedRes?.physical_card_structre?.createdAt,
            "updatedAt": orderedRes?.physical_card_structre?.updatedAt,
          },
          "name": orderedRes?.name,
          "degination": orderedRes?.degination,
          "weburl": "www.xyz.com",
          "card_logo": null,
          "logo": "https://vmycards.com/storage/card_logo/logo_16969236651843057845.png"
        })
      })
    } catch (err) {
      setTimeout(() => {
        navigate('/login')
      }, 200)
    }
  }
  useEffect(() => {
    GetPhysicalCard(id)
  }, [])

  const getFont = (cardId) => {
    axios
      .get(
        `${environmentVariables?.apiUrl}api/v1/phy_card/get_fonts/${cardId?.activeCardData?.id}`
      )
      .then((res) => {
        setFontFile(res?.data?.fontFiles[0]?.data);
        setFontFile2(res?.data?.fontFiles[1]?.data);
      })
      .catch((err) => {
      });
  };

  useEffect(() => {
    if (data_props.activeCardData) {
      setFrontImage(
        `${environmentVariables?.apiUrl}${data_props.activeCardData?.card_name}/${data_props.activeCardData?.front_image}`
      );
      setBackImage(
        `${environmentVariables?.apiUrl}${data_props.activeCardData?.card_name}/${data_props.activeCardData?.back_image}`
      );
      setStyleFile(
        `${environmentVariables?.apiUrl}${data_props.activeCardData?.card_name}/${data_props.activeCardData?.style_file}`
      );
      if (data_props.activeCardData?.style_content) {
        setStyleContent(data_props.activeCardData?.style_content);
      }
    }
  }, [data_props.activeCardData]);

  useEffect(() => {
    // Event handler for when both images are loaded
    const handleImagesLoaded = () => {
      setIsImageLoaded(true);
    };

    // Event handler for front image loaded
    const handleFrontImageLoad = () => {
      console.log("Front Image Loaded!");
    };

    // Event handler for back image loaded
    const handleBackImageLoad = () => {
      console.log("Back Image Loaded!");
    };

    // Set source and attach load event listeners
    frontImg.src = `${environmentVariables?.apiUrl}${data_props.activeCardData?.card_name}/${data_props.activeCardData?.front_image}`;
    frontImg.addEventListener("load", handleFrontImageLoad);

    backImg.src = `${environmentVariables?.apiUrl}${data_props.activeCardData?.card_name}/${data_props.activeCardData?.back_image}`;
    backImg.addEventListener("load", handleBackImageLoad);

    // Attach the handleImagesLoaded event once both images are loaded
    Promise.all([frontImg, backImg]).then(handleImagesLoaded);

    // Cleanup event listeners on component unmount
    return () => {
      frontImg.removeEventListener("load", handleFrontImageLoad);
      backImg.removeEventListener("load", handleBackImageLoad);
    };
  }, [data_props.activeCardData]);

  useEffect(() => {
    if (data_props.activeCardData) {
      const css = `
      @font-face {
        font-family: 'dynamic-font';
        src: url(data:application/font-ttf;base64,${fontFile}) format('truetype');
      }
    `;

      const css2 = `
    @font-face {
      font-family: 'dynamic-font2';
      src: url(data:application/font-ttf;base64,${fontFile2}) format('truetype');
    }
  `;

      const blob = new Blob([css], { type: "text/css" });
      const url = URL.createObjectURL(blob);

      const blob2 = new Blob([css2], { type: "text/css" });
      const url2 = URL.createObjectURL(blob2);

      // Create a <link> element to apply the styles
      const link = document.createElement("link");
      link.rel = "stylesheet";
      link.href = url;

      // Append the <link> element to the document's head
      document.head.appendChild(link);

      const link1 = document.createElement("link");
      link1.rel = "stylesheet";
      link1.href = url2;  // Fix: use url2 here
      document.head.appendChild(link1);

      if (styleFile) {
        // console.log(styleFile,"L182")

        const link = document.createElement("link");
        link.href = styleFile;
        link.rel = "stylesheet";
        link.onload = () => {
          setIsCardLoaded(true);
        };
        const style = document.createElement("style");
        style.innerHTML = `
    @font-face {
      font-family: 'dynamic-font';
      src: url('${fontFile}') format('truetype');
    }
    `;
        document.head.appendChild(link);
        document.head.appendChild(style);

        return () => {
          console.log("L199")
          document.head.removeChild(link);
        };
      } else if (styleContent) {
        const styleElement = document.createElement("style");
        styleElement.type = "text/css";
        styleElement.appendChild(document.createTextNode(styleContent));
        document.head.appendChild(styleElement);
        setIsCardLoaded(true);

        return () => {
          document.head.removeChild(styleElement);
        };
      }
    }
  }, [styleFile, fontFile]);

  useEffect(() => {
    if (storedData !== "undefined") {
      const businessData = JSON.parse(storedData);
      const slugb = businessData?.slug;
      const originUrl = window.location.origin;
      setBusinessLink(`${originUrl}/${slugb}`);
    }
  }, [storedData]);

  const PrintContent = () => {
    return (
      <>
        {data_props.physicalCardLoading || !isImageLoaded ? (
          <article id={data_props.card_id}>
            <div className="row justify-content-center">
              <div className="col-md-5">
                <div
                  style={{ display: "flex", justifyContent: "center" }}
                  className="cardviewiiner"
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      textAlign: "center",
                      justifyContent: "center",
                    }}
                  >
                    <CircularLoader size={40} />
                  </div>
                </div>
              </div>
            </div>
          </article>
        ) : (
          <>
            <article id={data_props.card_id}>
              <div className="row justify-content-center">
                <div className="col-md-5">
                  <div className="cardviewiiner">
                    <div className="flip-card">
                      <div className="flip-card-inner1">
                        <div className="flip-card-front1">
                          <div className="pos-r">
                            <img
                              src={frontImage}
                              className="mx-auto d-block img-fluid"
                              loading="lazy"
                            />
                            <div className="caption-front">
                              <h1
                                style={{ fontFamily: "dynamic-font" }}
                                className="u-name Physical_card_one"
                              >
                                {data_props.name}
                              </h1>
                              <p
                                style={{ fontFamily: "dynamic-font2" }}
                                className="u-deg Physical_card_one_backend"
                              >
                                {data_props.degination}
                              </p>
                              <p
                                style={{ fontFamily: "dynamic-font" }}
                                className="u-weblink pos-ab"
                              ></p>
                            </div>
                          </div>
                        </div>

                        <br />
                        <div className="flip-card-back1">
                          <div
                            className="pos-r"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <img src={backImage} className="img-fluid  " />
                            <div
                              className="caption-back"
                              style={{ position: "absolute", zIndex: 999999 }}
                              loading="lazy"
                            >
                              <div className="qr_scanner_wrapper">
                                <QRCode
                                  style={{ height: "128px" }}
                                  className="qrCodeStyle"
                                  value={businessLink}
                                  bgColor="transparent"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </article>
          </>
        )}
      </>
    );
  };

  return (
    <>
      <button className="btn btn-primary icon-btn Admin_deleteplan_button" onClick={handlePrint}>
        Print
      </button>
      <div className="print-container">
        <PrintContent />
      </div>
    </>
  );
};
export default ViewCardDownload;
//712