import React, { useContext, useEffect, useState, useRef } from "react";
import PageName from "../Utils/PageName";
import { Link } from "react-router-dom";
import { environmentVariables } from "../../config/env.config";
import axios from "axios";
import { userContext } from "../../context/userContext";

import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import ReactDOM from "react-dom";
import DataTable from "react-data-table-component";
import SortIcon from "@material-ui/icons/ArrowDownward";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import { businessContext } from "../../context/businessContext";
// import CircularLoader from "../Components/CircularLoader/CircularLoader";
import CircularLoader from "../../Backend/Components/CircularLoader/CircularLoader";
import Swal from "sweetalert2";
import DatePicker from "react-datepicker";
import "./UpdateRequestcardstyle.css";
import Modal from "@mui/material/Modal";
import { Box } from "@mui/material";
import FilterIcon from "../../../src/Images/FilterIcon.png";

function Business({
  isChangeDynamicBusinessName,
  setIsChangeDynamicBusinessName,
  setDynamicBusinessName,
}) {
  const [data, setData] = useState([]);

  const [loading, setLoading] = useState(true);
  const baseUrl = environmentVariables?.apiUrl;
  const { userData, setUserData } = useContext(userContext);
  const { businessData, setBusinessData } = useContext(businessContext);
  const [businesslistData, setBusinesslistData] = useState([]);
  const [isBusiness, setIsBusiness] = useState(false);
  // console.log(businessData, "business111");
  const [isLoader, setIsloader] = useState(true);
  const [userId, setUserId] = useState(null);
  const storedUserData = localStorage.getItem("user");
  const userDataObject = JSON.parse(storedUserData);
  const [isLoading, setIsLoading] = useState(true);

  const [businessLink, setBusinessLink] = useState("");
  const [copied, setCopied] = useState(false);
  const tableExtensions = {
    export: false,
    print: false,
  };
  const fromDate = useRef(null);
  const toDate = useRef(null);
  // console.log(userId, "userId1234");
  // console.log(businessData?.slug, "businessData?.slug,");
  // const customStyles = {
  //   dataTableExtensions: {
  //     backgroundColor: "lightgray",
  //     padding: "10px",
  //   },
  // };

  const Boxstyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    // height: 500,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    zIndex: 999,
    borderRadius: "15px",
    border: "none",
    outline: "none",
    focus: "none",
    boxShadow:
      " rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;",
    padding: "3rem 5rem",
    "@media (max-width: 650px)": {
      width: "425px",
      padding: "2rem",
    },
    "@media (max-width: 430px)": {
      width: "380px",
      padding: "2rem",
    },
    "@media (max-width: 390px)": {
      width: "350px",
      padding: "2rem",
    },
  };

  const customStyles = {
    dataTableExtensions: {
      backgroundColor: "lightgray",
      padding: "10px",
    },
    headRow: {
      style: {
        backgroundColor: "#e9e9e9",
        borderRadius: "2px 2px 0 0",
        paddingLeft: "25px",
        border: "none",
        textTransform: "capitalize",
        position: "sticky",
        left: 0,
        top: 0,
        zIndex: 1,
        boxShadow: "0 2px 2px -1px rgba(0, 0, 0, 0.1)",
        overflow: "hidden",
        whiteSpace: "nowrap",
      },
    },
    headCells: {
      style: {
        color: "#525252",
        fontSize: "16px",
        minHeight: "55px",
      },
    },
    rows: {
      style: {
        padding: "6px 0px 6px 25px",
        fontSize: "14px",
        textTransform: "capitalize",
        minHeight: "58px !important",
      },
    },
    BusinessUserName: {
      position: "sticky",
      left: "0",
      zIndex: "1",
      backgroundColor: "#fff",
      overflow: "hidden",
      whiteSpace: "nowrap",
    },
  };
  const [tempData, setTempData] = useState([]);
  const [tempBusinessList, setTempBusinessList] = useState([]);

  const fetchBusinessData = async (userId) => {
    try {
      // console.log(userId, "userDDDDDDDDDDDDDDD");
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${environmentVariables?.apiUrl}api/v1/business/fetchBusiness?id=${userId}`,  //note: same api on APP.js please check too
        headers: {
          _token: userData?.token,
        },
        withCredentials: true,
      };
      await axios
        .request(config)
        .then((response) => {
          setIsloader(false);
          // console.log(response?.data?.data, "fetch ss admin");

          let newData = response?.data?.data.map((val) => {
            val.created_at = val.created_at.split("T")[0];
            return val;
          });
          newData = newData?.sort(
            (x, y) => new Date(y?.created_at) - new Date(x?.created_at)
          );
          setTempData(newData);
          // console.log(
          //   activeBusinessStatus,
          //   "activeBusinessStatus admin",
          //   businesslistData
          // );
          let filteredData = [];

          if (activeBusinessStatus != "All Status") {
            filteredData = newData.filter(
              (el) => el?.status == activeBusinessStatus
            );
          } else {
            filteredData = newData;
          }
          if (selectedDateFrom && selectedDateTo) {
            filteredData = filteredData?.filter((el) => {
              const date = new Date(el.created_at);
              return date >= selectedDateFrom && date <= selectedDateTo;
            });
          }
          // setTempData(filteredData);
          // console.log(filteredData.length,"admin 34",activeBusinessStatus)
          if (activeBusinessStatus != "") {
            // console.log(filteredData.length,"admin 35 36")
            setBusinesslistData(filteredData);
          } else {
            setBusinesslistData(newData);
          }
        })
        .catch((error) => {
          setIsloader(false);
          // console.log(error?.response?.data?.message, "hell in error ");
        });
      // setBusinesslistData(newData);
      setIsLoading(false);
    } catch (err) {
      console.log(err, "error");
    }
  };

  useEffect(() => {
    // console.log(userId, "E");
    // if (userId) {
      fetchBusinessData(userId);
    // }
  }, [userId]);

  useEffect(() => {
    // console.log(userDataObject, "@!userDataObject");
    if (userDataObject) {
      if (userDataObject && userDataObject.created_by != 0) {
        if (
          userDataObject?.permission.some(
            (obj) =>
              obj.permissionobj?.name?.includes("show business") ||
              obj.permissionobj?.name?.includes("create business")
          )
        ) {
          setUserId(userDataObject.created_by);
        }
      } else {
        const userId = userDataObject.id;
        setUserId(userId); // Set the 'userId' state
      }
      // console.log(userId, "userid");
    } else {
      console.log("Failed to parse user data from storage.");
    }
  }, []);

  const navigate = useNavigate();

  const handleTabClick = (route) => {
    // console.log(route, "routeinbusinesslist");
    navigate(route);
  };

  const handleviewClick = (slug) => {
    if (window != undefined) {
      window.open(`/${slug}`, "_blank");
    }
  };
  const [activeBusinessStatus, setBusinessStatus] = useState("");

  const handleToggleStatus = (businessId, currentStatus) => {
    const newStatus = currentStatus === "active" ? "inactive" : "active";
    // Make an API call to update the status
    axios
      .put(
        `${baseUrl}api/v1/business/updateStatus/${businessId}`,
        { status: newStatus },
        {
          headers: {
            _token: userData?.token,
          },
          // withCredentials: true
        }
      )
      .then((response) => {
        toast.success(response?.data?.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        fetchBusinessData();
        const updatedBusinessListData = businesslistData.map((business) => {
          if (business.id === businessId) {
            return { ...business, status: newStatus };
          }
          return business;
        });
        setBusinesslistData(updatedBusinessListData);
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        console.error("Error updating business status:", error);
      });
  };

  const handleDeleteUser = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        // let data = JSON.stringify({
        //   userId: id,
        // });

        let config = {
          method: "delete",
          maxBodyLength: Infinity,
          url: `${environmentVariables?.apiUrl}api/v1/business/deleteBusiness?id=${id}`,
          headers: {
            _token: userData?.token,
            "Content-Type": "application/json",
          },
          // withCredentials: true,
        };

        axios
          .request(config)
          .then((response) => {
            setIsChangeDynamicBusinessName(!isChangeDynamicBusinessName);
            Swal.fire(
              "Deleted!",
              "Selected Business has been deleted.",
              "success"
            );
            fetchBusinessData();
            window.location.reload(2000);
            setDynamicBusinessName(businessData[0]?.title);
          })
          .catch((error) => {
            Swal.fire(
              "Error!",
              error?.response?.data?.message || error?.message,
              "error"
            );
          });
      }
    });
  };

  const handleCopuClick1 = (slugb) => {
    const originUrl = window.location.origin;
    setBusinessLink(`${originUrl}/${slugb}`);
    navigator.clipboard
      .writeText(businessLink)
      .then((res) => {
        setCopied(true);
      })
      .catch((error) => {
        console.error("Failed to copy link: ", error);
      });
    toast.info("Link Copy on Clipboard", {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 1000,
    });
  };

  const handleCopuClick = (slugb) => {
    const originUrl = window.location.origin;
    const linkToCopy = `${originUrl}/${slugb}`;
    setBusinessLink(linkToCopy);
  };

  useEffect(() => {
    if (businessLink) {
      navigator.clipboard
        .writeText(businessLink)
        .then((res) => {
          setCopied(true);
        })
        .catch((error) => {
          console.error("Failed to copy link: ", error);
        });
      toast.info("Link Copy on Clipboard", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    }
  }, [businessLink]);

  const columns = [
    {
      name: "User Name",
      selector: (row) => row?.userObj?.name || userData?.name,
      sortable: true,
      fixed: "left",
      // style: customStyles.BusinessUserName,
    },
    {
      name: "Business Name",
      selector: (row) => row.title,
      sortable: true,
      cell: (d) => {
        const capitalizedName =
          d.title.charAt(0).toUpperCase() + d.title.slice(1);
        return capitalizedName;
      },
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
      cell: (d) => {
        const capitalizedName =
          d.status.charAt(0).toUpperCase() + d.status.slice(1);
        return capitalizedName;
      },
    },
    {
      name: "Date",
      // selector: (row) => row.created_at,
      selector: (row) => {
        let dateString=new Date(row?.created_at).toLocaleDateString()
        const [month, day,year] = dateString.split("/");
        return `${day}/${month}/${year}`;
      },
      // selector: (row) => {
      //   let dateString = row?.created_at.split(" ")[0];
      //   const [year, month, day] = dateString.split("-");
      //   return `${day}/${month}/${year}`;
      // },
      sortable: true,
    },
    {
      name: "Active / In-Active",
      sortable: false,
      cell: (d) => (
        <div>
          {userDataObject?.user_type == "super_admin" ? (
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                checked={d?.status == "active" ? true : false}
                id={`flexSwitchCheckDefault_${d.id}`}
                onClick={() => {
                  // console.log(d,"dddddddddd")
                  handleToggleStatus(d.id, d.status);
                }}
              />
            </div>
          ) : userDataObject && userDataObject?.created_by != 0 ? (
            userDataObject &&
            userDataObject?.permission &&
            userDataObject?.permission.length &&
            userDataObject?.permission?.some((obj) =>
              obj?.permissionobj?.name?.includes("manage business")
            ) ? (
              <div className="form-check form-switch">
                <input
                  className="form-check-input"
                  // type="checkbox"
                  type="checkbox"
                  checked={d?.status == "active" ? true : false}
                  id={`flexSwitchCheckDefault_${d.id}`}
                  onClick={() => {
                    // console.log(d,"dddddddddd")
                    handleToggleStatus(d.id, d.status);
                  }}
                />
              </div>
            ) : (
              "--"
            )
          ) : (
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                // type="checkbox"
                type="checkbox"
                checked={d?.status == "active" ? true : false}
                id={`flexSwitchCheckDefault_${d.id}`}
                onClick={() => {
                  // console.log(d,"dddddddddd")
                  handleToggleStatus(d.id, d.status);
                }}
              />
            </div>
          )}
        </div>
      ),
    },
    {
      name: "Action",
      sortable: false,
      cell: (d) => (
        <div>
          {userDataObject?.user_type == "super_admin" ? (
            <i
              key={`edit_${d.id}`}
              onClick={() => handleTabClick(`/business?id=${d.id}`)}
              className="first fas fa-pen"
            ></i>
          ) : userDataObject && userDataObject?.created_by != 0 ? (
            userDataObject &&
            userDataObject?.permission &&
            userDataObject?.permission.length &&
            userDataObject?.permission?.some((obj) =>
              obj?.permissionobj?.name?.includes("manage business")
            ) ? (
              <i
                key={`edit_${d.id}`}
                onClick={() => handleTabClick(`/business?id=${d.id}`)}
                className="first fas fa-pen"
              ></i>
            ) : (
              ""
            )
          ) : (
            <i
              key={`edit_${d.id}`}
              onClick={() => handleTabClick(`/business?id=${d.id}`)}
              className="first fas fa-pen"
            ></i>
          )}
          {userDataObject?.user_type == "super_admin" ? (
            <i
              style={{ width: "50px" }}
              onClick={() => handleDeleteUser(d.id)}
              className="fas fa-trash-alt"
            ></i>
          ) : userDataObject && userDataObject?.created_by != 0 ? (
            userDataObject &&
            userDataObject?.permission &&
            userDataObject?.permission.length &&
            userDataObject?.permission?.some((obj) =>
              obj?.permissionobj?.name?.includes("create business")
            ) ? (
              <i
                style={{ width: "50px" }}
                onClick={() => handleDeleteUser(d.id)}
                className="fas fa-trash-alt"
              ></i>
            ) : (
              ""
            )
          ) : (
            <i
              style={{ width: "50px" }}
              onClick={() => handleDeleteUser(d.id)}
              className="fas fa-trash-alt"
            ></i>
          )}

          <i
            key={`view_${d.id}`}
            onClick={() => handleviewClick(d.slug)}
            className="first fas fa-eye"
          ></i>
          <i
            key={`copy_${d.id}`}
            onClick={() => handleCopuClick(d.slug)}
            className="first fas fa-copy"
          ></i>
        </div>
      ),
    },
  ];

  const tableData = {
    columns,
    data: businesslistData.length === 0 ? businessData : businesslistData
  };
  console.log(businessData,businesslistData,tableData,"tableDatatableDatatableDatatableData")
  const [selectedDateFrom, setSelectedDateFrom] = useState("");
  const [selectedDateTo, setSelectedDateTo] = useState("");
  const [showFilter, setShowFilter] = useState(false);

  const handleFilterOpen = () => {
    setShowFilter(!showFilter);
  };

  const handleFilterClose = () => {
    setShowFilter(false);
  };
  const handleChangeBusinessStatus = (e) => {
    // console.log(e.target.value,"e.target.value admin fetch")
    if (e.target.value == "active") {
      setBusinessStatus("active");
    } else if (e.target.value == "inactive") {
      setBusinessStatus("inactive");
    } else if (e.target.value == "All Status") {
      setBusinessStatus("All Status");
    }
  };

  useEffect(() => {
    if (selectedDateTo != "" && selectedDateFrom != "") {
      if (selectedDateTo < selectedDateFrom) {
        toast.warning("To Date should be equal to or greater then From Date", {
          position: toast.POSITION.TOP_RIGHT,
        });
        return;
      }
    }
    if (
      activeBusinessStatus == "All Status" &&
      !selectedDateFrom &&
      !selectedDateTo
    ) {
      setBusinesslistData(tempData);
    } else {
      let filteredData = tempData;
      if (activeBusinessStatus != "" && activeBusinessStatus != "All Status") {
        filteredData = filteredData.filter(
          (el) => el?.status == activeBusinessStatus
        );
      }
      if (selectedDateFrom && selectedDateTo) {
        console.log(filteredData, "filteredData fetch admin");

        filteredData = filteredData.filter((el) => {
          const date = new Date(el.created_at);
          return date >= selectedDateFrom && date <= selectedDateTo;
        });
      }
      // setTempData(filteredData);
      setBusinesslistData(filteredData);
    }
  }, [activeBusinessStatus, selectedDateFrom, selectedDateTo]);

  useEffect(() => {
    let Mydata = businesslistData.length === 0 ? businessData : businesslistData;
    setIsloader(false);
    if (Mydata && Mydata.length > 0) {
      setIsloader(false);
    } 
  }); 

  const handleReset = () => {
    setBusinessStatus("All Status");
    setBusinesslistData(tempData);
    setSelectedDateFrom("");
    setSelectedDateTo("");
  };

  return (
    <div>
      <PageName PageName="Business Information" />

      <article>
        <div class="row d-none" style={{ position: "relative" }}>
          <div class="col-md-12">
            <div class="tile">
              <div class="tile-body">
                <form class="row">
                  <div class="mb-2 col-md-3">
                    <input
                      class="form-control"
                      type="text"
                      placeholder="Search....."
                    />
                  </div>
                  <div class="mb-2 col-md-3">
                    <select class="form-select">
                      <option value="">Select an option</option>
                      <option value="option1">xxx </option>
                      <option value="option2">xxxx</option>
                    </select>
                  </div>
                  <div class="mb-2 col-md-4 align-self-end">
                    <button class="btn btn-primary me-3" type="button">
                      <i class="bi bi-check-circle-fill ms-2 "></i>Search
                    </button>
                    <button class="btn btn-danger" type="button">
                      <i class="bi bi-check-circle-fill me-2"></i>Reset
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        {isLoader ? (
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "55%",
              display: "flex",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <CircularLoader size={40} />
          </div>
        ) : (
          <div className="main">
            {/* {businesslistData && businesslistData?.length > 0 && ( */}
            <>
              <div className="filter-button-wrapper requestcardfilterbuttonWrapper">
                <span class="FilterIconTextWrapper" onClick={handleFilterOpen}>
                  {" "}
                  {/* <i class="fa fa-filter" aria-hidden="true"></i> */}
                  <div className="FilterIcon">
                    {" "}
                    <img src={FilterIcon} width={28} />
                    <div className="filtertext">Filters</div>
                  </div>
                </span>
                <button
                  className="btn btn-primary requestcardfilterbuttonstyle"
                  onClick={handleFilterOpen}
                  id="FilterIconText"
                >
                  Filter
                </button>
              </div>
              <Modal
                open={showFilter}
                onClose={handleFilterClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={Boxstyle}>
                  {showFilter && (
                    <div class="Contact_filter_fieldbutton_wrapper ModalPopupContainer">
                      <div class="Contact_filter_field_wrapper ModalPopupWrapper">
                        <div
                          class="col-md-2 Contact_filter_Inputfield"
                          id="ModalSelectfield"
                        >
                          {/* <h5 className="mb-3">Filter by Status</h5> */}
                          <select
                            value={activeBusinessStatus}
                            onChange={(e) => handleChangeBusinessStatus(e)}
                            class="form-select"
                          >
                            <option value={"All Status"}>All Status</option>
                            <option value="active">Active </option>
                            <option value="inactive">In-Active </option>
                          </select>
                          <div
                            style={{
                              fontSize: "14px",
                              color: "red",
                              bottom: "70px",
                            }}
                          ></div>
                        </div>
                        <div
                          class="col-md-2 Contact_filter_Inputfield"
                          id="ModalSelectfield"
                        >
                          {/* <h5 className="mb-3">From Date </h5> */}
                          <div className="dateiconmain">
                            <DatePicker
                              placeholderText="From Date"
                              selected={selectedDateFrom}
                              onChange={(date) => setSelectedDateFrom(date)}
                              className="form-control"
                              ref={fromDate}
                              dateFormat="dd/MM/yyyy"
                              popperPlacement="bottom-end"
                            />
                            <div
                              class="contact_filter_toDate_calander_icon"
                              onClick={() => fromDate.current.setOpen(true)}
                            >
                              <i class="fa-regular fa-calendar"></i>
                            </div>
                          </div>
                        </div>

                        <div
                          class="col-md-2 Contact_filter_Inputfield"
                          id="ModalSelectfield"
                        >
                          {/* <h5 className="mb-3">To Date </h5> */}
                          <div className="dateiconmain">
                            <DatePicker
                              placeholderText="To Date"
                              selected={selectedDateTo}
                              onChange={(date) => setSelectedDateTo(date)}
                              className="form-control"
                              ref={toDate}
                              dateFormat="dd/MM/yyyy"
                              popperPlacement="bottom-end"
                            />
                            <div
                              class="contact_filter_toDate_calander_icon"
                              onClick={() => toDate.current.setOpen(true)}
                            >
                              <i class="fa-regular fa-calendar"></i>
                            </div>
                          </div>
                        </div>
                        {/* <div class="mb-2 col-md-2 Contact_filter_Inputfield">
                      <h5 className="mb-3">From Date </h5>
                      <DatePicker
                        selected={selectedDateFrom}
                        onChange={(date) => setSelectedDateFrom(date)}
                        className="form-control"
                        ref={fromDate}
                        dateFormat="dd/MM/yyyy"
                      />
                      <div
                        class="contact_filter_toDate_calander_icon"
                        onClick={() => fromDate.current.setOpen(true)}
                      >
                        <i class="fa-regular fa-calendar"></i>
                      </div>
                    </div> */}
                        {/* <div class="mb-2 col-md-2 Contact_filter_Inputfield">
                      <h5 className="mb-3">To Date </h5>
                      <DatePicker
                        selected={selectedDateTo}
                        onChange={(date) => setSelectedDateTo(date)}
                        className="form-control"
                        ref={toDate}
                        dateFormat="dd/MM/yyyy"
                      />
                      <div
                        class="contact_filter_toDate_calander_icon"
                        onClick={() => toDate.current.setOpen(true)}
                      >
                        <i class="fa-regular fa-calendar"></i>
                      </div>
                    </div> */}
                        <div class="contact_filter_buttonwrapper">
                          <button
                            class="contact_filter_buttonstyle"
                            onClick={handleFilterClose}
                          >
                            Close
                          </button>
                          <button
                            class="contact_filter_buttonstyle"
                            onClick={() => handleReset()}
                          >
                            Reset{" "}
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </Box>
              </Modal>
            </>
            {/* // )} */}

            <div className="scrollable-columns">
              <div className="sticky-header-container">
                <DataTableExtensions
                  {...tableData}
                  {...tableExtensions}
                  customStyles={customStyles}
                  filterPlaceholder="Search Business"
                >
                  <DataTable
                    columns={columns}
                    data={data}
                    noHeader
                    defaultSortField="id"
                    sortIcon={<SortIcon />}
                    defaultSortAsc={true}
                    pagination
                    highlightOnHover
                    customStyles={customStyles}
                  />
                </DataTableExtensions>
              </div>
            </div>
          </div>
          )}
      </article>
    </div>
  );
}

export default Business;