import React, { useEffect, useContext } from "react";
import { useState } from "react";
import { userContext } from "../../context/userContext";
import axios from "axios";
import { environmentVariables } from "../../config/env.config";
import "./../Pages/Updatedcss/HeaderUpdatedStyle.css";

import { Link, NavLink } from "react-router-dom";
import styled from "styled-components";

const LogoutButtonContainer = styled.div`
  display: none;

  @media (max-width: 768px) {
    display: block;
    margin-left: 20px;
  }
`;

const LogoutButton = styled.div`
  border: none;
  border-radius: 6px;
  color: #fff;
  font-weight: 700;
  margin-top: 10px;
  padding: 10px 14px;
  background: linear-gradient(to right, #01c3ccdb, #2a76e8f2);
`;

function Sidebar({ isSidebarVisible, handleToggleSidebar }) {
  const sidebarClass = `app-sidebar ${isSidebarVisible ? "visible" : ""}`;
  const { userData, setUserData } = useContext(userContext);
  const storedData = localStorage.getItem("user");
  const userDataObj = JSON.parse(storedData);
  const [userPermission, setUserPermission] = useState([]);
  const user_type = userDataObj?.user_type;
  const [planName, setPlanName] = useState("");
  const baseUrl = environmentVariables?.apiUrl;
  const isMobileDevice = window.innerWidth <= 768;
  useEffect(() => {
    if (userData && userData.id) {
      setUserPermission(userData?.permission);
    }
  }, [userData]);
  const Dropmenu = (event) => {
    event?.currentTarget?.classList.toggle("is-expanded");
  };

  const [expandedItems, setExpandedItems] = useState([]);
  const handleToggle = (event, item) => {
    event.preventDefault();
    if (expandedItems.includes(item)) {
      setExpandedItems(
        expandedItems.filter((expandedItem) => expandedItem !== item)
      );
    } else {
      setExpandedItems([item]);
    }
  };

  const getPlanName = () => {
    axios
      .get(`${baseUrl}api/v1/plan/fetchPlanByIdForUser`, {
        params: { planId: userData?.plan },
        headers: {
          _token: userData?.token,
        },
      })
      .then((res) => {
        setPlanName(res?.data?.result?.name);
      })
      .catch((err) => {
        console.log({ err: err });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    // console.log("one time only",userData?.plan)
    getPlanName();
  }, []);
  useEffect(()=>{
    // console.log("at time of userData",userData?.plan)
    getPlanName();
  },[userData])
  const handleLogout = () => {
    localStorage.clear();
    setUserData(null);
  };

  const MenuAdmin=()=>{
    return ( <>
    <li>
      <NavLink
        to="/super_dashboard"
        className="app-menu__item"
        href="super_dashboard.html"
      >
        <i className="app-menu__icon fa fa-home"></i>
        <span className="app-menu__label">Dashboard</span>
      </NavLink>
    </li>
    <li>
      <NavLink
        to="/businesslist"
        className="app-menu__item"
        href="dashboard.html"
      >
        <i className="app-menu__icon fa-solid fa-business-time"></i>
        <span className="app-menu__label">Business</span>
      </NavLink>
    </li>
    <li className="treeview" onClick={Dropmenu}>
      <a className="app-menu__item" href="#" data-toggle="treeview">
        <i className="app-menu__icon fa-solid fa-users"></i>
        <span className="app-menu__label">Staff</span>
        <i className="treeview-indicator bi bi-chevron-right"></i>
      </a>
      <ul className="treeview-menu">
        <li>
          <NavLink
            to="/Roles"
            className="treeview-item"
            href="form-samples.html"
          >
            <div className="pointcircal"></div> Roles
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/user"
            className="treeview-item"
            href="form-components.html"
          >
            <div className="pointcircal"></div> Users
          </NavLink>
        </li>
      </ul>
    </li>


    <li className="treeview" onClick={Dropmenu}>
      <a className="app-menu__item" href="#" data-toggle="treeview">
        <i className="app-menu__icon fa-solid fa-credit-card"></i>
        <span className="app-menu__label">Physical Card</span>
        <i className="treeview-indicator bi bi-chevron-right"></i>
      </a>
      <ul className="treeview-menu">
        <li>
          <NavLink className="treeview-item" to="/requestCard_admin">
            <div className="pointcircal"></div> Orders
          </NavLink>
        </li>
      </ul>
    </li>
    <li>
      <NavLink
        to="/PlansSuperAdmin"
        className="app-menu__item"
        href="docs.html"
      >
        <i className="app-menu__icon bi bi-ui-checks"></i>
        <span className="app-menu__label">Plans</span>
      </NavLink>
    </li>
    {/* <li>
      <NavLink
        to="/CustomizedPlansSuperAdmin"
        className="app-menu__item"
        href="docs.html"
      >
        <i className="app-menu__icon bi bi-ui-checks"></i>
        <span className="app-menu__label">Customized Plans</span>
      </NavLink>
    </li> */}
    <li>
      <NavLink
        to="/Coupons_admin"
        className="app-menu__item"
        href="docs.html"
      >
        <i className="app-menu__icon fa-solid fa-gift"></i>
        <span className="app-menu__label">Coupons</span>
      </NavLink>
    </li>
    <li>
      <NavLink
        to="/Currency_admin"
        className="app-menu__item"
        href="docs.html"
      >
        <i className="app-menu__icon fa-solid fa-indian-rupee-sign"></i>
        <span className="app-menu__label">Currency</span>
      </NavLink>
    </li>
    <li>
      <NavLink to="/addPhysicalCard" className="app-menu__item">
        <i className="app-menu__icon fa-regular fa-credit-card"></i>
        <span className="app-menu__label">Add Physical Card</span>
      </NavLink>
    </li>
    <li>
    <NavLink
          to="/addshipping"
          className="app-menu__item"
          // onClick={() => isMobileDevice && handleToggleSidebar()}
        >
          <i className="app-menu__icon fa-solid fa-plus"></i>
          <span
            className="app-menu__label"
            style={{ textAlign: "start" }}
          >
            Add shipping
          </span>
        </NavLink>
    </li>

    <li className="treeview" onClick={Dropmenu}>
      <a className="app-menu__item" href="#" data-toggle="treeview">
        <i className="app-menu__icon fa-solid fa-users"></i>
        <span className="app-menu__label">Bulk</span>
        <i className="treeview-indicator bi bi-chevron-right"></i>
      </a>
      <ul className="treeview-menu">
        <li>
          <NavLink
            to="/bulk_upload"
            className="treeview-item"
            href="form-samples.html"
          >
            <div className="pointcircal"></div> Bulk Upload
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/userUploadList"
            className="treeview-item"
            href="form-components.html"
          >
            <div className="pointcircal"></div> Imported Users
          </NavLink>
        </li>
      </ul>
    </li>
    <li>
      <NavLink
        to="/Setting_admin"
        className="app-menu__item"
        href="docs.html"
      >
        <i className="app-menu__icon fa-solid fa-gear"></i>
        <span className="app-menu__label">Settings</span>
      </NavLink>
    </li>

    <li>
      <NavLink
        to="/mailtemplate"
        className="app-menu__item"
        href="docs.html"
      >
        <i className="app-menu__icon fa-solid fa-synagogue"></i>
        <span className="app-menu__label">Mail Template</span>
      </NavLink>
    </li>


  </>)
  }

  const MenuUser=()=>{
    return ( <>
    {userDataObj?.created_by != 0 ? (
      userPermission &&
      userPermission.length &&
      userDataObj?.created_by != 0 &&
      userPermission.some((obj) =>
        obj.permission_id==1
      ) ? (
        <li>
          <NavLink
            to="/dashboard"
            className="app-menu__item"
            href="dashboard.html"
            onClick={() => isMobileDevice && handleToggleSidebar()}
          >
            <i className="app-menu__icon fa fa-home"></i>
            <span className="app-menu__label"> Dashboard</span>
            <span className="plan_name">{planName}</span>
          </NavLink>
        </li>
      ) : (
        ""
      )
    ) : (
      <li>
        <NavLink
          to="/dashboard"
          className="app-menu__item"
          href="dashboard.html"
          onClick={() => isMobileDevice && handleToggleSidebar()}
        >
          <i className="app-menu__icon fa fa-home"></i>
          <span
            className="app-menu__label"
            style={{ textAlign: "start" }}
          >
            {" "}
            Dashboard{" "}
          </span>
          <span className="plan_name">{planName}</span>
        </NavLink>
      </li>
    )}
    {userDataObj?.created_by != 0 ? (
      userPermission &&
      userPermission.length &&
      userPermission.some(
        (obj) =>
          obj.permission_id==17 || obj.permission_id==18
      ) ? (
        <li>
          <NavLink
            to="/businesslist"
            className="app-menu__item"
            href="dashboard.html"
            onClick={() => isMobileDevice && handleToggleSidebar()}
          >
            <i className="app-menu__icon fa-solid fa-business-time"></i>
            <span className="app-menu__label">Business</span>
          </NavLink>
        </li>
      ) : (
        ""
      )
    ) : (
      <li>
        <NavLink
          to="/businesslist"
          className="app-menu__item"
          href="dashboard.html"
          onClick={() => isMobileDevice && handleToggleSidebar()}
        >
          <i className="app-menu__icon fa-solid fa-business-time"></i>
          <span
            className="app-menu__label"
            style={{ textAlign: "start" }}
          >
            Business
          </span>
        </NavLink>
      </li>
    )}
    <li
      className={`treeview ${
        expandedItems.includes("item1") ? "is-expanded" : ""
      }`}
    >
      {userDataObj?.created_by != 0 ? (
        userPermission &&
        userPermission.length &&
        userPermission.some(
          (obj) =>
            obj.permissionobj?.name?.includes("create user") ||
            obj.permissionobj?.name?.includes("create role")
        ) ? (
          <a
            className="app-menu__item"
            onClick={(e) => handleToggle(e, "item1")}
            data-toggle="treeview"
          >
            <i className="app-menu__icon fa-solid fa-users"></i>
            <span className="app-menu__label">Staff</span>
            <i className="treeview-indicator bi bi-chevron-right"></i>
          </a>
        ) : (
          ""
        )
      ) : (
        <a
          className="app-menu__item"
          onClick={(e) => handleToggle(e, "item1")}
          data-toggle="treeview"
        >
          <i className="app-menu__icon fa-solid fa-users"></i>
          <span
            className="app-menu__label"
            style={{ textAlign: "start" }}
          >
            Staff
          </span>
          <i className="treeview-indicator bi bi-chevron-right"></i>
        </a>
      )}
      <ul className="treeview-menu">
        {userDataObj?.created_by != 0 ? (
          userPermission &&
          userPermission.length &&
          userPermission.some((obj) =>
            obj?.permissionobj?.name?.includes("create role")
          ) ? (
            <li>
              <NavLink
                to="/Roles"
                className="treeview-item"
                href="form-samples.html"
                onClick={() =>
                  isMobileDevice && handleToggleSidebar()
                }
              >
                <div className="pointcircal"></div> Roles
              </NavLink>
            </li>
          ) : (
            ""
          )
        ) : (
          <li>
            <NavLink
              to="/Roles"
              className="treeview-item"
              href="form-samples.html"
              onClick={() => isMobileDevice && handleToggleSidebar()}
            >
              <div className="pointcircal"></div> Roles
            </NavLink>
          </li>
        )}
        {userDataObj?.created_by != 0 ? (
          userPermission &&
          userPermission.length &&
          userPermission.some((obj) =>
            obj.permissionobj?.name?.includes("create user")
          ) ? (
            <li>
              <NavLink
                to="/user"
                className="treeview-item"
                href="form-components.html"
                onClick={() =>
                  isMobileDevice && handleToggleSidebar()
                }
              >
                <div className="pointcircal"></div> Users
              </NavLink>
            </li>
          ) : (
            ""
          )
        ) : (
          <li>
            <NavLink
              to="/user"
              className="treeview-item"
              href="form-components.html"
              onClick={() => isMobileDevice && handleToggleSidebar()}
            >
              <div className="pointcircal"></div> Users
            </NavLink>
          </li>
        )}
      </ul>
    </li>
    <li
      className={`treeview ${
        expandedItems.includes("item2") ? "is-expanded" : ""
      }`}
    >
      {userDataObj?.created_by != 0 ? (
        userPermission &&
        userPermission?.length &&
        userPermission?.some(
          (obj) =>
          obj.permission_id==97 ||
          obj.permission_id==96
        ) ? (
          <a
            className="app-menu__item"
            onClick={(e) => handleToggle(e, "item2")}
            data-toggle="treeview"
          >
            <i className="app-menu__icon fa-solid fa-credit-card"></i>
            <span className="app-menu__label">Physical Card</span>
            <i className="treeview-indicator bi bi-chevron-right"></i>
          </a>
        ) : (
          ""
        )
      ) : (
        <a
          className="app-menu__item"
          onClick={(e) => handleToggle(e, "item2")}
          data-toggle="treeview"
        >
          <i className="app-menu__icon fa-solid fa-credit-card"></i>
          <span
            className="app-menu__label"
            style={{ textAlign: "start" }}
          >
            Physical Card
          </span>
          <i className="treeview-indicator bi bi-chevron-right"></i>
        </a>
      )}
      <ul className="treeview-menu">
        {userDataObj?.created_by != 0 ? (
          userPermission &&
          userPermission.length &&
          userPermission.some((obj) =>
            obj.permission_id==96
          ) ? (
            <li>
              <NavLink
                className="treeview-item"
                to="/create-card"
                onClick={() =>
                  isMobileDevice && handleToggleSidebar()
                }
              >
                <div className="pointcircal"></div> Create Card{" "}
              </NavLink>
            </li>
          ) : (
            ""
          )
        ) : (
          <>
            <li>
              <NavLink
                className="treeview-item"
                to="/create-card"
                onClick={() =>
                  isMobileDevice && handleToggleSidebar()
                }
              >
                <div className="pointcircal"></div> Create Card{" "}
              </NavLink>
            </li>
          </>
        )}
        <li>
          <NavLink
            className="treeview-item"
            to="/requestCard"
            onClick={() => isMobileDevice && handleToggleSidebar()}
          >
            <div className="pointcircal"></div> Orders
          </NavLink>
        </li>
      </ul>
    </li>
    {userDataObj?.created_by != 0 ? (
      userPermission &&
      userPermission?.length &&
      userPermission?.some((obj) =>
        obj.permissionobj?.name?.includes("manage plan")
      ) ? (
        ""
      ) : (
        ""
      )
    ) : (
      <li>
        <NavLink
          to="/plans"
          className="app-menu__item"
          href="docs.html"
          onClick={() => isMobileDevice && handleToggleSidebar()}
        >
          <i className="app-menu__icon fa-regular fa-handshake"></i>
          <span
            className="app-menu__label"
            style={{ textAlign: "start" }}
          >
            Plans
          </span>
        </NavLink>
      </li>
    )}
    {userDataObj?.created_by != 0 ? (
      userPermission &&
      userPermission.length &&
      userPermission.some(
        (obj) =>
          obj.permission_id==27 || obj.permission_id==25
      ) ? (
        <>
          <li>
          <NavLink
            to="/appoinments"
            className="app-menu__item"
            href="docs.html"
            onClick={() => isMobileDevice && handleToggleSidebar()}
          >
            <i className="app-menu__icon fa-regular fa-handshake"></i>
            <span className="app-menu__label">Appointments</span>
          </NavLink>
        </li>
        </>
      ) : (
        ""
      )
    ) : (
      <>
        <li>
          <NavLink
            to="/appoinments"
            className="app-menu__item"
            href="form-samples.html"
            onClick={() => isMobileDevice && handleToggleSidebar()}
          >
            <i className="app-menu__icon fa-solid fa-handshake"></i>
            <span
              className="app-menu__label"
              style={{ textAlign: "start" }}
            >
              Appointments
            </span>
          </NavLink>
        </li>
      </>
    )}
    {userDataObj?.created_by != 0 ? (
      userPermission &&
      userPermission.length &&
      userPermission.some(
        (obj) =>
          obj.permission_id==77 || obj.permission_id==78
      ) ? (
        <li>
          <NavLink
            to="/contact"
            className="app-menu__item"
            href="docs.html"
            onClick={() => isMobileDevice && handleToggleSidebar()}
          >
            <i className="app-menu__icon fa-solid fa-address-book"></i>
            <span className="app-menu__label">Contacts</span>
          </NavLink>
        </li>
      ) : (
        ""
      )
    ) : (
      <li>
        <NavLink
          to="/contact"
          className="app-menu__item"
          href="docs.html"
          onClick={() => isMobileDevice && handleToggleSidebar()}
        >
          <i className="app-menu__icon fa-solid fa-address-book"></i>
          <span
            className="app-menu__label"
            style={{ textAlign: "start" }}
          >
            Contacts
          </span>
        </NavLink>
        <NavLink
          to="/profile"
          className="app-menu__item"
          onClick={() => isMobileDevice && handleToggleSidebar()}
        >
          <i className="app-menu__icon fa-solid fa-user-circle"></i>
          <span
            className="app-menu__label"
            style={{ textAlign: "start" }}
          >
            Profile
          </span>
        </NavLink>
        
      </li>
    )}
  </>
    )
  }

  return (
    <>
      <div
        className="app-sidebar__overlay"
        data-toggle="sidebar"
        id="app_sideBarStyle_wrapper"
      ></div>
      <aside className={sidebarClass} id="app_sidebarStyle_maincontainer">
        <ul className="app-menu">
          <div
            className="MobilesidebarcloseButton"
            onClick={() => isMobileDevice && handleToggleSidebar()}
          >
            {/* <i class="fa-solid fa-square-xmark"></i> */}
            <button type="button" class="btn-close" aria-label="Close"></button>
          </div>
          {user_type === "super_admin" && (
           <MenuAdmin></MenuAdmin>
          )}

          {user_type != "super_admin" && (
            <MenuUser></MenuUser>
          )}

          <LogoutButtonContainer>
            <LogoutButton
              style={{
                display: "flex",
                width: "50%",
                justifyContent: "center",
              }}
            >
              <Link
                to="/"
                onClick={handleLogout}
                style={{ color: "#fff", fontSize: "16px" }}
              >
                Logout
              </Link>
            </LogoutButton>
          </LogoutButtonContainer>
        </ul>
      </aside>
    </>
  );
}
//112 16:28
export default Sidebar;
