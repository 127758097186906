import React, { useEffect, useState } from "react";
// import
import { defaultPic } from "../../../Images/placeholder-imagevmycard.jpg";
import { environmentVariables } from "../../../config/env.config";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import styled from "styled-components";

const FormikErrorStyle = styled.div`
font-size:11px;
color: #ff3333;
padding-bottom: 5px;
`;

const ServiceForm = ({
  formCount,
  servicesData,
  setIsAddServiceDisable,
  setServicesData,
  removeForm,
  index,
}) => {
  const [data, setData] = useState({
    formCount: formCount,
    banner: null,
    name: "",
    description: "",
    buttonLink: "",
    buttonName: "",
    bannerImage: null,
    isImageUpload: true,
  });

  const [tempFormCount, setTempFormCount] = useState(0);
  const [addBtnDisable, setAddBtnDisable] = useState(false);
  const [image, setImage] = useState(null);
  const [isShowAdd, setIsShowAdd] = useState(true);

  const validationSchema = Yup.object({
    // name: Yup.string().matches(/^[a-zA-Z]+([a-zA-Z' -]*[a-zA-Z])*$/, 'Only alphabets are allowed').min(2, 'Minimum 2 characters'),
    name: Yup.string().matches(/^[a-zA-Z0-9]+([a-zA-Z0-9' -]*[a-zA-Z0-9])*$/, 'Only alphabets/Character are allowed').min(2, 'Minimum 2 characters'),
  });

  const formik = useFormik({
    initialValues: {
      name: data.name || '',
    },
    validationSchema: validationSchema,
    onSubmit: () => {
      handleAddService(formik.values.name);
    },
  });

  const handleServiceImgChange = (e) => {
    setImage(e.target.files);
    if (e.target.files.length > 0) {
      const reader = new FileReader();
      reader.onload = () => {
        setData({ ...data, bannerImage: reader.result });
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  useEffect(() => {
    let currentForm = servicesData[index];
    setTempFormCount(formCount);
    // console.log(index,"inexx")
    setData({
      // formCount: index,
      formCount: currentForm?.formCount,
      banner: currentForm?.image, ///////////////////////
      name: currentForm?.name,
      description: currentForm?.description,
      buttonLink: currentForm?.buttonLink,
      buttonName: currentForm?.buttonName,
      bannerImage: currentForm?.bannerImage,
      isImageUpload: currentForm?.isImageUpload,
    });

      // If 'name' field already has data, disable the add button
  if (currentForm?.name) {
    setAddBtnDisable(true);
  } else {
    setAddBtnDisable(false);
  }


    data["name"] ? setIsShowAdd(false) : setIsShowAdd(true);
  }, [servicesData]);

  useEffect(() => {
    // console.log(data,"datatatat")
    setTempFormCount(data?.formCount);
  }, [data]);

  const handleAddService = (nameValue) => {
    setAddBtnDisable(true);
    setIsAddServiceDisable(false);
    // console.log(servicesData,"servicesData!@#! ")
    // const newServicesData = servicesData.map((val) => {
    //   if (val.formCount == formCount) {
    //     //     banner: [],
    //     // name: "",
    //     // description: "",
    //     // buttonLink: "",
    //     // buttonName: "",
    //     // bannerImage: null,
    //     val.banner =image;
    //     val.name = data?.name;
    //     val.description = data?.description;
    //     val.buttonLink = data?.buttonLink;
    //     val.buttonName = data?.buttonName;
    //     val.bannerImage = data?.bannerImage;
    //     return val;
    //   }
    //   return val;
    // });

    // // setData({ ...data, formCount: formCount });
    // setData(newServicesData);
    // setServicesData(newServicesData);
    //==============================================
    const newServicesData = [...servicesData];
    newServicesData.forEach((val) => {
      if (val.formCount === tempFormCount) {
        val.banner = image;
        val.name = nameValue;
        val.description = data?.description;
        val.buttonLink = data?.buttonLink;
        val.buttonName = data?.buttonName;
        val.bannerImage = data?.bannerImage;
        val.isImageUpload = data?.isImageUpload;
      }
    });
    // console.log(newServicesData, "new ervices daata");
    setData(newServicesData);
    setServicesData(newServicesData);
    // setIsAddDisable(true);
    
  };

  return (
    <div key={`form-${formCount}`} className="col-12" id="AddNewStyleFormfieldpad">
      {/* {console.log(formCount,"formcountntttntn")} */}
      <div className="serviceboxedit">
        <i
          class="fa-solid fa-circle-xmark crosseditservice crossIconAdddetails"
          onClick={() => removeForm(index)}
        ></i>
        {/* <div class="boxbanner">
          <label class="form-label">Banner:</label>
          <div class="bannerbox">
            <img
              src={
                data["bannerImage"]
                  ? data["bannerImage"]
                  : `${environmentVariables?.apiUrl}uploads/${data["banner"]}`
              }
              class="mx-auto d-block img-fluid"
            />
          </div>
          {data["isImageUpload"] && (
            <input
              onChange={(e) => {
                handleServiceImgChange(e);
              }}
              class="form-control"
              type="file"
              placeholder="Title"
            />
          )}
        </div> */}
        <div>
          {/* <label class="form-label">Name</label> */}
          {data["isImageUpload"] ? (
            <div>
              <input
              name="name"
                disabled={addBtnDisable}
                // onChange={(e) => {
                //   setData({ ...data, name: e.target.value });
                // }}
                class="form-control"
                style={{ padding: "14px 25px", marginBottom: "10px" }}
                type="text"
                placeholder="Name"
                // value={data["name"] || ""}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.name}
              />
              {formik.touched.name && formik.errors.name ? (
                <FormikErrorStyle>{formik.errors.name}</FormikErrorStyle>
              ) : null}
            </div>
          ) : (
            <div className="servicenameedit" id="ServicesEditformstyle">{data["name"] || ""}</div>
          )}
        </div>

        {/* <div class="mb-3">
          <label class="form-label">Description:</label>
          {data["isImageUpload"] ? (
            <textarea
              class="form-control"
              rows="4"
              placeholder="Description"
              onChange={(e) =>
                setData({ ...data, description: e.target.value })
              }
              value={
                data["description"] == "undefined" ? "" : data["description"]
              }
            ></textarea>
          ) : (
            <div>{data["description"] == "undefined" ? "" : data["description"]}</div>
          )}
        </div>

        <div class="mb-3">
          <label class="form-label">Button Name:</label>
          {data["isImageUpload"] ? (
            <input
              onChange={(e) => setData({ ...data, buttonName: e.target.value })}
              class="form-control"
              type="text"
              placeholder="Button Name"
              value={
                data["buttonName"] == "undefined" ? "" : data["buttonName"]
              }
            />
          ) : (
            <div>{data["buttonName"]== "undefined" ? "" : data["buttonName"]}</div>
          )}
        </div>
        <div class="mb-3">
          <label class="form-label">Button link:</label>
          {data["isImageUpload"] ? (
            <input
              onChange={(e) => setData({ ...data, buttonLink: e.target.value })}
              class="form-control"
              type="text"
              placeholder="Button link"
              value={
                data["buttonLink"] == "undefined" ? "" : data["buttonLink"]
              }
            />
          ) : (
            <div>{data["buttonLink"] == "undefined" ? "" : data["buttonLink"]}</div>
          )}
        </div> */}
        {isShowAdd && !addBtnDisable ? (
          <button
            type="button"
            // disabled={addBtnDisable}
            // onClick={handleAddService}
            disabled={!formik.isValid || !formik.dirty}
            onClick={() => {
              formik.handleSubmit();
              handleAddService(formik.values.name);
            }}
            className="btn btn-cu addbuttoneditform"
          >
            Add
          </button>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default ServiceForm;
