import React, { useContext, useEffect, useRef, useState } from "react";
import PageName from "../Utils/PageName";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import axios from "axios";
import { userContext } from "../../context/userContext";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { environmentVariables } from "../../config/env.config";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import ReactDOM from "react-dom";
import DataTable from "react-data-table-component";
import SortIcon from "@material-ui/icons/ArrowDownward";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import { businessContext } from "../../context/businessContext";
import CircularLoader from "../Components/CircularLoader/CircularLoader";
import styled from "styled-components";
import "./UpdateRequestcardstyle.css";
import Modal from "@mui/material/Modal";
import { Box } from "@mui/material";
import DatePicker from "react-datepicker";
import FilterIcon from "../../../src/Images/FilterIcon.png";
// import "./PlansSuperAdmin.css";
// import "./Pages/PlansSuperAdmin.css";
// import "./.."
import { useCallback,useMemo } from "react";


const AddUserText = styled.div`
@media(max-width: 420px){
  display:none !important;
}
`;


const Boxstyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  // height: 500,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  zIndex: 999,
  borderRadius: "15px",
  border: "none",
  outline: "none",
  focus: "none",
  boxShadow:
    " rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;",
  padding: "3rem 5rem",
  "@media (max-width: 650px)": {
    width: "425px",
    padding: "2rem",
  },
  "@media (max-width: 430px)": {
    width: "380px",
    padding: "2rem",
  },
  "@media (max-width: 390px)": {
    width: "350px",
    padding: "2rem",
  },
};

const customStyles = {
  dataTableExtensions: {
    backgroundColor: "lightgray",
    padding: "10px",
  },
  headRow: {
    style: {
      backgroundColor: "#e9e9e9",
      borderRadius: "2px 2px 0 0",
      paddingLeft: "25px",
      border: "none",
      textTransform: "capitalize",
      position: "sticky",
      left: 0,
      top: 0,
      zIndex: 1,
      boxShadow: "0 2px 2px -1px rgba(0, 0, 0, 0.1)",
      overflow: "hidden",
      whiteSpace: "nowrap",
    },
  },
  headCells: {
    style: {
      color: "#525252",
      fontSize: "16px",
      minHeight: "52px",
    },
  },
  rows: {
    style: {
      padding: "6px 0px 6px 25px",
      fontSize: "14px",
      textTransform: "capitalize",
      minHeight: "58px !important",
    },
  },
  UserNameColumn: {
    position: "sticky",
    left: "0",
    zIndex: "1",
    backgroundColor: "#fff",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
};



function User() {
  const navigate = useNavigate();
  const { userData } = useContext(userContext);
  const createUserRef = useRef(null);
  const [openUserModal, setOpenUserModal] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [isEditUserModalOpen, setIsEditUserModalOpen] = useState(false);

  const baseUrl = environmentVariables?.apiUrl;
  const [action] = useState({ fromUser: false });
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [paginationTotalRows, setPaginationTotalRows] = useState(0);
  const [paginationPerPage, setPaginationPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedRowsPerPage, setSelectedRowsPerPage] = useState([]);
  // const openModal = () => {
  // };
  // const closeModal = () => {
  //   createUserRef.current.style.display = "none";
  // };

  const [userCreateData, setUserCreateData] = useState({
    name: "",
    email: "",
    password: "",
    roleId: 0,
  });
  const [roleData, setRoleData] = useState(null);
  const [usersData, setUsersData] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [searchOption, setSearchOption] = useState("-1");
  const [usersDataSearch, setUsersDataSearch] = useState(null);
  const [usersDataApi, setUsersDataApi] = useState(null);
  const [userId, setUserId] = useState(null);
  const [errorMsg, setErrorMsg] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const [errorMsgName, setErrorMsgName] = useState(null);
  const [errorMsgEmail, setErrorMsgEmail] = useState(null);
  const [errorMsgPassword, setErrorMsgPassword] = useState(null);
  const [errorMsgRole, setErrorMsgRole] = useState(null);

  const [isLoader, setIsloader] = useState(true);
  const tableExtensions = {
    export: false,
    print: false,
  };
  const handleChangeUserData = (e, type) => {
    setErrorMsgName("");
    setUserCreateData({ ...userCreateData, [`${type}`]: e.target.value });
  };
  const [role, setRole] = useState([]);

  const handleEditUser = (id) => {
    if (usersData?.length > 0) {
      const editUserData = usersData.filter((val) => val?.id == id);
      setUserId(id);
      setUserCreateData({
        name: editUserData[0]?.name,
        email: editUserData[0]?.email,
        password: "",
        roleId: editUserData[0]?.role_id,
      });
    } else {
      alert("users not available");
    }
  };

  const searchByRoleId = () => {
    setUsersData(usersDataApi);
    if (searchOption != "-1" && searchInput == "") {
      const newUsersData = usersDataApi.filter(
        (item) => item?.role_id == searchOption
      );
      setUsersData(newUsersData);
      // item?.role_name.toLowerCase().includes(searchInput?.toLowerCase())
    } else if (searchInput != "" && searchOption == "-1") {
      const newUsersData = usersDataApi.filter((item) =>
        item?.name.toLowerCase().includes(searchInput?.toLowerCase())
      );
      setUsersData(newUsersData);
    } else if (searchInput != "" && searchOption != "1") {
      const newUsersData = usersDataApi.filter(
        (item) => item?.role_id == searchOption
      );
      const newUsersData2 = newUsersData.filter((item) =>
        item?.name.toLowerCase().includes(searchInput?.toLowerCase())
      );
      setUsersData(newUsersData2);
    } else {
      setUsersData(usersDataApi);
    }
  };

  // const custom_header_class = styled.div`
  //   height: 52px !important;
  //   width: 500px !important;
  //   color: red !important ;
  // `;

  const handleDeleteUser = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        let data = JSON.stringify({
          userId: id,
        });

        let config = {
          method: "delete",
          maxBodyLength: Infinity,
          url: `${environmentVariables?.apiUrl}api/v1/user/deleteUser`,
          headers: {
            _token: userData?.token,
            "Content-Type": "application/json",
          },
          // withCredentials: true,
          data: data,
        };

        axios
          .request(config)
          .then((response) => {
            Swal.fire("Deleted!", "Selected user has been deleted.", "success");
            getUsersData();
          })
          .catch((error) => {
            Swal.fire(
              "Error!",
              error?.response?.data?.message || error?.message,
              "error"
            );
          });
      }
    });
  };
  const isValidEmail = (email) => {
    const emailRegex = /^[A-Za-z0-9._%-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/;
    return emailRegex.test(email);
  };

  const isValidPassword = (password) => {
    // Minimum length of 8 characters and at least one letter and one digit
    const passwordRegex = /^(?=.*[a-zA-Z])(?=.*[0-9]).{8,}$/;
    return passwordRegex.test(password);
  };

  const isValidName = (name) => {
    const nameRegex = /^[A-Za-z -]{3,}$/;
    return nameRegex.test(name);
  };

  const handleSubmit = () => {
    setErrorMsgName("");
    setErrorMsgEmail("");
    setErrorMsgPassword("");
    setErrorMsgRole("");

    if (userCreateData["name"] === ""||userCreateData["name"]?.trim()?.length==0) {
      setErrorMsgName("Name is mandatory");
      return;
    } 
    else if (!isValidName(userCreateData["name"])) {
      if (userCreateData["name"].length < 3) {
        setErrorMsgName("Name should be at least 3 characters long");
        return;
      } else {
        setErrorMsgName("Name can only contain alphabets, hyphens, and spaces");
        return;
      }
    } else if (userCreateData["email"] === "") {
      setErrorMsgEmail("Email is mandatory");
      return;
    } else if (!isValidEmail(userCreateData["email"])) {
      setErrorMsgEmail("Invalid email format");
      return;
    } else if (!isValidPassword(userCreateData["password"])) {
      setErrorMsgPassword(
        "Password should be at least 8 characters long and contain at least one letter and one digit"
      );
      return;
    } else if (userCreateData["roleId"] === 0) {
      setErrorMsgRole("Role is mandatory");
      return;
    }
    if (
      userCreateData["name"] !== "" &&
      userCreateData["email"] !== "" &&
      userCreateData["password"] !== "" &&
      userCreateData["roleId"] !== 0
    ) {
      let data = JSON.stringify(userCreateData);
      setIsSubmit(true);
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${environmentVariables?.apiUrl}api/v1/user/createUser`,
        headers: {
          _token: userData?.token,
          "Content-Type": "application/json",
        },
        // withCredentials: true,
        data: data,
      };

      axios
        .request(config)
        .then((response) => {
          // console.log(response,"EEEEEEEEEEEEe")
          setIsSubmit(false);
          toast.success("User created", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          window.location.reload(2000);
        })
        .catch((error) => {
          setIsSubmit(false);
// console.log(error,'"error?.error?.')
          toast.error(error?.response?.data?.message || error?.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });

          setErrorMsg(null);
        });
    } else {
      setIsSubmit(false);
      setErrorMsg("Please fill all the data");
    }
  };

  const handleEditSubmit = () => {
    if (userCreateData.name == "" || userCreateData.name?.trim() == "") {
      toast.error("Name is mandatory", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
      return;
    }
    // if (userCreateData.name == "") {
    //   setErrorMsgName("Name is mandatory");
    // } else
    if (!isValidName(userCreateData.name)) {
      if (userCreateData.name?.trim().length < 3) {
        // setErrorMsgName("Name should be at least 3 characters long");
        toast.error("Name should be at least 3 characters long", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        return;
      } else {
        toast.error("Name can only contain alphabets, hyphens, and spaces", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        // setErrorMsgName("Name can only contain alphabets, hyphens, and spaces");
        return;
      }
    }
    if (userCreateData.roleId == "") {
      toast.error("Role is mandatory", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
      // setErrorMsgRole("Role is mandatory");
      return;
    }

    // if (userCreateData["name"] == "") {
    //   setErrorMsgName("Name is mandatory");
    // } else if (!isValidName(userCreateData["name"])) {
    //   if (userCreateData["name"].length < 3) {
    //     setErrorMsgName("Name should be at least 3 characters long");
    //     return
    //   } else {
    //     setErrorMsgName("Name can only contain alphabets, hyphens, and spaces");
    //     return
    //   }
    // }
    // if (userCreateData["roleId"] == 0) {
    //   setErrorMsgRole("Role is mandatory");
    //   return
    // }
    const data = {
      roleId: userCreateData["roleId"].toString(),
      name: userCreateData["name"],
      email: userCreateData["email"],
      userId: userId.toString(),
    };
    setIsSubmit(true);
    let config = {
      method: "put",
      maxBodyLength: Infinity,
      url: `${environmentVariables?.apiUrl}api/v1/user/updateNormalUser`,
      headers: {
        _token: userData?.token,
        "Content-Type": "application/json",
      },
      // withCredentials: true,
      data: JSON.stringify(data),
    };

    axios
      .request(config)
      .then((response) => {
        toast.success("User updated", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        getUsersData();
        setIsSubmit(false);

        if (typeof bootstrap !== "undefined" && bootstrap.Modal) {
          const modal = new bootstrap.Modal(createUserRef.current);
          modal.hide(); // Close the modal
        }
      })
      .catch((error) => {
        setIsSubmit(false);
        toast.error(error?.response?.data?.message || error?.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        if (!error?.response?.data?.message) {
          if (typeof bootstrap !== "undefined" && bootstrap.Modal) {
            const modal = new bootstrap.Modal(createUserRef.current);
            modal.hide(); // Close the modal
          }
        }
      });
  };

  const clearData = () => {
    setUserCreateData({
      name: "",
      email: "",
      password: "",
      roleId: 0,
    });
  };

  const getRoleData = () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${environmentVariables?.apiUrl}api/v1/role/rolesPermissionList`,
      headers: {
        _token: userData?.token,
      },
      // withCredentials: true,
    };

    axios
      .request(config)
      .then((response) => {
        // console.log(response?.data?.data, "WWQWE");
        // let temp=response?.data?.data?.map((el)=>el.role_name)
        // let temp2=new Set(temp)
        // temp2=[...temp2]
        // console.log(temp2,"temp2WWQWE")
        // setRole(temp2)
        setRoleData(response?.data?.data);
      })
      .catch((error) => {
        console.log(error);
        if (error?.response?.status) {
          // navigate("/login");
        }
      });
  };
  const [tempData, setTempData] = useState([]);
  const [allParentName, setAllParentName] = useState([]);

  const getUsersData = (page) => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${environmentVariables?.apiUrl}api/v1/user/getAlluser_withpagination?page=${page}&limit=${rowsPerPage}`,
      headers: {
        _token: userData?.token,
      },
      // withCredentials: true,
    };
    axios
      .request(config)
      .then((response) => {
        setIsloader(false);
        let temp3 = response?.data?.result;
        temp3 = temp3.sort(
          (x, y) => new Date(y?.createdAt) - new Date(x?.createdAt)
        );
        setUsersData(response?.data?.result);
        let temp = [];
        // console.log(
        //   response?.data?.result,
        //   "temptemptemp admin ",
        //   userData,
        //   " ___"
        // );
        if (userData && userData.user_type == "super_admin") {
          temp = response?.data?.result?.map((el) => el.roleObj?.name);
          // temp=temp.sort((x,y)=> new Date(y?.createdAt)-new Date(x?.createdAt))
          console.log(response?.data?.pagination?.totalCount, "totalCount")
        } else {
          temp = response?.data?.result?.map((el) => el.rname);
        }
        let temp2 = new Set(temp);
        temp2 = [...temp2];
        temp2 = temp2.filter((el) => el != null);
        // console.log(temp2, "temp2W WQWE", "admin");
        setRole(temp2);
        setTempData(response?.data?.result);
        setUsersDataSearch(response?.data?.result);
        setUsersDataApi(response?.data?.result);
        setIsLoading(false);
        let allParentUserData = response?.data?.result?.map(
          (el) => el?.parentUser?.name
        );
        allParentUserData = new Set(allParentUserData);
        allParentUserData = [...allParentUserData];
        allParentUserData = allParentUserData.filter((el) => el != null);
        setAllParentName(allParentUserData);
        setPaginationTotalRows(response?.data?.pagination?.totalCount)

      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleOnSelectedRowsChange = useCallback(
    ({ selectedRows }) => {
      if (!action.fromUser) return; //the component always trigger this with 0 selected rows when it renders a page, what would clear the selection

      selectedRowsPerPage[currentPage] = selectedRows; //there is no way to tell if a row was DEselected, so I had to control the selected rows per page,
      //the array would get an index to control each page
      console.log(JSON.stringify(selectedRowsPerPage));
    },
    [currentPage, selectedRowsPerPage, action.fromUser]
  );

  const handlePageChange = (page) => {
    const nextPage = page; 
    getUsersData(nextPage);
    setCurrentPage(page);
  };

  const handleRowsPerPageChange = async (newRowsPerPage) => {
    setRowsPerPage(newRowsPerPage);
    setCurrentPage(1);
    setSelectedRowsPerPage([]);
  
    getUsersData(1);
  };
  
  

  useEffect(() => {
    getRoleData();
    getUsersData();
  }, [rowsPerPage]);

  const handleToggleStatus = (userId, currentStatus) => {
    // console.log(userId, currentStatus);
    const newStatus = currentStatus == "0" ? "1" : "0";
    // console.log(newStatus, "newStatusnewStatusnewStatus", baseUrl);
    // Make an API call to update the status
    axios
      .put(
        `${baseUrl}api/v1/user/updateStatusOfUser/${userId}`,
        { status: newStatus },
        {
          headers: {
            _token: userData?.token,
          },
        }
      )
      .then((response) => {
        // console.log(response?.data?.message, "user updated");
        toast.success(response?.data?.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        getUsersData();
        // const updatedUserListData = usersData.map((el) => {
        //   if (el.id == userId) {
        //     return { ...el, status: newStatus };
        //   }
        //   return el;
        // });
        // console.log(updatedUserListData,"updatedUserListData12")
        // setUsersData(updatedUserListData);
        // setUsersDataSearch(updatedUserListData);
        // setUsersDataApi(updatedUserListData);
      })

      .catch((error) => {
        toast.error(error?.response?.data?.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        console.error("Error updating user status:", error);
      });
  };

  const columns = [
    {
      name: "User Name",
      selector: (row) => row?.name,
      sortable: true,
      cell: (d) => {
        const capitalizedName =
          d?.name?.charAt(0)?.toUpperCase() + d?.name?.slice(1);
        return capitalizedName;
      },
      color: "red",
      fixed:"left",
      // style: customStyles.UserNameColumn,
       width: "150px",
    },
    {
      name: "Email",
      selector: (row) => row?.email,
      sortable: true,
      cell: (row) => <div style={{ width: "1000px" }}>{row?.email}</div>,
      width: "300px",
    },
    {
      name: "User Role",
      selector: (row) => row?.roleObj?.name,
      sortable: true,
      cell: (d) => {
        const capitalizedName =
          d?.roleObj?.name?.charAt(0)?.toUpperCase() + d?.roleObj?.name?.slice(1);
        // return capitalizedName=="NaN"?"--":  capitalizedName;
        return capitalizedName || "--";
      },
      width: "250px",
    },
    {
      name: "Parent Name",
      selector: (row) => row?.parentUser?.name || "--",
      sortable: true,
      cell: (d) => {
        const capitalizedName =
          d?.parentUser?.name?.charAt(0)?.toUpperCase() +
          d?.parentUser?.name?.slice(1);
        return capitalizedName ?capitalizedName:  "--";
      },
      minWidth: "200px",
      // cell: (row) => <div style={{ width: '90px' }}>{row?.parentUser?.name || "--"}</div>
    },
    {
      name: "Date",
      // selector: (row) => row?.updatedAt,
      selector: (row) => new Date(row?.updatedAt).toLocaleDateString(),
      sortable: true,
      width: "150px",
    },
    {
      name: "Action",
      sortable: false,
      cell: (d) => (
        <>
          {userData?.user_type == "super_admin" ? (
            d?.parentUser?.user_type == "super_admin" ? (
              <>
                <i
                  style={{ width: "30px", marginRight: "15px" }}
                  key={d.id}
                  data-bs-toggle="modal"
                  data-bs-target="#edituser"
                  onClick={() => handleEditUser(d.id)}
                  className="first fas fa-pen"
                  // style={{  marginRight: '15px' }}
                ></i>
                <i
                  style={{ width: "50px" }}
                  onClick={() => handleDeleteUser(d.id)}
                  className="fas fa-trash-alt"
                  // style={{  marginRight: '15px' }}
                ></i>
              </>
            ) : (
              ""
            )
          ) : (
            <>
              <i
                style={{ width: "30px", marginRight: "15px" }}
                key={d.id}
                data-bs-toggle="modal"
                data-bs-target="#edituser"
                onClick={() => handleEditUser(d.id)}
                className="first fas fa-pen"
                // style={{  marginRight: '15px' }}
              ></i>
              <i
                style={{ width: "50px" }}
                onClick={() => handleDeleteUser(d.id)}
                className="fas fa-trash-alt"
                // style={{  marginRight: '15px' }}
              ></i>
            </>
          )}

          <div
            className="form-check form-switch"
            style={{ marginLeft: "15px" }}
          >
            <input
              className="form-check-input"
              // type="checkbox"
              type="checkbox"
              checked={d?.isDeleted == "0" ? true : false}
              id={`flexSwitchCheckDefault_${d.id}`}
              onClick={() => {
                // console.log(d,"dddddddddd")
                handleToggleStatus(d.id, d.isDeleted);
              }}
              style={{ marginLeft: "-20px" }}
            />
          </div>
        </>
      ),
      minWidth: "1px",
    },

    // {
    //   name: "Active / Deactive",
    //   sortable: false,
    //   cell: (d) => (
    //     <div>
    //       <div className="form-check form-switch">
    //         <input
    //           className="form-check-input"
    //           // type="checkbox"
    //           type="checkbox"
    //           checked={d?.isDeleted=="0"? true:false}
    //           id={`flexSwitchCheckDefault_${d.id}`}
    //           onClick={() =>{
    //             // console.log(d,"dddddddddd")
    //             handleToggleStatus(d.id, d.isDeleted)
    //           }}
    //         />
    //       </div>
    //     </div>
    //   ),
    // },
  ];

  const handleClick = (title) => {
    console.log(`You clicked me! ${title}`);
  };

  const tableData = {
    columns,
    data: usersData,
  };
  const [activeRoleName, setActiveRoleName] = useState("");
  const [activeParentName, setActiveParentName] = useState("");
  const [selectedDateFrom, setSelectedDateFrom] = useState("");
  const [selectedDateTo, setSelectedDateTo] = useState("");
  const [showFilter, setShowFilter] = useState(false);

  const handleChangeRoleData = (e) => {
    setActiveRoleName(e.target.value);
  };

  const handleFilterOpen = () => {
    setShowFilter(!showFilter);
  };

  const handleFilterClose = () => {
    setShowFilter(false);
  };

  useEffect(() => {
    // usersData, setUsersData,
    let filteredData = tempData;
    if (activeRoleName == "All Role") {
      // setUsersData(tempData);
    } else {
      if (activeRoleName != "" && activeRoleName != "All Role") {
        if (userData && userData.user_type == "super_admin") {
          filteredData = filteredData?.filter(
            (el) => el.roleObj?.name == activeRoleName
          );
        } else {
          filteredData = filteredData.filter(
            (el) => el.rname === activeRoleName
          );
        }
      }
    }
    if (activeParentName != "All" && activeParentName != "") {
      filteredData = filteredData.filter(
        (el) => el?.parentUser?.name == activeParentName
      );
    }
    setUsersData(filteredData);
  }, [activeRoleName, selectedDateFrom, selectedDateTo, activeParentName]);

  const handleReset = () => {
    setActiveRoleName("All Role");
    setUsersData(tempData);
    setActiveParentName("");
    // setTempData
    // setUsersDataSearch
  };
  const handleChangeParentData = (e) => {
    setActiveParentName(e.target.value);
  };

  return (
    <>
      <PageName PageName="Users" />

      <article>
        <div className="row">
          <div class="col-md-12">
            <p className="addusermainright">
              <a
                class="icon-btn addrolemain_roleAdduser "
                data-bs-toggle="modal"
                data-bs-target="#newuser"
                onClick={() => clearData()}
              >
               <AddUserText className="AddNewPlanClass"> Add User</AddUserText><span className="addrole_role">+</span>
              </a>
            </p>
          </div>
        </div>

        {isLoader ? (
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "55%",
              display: "flex",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <CircularLoader size={40} />
          </div>
        ) : (
          <div className="main">
            {isLoading ? (
              <div
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "55%",
                  display: "flex",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                <CircularLoader size={40} />
              </div>
            ) : (
              <>
                {role && role.length > 0 && (
                  <>
                   <div className="filter-button-wrapper requestcardfilterbuttonWrapper">
                    <span
                      class="FilterIconTextWrapper"
                      onClick={handleFilterOpen}
                    >
                      {" "}
                      {/* <i class="fa fa-filter" aria-hidden="true"></i> */}
                      <div className="FilterIcon">
                    {" "}
                    <img src={FilterIcon} width={28}/> 
                    <div className="filtertext">Filters</div>
                  </div>
                    </span>
                    <button
                      className="btn btn-primary requestcardfilterbuttonstyle"
                      onClick={handleFilterOpen}
                      id="FilterIconText"
                    >
                      Filter
                    </button>
                  </div>
                  <Modal
                    open={showFilter}
                    onClose={handleFilterClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box sx={Boxstyle}>
                      {showFilter && (
                    <div class="Contact_filter_fieldbutton_wrapper">
                      <div class="Contact_filter_field_wrapper UserEditForme">
                        <div class=" col-md-2 Contact_filter_Inputfield">
                          {/* <h5 className="mb-3">Filter by Role </h5> */}
                          <select
                            value={activeRoleName}
                            onChange={(e) => handleChangeRoleData(e)}
                            class="form-select"
                          >
                            {" "}
                            <option value={"All Role"}>All Role</option>
                            {role &&
                              role.map((val, index) => (
                                <option key={index} value={val}>{val} </option>
                              ))}
                          </select>
                          <div
                            style={{
                              fontSize: "14px",
                              color: "red",
                              bottom: "70px",
                            }}
                          ></div>
                        </div>
                        {userData && userData?.user_type == "super_admin" && (
                          <div class=" col-md-2 Contact_filter_Inputfield">
                            {/* <h5 className="mb-3">Filter by Parent Name </h5> */}
                            <select
                              value={activeParentName}
                              onChange={(e) => handleChangeParentData(e)}
                              class="form-select"
                            >
                              {" "}
                              <option value={"All"}>All</option>
                              {allParentName &&
                                allParentName.map((val, index) => (
                                  <option value={val} key={index}>
                                    {val}{" "}
                                  </option>
                                ))}
                            </select>
                            <div
                              style={{
                                fontSize: "14px",
                                color: "red",
                                bottom: "70px",
                              }}
                            ></div>
                          </div>
                        )}

                        {/* <div class="mb-2 col-md-2 Contact_filter_Inputfield">
                      <h5 className="mb-3">From Date </h5>
                      <DatePicker
                        selected={selectedDateFrom}
                        onChange={(date) => setSelectedDateFrom(date)}
                        className="form-control"
                        ref={fromDate}
                        dateFormat="dd/MM/yyyy"
                      />
                      <div
                        class="contact_filter_toDate_calander_icon"
                        onClick={() => fromDate.current.setOpen(true)}
                      >
                        <i class="fa-regular fa-calendar"></i>
                      </div>
                    </div> */}
                        {/* <div class="mb-2 col-md-2 Contact_filter_Inputfield">
                      <h5 className="mb-3">To Date </h5>
                      <DatePicker
                        selected={selectedDateTo}
                        onChange={(date) => setSelectedDateTo(date)}
                        className="form-control"
                        ref={toDate}
                        dateFormat="dd/MM/yyyy"
                      />
                      <div
                        class="contact_filter_toDate_calander_icon"
                        onClick={() => toDate.current.setOpen(true)}
                      >
                        <i class="fa-regular fa-calendar"></i>
                      </div>
                    </div> */}
                        <div class="contact_filter_buttonwrapper ">
                        <button
                                class="contact_filter_buttonstyle"
                                onClick={handleFilterClose}
                              >
                                Apply
                              </button>
                          <button
                            class="contact_filter_buttonstyle"
                            onClick={() => handleReset()}
                          >
                            Reset{" "}
                          </button>
                        </div>
                      </div>
                    </div>)}
                           </Box>
                           </Modal>
                    
                  </>
                )}

<div className="scrollable-columns">
                    <div className="sticky-header-container">
                <DataTableExtensions
                  {...tableData}
                  {...tableExtensions}
                  customStyles={customStyles}
                  filterPlaceholder="Search User"
                >
                  <DataTable
                    columns={columns}
                    data={tableData.data}
                    noHeader
                    defaultSortField="id"
                    sortIcon={<SortIcon />}
                    defaultSortAsc={true}
                    pagination
                    highlightOnHover
                    paginationServer
                    paginationTotalRows={paginationTotalRows}
                    paginationPerPage={paginationPerPage}
                    paginationRowsPerPageOptions={[10,20,50,100,300,1000,10000]} // Customize as needed
                    onChangePage={handlePageChange}
                    onChangeRowsPerPage={handleRowsPerPageChange}
                    onSelectedRowsChange={handleOnSelectedRowsChange}
                    dense
                    customStyles={customStyles}
                  />
                </DataTableExtensions>
                </div>
                  </div>
              </>
            )}
          </div>
        )}
      </article>

      <div
        class="modal fade"
        id="newuser"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        ref={createUserRef}
      >
        <div class="modal-dialog">
          <div class="modal-content" id="exampleModalLabelContainer">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                Create New User
              </h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <form class="row">
                <div class="mb-2 col-md-12">
                  <input
                    onChange={(e) => {
                      handleChangeUserData(e, "name");
                    }}
                    value={userCreateData["name"]}
                    class="form-control"
                    type="text"
                    placeholder="Name"
                  />
                  <div
                    style={{
                      fontSize: "14px",
                      color: "red",

                      bottom: "70px",
                    }}
                  >
                    {errorMsgName}
                  </div>
                </div>
                <div class="mb-2 col-md-12">
                  <input
                    value={userCreateData["email"]}
                    onChange={(e) => handleChangeUserData(e, "email")}
                    class="form-control"
                    type="text"
                    placeholder="Email"
                  />
                  <div
                    style={{
                      fontSize: "14px",
                      color: "red",

                      bottom: "70px",
                    }}
                  >
                    {errorMsgEmail}
                  </div>
                </div>
                <div class="mb-2 col-md-12">
                  <input
                    class="form-control"
                    type="text"
                    placeholder="Password"
                    value={userCreateData["password"]}
                    onChange={(e) => handleChangeUserData(e, "password")}
                  />
                  <div
                    style={{
                      fontSize: "14px",
                      color: "red",

                      bottom: "70px",
                    }}
                  >
                    {errorMsgPassword}
                  </div>
                </div>
                <div class="mb-2 col-md-12">
                  <select
                    value={userCreateData["roleId"]}
                    onChange={(e) => handleChangeUserData(e, "roleId")}
                    class="form-select"
                  >
                    <option value={""}>User Role</option>
                    {roleData &&
                      roleData.map((val,inde) => (
                        <option key={inde} value={val?.role_id}>{val?.role_name} </option>
                      ))}
                  </select>
                  <div
                    style={{
                      fontSize: "14px",
                      color: "red",

                      bottom: "70px",
                    }}
                  >
                    {errorMsgRole}
                  </div>
                </div>
                {/* <div
                  style={{
                    fontSize: "14px",
                    color: "red",
                    position: "absolute",
                    bottom: "70px",
                  }}
                >
                  {errorMsg}
                </div> */}
                <div class="mb-2 flex-center col-md-12">
                  <button
                    onClick={handleSubmit}
                    class="btn btn-primary me-3 save-cta"
                    type="button"
                  >
                    {/* <i class="bi bi-check-circle-fill ms-2 "></i> */}
                    {isSubmit ? <CircularLoader size={20} /> : "Save"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div
        class="modal fade"
        id="edituser"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        ref={createUserRef}
      >
        <div class="modal-dialog">
          <div class="modal-content userUpdate_action_form" id="exampleModalLabelContainer">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                Update User
              </h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <form class="row">
                <div class="mb-2 col-md-12">
                  <input
                    onChange={(e) => {
                      handleChangeUserData(e, "name");
                    }}
                    value={userCreateData["name"]}
                    class="form-control"
                    type="email"
                    placeholder="Name"
                  />
                </div>
                <div class="mb-2 col-md-12">
                  <input
                    value={userCreateData["email"]}
                    // onChange={(e) => handleChangeUserData(e, "email")}
                    class="form-control"
                    type="text"
                    placeholder="Email"
                  />
                </div>
                {/* <div class="mb-2 col-md-12">
                  <input
                    class="form-control"
                    type="text"
                    placeholder="Password"
                    value={userCreateData["password"]}
                    onChange={(e) => handleChangeUserData(e, "password")}
                  />
                </div> */}
                <div class="mb-2 col-md-12">
                  <select
                    value={userCreateData["roleId"]}
                    onChange={(e) => handleChangeUserData(e, "roleId")}
                    class="form-select"
                  >
                    <option value={0}>User Role</option>
                    {roleData &&
                      roleData.map((val,inde) => (
                        <option key={inde} value={val?.role_id}>{val?.role_name} </option>
                      ))}
                  </select>
                </div>
                <div
                  style={{
                    fontSize: "14px",
                    color: "red",
                    position: "absolute",
                    bottom: "70px",
                  }}
                >
                  {errorMsg}
                </div>
                <div class="mb-2 flex-center col-md-12">
                  <button
                    onClick={handleEditSubmit}
                    class="btn btn-primary me-3 save-cta"
                    type="button"
                    data-bs-dismiss="modal"
                  >
                    {/* <i class="bi bi-check-circle-fill ms-2 "></i> */}
                    Update
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default User;
