import React from "react";
import Logo from "../../../Images/whitelogo.png";
import facebook from "../../../Images/facebook.png";
import instagram from "../../../Images/instagram.png";
import linkdin from "../../../Images/linkdin.png";
import twitter from "../../../Images/twitter.png";
import pinterest from "../../../Images/pinterest.png";
import "./Footer.css";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate(null);
  return (
    <>
      <div className="container-fluid black">
        <div className="container">
          <div className="row">
            <div className="col-md-3 first-col">
              <img src={Logo} />
              <h2>
                Get Bulk <span>Orders</span>
              </h2>
              <div
                onClick={() => navigate("/register")}
                className="blue-button"
                style={{ cursor: "pointer" }}
              >
                Get Started
              </div>
              <div className="social">
                <ul>
                  <li
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      window.open(
                        "https://www.facebook.com/people/VMyCards/61551454974033/",
                        "_blank"
                      )
                    }
                  >
                    <img src={facebook} />
                  </li>
                  <li
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      window.open(
                        "https://www.instagram.com/vmycards/",
                        "_blank"
                      )
                    }
                  >
                    <img src={instagram} />
                  </li>
                  <li
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      window.open(
                        "https://www.linkedin.com/company/vmycards/",
                        "_blank"
                      )
                    }
                  >
                    <img src={linkdin} />
                  </li>
                  <li
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      window.open("https://twitter.com/vmycards93816", "_blank")
                    }
                  >
                    <img src={twitter} />
                  </li>
                  <li
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      window.open(
                        "https://in.pinterest.com/vmycards/",
                        "_blank"
                      )
                    }
                  >
                    <img src={pinterest} />
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-3 links">
              <h2>Useful Links</h2>
              <ul>
                <li
                  style={{ cursor: "pointer" }}
                  onClick={() => navigate("/login")}
                >
                  Login
                </li>
                <li
                  style={{ cursor: "pointer" }}
                  onClick={() => navigate("/register")}
                >
                  Register
                </li>
              </ul>
            </div>
            <div className="col-md-3 links">
              <h2>Links</h2>
              <ul>
                <li
                  onClick={() => navigate("/shipping")}
                  style={{ cursor: "pointer" }}
                >
                  Shipping
                </li>
                <li
                  onClick={() => navigate("/faqs")}
                  style={{ cursor: "pointer" }}
                >
                  FAQs
                </li>
                <li
                  onClick={() => navigate("/privacy")}
                  style={{ cursor: "pointer" }}
                >
                  Privacy
                </li>
                <li
                  onClick={() => navigate("/refund")}
                  style={{ cursor: "pointer" }}
                >
                  Refund
                </li>
                <li
                  onClick={() => navigate("/term-conditions")}
                  style={{ cursor: "pointer" }}
                >
                  T&C
                </li>
              </ul>
            </div>
            <div className="col-md-3 links">
              <h2>Contact Info</h2>
              <ul>
                <li>
                  Metaspace Labs Information Technology L.L.C <br /> UAE- Dubai
                  - Al meydan road - KML building - office 121
                </li>

                <li>info@vmycards.com</li>

                <li>+91-9355610532</li>
                <li>+971-565954252</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="copyright">
          <div><h5>© 2024, VmyCards. All rights reserved.</h5></div>
         <div>
         <h5
            onClick={() => window.open(`https://metaspacelab.io/`, "_blank")}
            style={{
            //   cursor: "pointer",
            //   position: "absolute",
            //   right: "20px",
            //   top: "20px",
            }}
            // className="poweredbytext"
          >
            Powered by : Metaspace Labs Information Technology L.L.C
          </h5>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
