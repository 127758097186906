import "./App.css";
import LandingPage from "./frontend/LandingPage";
import LandingNew from "./frontend/LandingNew";
import { Routes, Route } from "react-router-dom";
import Layout from "./frontend/Components/Layout";
import Login from "./Auth/Login";
import ForgotPassword from "./Auth/ForgotPassword";
import Register from "./Auth/Register";
import DashbordLayout from "./Backend/Components/DashbordLayout";
import Dashbord from "./Backend/Pages/Dashbord";
import User from "./Backend/Pages/User";
import UserUploadList from "./Backend/Pages/UserUploadList";
import Plans from "./Backend/Pages/Plans";
import PlansSuperAdmin from "./Backend/Pages/PlansSuperAdmin";
import CustomizedPlansSuperAdmin from "./Backend/Pages/CustomizedPlansSuperAdmin";
import Roles from "./Backend/Pages/Roles";
import Contacts from "./Backend/Pages/Contacts";
import Appoinments from "./Backend/Pages/Appoinments";
import Appoinments_slots from "./Backend/Pages/Appoinments_slots";
import RequestCard from "./Backend/Pages/RequestCard";
import Requestnewcard from "./Backend/Pages/Requestnewcard";
import Business from "./Backend/Pages/Business";
import Businesslist from "./Backend/Pages/Businesslist";
import ChangePassword from "./Auth/ChangePassword";
import Profile from "./Backend/Pages/Profile";
import Requestcard_admin from "./Backend/SuperAdmin/Requestcard_admin";
import Planrequest_admin from "./Backend/SuperAdmin/Planrequest_admin";
import Coupons_admin from "./Backend/SuperAdmin/Coupons_admin";
import Order_admin from "./Backend/SuperAdmin/Order_admin";
import Email_template_admin from "./Backend/SuperAdmin/Email_template_admin";
import Setting_admin from "./Backend/SuperAdmin/Setting_admin";
import AddPhysicalCard from "./Backend/SuperAdmin/AddPhysicalCard";
import BulkUpload from "./Backend/SuperAdmin/BulkUpload";
import AllUser from "./Backend/Pages/AllUser";
import Currency_admin from "./Backend/SuperAdmin/Currency_admin";
import AdminLogin from "./Auth/AdminLogin";
import CreateCard from "./Backend/Pages/Physical Card/CreateCard";
import OrderNow from "./Backend/Pages/OrderNow";
import Paystripe from "./Backend/Pages/Paystripe";
import SuperDashboard from "./Backend/Pages/SuperDashboard";
import { useContext, useState, useEffect } from "react";
import { userContext } from "./context/userContext";
import {
  businessContext,
  isBusinesCreatedContext,
  businessNameContext,
} from "./context/businessContext";
import { planContext } from "./context/planContext";
import VerifyEmail from "./Auth/VerifyEmail";
import ThemeSelect from "./Backend/Pages/BusinessPages/ThemeSelect";
import Payment from "./Backend/Pages/Payment";
import Dpopayment from "./Backend/Pages/Dpopayment";


import PaymentPlan from "./Backend/Pages/PaymentPlan";
import PaymentPlanList from "./Backend/Pages/PaymentPlanList";
import { businessObjContext } from "./context/businessObjContext";
import BusinessNew from "./Backend/Pages/BusinessNew";
import ViewAllPhycialCard from "./Backend/Pages/ViewAllPhycialCard";
import Aboutus from "./frontend/Aboutus";
import Privacy from "./frontend/Privacy";
import Term from "./frontend/Term";
import Faqs from "./frontend/Faqs";
import Shipping from "./frontend/Shipping";
import Refund from "./frontend/Refund";
import Template1 from "./Backend/Templates/Template1";
import Template2 from "./Backend/Templates/Template2";
import { useParams, useLocation } from "react-router-dom";
import StripeSubscription from "./Backend/Pages/StripeSubscription";
import StripeProduct from "./Backend/Pages/StripeProduct";
import StripeSubscriptionPaymentCancel from "./Backend/Pages/StripeSubscriptionPaymentCancel";
import StripeSubscriptionPaymentSuccess from "./Backend/Pages/StripeSubscriptionPaymentSuccess";
import {
  currencyArrayContext,
  currencyContext,
} from "./context/currencyContext";
import { environmentVariables } from "./config/env.config";
import axios from "axios";
import Theme1 from "./Backend/Templates/themes/theme1/Theme1";
import Theme2 from "./Backend/Templates/themes/theme2/Theme2";
import Theme3 from "./Backend/Templates/themes/theme3/Theme3";
import Theme4 from "./Backend/Templates/themes/theme4/Theme4";
import DisplayCardList_admin from "./Backend/SuperAdmin/DisplayCardList_admin";
import ViewCardDownload from "./Backend/Pages/Physical Card/ViewCardDownload";
import  AddShipping  from "./Backend/Pages/AddShipping";
import MailTemplate from "./Backend/Pages/MailTemplate";
import ViewMailTemplate from "./Backend/Pages/ViewMailTemplate";
import DpoPaymentPlan from "./Backend/Pages/DpoPaymentPlan";

function App() {
  const location = useLocation();
  const currentPath = location?.pathname?.split("/");
  const isHomePage = location.pathname === "/";
  const [userData, setUserData] = useState(
    JSON.parse(localStorage.getItem("user"))
  );
  const getComponenent = () => {
    let obj = {
      theme1: <Theme1 />,
      theme2: <Theme2 />,
      theme3: <Theme3 />,
      theme4: <Theme4 />,
    };
    return obj[themeNumber];
  };
  const [businessData, setBusinessData] = useState(null);
  const [isBusinessCreated, setIsBusinessCreated] = useState({});
  const [dynamicBusinessName, setDynamicBusinessName] = useState();
  const [businessNameData, setBusinessNameData] = useState([]);
  //state to change dynamic business name when a business is deleted
  const [isChangeDynamicBusinessName, setIsChangeDynamicBusinessName] =
    useState(false);
  const [themeNumber, setThemeNumber] = useState("theme2");
  const [businessObjData, setBusinessObjData] = useState(null);
  const [currencyObjData, setCurrencyObjData] = useState(null);
  const [currencyArrayData, setCurrencyArrayData] = useState([]);
  const [planData, setPlanData] = useState(null);
  const [businessId, setBusinessId] = useState(
    localStorage.getItem("business_id")
  );
  const storedData = localStorage.getItem("businessObj");
  const [businessLink, setBusinessLink] = useState("");
  useEffect(() => {
    if (storedData !== undefined && storedData !== null) {
      const businessData = JSON.parse(storedData);
      const slugb = businessData?.slug;
      const originUrl = window.location.origin;
      setBusinessLink(slugb);
    }
  }, [storedData]);

  useEffect(() => {
    if (userData?.token) {
      handleFetchUserBusiness();
    }else{
      setBusinessData(true)
    }
    console.log(userData,"userData")
  }, [userData, isChangeDynamicBusinessName]);
  const isAuth = () => {
    if (userData?.token) {
      return true;
    } else {
      return false;
    }
  };
  const isAdminAuth = () => {
    if (userData?.token && userData?.user_type === "super_admin") {
      return true;
    } else {
      return false;
    }
  };
  const handleFetchUserBusiness = async () => {
    try {
      await axios
        .get(`${environmentVariables?.apiUrl}api/v1/business/fetchBusiness`, {
          headers: {
            _token: userData?.token,
          },
        })
        .then((res) => {
          if (res?.data?.data) {
            setBusinessData(res?.data?.data);
            localStorage.setItem("business_id", res?.data?.data[0]?.id);
            setDynamicBusinessName(res?.data?.data[0]?.title);
            setBusinessId(res?.data?.data[0]?.id);
          }
        })
        .catch((err) => {
          // console.log(err,"userData catch");
        });
    } catch (err) {
      // console.log(err);
    }
  };
  return (
    <planContext.Provider value={{ planData, setPlanData }}>
      <businessObjContext.Provider
        value={{ businessObjData, setBusinessObjData }}
      >
        <currencyArrayContext.Provider
          value={{ currencyArrayData, setCurrencyArrayData }}
        >
          <currencyContext.Provider
            value={{ currencyObjData, setCurrencyObjData }}
          >
            {businessData && (
            <businessContext.Provider value={{ businessData, setBusinessData }}>
              <isBusinesCreatedContext.Provider
                value={{ isBusinessCreated, setIsBusinessCreated }}
              >
                <businessNameContext.Provider
                  value={{ businessNameData, setBusinessNameData }}
                >
                  <userContext.Provider value={{ userData, setUserData }}>
                    <div className="App">
                      <Routes>
                        <Route path="/login" element={<Login />} />
                        <Route path="*" element={<Login />} />
                        <Route
                          path="/forgot-password"
                          element={<ForgotPassword />}
                        />
                        <Route path="/register" element={<Register />} />
                        <Route
                          path="/checkingtheme"
                          element={getComponenent()}
                        />
                        <Route
                          path="/change-password"
                          element={<ChangePassword />}
                        />
                        <Route path="/verify-email" element={<VerifyEmail />} />
                        <Route
                          path={`/:${currentPath[2]}`}
                          element={<Template2 Slug={currentPath[2]} />}
                        />
                        <Route path="/template-main" element={<Template1 />} />
                        <Route index element={<LandingNew />} />
                        <Route
                          element={
                            <Layout
                              isChangeDynamicBusinessName={
                                isChangeDynamicBusinessName
                              }
                              setDynamicBusinessName={setDynamicBusinessName}
                              dynamicBusinessName={dynamicBusinessName}
                              setBusinessId={setBusinessId}
                              businessId={businessId}
                            />
                          }
                        >
                          <Route path="/home" element={<LandingPage />} />
                          <Route path="/adminlogin" element={<AdminLogin />} />
                          <Route path="/privacy" element={<Privacy />} />
                          <Route path="/term-conditions" element={<Term />} />
                          <Route path="/faqs" element={<Faqs />} />
                          <Route path="/shipping" element={<Shipping />} />
                          <Route path="/refund" element={<Refund />} />
                          <Route path="/about" element={<Aboutus />} />
                          <Route
                            path="/ViewCardDownload/:id"
                            element={<ViewCardDownload />}
                          />
                        </Route>
                        {isAuth() && (
                          <Route
                            element={
                              <DashbordLayout
                                isChangeDynamicBusinessName={
                                  isChangeDynamicBusinessName
                                }
                                setDynamicBusinessName={setDynamicBusinessName}
                                dynamicBusinessName={dynamicBusinessName}
                                setBusinessId={setBusinessId}
                                businessId={businessId}
                              />
                            }
                          >
                            <Route
                              path="/StripeSubscription"
                              element={<StripeSubscription />}
                            />
                            <Route
                              path="/StripeSubscriptionPaymentSuccess"
                              element={<StripeSubscriptionPaymentSuccess />}
                            />
                            <Route
                              path="/StripeSubscriptionPaymentCancel"
                              element={<StripeSubscriptionPaymentCancel />}
                            />
                            <Route path="/ordernow" element={<OrderNow />} />
                            <Route path="/dashboard" element={<Dashbord />} />
                            <Route path="/user" element={<User />} />
                            <Route path="/userUploadList" element={<UserUploadList />} />
                            <Route path="/roles" element={<Roles />} />
                            <Route path="/plans" element={<Plans />} />
                            <Route path="/contact" element={<Contacts />} />
                            <Route
                              path="/appoinments"
                              element={<Appoinments />}
                            />
                            <Route
                              path="/appoinments_slots"
                              element={<Appoinments_slots />}
                            />
                            <Route
                              path="/requestCard"
                              element={<RequestCard />}
                            />
                            <Route
                              path="/requestnewcard"
                              element={<Requestnewcard />}
                            />
                            <Route
                              path="/business/:id"
                              element={
                                <Business
                                  themeNumber={themeNumber}
                                  setThemeNumber={setThemeNumber}
                                />
                              }
                            />
                            <Route
                              path="/business"
                              element={
                                <Business
                                  themeNumber={themeNumber}
                                  setThemeNumber={setThemeNumber}
                                />
                              }
                            />
                            <Route
                            path="/mailtemplate"
                            element={
                              <MailTemplate />
                            }
                            />
                             <Route
                            path="/viewmailtemplate"
                            element={
                              <ViewMailTemplate />
                            }
                            />
                            <Route
                              path="/businesslist"
                              element={
                                <Businesslist
                                  isChangeDynamicBusinessName={
                                    isChangeDynamicBusinessName
                                  }
                                  setDynamicBusinessName={
                                    setDynamicBusinessName
                                  }
                                  setIsChangeDynamicBusinessName={
                                    setIsChangeDynamicBusinessName
                                  }
                                />
                              }
                            />
                            <Route path="/profile" element={<Profile />} />
                            <Route path="/addshipping" element={<AddShipping />} />
                            <Route
                              path="/addphysicalCard"
                              element={<AddPhysicalCard />}
                            />
                            <Route path="/profile" element={<Profile />} />
                            <Route path="/paystripe" element={<Paystripe />} />
                            <Route
                              path="/create-card"
                              element={<CreateCard />}
                            />
                            <Route path="/payment" element={<Payment />} />
                            <Route path="/Dpopayment" element={<Dpopayment />} />
                            <Route
                              path="/businessNew"
                              element={<BusinessNew />}
                            />
                            <Route
                              path="/payment-plan-list"
                              element={<PaymentPlanList />}
                            />
                            <Route
                              path="/payment-plan"
                              element={<PaymentPlan />}
                            />
                             <Route
                              path="/Dpo-payment-plan"
                              element={<DpoPaymentPlan />}
                            />
                          </Route>
                        )}

                        {isAdminAuth() && (
                          <Route
                            element={
                              <DashbordLayout
                                isChangeDynamicBusinessName={
                                  isChangeDynamicBusinessName
                                }
                                setBusinessId={setBusinessId}
                                businessId={businessId}
                              />
                            }
                          >
                            <Route
                              path="/super_dashboard"
                              element={
                                <SuperDashboard
                                  setDynamicBusinessName={
                                    setDynamicBusinessName
                                  }
                                  dynamicBusinessName={dynamicBusinessName}
                                  isChangeDynamicBusinessName={
                                    isChangeDynamicBusinessName
                                  }
                                />
                              }
                            />
                            <Route path="/AllUser" element={<AllUser />} />
                            <Route
                              path="/Setting_admin"
                              element={<Setting_admin />}
                            />
                            <Route
                              path="/bulk_upload"
                              element={<BulkUpload />}
                            />
                            <Route
                              path="/Email_template_admin"
                              element={<Email_template_admin />}
                            />
                            <Route
                              path="/Order_admin"
                              element={<Order_admin />}
                            />
                            <Route
                              path="/Coupons_admin"
                              element={<Coupons_admin />}
                            />
                            <Route
                              path="/Currency_admin"
                              element={<Currency_admin />}
                            />
                            <Route
                              path="/requestcard_admin"
                              element={<Requestcard_admin />}
                            />
                            <Route
                              path="/Planrequest_admin"
                              element={<Planrequest_admin />}
                            />
                            <Route
                              path="/PlansSuperAdmin"
                              element={<PlansSuperAdmin />}
                            />
                            <Route
                              path="/CustomizedPlansSuperAdmin"
                              element={<CustomizedPlansSuperAdmin />}
                            />
                            <Route
                              path="/create_business_theme"
                              element={<ThemeSelect />}
                            />
                            <Route
                              path="/DisplayCardList_admin"
                              element={<DisplayCardList_admin />}
                            />
                          </Route>
                        )}
                      </Routes>
                    </div>
                  </userContext.Provider>
                </businessNameContext.Provider>
              </isBusinesCreatedContext.Provider>
            </businessContext.Provider>
            )}
          </currencyContext.Provider>
        </currencyArrayContext.Provider>
      </businessObjContext.Provider>
    </planContext.Provider>
  );
}
export default App;
//612
