import React, { useContext, useEffect, useState } from "react";
import PageName from "../Utils/PageName";
import { Link, useSearchParams } from "react-router-dom";
import { userContext } from "../../context/userContext";
import axios from "axios";
import { environmentVariables } from "../../config/env.config";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import CircularLoader from "../Components/CircularLoader/CircularLoader";
import "./CreateUpdateStyle.css";

function Roles() {
  const moduleIdMapsWithPermissionIds = [
    ["1"],
    ["2", "3", "4", "5"],
    ["17", "18", "15", "16"],
    ["25", "27", "26", "28"],
    ["77", "79", "78"],
    [],
    ["6", "7", "8", "9"],
    ["97", "96"],
    ["98"],
    ["84", "87"],
  ];

  const [roleName, setRoleName] = useState(null);
  const [editedRoleName, setEditedRoleName] = useState("");

  const [moduleIds, setModuleIds] = useState([]);
  const [permissionIds, setPermissionsIds] = useState([]);
  const [roleData, setRoleData] = useState(null);
  const [tempRoleData, setTempRoleData] = useState([]);
  const [roleDataApi, setRoleDataApi] = useState(null);
  const [searchInput, setSearchInput] = useState("");
  const [error, setError] = useState("");
  const [roleId, setRoleId] = useState(null);
  const { userData, setUserData } = useContext(userContext);
  const [userPermission, setUserPermission] = useState([]);
  const [loader, setLoader] = useState(false);
  const [allUserName, setAllUserName] = useState([]);
  const [isLoader, setIsloader] = useState(true);

  useEffect(() => {
    if (userData && userData.id) {
      setUserPermission(userData?.permission);
    }
  }, [userData]);

  const getRoleData = () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${environmentVariables?.apiUrl}api/v1/role/rolesPermissionList`,
      headers: {
        _token: userData?.token,
      },
      withCredentials: true,
    };

    axios
      .request(config)
      .then((response) => {
        console.log(response?.data?.data,"responseeee ")
        let temp = response?.data?.data?.map((el) => el.userName);
        temp = new Set(temp);
        temp = [...temp];
        setAllUserName(temp);
        setIsloader(false);
        setRoleData(response?.data?.data);
        setTempRoleData(response?.data?.data);
        setRoleDataApi(response?.data?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getRoleData();
  }, []);

  const removeItemfromArray = (
    valueToRemove,
    stateOfArray,
    setStateOfArray
  ) => {
    // Create a copy of the array
    const newArray = [...stateOfArray];

    // Find the index of the item to remove
    const indexToRemove = newArray.indexOf(valueToRemove);

    if (indexToRemove !== -1) {
      // Use splice to remove the item from the copy
      newArray.splice(indexToRemove, 1);
      // console.log("newArray", newArray);
      // Update the state with the modified array
      setStateOfArray(newArray);
    }
  };

  const handleRoleSubmit = () => {
    // console.log(roleName,"roleNameroleName")
    if (roleName == null) {
      toast.error("Role name is mandatory", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      });
      return;
    }else if(roleName?.trim()==""){
      toast.error("Role name is mandatory", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      });
      return;
    
    }else if(roleName?.length>40||roleName?.trim()?.length>40){
      toast.error("Role name length must be less than 40 character", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      });
      return;
    }
    if (moduleIds.length === 0) {
      toast.warning("Select at least 1 Permissions", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
      return;
    }
    if (roleName) {
      // console.log(permissionIds, "permissions id @", moduleIds, roleName);
      let data = JSON.stringify({
        name: roleName,
        module_id: moduleIds,
        permission_id: permissionIds,
      });
      setLoader(true);

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${environmentVariables?.apiUrl}api/v1/role/create`,
        headers: {
          "Content-Type": "application/json",
          _token: userData?.token,
        },
        withCredentials: true,
        data: data,
      };

      axios
        .request(config)
        .then((response) => {
          if (response) {
            // console.log(response);
            getRoleData();
            setModuleIds([]);
            setPermissionsIds([]);
            setRoleName("");
            setLoader(false);
            toast.success("Role created", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 1000,
            });
          }
          window.location.reload(5000);
        })
        .catch((error) => {
          // console.log(error.response, "ERERERE");
          setLoader(false);
          toast.error(
            error?.response?.data?.message?.message ||
              error?.response?.data?.message ||
              error?.message,
            {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 1000,
            }
          );
        });
    } else {
      setError("Role Name is required");
    }
  };

  const handleRoleEditSubmit = () => {
    if (moduleIds.length === 0) {
      toast.warning("Select at least 1 Permissions", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
      return;
    }
    if (editedRoleName == "" || editedRoleName?.trim() == "") {
      toast.error("Name is mandatory", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      });
      return;
    }else if(editedRoleName?.length>40 && editedRoleName?.trim()?.length> 40){
      toast.error("Name length must be less than 40 character", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      });
      return;
    }
    // console.log(roleId,"213",moduleIds,"#$%",permissionIds)

    let data = JSON.stringify({
      roleId: roleId,
      module_id: moduleIds,
      permission_id: permissionIds,
      roleName: editedRoleName,
    });
    // console.log(data, "handleRoleEditSubmit");
    let config = {
      method: "put",
      maxBodyLength: Infinity,
      url: `${environmentVariables?.apiUrl}api/v1/role/updateRole`,
      headers: {
        "Content-Type": "application/json",
        _token: userData?.token,
      },
      withCredentials: true,
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        toast.success("Role Updated", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
        window.location.reload(2000);
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message?.message|| error?.response?.data?.message || error?.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
      });
  };

  const handleEditRole = (id) => {
    const roleToBeEdit = roleData.filter((item) => item.role_id == id);

    const newPermissionIds = roleToBeEdit[0]?.permissions.map(
      (val) => `${val?.permission_id}`
    );
    const newmoduleId = roleToBeEdit[0]?.permissions.map(
      (val) => `${val?.module_id}`
    );
    const newModuleSet = new Set([...newmoduleId]);
    // console.log(newModuleSet, "newmoduleset");
    // setRoleName(roleToBeEdit[0]?.role_name);
    setEditedRoleName(roleToBeEdit[0]?.role_name);
    setRoleId(roleToBeEdit[0]?.role_id);
    setPermissionsIds([...newPermissionIds]);
    setModuleIds([...newModuleSet]);
  };

  const handleDeleteRole = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: `You won't be able to revert this!`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        let data = JSON.stringify({
          roleId: id,
        });

        let config = {
          method: "delete",
          maxBodyLength: Infinity,
          url: `${environmentVariables?.apiUrl}api/v1/role/deleteRole`,
          headers: {
            "Content-Type": "application/json",
            _token: userData?.token,
          },
          withCredentials: true,
          data: data,
        };

        axios
          .request(config)
          .then((response) => {
            toast.success("Selected role has been deleted", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 1000,
            });
            getRoleData();
          })
          .catch((error) => {
            toast.error(error?.response?.data?.message || error?.message, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 1000,
            });
          });
      }
    });
  };

  const handleCheckedAll = (e) => {
    if (e.target.checked) {
      setModuleIds(["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"]);
      let newPermissionIds = [].concat(...moduleIdMapsWithPermissionIds);
      setPermissionsIds(newPermissionIds);
    } else {
      setModuleIds([]);
      setPermissionsIds([]);
    }
  };

  const handleChangeModule = (e) => {
    // console.log(e.target.checked, "checkbox", e.target.value, "e.target.value");
    let permissionIdOfModuleId =
      moduleIdMapsWithPermissionIds[parseInt(e.target.value) - 1];
    // console.log(moduleIdMapsWithPermissionIds, "module rmissionIds");
    // if(parseInt (e.target.value)==4){
    //   permissionIdOfModuleId=[25,27]
    // }
    // console.log(permissionIdOfModuleId, "permiss uleId!@");
    if (e.target.checked) {
      console.log("Hello");
      setModuleIds([...moduleIds, `${e.target.value}`]);
      setPermissionsIds([...permissionIds, ...permissionIdOfModuleId]);
    } else {
      console.log("Hello 1");
      removeItemfromArray(e.target.value, moduleIds, setModuleIds);
      const newPermissionIds = permissionIds.filter(
        (item) => !permissionIdOfModuleId.includes(item)
      );
      setPermissionsIds(newPermissionIds);
    }
  };

  const handleChangePermissions = (e) => {
    // console.log(e.target.checked, "e.target.checked", e.target.value);
    let permissionIdOfModuleId =
      moduleIdMapsWithPermissionIds[parseInt(e.target.value) - 1];
    console.log(permissionIdOfModuleId, "permiss ule");

    if (e.target.checked) {
      setPermissionsIds([...permissionIds, `${e.target.value}`]);
    } else {
      removeItemfromArray(e.target.value, permissionIds, setPermissionsIds);
    }
  };

  const roleSearchByName = () => {
    const filteredResults = roleDataApi.filter((item) =>
      item?.role_name.toLowerCase().includes(searchInput?.toLowerCase())
    );
    setRoleData(filteredResults);
  };

  const [activeUserName, setActiveUserName] = useState("");

  const handleChangeUserNam = (e) => {
    // console.log(e.target.value, " admin e/.target.value fetch");
    setActiveUserName(e.target.value);
  };
  useEffect(() => {
    if (activeUserName == "All User") {
      setRoleData(tempRoleData);
    } else {
      // console.log(tempRoleData,"tempRoleData admin")
      let temp = tempRoleData.filter((el) => el.userName == activeUserName);

      setRoleData(temp);
    }
  }, [activeUserName]);
  return (
    <>
      {isLoader ? (
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "55%",
            display: "flex",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <CircularLoader size={40} />
        </div>
      ) : (
        <>
          <PageName PageName="Roles" />
          <article>
            <div className="row">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-12">
                    {userData && userData?.created_by != 0 ? (
                      userPermission &&
                      userPermission.length &&
                      userPermission?.some((obj) =>
                        obj.permissionobj?.name?.includes("create role")
                      ) ? (
                        <p>
                          <a
                            className="btn btn-primary icon-btn"
                            data-bs-toggle="modal"
                            data-bs-target="#newuser"
                            onClick={() => {
                              setRoleName(null);
                              setModuleIds([]);
                              setPermissionsIds([]);
                            }}
                          >
                            Add Role
                          </a>
                        </p>
                      ) : (
                        ""
                      )
                    ) : (
                      <div class="addnewRole_Filterwrapper">
                        {userData?.user_type == "super_admin" && (
                          <div class="Contact_filter_fieldbutton_wrapper">
                            <div class="Contact_filter_field_wrapper">
                              <div class="col-md-2 Contact_filter_Inputfield">
                                {/* <h5 className="mb-3">Filter by User name</h5> */}
                                <select
                                  value={activeUserName}
                                  onChange={(e) => handleChangeUserNam(e)}
                                  class="form-select"
                                >
                                  <option value={"All User"}>All User</option>
                                  {allUserName &&
                                    allUserName.length &&
                                    allUserName?.map((el) => {
                                      return <option value={el}>{el} </option>;
                                    })}
                                </select>
                                <div
                                  style={{
                                    fontSize: "14px",
                                    color: "red",
                                    bottom: "70px",
                                  }}
                                ></div>
                              </div>
                            </div>
                          </div>
                        )}
                        <p className="addrolep">
                          <a
                            className="icon-btn addrolemain_role"
                            data-bs-toggle="modal"
                            data-bs-target="#newuser"
                            onClick={() => {
                              setRoleName(null);
                              setModuleIds([]);
                              setPermissionsIds([]);
                            }}
                          >
                            Add New Role <span className="addrole_role">+</span>
                          </a>
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="tile">
                  <div className="tile-body">
                    <div className="table-responsive">
                      {roleData && roleData.length > 0 && (
                        <>
                          <table className="table ">
                            <thead>
                              <tr>
                                <div>
                                <th
                                  style={{ width: "8%", padding: "10px 0px 15px 0px", fontSize: "15px" }}
                                >
                                  User
                                </th>
                                <th style={{ width: "12%", padding: "10px 0px 15px 10px", fontSize: "15px" }}>Role</th>

                                <th style={{ width: "78%",maxWidth:"80%", padding: "10px 0px 15px 0px", fontSize: "15px" }}>Permissions </th>
                                <th style={{width: "4%", padding: "10px 0px 15px 0px", fontSize: "15px" }}>Action </th>
                          
                                </div>
                              </tr>
                            </thead>
                            <tbody>
                              {roleData?.map((val, key) => (
                                <tr key={key}>
                                  {/* <td>{val.role_id}</td> */}
                                <div 
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "start",
                                  textAlign: "start",
                                  borderBottom: "1px solid #dedede",
                                  // borderTop:  "1px solid red"
                                }}
                                >  <td style={{ minWidth: "8%", overflow:"hidden", textOverflow:"ellipsis",whiteSpace:"break spaces", marginRight:"10px"}}>{val?.userName}</td>
                                  <td style={{ minWidth: "10%",overflow:"hidden", textOverflow:"ellipsis",whiteSpace:"break spaces", marginRight:"10px"}}>{val.role_name}</td>
                                  <td 
                                  class="permissionSlotUpdatedStyle"
                                  style={{ minWidth: "75%", maxWidth:"75%"}}
                                  >
                                    {/* <span className="badge rounded p-2 m-1 px-3 bg-primary">
                                  show dashboard
                                </span>{" "}
                                <span className="badge rounded p-2 m-1 px-3 bg-primary">
                                  show dashboard
                                </span> */}
                                    {val?.permissions
                                      ?.filter(
                                        (el) =>
                                          el?.permission_name !=
                                            "edit contact" &&
                                          el?.permission_name !=
                                            "calendar appointment" &&
                                          el?.permission_name !=
                                            "edit appointment" &&
                                          el?.permission_name !=
                                            "delete business" &&
                                          el?.permission_name != "edit business"
                                      )
                                      ?.map((val2, index) => (
                                        <span
                                          className="badge rounded m-1 px-3"
                                          key={index}
                                        >
                                          {val2?.permission_name ==
                                          "create business"
                                            ? "Manage Business"
                                            // : val2?.permission_name ==
                                            //   "manage appointment"
                                            // ? "Show Appointment"
                                            : val2?.permission_name ==
                                              "delete appointment"
                                            ? "Manage Appointment"
                                            // : val2?.permission_name ==
                                            //   "manage contact"
                                            // ? "Show contact"
                                            : val2?.permission_name ==
                                              "order physical card"
                                            ? "view Physical Card"
                                            : val2?.permission_name ==
                                              "view analytics business"
                                            ? "Manage Physical Card"
                                            : val2?.permission_name}
                                        </span>
                                      ))}
                                  </td>

                                  <td style={{width: "12%"}}>
                                    <div className="btn-group">
                                      {/* <Link to="/"><button className="btn btn-success" href="#"><i className="bi bi-eye-fill fs-5"></i></button></Link> */}
                                      <button
                                        className="btn btn-warning"
                                        data-bs-toggle="modal"
                                        data-bs-target="#editrols"
                                        onClick={() =>
                                          handleEditRole(val?.role_id)
                                        }
                                      >
                                        <i className="first fas fa-pen fs-5"></i>
                                      </button>
                                      <Link>
                                        <button
                                          className="btn btn-danger"
                                          onClick={() =>
                                            handleDeleteRole(val?.role_id)
                                          }
                                        >
                                          <i className="bi bi-trash fs-5"></i>
                                        </button>
                                      </Link>
                                    </div>
                                  </td>
                                  </div>

                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </article>
          <div
            className="modal fade RolesAddNewRoles"
            id="newuser"
            tabindex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
            
          >
            <div className="modal-dialog modal-xl modal-style" >
              <div className="modal-content" id="CreateNewRoleSpacingIssueResolved">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">
                    Create New Role
                  </h5>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body">
                  <div className="row">
                    <div className="col-12 col-lg-12 mb-2">
                      {/* <label for="exampleFormControlInput1" className="form-label">
                    Role Name
                  </label> */}
                      <input
                        onChange={(e) => setRoleName(e.target.value)}
                        className="form-control"
                        type="text"
                        placeholder="Enter Role Name"
                      />
                    </div>
                    <div
                      style={{
                        color: "red",
                        position: "absolute",
                        top: "92px",
                      }}
                    >
                      {error}
                    </div>
                  </div>

                  <br></br>

                  <table className="table ">
                    <thead>
                      <tr>
                        <th scope="col">
                          <div className="form-check">
                            {/* <input
                          className="form-check-input"
                          type="checkbox"
                          value="-1"
                          onClick={(e) => handleCheckedAll(e)}
                          id="flexCheckDefault"
                        /> */}
                          </div>
                        </th>
                        <th scope="col">MODULE</th>
                        <th scope="col"> PERMISSIONS</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th scope="row" class="check_box_row">
                          {" "}
                          <input
                            className="form-check-input L420"
                            type="checkbox"
                            value="1"
                            id="flexCheckDefault"
                            checked={moduleIds.includes("1")}
                            onChange={(e) => handleChangeModule(e)}
                          />
                        </th>
                        <td
                          class="check_box_dashboard"
                          id="check_box_dashboard_id"
                        >
                          {" "}
                          Dashboard
                        </td>
                        <td class="check_box_dashboard_id" id="check_box_dashboard_id">
                          <div className="form-check check_box_dashboard CreateUpdateRoleform">
                            <input
                              className="form-check-input "
                              type="checkbox"
                              value="1"
                              checked={permissionIds.includes("1")}
                              id="flexCheckDefault"
                              onChange={(e) => handleChangePermissions(e)}
                            />
                            <label className="form-check-label">view</label>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th
                          scope="row"
                          // class="business_row"
                          class="check_box_row"
                          // style={padding:"10px 0"}
                        >
                          {" "}
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value="3"
                            checked={moduleIds.includes("3")}
                            id="flexCheckDefault"
                            onChange={(e) => handleChangeModule(e)}
                          />
                        </th>
                        <td id="check_box_dashboard_id">Business</td>
                        <td class="check_box_dashboard_idMid" id="check_box_dashboard_idMid">
                          <table className="table">
                            <tr>
                              <td >
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    value="17"
                                    checked={permissionIds.includes("17")}
                                    id="flexCheckDefault"
                                    // onChange={(e) => handleChangePermissions(e)}
                                    onClick={(e) => handleChangePermissions(e)}
                                  />
                                  <label className="form-check-label">
                                    view
                                  </label>
                                </div>{" "}
                              </td>

                              <td>
                                {" "}
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    value="18"
                                    checked={permissionIds.includes("18")}
                                    id="flexCheckDefault"
                                    onChange={(e) => handleChangePermissions(e)}
                                  />
                                  <label className="form-check-label">
                                    Manage
                                  </label>
                                </div>{" "}
                              </td>

                              <td></td>

                              <td></td>
                            </tr>
                          </table>
                        </td>
                      </tr>

                      <tr>
                        <th
                          scope="row"
                          // class="physical_card_row_th"
                          class="check_box_row"
                        >
                          {" "}
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value="8"
                            checked={moduleIds.includes("8")}
                            id="flexCheckDefault"
                            onChange={(e) => handleChangeModule(e)}
                          />
                        </th>
                        <td id="check_box_dashboard_id">Physical Card</td>
                        <td class="check_box_dashboard_idMid" id="check_box_dashboard_idMid">
                          <table className="table">
                            <tr>
                              <td>
                                {" "}
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    value="97"
                                    checked={permissionIds.includes("97")}
                                    id="flexCheckDefault"
                                    onChange={(e) => handleChangePermissions(e)}
                                  />
                                  <label className="form-check-label">
                                    view
                                  </label>
                                </div>
                              </td>
                              <td>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    value="96"
                                    checked={permissionIds.includes("96")}
                                    id="flexCheckDefault"
                                    onChange={(e) => handleChangePermissions(e)}
                                  />
                                  <label className="form-check-label">
                                    Manage
                                  </label>
                                </div>
                              </td>
                            </tr>
                          </table>
                        </td>
                        {/* <td class="check_box_dashboard_idMid" id="check_box_dashboard_idMid">
                          <table className="table">
                            <tr>
                              <td>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    value="96"
                                    checked={permissionIds.includes("96")}
                                    id="flexCheckDefault"
                                    onChange={(e) => handleChangePermissions(e)}
                                  />
                                  <label className="form-check-label">
                                    Manage
                                  </label>
                                </div>
                              </td>
                            </tr>
                          </table>
                        </td> */}
                      </tr>

                      <tr>
                        <th
                          scope="row"
                          // class="physical_card_row_th"
                          class="check_box_row"
                        >
                          {" "}
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value="4"
                            checked={moduleIds.includes("4")}
                            id="flexCheckDefault"
                            onChange={(e) => handleChangeModule(e)}
                          />
                        </th>
                        <td id="check_box_dashboard_id">Appointment</td>
                        <td class="check_box_dashboard_idMid" id="check_box_dashboard_idMid">
                          <table className="table">
                            <tr>
                              <td>
                                {" "}
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    value="25"
                                    checked={permissionIds.includes("25")}
                                    id="flexCheckDefault"
                                    onChange={(e) => handleChangePermissions(e)}
                                  />
                                  <label className="form-check-label">
                                    view
                                  </label>
                                </div>
                              </td>
                              <td>
                                {" "}
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    value="27"
                                    checked={permissionIds.includes("27")}
                                    id="flexCheckDefault"
                                    onChange={(e) => handleChangePermissions(e)}
                                  />
                                  <label className="form-check-label">
                                    Manage
                                  </label>
                                </div>
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>

                      <tr>
                        <th
                          scope="row"
                          // class="physical_card_row_th"
                          class="check_box_row"
                        >
                          {" "}
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value="5"
                            checked={moduleIds?.includes("5")}
                            id="flexCheckDefault"
                            onChange={(e) => handleChangeModule(e)}
                          />
                        </th>
                        <td id="check_box_dashboard_id"> Contact</td>
                        <td>
                          <table className="table">
                            <tr>
                              <td class="check_box_dashboard_idMid" id="check_box_dashboard_idMid">
                                {" "}
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    value="77"
                                    checked={permissionIds.includes("77")}
                                    id="flexCheckDefault"
                                    onChange={(e) => handleChangePermissions(e)}
                                  />
                                  <label className="form-check-label">
                                    view
                                  </label>
                                </div>
                              </td>

                              <td>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    value="78"
                                    checked={permissionIds?.includes("78")}
                                    id="flexCheckDefault"
                                    onChange={(e) => handleChangePermissions(e)}
                                  />
                                  <label className="form-check-label">
                                    Manage
                                  </label>
                                </div>
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="modal-footer">
                  <button
                    onClick={handleRoleSubmit}
                    type="button"
                    className="btn btn-primary save-cta"
                  >
                    {loader ? <CircularLoader /> : "Create"}
                  </button>
                  {/* <button
                type="button"
                className="btn btn-secondary close-cta"
                data-bs-dismiss="modal">
                  Close
              </button> */}
                </div>
              </div>
            </div>
          </div>

          <div
            className="modal fade"
            id="editrols"
            tabindex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-xl">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">
                    Update Role
                  </h5>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body">
                  <div className="row">
                    <div className="col-12 col-lg-12">
                      {/* <label for="exampleFormControlInput1" className="form-label">
                    Role Name
                  </label> */}
                      <input
                        // onChange={(e) => setRoleName(e.target.value)}
                        onChange={(e) => setEditedRoleName(e.target.value)}
                        className="form-control"
                        type="text"
                        // value={roleName}
                        value={editedRoleName}
                        placeholder="Enter Role Name"
                      />
                    </div>
                  </div>
                  {/* <hr /> */}

                  <table className="table ">
                    <thead>
                      <tr>
                        <th scope="col">
                          <div className="form-check">
                            {/* <input
                          className="form-check-input"
                          type="checkbox"
                          value="-1"
                          onClick={(e) => handleCheckedAll(e)}
                          id="flexCheckDefault"
                        /> */}
                          </div>
                        </th>
                        <th scope="col">MODULE</th>
                        <th scope="col"> PERMISSIONS</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th
                          scope="row"
                          // class="physical_card_row_th"
                          class="check_box_row"
                        >
                          {" "}
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value="1"
                            id="flexCheckDefault"
                            checked={moduleIds.includes("1")}
                            onChange={(e) => handleChangeModule(e)}
                          />
                        </th>
                        <td
                          class="check_box_dashboard"
                          id="check_box_dashboard_id"
                        >
                          {" "}
                          Dashboard
                        </td>
                        <td class="check_box_dashboard_id" id="check_box_dashboard_id">
                          <div className="form-check check_box_dashboard CreateUpdateRoleform">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              value="1"
                              checked={permissionIds.includes("1")}
                              id="flexCheckDefault"
                              onChange={(e) => handleChangePermissions(e)}
                            />
                            <label className="form-check-label">view</label>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row" 
                        // class="physical_card_row_th"
                        class="check_box_row"
                        >
                          {" "}
                          <div >
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value="3"
                            checked={moduleIds.includes("3")}
                            id="flexCheckDefault"
                            onChange={(e) => handleChangeModule(e)}
                          />
                          </div>
                        </th>
                        <td
                          class="check_box_dashboard"
                          id="check_box_dashboard_id"
                        >
                          Business
                        </td>
                        <td class="check_box_dashboard_idMid" id="check_box_dashboard_idMid">
                          <table className="table">
                            <tr>
                              <td>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    value="17"
                                    checked={permissionIds.includes("17")}
                                    id="flexCheckDefault"
                                    onChange={(e) => handleChangePermissions(e)}
                                  />
                                  <label className="form-check-label">
                                    view
                                  </label>
                                </div>{" "}
                              </td>

                              <td>
                                {" "}
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    value="18"
                                    checked={permissionIds.includes("18")}
                                    id="flexCheckDefault"
                                    onChange={(e) => handleChangePermissions(e)}
                                  />
                                  <label className="form-check-label">
                                    Manage
                                  </label>
                                </div>{" "}
                              </td>

                              {/* <td></td> */}

                              {/* <td></td> */}
                            </tr>
                          </table>
                        </td>
                      </tr>

                      <tr>
                        <th scope="row" 
                        class="check_box_row">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value="8"
                            checked={moduleIds.includes("8")}
                            id="flexCheckDefault"
                            onChange={(e) => handleChangeModule(e)}
                          />
                        </th>
                        <td id="check_box_dashboard_id">Physical Card</td>
                        <td >
                          <table className="table">
                            <tr>
                              <td>
                                {" "}
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    value="97"
                                    checked={permissionIds.includes("97")}
                                    id="flexCheckDefault"
                                    onChange={(e) => handleChangePermissions(e)}
                                  />
                                  <label className="form-check-label">
                                    view
                                  </label>
                                </div>
                              </td>
                              <td class="check_box_dashboard_idMid" id="check_box_dashboard_idMid">
                                {" "}
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    value="96"
                                    checked={permissionIds.includes("96")}
                                    id="flexCheckDefault"
                                    onChange={(e) => handleChangePermissions(e)}
                                  />
                                  <label className="form-check-label">
                                    Manage
                                  </label>
                                </div>
                              </td>
                            </tr>
                          </table>
                        </td>
                        {/* <td>
                          <table className="table">
                            <tr>
                              <td class="check_box_dashboard_idMid" id="check_box_dashboard_idMid">
                                {" "}
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    value="96"
                                    checked={permissionIds.includes("96")}
                                    id="flexCheckDefault"
                                    onChange={(e) => handleChangePermissions(e)}
                                  />
                                  <label className="form-check-label">
                                    Manage
                                  </label>
                                </div>
                              </td>
                            </tr>
                          </table>
                        </td> */}
                      </tr>

                      <tr>
                        <th scope="row" class="check_box_row">
                          {" "}
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value="4"
                            checked={moduleIds.includes("4")}
                            id="flexCheckDefault"
                            onChange={(e) => handleChangeModule(e)}
                          />
                        </th>
                        <td id="check_box_dashboard_id">Appointment</td>
                        <td>
                          <table className="table">
                            <tr>
                              <td class="check_box_dashboard_idMid" id="check_box_dashboard_idMid">
                                {" "}
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    value="25"
                                    checked={permissionIds?.includes("25")}
                                    id="flexCheckDefault"
                                    onChange={(e) => handleChangePermissions(e)}
                                  />
                                  <label className="form-check-label">
                                    view
                                  </label>
                                </div>
                              </td>
                              <td>
                                {" "}
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    value="27"
                                    checked={permissionIds.includes("27")}
                                    id="flexCheckDefault"
                                    onChange={(e) => handleChangePermissions(e)}
                                  />
                                  <label className="form-check-label">
                                    Manage
                                  </label>
                                </div>
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>

                      <tr>
                        <th scope="row" class="check_box_row">
                          {" "}
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value="5"
                            checked={moduleIds.includes("5")}
                            id="flexCheckDefault"
                            onChange={(e) => handleChangeModule(e)}
                          />
                        </th>
                        <td id="check_box_dashboard_id"> Contact</td>
                        <td class="check_box_dashboard_idMid" id="check_box_dashboard_idMid">
                          <table className="table">
                            <tr>
                              <td>
                                {" "}
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    value="77"
                                    checked={permissionIds.includes("77")}
                                    id="flexCheckDefault"
                                    onChange={(e) => handleChangePermissions(e)}
                                  />
                                  <label className="form-check-label">
                                    view
                                  </label>
                                </div>
                              </td>

                              <td>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    value="78"
                                    checked={permissionIds.includes("78")}
                                    id="flexCheckDefault"
                                    onChange={(e) => handleChangePermissions(e)}
                                  />
                                  <label className="form-check-label">
                                    Manage
                                  </label>
                                </div>
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="modal-footer" style={{ border: "none" }}>
                  {/* <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button> */}
                  <button
                    onClick={handleRoleEditSubmit}
                    type="button"
                    className="btn btn-primary save-cta"
                  >
                    Update
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default Roles;
