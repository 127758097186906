import React, { useContext, useEffect, useState } from "react";
import "./Style.css";
import PageName from "../Utils/PageName";
import axios from "axios";
import { userContext } from "../../context/userContext";
import { environmentVariables } from "../../config/env.config";
import QRCode from "qrcode.react";
import Charttb from "./Charttb";
import Charttc from "./Charttc";
import Chartte from "./Chartte";
import Charttf from "./Charttf";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import "./Updatedcss/UpdatedDashboardstyle.css";

function Dashbord() {
  const navigate = useNavigate();
  const [cardsData, setCardsData] = useState(null);
  const { userData } = useContext(userContext);
  const [storedData, setStoredData] = useState(
    localStorage.getItem("businessObj")
  );
  const [appData, setAppData] = useState([]);
  const [contData, setContData] = useState([]);
  const [chartsRs, setChartsRs] = useState([]);
  const [businessLink, setBusinessLink] = useState("");
  const [sevdays, setSevdays] = useState({});
  const [graphData, setGraphData] = useState({
    transformedData: [],
    gData2: [],
  });
  const [GreatingMsg, setGreatingMsg] = useState("");
  async function getGreatingMsg() {
    try {
      const response = await axios.get(
        `${baseUrl}api/v1/admin/setting/fetchConfig`,
        {
          params: {
            cnf_type: "Web",
            name: ["Greeting"],
          },
        }
      );
      if (response.status === 200) {
        setGreatingMsg(response?.data?.result?.Greeting);
      }
    } catch (error) {
      console.error({ "Error:": error, msg: "ErrorLanding" });
    }
  }
  const baseUrl = environmentVariables?.apiUrl;
  const getGraphData = () => {
    axios
      .get(`${baseUrl}api/v1/business/fetchGraphData`, {
        params: { UserId: userData?.id },
        headers: {
          _token: userData?.token,
        },
      })
      .then((res) => {
        if (res?.status === 200) {
          // console.log(res?.data,"res?.datares?.data")
          setSevdays(res?.data);
          if (res?.data?.resultA?.sum_of_appointments) {
            setAppData(res.data.resultA.sum_of_appointments);
          }
          if (res?.data?.resultC?.sum_of_contacts) {
            setContData(res.data.resultC.sum_of_contacts);
          }
          if (res?.data?.resultViewr) {
            setChartsRs(res.data?.resultViewr);
          }
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 404) {
          console.error("Resource not found:", error);
        } else {
          console.error("error:", error);
        }
      });
  };

  let data1 = graphData?.transformedData;
  let data2 = [["Language", "Speakers (in millions)"]];
  data2.push(...graphData.gData2);
  useEffect(() => {
    getGraphData();
    getGreatingMsg();
    if (storedData != "undefined") {
      const businessData = JSON.parse(storedData);
      const slugb = businessData?.slug;
      const originUrl = window.location.origin;
      setBusinessLink(`${originUrl}/${slugb}`);
    }
  }, [storedData]);

  const handleCopyClick = () => {
    const linkToCopy = window.location.href;
    navigator.clipboard
      .writeText(businessLink)
      .then((res) => {
        setCopied(true);
      })
      .catch((error) => {
        console.error("Failed to copy link: ", error);
      });
    toast.info("Link Copy on Clipboard", {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 1000,
    });
  };
  const apiUrl = environmentVariables?.apiUrl;

  useEffect(() => {
    let config = {
      method: "get",
      url: `${apiUrl}api/v1/user/getDashboardData`,
      headers: {
        _token: userData?.token,
      },
    };

    axios
      .request(config)
      .then((response) => {
        setCardsData(response?.data?.data);
      })
      .catch((error) => {
        if (error?.response?.status) {
          localStorage.clear();
          window.location.href = "/";
          window.location.reload();
        }
      });
  }, []);

  return (
    <>
      <PageName PageName="Dashboard" />
      <div className="row Greeting_total_wrapper">
        <div className="col-md-5" id="grettingWrapper">
          <div className="tile  welcom ">
            <div>
              <h6>
                <b>{userData?.name}</b>
              </h6>

              <p>{GreatingMsg}</p>
              <div style={{marginTop:"10px" ,fontSize:"16px", cursor:"pointer"}}><a href="https://www.youtube.com/watch?v=sg_ZoHZ-hEk" target="_blank">How it works <i class="fa-solid fa-up-right-from-square"></i></a></div>
              {businessLink?.includes("undefined") ? (
                ""
              ) : (
                <button className="copybtn" onClick={handleCopyClick}>
                  Business Link <i className="fa-solid fa-copy"></i>
                </button>
              )}
            </div>
            <div className="QRcode_wrapper">
              {businessLink?.includes("undefined")? 
            "":  
              <QRCode
              value={businessLink}
              style={{
                height: "65px",
                width: "66px",
                margin: "auto",
                display: "block",
              }}
              />
            }
            </div>
          </div>
        </div>
        <div className="col-md-7" id="total_wrapper_container">
          <div className="row">
            <div className="col-md-4">
              <div className="tile bbox Total_Titlebox_container">
                <i className="fa-solid fa-business-time"></i>
                <h3>Total Business</h3>
                <h4>{sevdays?.totalBusiness?.total || 0}</h4>
              </div>
            </div>
            <div className="col-md-4">
              <div className="tile bbox Total_Titlebox_container">
                <i className=" fa-regular fa-handshake"></i>
                <h3>Total Appointments</h3>
                <h4>{sevdays?.resultA?.total || 0}</h4>
              </div>
            </div>
            <div className="col-md-4">
              <div className="tile bbox ">
                <i className="fa-solid fa-users"></i>
                <h3>Total Staff</h3>
                <h4>{sevdays?.totalStaff?.total || 0}</h4>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="Dashboard_appointment_visitors_wrapper">
          <div className="col-12 col-lg-6 Appointment_wrapper">
            <div className="tile border-left">
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <h5>Appointments </h5>
                <span>Last 7 Days</span>
              </div>
              {sevdays &&
              typeof sevdays === "object" &&
              Object.keys(sevdays).length > 0 ? (
                <Charttc data={sevdays} />
              ) : null}
            </div>
          </div>

        <div className="col-12 col-lg-6 Visitors_wrapper">
          <div className="tile border-left">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <h5>Visitor </h5>
              <span></span>
            </div>
            {sevdays &&
            typeof sevdays === "object" &&
            Object.keys(sevdays).length > 0 ? (
              <Charttb data={sevdays} />
            ) : null}
          </div>
        </div>
      </div>

      <div className="physicalcard_recentbusiness_wrapper">
        <div className="col-12 col-lg-8 recentbusines_card_wrapper">
          <div className="tile border-left ">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <h5>Recent Business </h5>
              <span></span>
              <div className="contact_filter_buttonwrapper">
                <button
                  className="contact_filter_buttonstyle"
                  onClick={() => navigate("/businesslist")}
                >
                  View all
                </button>
              </div>
            </div>
            {sevdays &&
            typeof sevdays === "object" &&
            Object.keys(sevdays).length > 0 ? (
              <Charttf data={sevdays} />
            ) : null}
          </div>
        </div>

        <div className="col-12 col-lg-4 kheight physical_card_wrapperAdmin">
          <div className="tile border-left ">
            <h5>Physical Card </h5>
            {sevdays &&
            typeof sevdays === "object" &&
            Object.keys(sevdays).length > 0 ? (
              <Chartte data={sevdays} />
            ) : null}
          </div>
        </div>
        </div>
      </div>
    </>
  );
}
export default Dashbord;
//1112