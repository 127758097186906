import React from "react";
import "./LandingFoolterStyle.css";

function Faqs() {
  return (
    <div>
      <section className="pagecover">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-12">
              <h1>VmyCards (FAQ's)</h1>
            </div>
          </div>
        </div>
      </section>

      <section class="tandcpage" id="faq_container">
        <div class="container">
          <div class="row">
            <div class="col-12 col-lg-12">
              <ol class="faqui" id="faqUi">
                <li>
                  {/* <h4>What Is A Digital Business Card?</h4> */}
                  <div className="boldfont">What Is A Digital Business Card?</div>
                  <p>
                    It is a digital card for sending contact
                    details electronically, replacing outdated visiting cards.
                    It is a personalized, interactive digital card that
                    simplifies networking for you.{" "}
                  </p>
                </li>

                <li>
                  {/* <h4>What Is A vMyCards Business Card?</h4> */}
                  <div className="boldfont">What Is A vMyCards Business Card?</div>
                  <p>
                    It is designed for professionals who attend
                    meetings, tradeshows, or seminars to quickly share their
                    business details without the hassle of manual sharing.
                  </p>
                </li>
                <li>
                  {/* <h4>Tell Us How vMyCards Works?</h4> */}
                  <div className="boldfont">Tell Us How vMyCards Works?</div>
                  <p>
                    You can start by taking a 30-day free trial
                    that lets you create, design, and share the business
                    information you want others to see with the help of powerful
                    technologies like NFC.
                  </p>
                </li>
                <li>
                  {/* <h4>How Do I Share My Digital Business Card?</h4> */}
                  <div className="boldfont">How Do I Share My Digital Business Card?</div>
                  <p>
                    Sharing your digital business card with
                    vMyCards is simple; just tap your card on the back of a
                    phone or access it through a QR code. But first, you need to
                    sign up and create an account with us.
                  </p>
                </li>
                <li>
                  {/* <h4> How Many Digital Business Cards Can I Create?</h4> */}
                  <div className="boldfont">How Many Digital Business Cards Can I Create?</div>
                  <p>
                    With vMyCards, you can create as many digital
                    business cards as you need to suit various networking and
                    business situations.
                  </p>
                </li>
                <li>
                  {/* <h4>Will My Digital Business Card Be Mobile Friendly?</h4> */}
                  <div className="boldfont">Will My Digital Business Card Be Mobile Friendly?</div>
                  <p>
                    Yes, vMyCards ensures your digital business
                    card is mobile-friendly, making it accessible and
                    user-friendly on smartphones.
                  </p>
                </li>
                <li>
                  {/* <h4> Does vMyCards Require A Subscription?</h4> */}
                  <div className="boldfont">Does vMyCards Require A Subscription?</div>
                  <p>
                    vMyCards offers unparalleled deals on pricing
                    at a mere 30 Rupees per month! If this isn't convincing
                    enough, you get the card for free.{" "}
                  </p>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Faqs;
