import React, { useContext, useEffect, useState, useRef } from "react";
import PageName from "../Utils/PageName";
import { Link } from "react-router-dom";
import { environmentVariables } from "../../config/env.config";
import axios from "axios";
import { userContext } from "../../context/userContext";
import ReactDOM from "react-dom";
import DataTable from "react-data-table-component";
import SortIcon from "@material-ui/icons/ArrowDownward";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import { businessContext } from "../../context/businessContext";
import { businessObjContext } from "../../context/businessObjContext";
import CircularLoader from "../Components/CircularLoader/CircularLoader";
import { businessNameContext } from "../../context/businessContext";
import Swal from "sweetalert2";
import DatePicker from "react-datepicker";
import { ToastContainer, toast } from "react-toastify";
import "./Updatedcss/UpdatedAppointmentstyle.css";
const Appoinments_slots = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const baseUrl = environmentVariables?.apiUrl;
  const { userData, setUserData } = useContext(userContext);
  const { businessObjData, setBusinessObjData } = useContext(
    businessObjContext
  );
  const [isLoading, setIsLoading] = useState(true);
  const { businessNameData, setBusinessNameData } = useContext(
    businessNameContext
  );
  const [selectedOption, setSelectedOption] = useState("");
  const [selectedData, setSelectedData] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [appointmentId, setAppointmentId] = useState("");
  const [businessData, setBusinessData] = useState([]);
  const [tempData, setTempData] = useState([]);
  const [activeBusinessName, setActiveBusinessName] = useState("");
  const tableExtensions = {
    export: false,
    print: false,
  };
  const fromDate = useRef(null);
  const toDate = useRef(null);
  const handleEditClick = (data) => {
    setSelectedData(data);
    setShowModal(true);
  };
  const handleCloseModal = () => {
    setShowModal(false);
  };
  const customStyles = {
    dataTableExtensions: {
      backgroundColor: "lightgray",
      padding: "10px",
    },
    headRow: {
      style: {
        backgroundColor: "#e9e9e9",
        borderRadius: "2px 2px 0 0",
        // paddingLeft: "25px",
        padding: "10px 25px",
        border: "none",
      },
    },
    headCells: {
      style: {
        color: "#525252",
        fontSize: "16px",
        minHeight: "52px",
      },
    },
    rows: {
      style: {
        padding: "20px 25px",
        fontSize: "14px",
        textTransform: "capitalize",
        minHeight: "48px !important",
      },
    },
  };

  const FetchSlots = () => {
    try {
      let obj = {
        business_id: 0,
      };
      axios
        .post(`${baseUrl}api/v1/appointment/FetchSlots`, obj, {
          headers: {
            _token: userData?.token,
          },
        })
        .then((response) => {
            let tempArr = response?.data?.data?.records?.filter((el) => el?.businessTitle !== '' && el?.businessTitle !== undefined)
                .map((el) => el.businessTitle);
          let tempUniqueArr = new Set(tempArr);
          tempUniqueArr = [...tempUniqueArr];
          setBusinessData(tempUniqueArr);
        let newDataArray = [];
        response?.data?.data?.records.forEach(rs => {
            rs?.slots?.forEach(rss => {
                if(rss?.startDate!=='' && rss?.endDate!==''){
                    let newData = {
                        id:`${rs?.id}_${rs?.business_id}_${rss?.formCount}`,
                        business_id: rs?.business_id,
                        businessTitle: rs?.businessTitle,
                        formCount: rss?.formCount,
                        startDate: rss?.startDate,
                        endDate: rss?.endDate,
                        status: rss?.status||'NA',
                    };
                    newDataArray.push(newData);
                 }
            });
        });

        // console.log(newDataArray)
        
          setTempData(newDataArray);
          setData(newDataArray);
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
        });
    } catch (err) {
      console.log(err, "erroror");
    }
  };

  useEffect(() => {
    FetchSlots();
  }, []);

  useEffect(() => {
    FetchSlots();
  }, [businessObjData]);

  const getBusinessNameById = (businessId) => {
    const business = businessNameData.find((item) => item.id === businessId);
    return business ? business.title : "";
  };

  const handleDeleteAppoinment = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        let data = JSON.stringify({
          appointmentId: id,
        });

        let config = {
          method: "delete",
          maxBodyLength: Infinity,
          url: `${environmentVariables?.apiUrl}api/v1/appointment/deleteAppointment`,
          headers: {
            _token: userData?.token,
            "Content-Type": "application/json",
          },
          data: data,
        };
        axios
          .request(config)
          .then((response) => {
            Swal.fire(
              "Deleted!",
              "Selected appointment has been deleted.",
              "success"
            );
            FetchSlots();
          })
          .catch((error) => {
            Swal.fire(
              "Error!",
              error?.response?.data?.message || error?.message,
              "error"
            );
          });
      }
    });
  };

 
  const handleToggleStatus = (id, currentStatus) => {
    axios
      .put(
        `${baseUrl}api/v1/appointment/put_available_slots/?id=${id}`,
        { status: currentStatus },
        {
          headers: {
            _token: userData?.token,
          },
        }
      )
      .then((response) => {
        toast.success(response?.data?.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        console.error("Error updating business status:", error);
      });
  };
  const fetchData = async (obj) => {
    try {
      setAppointmentId(obj.id);
      setSelectedOption(obj.status);
    } catch (error) {
      console.error("API Error:", error);
    }
  };
  const columns = [
    {
        name: "Business Name",
        selector: "business_title",
        sortable: true,
        width: "33%",
        cell: (row) => row?.businessTitle,
    },
    {
      name: "Time",
      selector: "startDate",
      sortable: true,
      width: "33%",
      cell: (row) => `${row?.startDate}-${row?.endDate}`, // {`${row?.startDate}-${row?.endDate}`},
    },
    {
        name: "Availability",
        selector: "status",
        sortable: true,
        width: "33%",
        cell: (d) => (  <> 
        <div className="form-check form-switch">
        <input
          className="form-check-input"
          type="checkbox"
          checked={d?.status == "active" ? true : false}
          id={`flexSwitchCheckDefault_${d.id}`}
          onClick={() => {
            handleToggleStatus(d?.id, d.status);
          }}
        />
      </div></> ),
      },

    //   {
    //     name: "ID",
    //     selector: (row) => row?.id,
    //     sortable: true,
    //     width: "08%",
    //   },
     
  ];
  const tableData = {
    columns,
    data,
  };
  const handleUpdate = () => {};
  const [editedData, setEditedData] = useState({
    date: new Date(),
  });
  const handleDateChange = (date) => {
    setEditedData({
      ...editedData,
      date: date,
    });
  };
  const handleDropdownChange = (e) => {
    if (e.target.value == "pending") {
      setSelectedOption(e.target.value);
    } else if (e.target.value == "confirm") {
      setSelectedOption(e.target.value);
    } else if (e.target.value == "cancel") {
      setSelectedOption(e.target.value);
    }
  };

  const handleSubmit = (e) => {
    let obj = {
      appointmentId,
      selectedOption,
    };
    axios
      .put(`${baseUrl}api/v1/appointment/UpdateAppointmentStatus`, obj, {
        headers: {
          _token: userData?.token,
        },
        withCredentials: true,
      })
      .then((response) => {
        window.location.reload(5000);
        toast.success(response?.data?.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      })
      .catch((error) => {
        console.log(error, "Appointment response");
      });
  };

  const handleChangeBusinessData = (e) => {
    setActiveBusinessName(e.target.value);
  };
  const [selectedDateFrom, setSelectedDateFrom] = useState("");
  const [selectedDateTo, setSelectedDateTo] = useState("");
  
  useEffect(() => {
    if (selectedDateTo != "" && selectedDateFrom != "") {
      if (selectedDateTo < selectedDateFrom) {
        toast.warning("To Date should be equal to or greater then From Date", {
          position: toast.POSITION.TOP_RIGHT,
        });
        return;
      }
    }
    if (
      activeBusinessName === "All Business" &&
      !selectedDateFrom &&
      !selectedDateTo
    ) {
      setData(tempData);
    } else {
      let filteredData = tempData;
      if (activeBusinessName != "" && activeBusinessName !== "All Business") {
        filteredData = filteredData.filter(
          (el) => el.businessTitle === activeBusinessName
        );
      }
      if (selectedDateFrom && selectedDateTo) {
        filteredData = filteredData.filter((el) => {
          const date = new Date(el.date);
          return date >= selectedDateFrom && date <= selectedDateTo;
        });
      }
      setData(filteredData);
    }
  }, [activeBusinessName, selectedDateFrom, selectedDateTo]);

  const handleReset = () => {
    setBusinessNameData("");
    setActiveBusinessName("All Business");
    setSelectedDateFrom("");
    setSelectedDateTo("");
    setData([...tempData]);
  };
  return (
    <>
      <PageName PageName="Appointments" />
      <article>
        <div className="row d-none">
          <div className="col-md-12">
            <div className="tile">
              <div className="tile-body">
                <form className="row">
                  <div className="mb-2 col-md-3">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Search....."
                    />
                  </div>
                  <div className="mb-2 col-md-3">
                    <select className="form-select">
                      <option value="">Select an option</option>
                      <option value="option1">xxx </option>
                      <option value="option2">xxxx</option>
                    </select>
                  </div>
                  <div className="mb-2 col-md-4 align-self-end">
                    <button className="btn btn-primary me-3" type="button">
                      <i className="bi bi-check-circle-fill ms-2 "></i>Search
                    </button>
                    <button className="btn btn-danger" type="button">
                      <i className="bi bi-check-circle-fill me-2"></i>Reset
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="main">
          {isLoading ? (
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "55%",
                display: "flex",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <CircularLoader size={40} />
            </div>
          ) : (
            <>
              <div className="Contact_filter_fieldbutton_wrapper">
                <div className="Contact_filter_field_wrapper">
                  <div className="col-md-2 Contact_filter_Inputfield">
                    {/* <h5 className="mb-3">Business Name </h5> */}
                    <select
                      value={activeBusinessName}
                      onChange={(e) => handleChangeBusinessData(e)}
                      className="form-select formselect_slotwidth"

                    >
                      {" "}
                      <option value={"All Business"}>All Business</option>
                      {businessData &&
                        businessData.map((val, index) => (
                          <option value={val}>{val} </option>
                        ))}
                    </select>
                    <div
                      style={{
                        fontSize: "14px",
                        color: "red",
                        bottom: "70px",
                      }}
                    ></div>
                  </div>
                </div>
              </div>

              
              <DataTableExtensions
                {...tableData}
                {...tableExtensions}
                customStyles={customStyles}
                filterPlaceholder="Search Appoinments"
              >
                <DataTable
                  columns={columns}
                  data={data}
                  noHeader
                  defaultSortField="id"
                  sortIcon={<SortIcon />}
                  defaultSortAsc={true}
                  pagination
                  highlightOnHover
                  dense
                  customStyles={customStyles}
                />
              </DataTableExtensions>
            </>
          )}
        </div>
      </article>
    </>
  );
};
//112 16:28
export default Appoinments_slots;
