import React from "react";
import "./LandingFoolterStyle.css";


function Term() {
  return (
    <div>
      <section className="pagecover">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-12">
              <h1>Terms & Conditions</h1>
            </div>
          </div>
        </div>
      </section>

      <section className="tandcpage" id="term_condition_Wrapper">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-12">
              <div className="conatntwrap pri">
                <div>
                  <div class="Term_condition_heading_paragraph">
                    <p>
                      <p class="Term_condition_heading" >Introduction:</p>
                    </p>
                    <p class="Term_condition_paragraph">
                      At vmycards.com, we are committed to protecting the
                      privacy and security of our users' personal information.
                      This Privacy Policy outlines how we collect, use,
                      disclose, and protect your data when you interact with our
                      website.
                    </p>
                  </div>
                  <div class="Term_condition_heading_paragraph"> 
                    <p>
                      <p class="Term_condition_heading">Information We Collect:</p>
                    </p>
                    <p class="Term_condition_paragraph"> 
                      - Personal Information: We may collect personal
                      information such as your name, email address, and contact
                      details when you register an account, subscribe to our
                      newsletter, or use our services.
                    </p>
                    <p class="Term_condition_paragraph">
                      - Usage Information: We gather data on how you interact
                      with our website, including your browsing activity, pages
                      visited, and interactions with features.
                    </p>
                  </div>
                  <div class="Term_condition_heading_paragraph">
                    <p>
                      <p class="Term_condition_heading">How We Use Your Information:</p>
                    </p>
                    <p class="Term_condition_paragraph">
                      - Provide Services: We use your information to deliver the
                      services you requested, such as processing transactions
                      and delivering products.
                    </p>
                    <p class="Term_condition_paragraph">
                      - Communication: We may use your contact information to
                      send you updates, promotions, and important notices.
                    </p>
                    <p class="Term_condition_paragraph">
                      - Improving Services: Your data helps us understand user
                      preferences and behaviors, allowing us to enhance our
                      website and services.
                    </p>
                    <p class="Term_condition_paragraph">
                      - Legal Requirements: We may share your information if
                      required by law or to protect our rights, safety, and
                      property.
                    </p>
                  </div>
                  <div class="Term_condition_heading_paragraph">
                    <p>
                      <p class="Term_condition_heading">Cookies and Tracking:</p>
                    </p>
                    <p class="Term_condition_paragraph">
                      We use cookies and similar tracking technologies to
                      improve your browsing experience, analyze site traffic,
                      and personalize content. You can manage your cookie
                      preferences through your browser settings.
                    </p>
                  </div>
                  <div class="Term_condition_heading_paragraph">
                    <p>
                      <p class="Term_condition_heading">Third-Party Services:</p>
                    </p>
                    <p class="Term_condition_paragraph">
                      We may use third-party services, such as analytics and
                      payment processors, to enhance our website's
                      functionality. These third parties may have their own
                      privacy policies.
                    </p>
                  </div>
                  <div class="Term_condition_heading_paragraph">
                    <p>
                      <p class="Term_condition_heading">Data Security:</p>
                    </p>
                    <p class="Term_condition_paragraph">
                      We employ industry-standard security measures to safeguard
                      your data from unauthorized access, disclosure, or misuse.
                    </p>
                  </div>
                  <div class="Term_condition_heading_paragraph">
                    <p>
                      <p class="Term_condition_heading">Your Choices:</p>
                    </p>
                    <p class="Term_condition_paragraph">
                      - You can update your personal information by logging into
                      your account.
                    </p>
                    <p class="Term_condition_paragraph">
                      - You can opt-out of receiving marketing communications by
                      following the unsubscribe instructions in our emails.
                    </p>
                    <p class="Term_condition_paragraph">
                      - You can disable cookies through your browser settings.
                    </p>
                  </div>
                  <div class="Term_condition_heading_paragraph">
                    <p>
                      <p class="Term_condition_heading">Children's Privacy:</p>
                    </p>
                    <p class="Term_condition_paragraph">
                      Our website is not intended for children under the age of
                      13. We do not knowingly collect personal information from
                      children.
                    </p>
                  </div>{" "}
                  <div class="Term_condition_heading_paragraph">
                    <p>
                      <p class="Term_condition_heading">Changes to This Policy:</p>
                    </p>
                    <p class="Term_condition_paragraph">
                      We may update this Privacy Policy periodically to reflect
                      changes in our practices. We encourage you to review the
                      policy whenever you visit our website.
                    </p>
                  </div>{" "}
                  <div class="Term_condition_heading_paragraph">
                    <p>
                      <p class="Term_condition_heading">Contact Us:</p>
                    </p>
                    <p class="Term_condition_paragraph">
                      If you have questions or concerns about our Privacy
                      Policy, you can contact us at{" "}
                      <a href="mailto:info@bastionex.net">info@bastionex.net</a>
                      .{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Term;
