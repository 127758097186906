import React, { useEffect, useContext } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js/pure";
import CheckoutForm from "./CheckoutForm";
import { useLocation } from "react-router";
import { currencyContext } from "../../context/currencyContext.js";
import PageName from "../Utils/PageName";

// const stripePromise = loadStripe(
//   "pk_test_51Ny9zqSC6naWxZFybFbmMifgAFZA42frj1sWOgDslsjuwVz4gePMx9VJqCcPsZR1YhCvYrZGM4XuQ3usaSD81kuv00bvBlddp0"
// );

const Payment = () => {
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);
  const location = useLocation();
  const clientSecret = location.state && location.state.data;
  const amount = location.state && location.state.amount;
  const cardId = location.state && location.state.cardId;
  const order_id=location.state&&location.state.order_id;
  const cardType=location.state&&location.state.cardType;
  const business_id=location.state&&location.state.business_id;
  const deliveryInstructions=location.state&&location.state.deliveryInstructions;
  const print_business_name=location.state&&location.state.print_business_name;
  
  // console.log(deliveryInstructions, "BBBBBBBBBBBBbb")
  useEffect(()=>{
// console.log(order_id,"order_idwer tyui")
  },[order_id])
  const options = {
    clientSecret: clientSecret,
  };
  const { currencyObjData, setCurrencyObjData } = useContext(currencyContext);

  return (
    <>
      <PageName PageName="Payment" />

      <div class="container mt-3 w-50">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">Payment Information </h5>
            <p class="card-text">
              Receiver's name: Vmycards
              <br />
              {`Amount : ${currencyObjData?.symbol} ${(
                amount
              )}`}
              {/* <br /> */}
              {/* CardType: pvc */}
            </p>
          </div>

          <div className="mt-3 w-100 px-3">
            <Elements stripe={stripePromise} options={options}>
              <CheckoutForm print_business_name={print_business_name} deliveryInstructions={deliveryInstructions} business_id={business_id} cardType={cardType} cardId={cardId} clientSecret={clientSecret} orderId={order_id} />
            </Elements>
          </div>
        </div>
      </div>
    </>
  );
};

export default Payment;
