import React, { useState, useContext } from "react";
// import { useState } from 'react'
import { userContext } from "../../context/userContext";
import axios from "axios";
import { data } from "jquery";
import { environmentVariables } from "../../config/env.config";
// import { cp } from "fs";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CircularLoader from "../Components/CircularLoader/CircularLoader";
import Cookies from "js-cookie";
import "././Updatedcss/ProfileUpdateStyle.css";

// import { baseUrl } from "./config";

function Profile() {
  const { userData, setUserData } = useContext(userContext);
  // console.log(userData, "userData");
  const baseUrl = environmentVariables?.apiUrl;
  const [editedData, setEditedData] = useState({
    name: userData?.name || "",
    // lastname: userData?.lastname || '',
    email: userData?.email || "",
    contact: userData?.contact || "",
  });
  const [password, setPassword] = useState("");
  const [cpassword, setCpassword] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);
  const [activeTab, setActiveTab] = useState("tab1");
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const handleSave = async (e) => {
    e.preventDefault();
    try {
      // setIsSubmit(true);
      // console.log(userData,"userDatauserDatauserData")
      if (editedData?.name == "" || editedData?.name?.trim() == "") {
        toast.error("Name is mandatory", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
        return;
      }else if(editedData?.name?.length>40 || editedData?.name?.trim()?.length>40 ){
        toast.error("Name length maximum 40 character", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
        return;

      }else if(editedData?.name?.length<3 || editedData?.name?.trim()?.length<3){
        toast.error("Name must be minimum 3 character", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
        return;
      }
      if (editedData?.contact == "" || editedData?.contact?.trim() == "") {
        toast.error("Contact number is mandatory", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
        return;
      }
      else if(editedData?.contact?.length >16 || editedData?.contact?.trim()?.length >16){
        toast.error("Invalid Contact number", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
        return;
      }
      let config = {
        method: "put",
        url: `${baseUrl}api/v1/user/${
          userData == "super_admin" ? "updateUser" : "updateNormalUser"
        }`,
        headers: {
          _token: userData?.token,
          "Content-Type": "application/json",
        },
        // withCredentials: true,
        data: {
          userId: userData?.id,
          name: editedData?.name,
          email: editedData?.email,
          contact: editedData?.contact,
        },
      };

      axios
        .request(config)
        .then((response) => {
          setIsSubmit(false);
          console.log("Update User info:j", response.data);
          // console.log(userData, "userDatssssssssssssssss");
          let obj = {
            ...userData,
          };
          obj.name = editedData?.name || obj.name;
          obj.email = editedData?.email || obj?.email;
          obj.contact = editedData?.contact || obj?.contact;
          setUserData(obj);
          localStorage.setItem("user", JSON.stringify(obj));

          // console.log(obj, "setUserDatasetUserDatasetUserData");
          toast.success("Your profile has been updated successfully.", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
        })
        .catch((error) => {
          setIsSubmit(false);
          console.error("Update User API Error:", error?.response?.data?.message);
          toast.error(
            error?.response?.data?.message||"Failed to update your profile. Please check your details and try again later.",
            {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 1000,
            }
          );
        });
    } catch (error) {
      setIsSubmit(false);
      console.error("API Error:", error);
      toast.error("An unexpected error occurred. Please try again later.", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    }
  };

  const handleChanePassword = async (e) => {
    e.preventDefault();
    // console.log("handleChanePassword");
    try {
      setIsSubmit(true);
      if (password !== cpassword) {
        setIsSubmit(false);
        // Passwords do not match, you can handle this case
        toast.error(
          "Passwords do not match. Please make sure both passwords match.",
          {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          }
        );
        return;
      }
      if (!password || !cpassword) {
        setIsSubmit(false);
        toast.error("Please fill both password fields.", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        return;
      }
      let config = {
        method: "post",
        url: `${baseUrl}api/v1/user/resetPassword`,
        headers: {
          _token: userData?.token,
          "Content-Type": "application/json",
        },
        data: {
          password: password,
          cpassword: cpassword,
        },
      };
      // console.log(data,"dara11111")

      axios
        .request(config)
        .then((response) => {
          setIsSubmit(false);
          toast.success("Password updated successfully", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          setPassword("");
          setCpassword("");
        })
        .catch((error) => {
          setIsSubmit(false);
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            toast.error(
              "Your new password should include at least one lowercase letter, one uppercase letter, one special character, and be at least 8 characters long.",
              {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 1000,
              }
            );
          } else {
            toast.error("Failed to update password. Please try again later.", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 1000,
            });
          }
        });
    } catch (error) {
      setIsSubmit(false);
      toast.error("An unexpected error occurred. Please try again later.", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    }
  };
  return (
    <>
      <div className="row ProfileContainer">
        <div className="col-md-4 ProfileWrapper  ">
          <div className="tile">
            <ul className="conatctprofile ProfileflexContainer">
              <li
                className={`tab-item ${activeTab === "tab1" ? "active" : ""}`}
                onClick={() => handleTabClick("tab1")}
                id="PersonalInput"
              >
                Personal info
              </li>
              <li
                className={`tab-item ${activeTab === "tab2" ? "active" : ""}`}
                onClick={() => handleTabClick("tab2")}
                id="PersonalInput"
              >
                Change Password
              </li>
            </ul>
          </div>
        </div>
        <div className="col-md-8 ProfileWrapper">
          <div className="tile">
            {activeTab === "tab1" && (
              <form>
                <div className="row mb-4">
                  <div className="col-md-6 ProfileNameWid">
                    {/* <label>Name</label> */}
                    <input
                      className="form-control"
                      placeholder="Name"
                      type="text"
                      value={editedData.name}
                      onChange={(e) =>
                        setEditedData({ ...editedData, name: e.target.value })
                      }
                    />
                  </div>
                  {/* <div className="col-md-6">
    <label>Last Name</label>
    <input className="form-control" type="text" value={editedData?.lastname || ''}
    onChange={(e) => setEditedData({ ...editedData, name: e.target.value })}/>
  </div> */}
                </div>
                <div className="row">
                  <div className="col-md-12 mb-4">
                    {/* <label>Email</label> */}
                    <input
                    placeholder="Email"
                      className="form-control"
                      type="text"
                      value={editedData?.email || ""}
                    />
                  </div>
                  <div className="clearfix"></div>
                  <div className="col-md-12 mb-4">
                    {/* <label>Mobile No</label> */}
                    <input
                      className="form-control"
                      placeholder="Mobile No"
                      maxLength={16}
                      type="number"
                      value={editedData?.contact || ""}
                      onChange={(e) =>
                        setEditedData({
                          ...editedData,
                          contact: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="clearfix"></div>
                </div>
                <div className="row mb-10">
                  <div className="col-md-12 ProfileUpdatedButtonContainer">
                    <button
                      className="btn btn-primary ProfileUpdatedButton"
                      type="button"
                      onClick={(e) => handleSave(e)}
                    >
                      {/* <i className="bi bi-check-circle-fill me-2"></i>  */}
                      {/* {isSubmit ? <CircularLoader size={20} /> : "Update"} */}
                      Update
                    </button>
                  </div>
                </div>
                <ToastContainer />
              </form>
            )}
            {activeTab === "tab2" && (
              <>
                <form onSubmit={handleChanePassword}>
                  <div className="row mb-4 UpdatePasswordWrapper">
                    <div className="col-md-6 ProfileChangepassWid">
                      {/* <label>Password</label> */}
                      <input
                        className="form-control "
                        placeholder="Password"
                        type="text"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </div>
                    <div className="col-md-6 ProfileChangepassWid">
                      {/* <label>Confirm Password</label> */}
                      <input
                        className="form-control"
                        placeholder="Confirm Password"
                        type="text"
                        value={cpassword}
                        onChange={(e) => setCpassword(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="row mb-10">
                    <div className="col-md-12 ProfileUpdatedButtonContainer">
                      <button className="btn btn-primary ProfileUpdatedButton" type="submit">
                        <i className="bi bi-check-circle-fill me-2"></i>
                        {isSubmit ? <CircularLoader size={20} /> : "Update"}
                      </button>
                    </div>
                  </div>
                  <ToastContainer />
                </form>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Profile;
