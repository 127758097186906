import React, { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { environmentVariables } from "../config/env.config";
import { userContext } from "../context/userContext";
function AdminLogin() {
  const { userData, setUserData } = useContext(userContext);
  const baseUrl = environmentVariables?.apiUrl;
  const [isLoginDisabled, setIsLoginDisabled] = useState(true);
  const navigate = useNavigate();
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [error, setError] = useState({});
  const onSubmit = () => {
    if (email && password) {
      axios
        .post(`${baseUrl}api/v1/user/adminLogin`, { email, password })
        .then((res) => {
          if (res?.data?.result?.token) {
            localStorage.setItem("user", JSON.stringify(res?.data?.result));
            setUserData(res?.data?.result);
            toast.success("Logged in successfully", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 1000,
            });
            setIsLoginDisabled(true);
            navigate("/super_dashboard");
          }
        })
        .catch((err) => {
          console.log(err?.response?.data?.message);
          toast.error(err?.response?.data?.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          setIsLoginDisabled(false);
        });
    }
  };
  const handleChange = (e) => {
    if (e.target.name == "email") {
      setEmail(e.target.value);
    } else if (e.target.name == "password") {
      setPassword(e.target.value);
    }
    if (email && password) {
      setIsLoginDisabled(false);
    }
  };
  return (
    <section className="py-5">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-lg-4">
            <div className="loginform">
              <h3>Admin Login</h3>
              <form className="row g-3">
                <div className="col-md-12">
                  <label for="exampleInputEmail1" className="form-label">
                    Email{" "}
                  </label>
                  <input
                    type="text"
                    className="form-control clear_string"
                    placeholder="Enter Your Email"
                    name="email"
                    value={email}
                    required=""
                    key="email"
                    onChange={(e) => handleChange(e)}
                  />
                </div>
                <div className="col-md-12">
                  <label for="exampleInputEmail1" className="form-label">
                    Password
                  </label>
                  <input
                    type="password"
                    className="form-control clear_string"
                    placeholder="Enter Your Password"
                    name="password"
                    key="password"
                    value={password}
                    onChange={(e) => handleChange(e)}
                  />
                </div>
                <div className="col-12">
                  <button
                    onClick={onSubmit}
                    type="button"
                    className="btn try_theme_btn "
                  >
                    Submit
                  </button>
                </div>
              </form>
              <Link to="/forgot-password">Forgot Your Password?</Link>
            </div>
            <ToastContainer />
          </div>
        </div>
      </div>
    </section>
  );
}

export default AdminLogin;
