import React, { useContext, useEffect, useState, useRef } from "react";
import PageName from "../Utils/PageName";
import { Link } from "react-router-dom";
import { environmentVariables } from "../../config/env.config";
import axios from "axios";
import { userContext } from "../../context/userContext";
import ReactDOM from "react-dom";
import DataTable from "react-data-table-component";
import SortIcon from "@material-ui/icons/ArrowDownward";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import { businessContext } from "../../context/businessContext";
import { businessObjContext } from "../../context/businessObjContext";
import CircularLoader from "../Components/CircularLoader/CircularLoader";
import { businessNameContext } from "../../context/businessContext";
import FilterIcon from "../../../src/Images/FilterIcon.png";
import Swal from "sweetalert2";
import DatePicker from "react-datepicker";
import { ToastContainer, toast } from "react-toastify";
import Modal from "@mui/material/Modal";
import { Box } from "@mui/material";
import "./UpdateRequestcardstyle.css";
import { useCallback,useMemo } from "react";
import { format } from 'date-fns';

const Appoinments = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const baseUrl = environmentVariables?.apiUrl;
  const { userData, setUserData } = useContext(userContext);
  
  const { businessObjData, setBusinessObjData } =
    useContext(businessObjContext);
  const [isLoading, setIsLoading] = useState(true);
  const { businessNameData, setBusinessNameData } =
    useContext(businessNameContext);
  const [selectedOption, setSelectedOption] = useState("");
  const [selectedData, setSelectedData] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [appointmentId, setAppointmentId] = useState("");
  const [businessData, setBusinessData] = useState([]);
  const [tempData, setTempData] = useState([]);
  const tableExtensions = { export: false, print: false };
  const [isSubmit, setIsSubmit] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const fromDate = useRef(null);
  const toDate = useRef(null);
  const [action] = useState({ fromUser: false });
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [paginationTotalRows, setPaginationTotalRows] = useState(0);
  const [paginationPerPage, setPaginationPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedRowsPerPage, setSelectedRowsPerPage] = useState([]);
  // Remove these lines from your component
const [activeBusinessName, setActiveBusinessName] = useState("");
const [selectedDateFrom, setSelectedDateFrom] = useState("");
const [selectedDateTo, setSelectedDateTo] = useState("");
const [showPopUp, setShowPopUp] = useState("");
const [maskedAppointmentsExist, setMaskedAppointmentsExist] = useState(false);
  // const handleEditClick = (data) => {
  //   setSelectedData(data);
  //   setShowModal(true);
  // };
  // const handleCloseModal = () => {
  //   setShowModal(false);
  // };

  const handleFilterOpen = () => {
    setShowFilter(!showFilter);
  };
  const handleFilterClose = () => {
    setShowFilter(false);
  };

  let showcount = userData?.appointments

  const Boxstyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    // height: 500,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    zIndex: 999,
    borderRadius: "15px",
    border: "none",
    outline: "none",
    focus: "none",
    boxShadow:
      " rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;",
    padding: "3rem 5rem",
    "@media (max-width: 650px)": {
      width: "425px",
      padding: "2rem",
    },
    "@media (max-width: 430px)": {
      width: "380px",
      padding: "2rem",
    },
    "@media (max-width: 390px)": {
      width: "350px",
      padding: "2rem",
    },
  };

  const customStyles = {
    dataTableExtensions: {
      backgroundColor: "lightgray",
      padding: "10px",
    },
    headRow: {
      style: {
        backgroundColor: "#e9e9e9",
        borderRadius: "2px 2px 0 0",
        paddingLeft: "25px",
        border: "none",
        textTransform: "capitalize",
        position: "sticky",
        left: 0,
        top: 0,
        zIndex: 1,
        boxShadow: "0 2px 2px -1px rgba(0, 0, 0, 0.1)",
        overflow: "hidden",
        whiteSpace: "nowrap",
      },
    },
    headCells: {
      style: {
        color: "#525252",
        fontSize: "16px",
        minHeight: "52px",
      },
    },
    rows: {
      style: {
        padding: "6px 0px 6px 25px",
        fontSize: "14px",
        textTransform: "capitalize",
        minHeight: "48px !important",
      },
    },
    BusinessColumn:{
      position: "sticky",
      left: "0",
      zIndex: "1",
      backgroundColor: "#fff",
      overflow: "hidden",
      whiteSpace: "nowrap",
    }
  };

  const fetchApppointent = (page) => {
    try {
      let obj = {
        business_id: businessObjData?.id || "2",
      };

      const formattedStartDate = selectedDateFrom ? format(new Date(selectedDateFrom), 'yyyy-MM-dd') : null;
      const formattedEndDate = selectedDateTo ? format(new Date(selectedDateTo), 'yyyy-MM-dd') : null;

      const filters = {
        startDate: formattedStartDate,  
        endDate: formattedEndDate,    
        business: activeBusinessName === "All Business" ? null : activeBusinessName,
      };

      axios
        .post(`${baseUrl}api/v1/appointment/FetchAppointments_withpagination?page=${page}&limit=${rowsPerPage}&showcount=${userData?.appointments}`,{ ...obj, ...filters }, {
          headers: {
            _token: userData?.token,
          },
          withCredentials: true,
        })
        .then((response) => {
          let tempArr = response?.data?.data?.records?.map(
            (el) => el?.businessTitle
          );

          console.log("console3",response.data?.data?.records)
         

          setShowPopUp(response.data?.pagination?.totalCount)
          let tempUniqueArr = new Set(tempArr);
          tempUniqueArr = [...tempUniqueArr];
          tempUniqueArr = tempUniqueArr;
          setBusinessData(tempUniqueArr);
          // console.log(response?.data?.data?.records, "admin !@#$");
          let arr = response?.data?.data?.records;
          // console.log("console.log2",userData)
          // if (arr.some(appointment => appointment.name === '***' || appointment.email === '***' || appointment.phone === '***')) {
          //   setMaskedAppointmentsExist(true);
          // }

         
          if(showPopUp >= userData?.appointments){
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Your free plan meeting quota has been exhausted. Upgrade now to check your upcoming appointments!",
              
            });
          }



          arr = arr.sort(
            (x, y) => new Date(y?.created_at) - new Date(x?.created_at)
          );
          setTempData(arr);
          setData(arr);
          setPaginationTotalRows(response?.data?.pagination?.totalCount)
          console.log("ttl ap", response?.data?.pagination?.totalCount)
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
        });
    } catch (err) {
      console.log(err, "erroror");
    }
  };

  const handleOnSelectedRowsChange = useCallback(
    ({ selectedRows }) => {
      if (!action.fromUser) return; //the component always trigger this with 0 selected rows when it renders a page, what would clear the selection

      selectedRowsPerPage[currentPage] = selectedRows; //there is no way to tell if a row was DEselected, so I had to control the selected rows per page,
      //the array would get an index to control each page
      console.log(JSON.stringify(selectedRowsPerPage));
    },
    [currentPage, selectedRowsPerPage, action.fromUser]
  );

  const handlePageChange = (page) => {
    const nextPage = page; 
    fetchApppointent(nextPage);
    console.log(page, "");
    // fetchBusinessData(page);
    setCurrentPage(page);
  };

  const handleRowsPerPageChange = async (newRowsPerPage) => {
    console.log(newRowsPerPage, "newRowsPerPagenewRowsPerPagenewRowsPerPage");
    // if (!businesslistData.length) return;
    // Update the state first
    setRowsPerPage(newRowsPerPage);
    setCurrentPage(1); // Reset current page to 1
    setSelectedRowsPerPage([]);
    // Now call the function with the updated state
    fetchApppointent(1);
  };

  useEffect(() => {
    fetchApppointent();
  }, [rowsPerPage]);

  useEffect(() => {
    fetchApppointent();
  }, [businessObjData]);

  const getBusinessNameById = (businessId) => {
    const business = businessNameData.find((item) => item.id === businessId);
    return business ? business.title : "";
  };

  const handleDeleteAppoinment = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        let data = JSON.stringify({
          appointmentId: id,
        });

        let config = {
          method: "delete",
          maxBodyLength: Infinity,
          url: `${environmentVariables?.apiUrl}api/v1/appointment/deleteAppointment`,
          headers: {
            _token: userData?.token,
            "Content-Type": "application/json",
          },
          data: data,
        };
        axios
          .request(config)
          .then((response) => {
            Swal.fire(
              "Deleted!",
              "Selected appointment has been deleted.",
              "success"
            );
            fetchApppointent();
          })
          .catch((error) => {
            Swal.fire(
              "Error!",
              error?.response?.data?.message || error?.message,
              "error"
            );
          });
      }
    });
  };

  const handleToggleStatus = () => {};
  const fetchData = async (obj) => {
    try {
      setAppointmentId(obj.id);
      setSelectedOption(obj.status);
    } catch (error) {
      console.error("API Error:", error);
    }
  };
  const columns = [
    {
      name: "Business Name",
      selector: "business_id",
      sortable: true,
      cell: (row) => row?.businessTitle,
      fixed: "left",
      // style: customStyles.BusinessColumn,
    },
    {
      name: "Appointment Date",
      selector: (row) => {
        let dateString = row?.date.split(" ")[0];
        const [year, month, day] = dateString.split("-");
        return `${day}/${month}/${year}`;
      },
      sortable: true,
      // width: "08%",
     
   
    },
    {
      name: "Time",
      selector: "time",
      sortable: true,
      width: "13%",
    },
   
    {
      name: "Name",
      selector: "name",
      sortable: true,
      cell: (d) => {
        const capitalizedName =
          d?.name?.charAt(0).toUpperCase() + d?.name?.slice(1);
        return capitalizedName;
      },
    },
    {
      name: "E-mail",
      selector: "email",
      sortable: true,
    },
    {
      name: "Phone",
      selector: "phone",
      sortable: true,
    },
    {
      name: "Status",
      selector: "status",
      sortable: true,
      cell: (d) => {
        const capitalizedStatus =
          d?.status?.charAt(0)?.toUpperCase() + d?.status?.slice(1);
        return capitalizedStatus;
      },
    },
    // {
    //   name: "Action",
    //   sortable: false,
    //   cell: (d) => (
    //     <>
    //       {userData?.created_by == 0 ? (
    //         <i
    //           style={{ width: "50px" }}
    //           onClick={() => handleDeleteAppoinment(d?.id)}
    //           className="fas fa-trash-alt"
    //         ></i>
    //       ) : userData &&
    //         userData?.permission &&
    //         userData?.permission?.some((obj) =>
    //           obj?.permissionobj?.name?.includes("manage appointment")
    //         ) ? (
    //         <i
    //           style={{ width: "50px" }}
    //           onClick={() => handleDeleteAppoinment(d?.id)}
    //           className="fas fa-trash-alt"
    //         ></i>
    //       ) : (
    //         "--"
    //       )}
    //       {userData.created_by == 0 ? (
    //         <i
    //           key={d?.id}
    //           data-bs-toggle="modal"
    //           data-bs-target="#planselect"
    //           onClick={() => fetchData(d)}
    //           className="first fas fa-pen"
    //         ></i>
    //       ) : userData &&
    //         userData?.permission &&
    //         userData?.permission?.some((obj) =>
    //           obj?.permissionobj?.name?.includes("manage appointment")
    //         ) ? (
    //         <i
    //           key={d?.id}
    //           data-bs-toggle="modal"
    //           data-bs-target="#planselect"
    //           onClick={() => fetchData(d)}
    //           className="first fas fa-pen"
    //         ></i>
    //       ) : (
    //         "--"
    //       )}
    //     </>
    //   ),
    //   minWidth: "1px",
    // },
    {
      name: "Action",
      sortable: false,
      cell: (d, index) => (
        <>
          {userData?.created_by == 0 ? (
            <i
              style={{ width: "50px" }}
              onClick={() => handleDeleteAppoinment(d?.id)}
              className="fas fa-trash-alt"
            ></i>
          ) : userData &&
            userData?.permission &&
            userData?.permission?.some((obj) =>
              obj?.permissionobj?.name?.includes("manage appointment")
            ) ? (
            <i
              style={{ width: "50px" }}
              onClick={() => handleDeleteAppoinment(d?.id)}
              className="fas fa-trash-alt"
            ></i>
          ) : (
            "--"
          )}
          {(userData.created_by == 0 ||
            (userData?.permission &&
              userData?.permission?.some((obj) =>
                obj?.permissionobj?.name?.includes("manage appointment")
              ))) &&
          (index >= showPopUp - showcount) ? ( // Checking if index is within the range of the oldest appointments
            <i
              key={d?.id}
              data-bs-toggle="modal"
              data-bs-target="#planselect"
              onClick={() => fetchData(d)}
              className="first fas fa-pen"
            ></i>
          ) : (
            <span style={{ width: "24px" }}></span>
          )}
        </>
      ),
      minWidth: "1px",
    }
    
    
    
    
  ];
  const tableData = {
    columns,
    data,
  };
  const handleUpdate = () => {};
  const [editedData, setEditedData] = useState({
    date: new Date(),
  });
  const handleDateChange = (date) => {
    setEditedData({
      ...editedData,
      date: date,
    });
  };
  const handleDropdownChange = (e) => {
    if (e.target.value == "pending") {
      setSelectedOption(e.target.value);
    } else if (e.target.value == "confirm") {
      setSelectedOption(e.target.value);
    } else if (e.target.value == "cancel") {
      setSelectedOption(e.target.value);
    }
  };

  const handleSubmit = (e) => {
    setIsSubmit(true);
    let obj = {
      appointmentId,
      selectedOption,
    };
    axios
      .put(`${baseUrl}api/v1/appointment/UpdateAppointmentStatus`, obj, {
        headers: {
          _token: userData?.token,
        },
        withCredentials: true,
      })
      .then((response) => {
      
        window.location.reload(5000);
        toast.success(response?.data?.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        setIsSubmit(false);
      })
      .catch((error) => {
        console.log(error, "Appointment response");
        setIsSubmit(false);
      });
  };

  const handleChangeBusinessData = (e) => {
    setActiveBusinessName(e.target.value);
  };


  useEffect(() => {
    if (selectedDateTo != "" && selectedDateFrom != "") {
      if (selectedDateTo < selectedDateFrom) {
        toast.warning("To Date should be equal to or greater then From Date", {
          position: toast.POSITION.TOP_RIGHT,
        });
        return;
      }
    }
    const filters = {
      businessId: activeBusinessName === "All Business" ? null : activeBusinessName,
      startDate: selectedDateFrom,
      endDate: selectedDateTo,
    };
    fetchApppointent(1, filters);
  }, [activeBusinessName, selectedDateFrom, selectedDateTo]);

  const handleReset = () => {
    setBusinessNameData("");
    setActiveBusinessName("All Business");
    setSelectedDateFrom("");
    setSelectedDateTo("");
    setData([...tempData]);
  };

  const handleSearch = () => {
    loadPageFilter()
    setShowFilter(false);
  };

  const loadPageFilter = () => {
    const nextPage = 1
    setCurrentPage(nextPage);
    fetchApppointent(nextPage)
    console.log("fetchApppointent called")
  };

  return (
    <>
      <PageName PageName="Appointments" />
      <article>
        <div className="row d-none">
          <div className="col-md-12">
            <div className="tile">
              <div className="tile-body">
                <form className="row">
                  <div className="mb-2 col-md-3">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Search....."
                    />
                  </div>
                  <div className="mb-2 col-md-3">
                    <select className="form-select">
                      <option value="">Select an option</option>
                      <option value="option1">xxx </option>
                      <option value="option2">xxxx</option>
                    </select>
                  </div>
                  <div className="mb-2 col-md-4 align-self-end">
                    <button className="btn btn-primary me-3" type="button">
                      <i className="bi bi-check-circle-fill ms-2 "></i>Search
                    </button>
                    <button className="btn btn-danger" type="button">
                      <i className="bi bi-check-circle-fill me-2"></i>Reset
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="main">
          {isLoading ? (
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "55%",
                display: "flex",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <CircularLoader size={40} />
            </div>
          ) : (
            
            <>
           <div className="" style={{ position: 'relative', top: '-64px', width: '70%', left: '20px' }}>
      {/* {maskedAppointmentsExist && (
        <div>
          <marquee style={{ color: "red", fontSize: "14px" }}>
                {maskedAppointmentsExist && <>Your free plan meeting quota has been exhausted. Upgrade now to check your upcoming appointments.*</>}
              </marquee>
        </div>
        
      )} */}
      {/* Your existing JSX */}
    </div>

              <div className="filter-button-wrapper requestcardfilterbuttonWrapper">
                <span class="FilterIconTextWrapper" onClick={handleFilterOpen}>
                  {" "}
                  {/* <i class="fa fa-filter" aria-hidden="true"></i> */}
                  <div className="FilterIcon">
                    {" "}
                    <img src={FilterIcon} width={28}/> 
                    <div className="filtertext">Filters</div>
                  </div>
                </span>
                <button
                  className="btn btn-primary requestcardfilterbuttonstyle"
                  onClick={handleFilterOpen}
                  id="FilterIconText"
                >
                  Filter
                </button>
              </div>
              <Modal
                open={showFilter}
                onClose={handleFilterClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={Boxstyle}>
                  {showFilter && (
                    <div className="Contact_filter_fieldbutton_wrapper ModalPopupContainer">
                      <div className="Contact_filter_field_wrapper ModalPopupWrapper">
                        <div className="col-md-2 Contact_filter_Inputfield"    id="ModalSelectfield">
                          {/* <h5 className="mb-3">Business Name </h5> */}
                          <select
                            value={activeBusinessName}
                            onChange={(e) => handleChangeBusinessData(e)}
                            className="form-select"
                          >
                            {" "}
                            <option value={"All Business"}>All Business</option>
                            {businessData &&
                              businessData.map((val, index) => (
                                <option value={val}>{val} </option>
                              ))}
                          </select>
                          <div
                            style={{
                              fontSize: "14px",
                              color: "red",
                              bottom: "70px",
                            }}
                          ></div>
                        </div>
                        {/* <div className="mb-2 col-md-2 Contact_filter_Inputfield">
                    <h5 className="mb-3">Status </h5>
                    <select
                      value={appointmentStatus}
                      onChange={(e) => handleChangeBusinessData(e)}
                      className="form-select"
                    >
                      {" "}
                      <option value={"All"}>All</option>
                      {businessData &&
                        businessData.map((val, index) => (
                          <option value={val}>{val} </option>
                        ))}
                    </select>
                    <div
                      style={{
                        fontSize: "14px",
                        color: "red",
                        bottom: "70px",
                      }}
                    ></div>
                  </div> */}

                        <div className="col-md-2 Contact_filter_Inputfield" id="ModalSelectfield">
                          {/* <h5 className="mb-3">From Date </h5> */}
                          <div className="dateiconmain">
                            <DatePicker
                              placeholderText="From date"
                              selected={selectedDateFrom}
                              onChange={(date) => setSelectedDateFrom(date)}
                              className="form-control"
                              ref={fromDate}
                              dateFormat="dd/MM/yyyy"
                              popperPlacement="bottom-end"
                            />
                            <div
                              className="contact_filter_toDate_calander_icon"
                              onClick={() => fromDate.current.setOpen(true)}
                            >
                              <i className="fa-regular fa-calendar"></i>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-2 Contact_filter_Inputfield"   id="ModalSelectfield">
                          {/* <h5 className="mb-3">To Date </h5> */}
                          <div className="dateiconmain">
                            <DatePicker
                              placeholderText="To Date"
                              selected={selectedDateTo}
                              onChange={(date) => setSelectedDateTo(date)}
                              className="form-control"
                              ref={toDate}
                              dateFormat="dd/MM/yyyy"
                              popperPlacement="bottom-end"
                            />
                            <div
                              className="contact_filter_toDate_calander_icon"
                              onClick={() => toDate.current.setOpen(true)}
                            >
                              <i className="fa-regular fa-calendar"></i>
                            </div>
                          </div>
                        </div>
                        <div className="contact_filter_buttonwrapper">
                        {/* <button
                                class="contact_filter_buttonstyle"
                                onClick={handleFilterClose}
                              >
                                Apply
                              </button> */}
                          <button
                            className="contact_filter_buttonstyle"
                            onClick={() => handleReset()}
                          >
                            Reset{" "}
                          </button>
                          <button
                            class="contact_filter_buttonstyle"
                            onClick={() => handleSearch()}
                          >
                            Search
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </Box>
              </Modal>

              <div className="scrollable-columns">
                <div className="sticky-header-container">
                  <DataTableExtensions
                    {...tableData}
                    {...tableExtensions}
                    customStyles={customStyles}
                    filterPlaceholder="Search Appoinments"
                  >
                    <DataTable
                      columns={columns}
                      data={data}
                      noHeader
                      defaultSortField="id"
                      sortIcon={<SortIcon />}
                      defaultSortAsc={true}
                      pagination
                      highlightOnHover
                      paginationServer
                      paginationTotalRows={paginationTotalRows}
                      paginationPerPage={paginationPerPage}
                      paginationRowsPerPageOptions={[10,20,50,100,300,1000,10000]} // Customize as needed
                      onChangePage={handlePageChange}
                      onChangeRowsPerPage={handleRowsPerPageChange}
                      onSelectedRowsChange={handleOnSelectedRowsChange}
                      dense
                      customStyles={customStyles}
                    />
                  </DataTableExtensions>
                </div>
              </div>
            </>
          )}
        </div>
      </article>
      <div
        className="modal fade"
        id="planselect"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content EditStatus_form">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Edit Status
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleUpdate}>
                <div className="row g-3 mb-3">
                  <div className="col d-none">
                    <textarea
                      className="form-control"
                      placeholder="Enter Remarks"
                      aria-label="Name"
                    ></textarea>
                  </div>
                  <div className="col " >
                    <select
                      name="type"
                      className="form-select register_inputfield"
                      value={selectedOption}
                      onChange={handleDropdownChange}
                    >
                      <option value="pending">Pending</option>
                      <option value="confirm">Confirm </option>
                      <option value="cancel">Cancel</option>
                    </select>
                  </div>
                  {/* <div className="col "></div> */}
                  <div className="col d-none">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Plan Name"
                      aria-label="Name"
                      name="txn_id"
                      onChange={(e) => console.log("w")}
                    />
                  </div>
                </div>
               <div class="ChangesEditform">
               <button
                  type="button"
                  className="btn btn-primary save-cta"
                  onClick={(e) => handleSubmit(e)}
                >
                  {isSubmit ? <CircularLoader size={20} /> : " Save changes"}
                </button>
               </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
//2911 5:14 to 3011 9:44 m 11:07
export default Appoinments;
