import React, { useContext, useEffect, useRef, useState } from "react";
import PageName from "../Utils/PageName";
import axios from "axios";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import "react-data-table-component-extensions/dist/index.css";
import { environmentVariables } from "../../config/env.config";
import { userContext } from "../../context/userContext";
import { ToastContainer, toast } from "react-toastify";
import CircularLoader from "../Components/CircularLoader/CircularLoader";


import ReactDOM from "react-dom";
import DataTable from "react-data-table-component";
import SortIcon from "@material-ui/icons/ArrowDownward";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import { businessContext } from "../../context/businessContext";
import "./SuperAdminUpdatedStyle.css";

function Setting() {
  const baseUrl = environmentVariables?.apiUrl;
  const [counts, setCounts] = useState({
    count_users: 0,
    count_cards: 0,
    count_business: 0,
    count_countries: 0,
  });
  const { userData } = useContext(userContext);

  // console.log("usertoken", userData?.token)
  const [GreatingMsg, setGreatingMsg] = useState("");
  const [hasChanges, setHasChanges] = useState(false);
  const [hasChangesGreating, setHasChangesGreating] = useState(false);
  const [usersData, setUsersData] = useState([]);
  const [settingData, setSettingData] = useState([]);
  const createUserRef = useRef(null);
  const [formConfigData, setFormConfigData] = useState({
    key: "",
    value: "",
  });
  // console.log(formConfigData, "formConfigDataformConfigData")
  const [isSubmit, setIsSubmit] = useState(false);
  const [selectedType, setSelectedType] = useState("");
  console.log(selectedType, "selectedTypeselectedType")
  const [isLoading, setIsLoading] = useState(true);


  async function fetchData() {
    try {
      const response = await axios.get(`${baseUrl}api/v1/admin/setting/fatchStatics/landing_page`, {
      });
      if (response.status === 200) {
        const responseData = JSON.parse(response.data.message);
        // console.log(responseData, "@@@@@@@@@@@@@@@@@@@@@@@@@")
        setCounts(responseData);
      }fetchData
    } catch (error) {
      console.error({ 'Error:': error, msg: "ErrorLanding" });
    }
  }


  async function fetchConfig() {
    try {
      const response = await axios.get(`${baseUrl}api/v1/admin/setting/fetchConfigSuperAdmin`, {

        headers: {
          _token: userData?.token
        }
      });
      if (response.status == 200) {
        console.log(response?.data?.result, "responsedata");
        // const responseData = JSON.parse(response?.data?.result);
        setSettingData(response?.data?.result);
        setIsLoading(false)

      }
    } catch (error) {
      console.error({ 'Error:': error, msg: "ErrorLanding" });
      setIsLoading(false)

    }
  }

  const getGreatingMsg = () => {
    axios
      .get(`${baseUrl}api/v1/admin/setting/greetingMsg/Greeting`, {
        // params: { planId: userData?.plan },
        headers: {
          _token: userData?.token,
        },

      })
      .then((res) => {
        // console.log(res?.data?.message, "greetingmsg");
        const cleanGreatingMsg = JSON.parse(res?.data?.message);
        setGreatingMsg(cleanGreatingMsg);
      })
      .catch((err) => {
        console.log({ msg: "some_error", err: err });
      })
  };

  useEffect(() => {
    fetchConfig();
    fetchData();
    getGreatingMsg()

  }, []);
  



  const handleFormConfigData = (e, type) => {
    setFormConfigData({ ...formConfigData, [type]: e.target.value });
    // setHasChanges(true);
  };



  const handleSaveConfig = async (event) => {
    try {
      event.preventDefault();
      const data = {
        key: formConfigData?.key,
        value: formConfigData?.value,
        type: selectedType,
      };
      console.log(data, "data123")
      const response = await axios.post(`${baseUrl}api/v1/admin/setting/addConfig`, data,
        {
          headers: {
            "Content-Type": "application/json",
            _token: userData?.token,
          },
        },
      );

      if (response.status === 200) {
        toast.success("Add Config Data Successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        window.location.reload(3000);
      } else {
        if (response.data && response.data.message) {
          toast.error(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
        } else {
          // Handle the case where the response status is not 200
          toast.error("Failed to save the data", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
        }
      }
    } catch (error) {
      // Handle network errors or other exceptions
      toast.error("Failed to save the data", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    }
  };

  const columns = [
    {
      name: "ID",
      selector: "id",
      sortable: true
    },
    {
      name: "Name",
      selector: "name",
      sortable: true
    },
    {
      name: "Value",
      selector: "value",
      sortable: true
    },
    {
      name: "Type",
      selector: "cnf_type",
      sortable: true
    },
    {
      name: "Action",
      sortable: false,
      selector: "null",
      cell: (d) => [
        <i
          key={d.id}
          data-bs-toggle="modal"
          data-bs-target="#edituser"
          onClick={() => setFormConfigData({ key: d.name, value: d.value })}
          className="first fas fa-pen"
        ></i>,

      ]
    }
  ]

  const customStyles = {
    
    headRow: {
      style: {
        display: "flex",
        alignItems: "stretch",
        width: "100%",
        backgroundColor: "#E9E9E9",
        minHeight: "52px",
        borderRadius: "2px 2px 0px 0px",
        padding: "8px 25px",
        border: "none",
      },
    },
    headCells: {
      style: {
        color: "#525252",
        fontSize: "16px",
        minHeight: "52px",
      },
    },
    rows: {
      style: {
        padding: "6px 0px 6px 25px",
        fontSize: "14px",
        textTransform: "capitalize",
        minHeight: "48px !important",
      },
    },
  };

  const tableData = {
    columns,
    data: settingData
  };
  const handleEditUser = async () => {
    try {
      console.log(formConfigData.value, "value")
      setIsSubmit(true);
      const response = await axios.put(
        `${baseUrl}api/v1/admin/setting/updateConfig`,
        {
          key: formConfigData.key,
          value: formConfigData.value,
          type: formConfigData.type,
        },
        {
          headers: {
            _token: userData?.token,
          },
        }
      );
      // console.log(response, "responseresponse")

      if (response && response?.data && response?.data?.message) {
        setIsSubmit(false);
        toast.success('Configuration updated successfully', {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        fetchData();
        window.location.reload(3000);

      } else {
        setIsSubmit(false);
        toast.error('Configuration update failed', {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        return;
      }
    } catch (error) {
      console.log(error, "ereeeeeeee")
      setIsSubmit(false);
      toast.error('An error occurred while updating the configuration1111', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
      console.error('Error updating configuration', error);
    }
  };

  return (
    <>
      <PageName PageName="Settings" />
      <div className="row">
        <div class="col-md-12">
          <p>
            <a
              class="btn btn-primary icon-btn add_config_buttonadminsetting"
              data-bs-toggle="modal"
              data-bs-target="#newuser"
            // onClick={() => clearData()}
            >
              <i class="bi bi-plus-square me-2"></i>Add Config
            </a>
          </p>
        </div>
      </div>
      <div
        class="modal fade"
        id="newuser"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        ref={createUserRef}
      >
        <div class="modal-dialog">
          <div class="modal-content SettingUpdateConfig">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                Add Config 
              </h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <form class="row" id="Update_config_form">
                <div class="mb-2 col-md-6" id="Update_config_FirstInput">
                  <input
                    onChange={(e) => {
                      handleFormConfigData(e, "key");
                    }}
                    value={formConfigData["key"]}
                    class="form-control"
                    type="text"
                    placeholder="Key"
                  />
                  <div
                    style={{
                      fontSize: "14px",
                      color: "red",
                      bottom: "70px",
                    }}
                  >
                    {/* {errorMsgName} */}
                  </div>
                </div>
                <div class="mb-2 col-md-6" id="Update_config_FirstInput">
                  <select
                    class="form-select"
                    value={selectedType}
                    onChange={(e) => setSelectedType(e.target.value)}
                  >
                    <option value={0}>Type</option>
                    <option value="Web">Web</option>
                    <option value="env">env</option>
                    <option value="App">App</option>
                    <option value="SMTP">SMTP</option>
                    <option value="Social">Social Media</option>
                    <option value="Payment">Payment gateway</option>
                  </select>
                </div>
                <div class="mb-2 col-md-12">
                  <textarea
                    class="form-control"
                    placeholder="Config Value"
                    onChange={(e) => {
                      handleFormConfigData(e, "value");
                    }}
                    value={formConfigData["value"]}
                    rows="4" // You can adjust the number of rows as needed
                  ></textarea>
                  <div
                    style={{
                      fontSize: "14px",
                      color: "red",
                      bottom: "70px",
                    }}
                  >
                    {/* {errorMsgPassword} */}
                  </div>
                </div>
                <div class="mb-2 mt-4 col-md-12" id="Updatee_config_Button_Wrapper">
                  <button
                   id="Update_config_Button"
                    onClick={handleSaveConfig}
                    class="btn btn-primary me-3"
                    type="button"
                  >
                    Save
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div
        class="modal fade"
        id="edituser"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        ref={createUserRef}

      >
        <div class="modal-dialog">
          <div class="modal-content SettingUpdateConfig">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                Update Config 
              </h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <form class="row" id="Update_config_form">
                <div class="mb-2 col-md-6" id="Update_config_FirstInput">
                  <input
                    value={formConfigData["key"]}
                    class="form-control"
                    type="text"
                    placeholder="Key"
                  />
                </div>
               
                <div class="mb-2 col-md-6" id="Update_config_FirstInput">
                  <select
                    class="form-select"
                    value={formConfigData["type"]}
                    onChange={(e) => {
                      handleFormConfigData(e, "type");
                    }}
                  >
                    <option value={0}>Type</option>
                    <option value="Web">Web</option>
                    <option value="env">env</option>
                    <option value="App">App</option>
                    <option value="SMTP">SMTP</option>
                    <option value="Social">Social Media</option>
                    <option value="Payment">Payment gateway</option>
                  </select>
                </div>
                <div class="mb-2 col-md-12">
                <textarea
                    class="form-control"
                    
                    placeholder="Config Value"
                    onChange={(e) => {
                      handleFormConfigData(e, "value");
                    }}
                    value={formConfigData["value"]}
                    rows="4" // You can adjust the number of rows as needed
                    ></textarea>
                </div>
                <div class="mb-2 mt-4 col-md-12" id="Updatee_config_Button_Wrapper">
                  <button
                  id="Update_config_Button"
                    // onClick={handleEditSubmit}
                    class="btn btn-primary me-3"
                    type="button"
                    onClick={handleEditUser}
                  >
                    {isSubmit ? <CircularLoader size={20} /> : "Update"}

                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>


      <div className="main">
      {isLoading ? (
            <CircularLoader size={40} />
          ) : (
        <DataTableExtensions 
        {...tableData}
        customStyles={customStyles}
         filterPlaceholder="Search Config">
          <DataTable
            columns={columns}
            data={tableData.data}
            noHeader
            defaultSortField="id"
            sortIcon={<SortIcon />}
            defaultSortAsc={true}
            pagination
            highlightOnHover
            dense
            customStyles={customStyles}
          />
        </DataTableExtensions>
          )}
      </div>

      {/* <div class="row">
        <div class="col-sm-12">
          <div class="row">
            <div class="col-xl-3">
              <div class="card sticky-top" style={{ top: '30px' }}>
                <div class="list-group list-group-flush" id="useradd-sidenav">

                  <a href="#statics-settings" class="list-group-item list-group-item-action border-0">statics data
                    <div class="float-end"><i class="ti ti-chevron-right"></i></div>
                  </a>
                  <a href="#Greeting-settings" class="list-group-item list-group-item-action border-0">Greeting Message
                    <div class="float-end"><i class="ti ti-chevron-right"></i></div>
                  </a>

                </div>
              </div>
            </div>

            <div class="col-xl-9">
              <div id="statics-settings" class="card">
                <form accept-charset="UTF-8">
                  <input type="hidden" name="_token" />
                  <div class="card-header row d-flex justify-content-between">
                    <div class="col-auto">
                      <h5>Update statics data</h5>
                    </div>
                    <form class="row">
                      <div class="mb-2 col-md-6">
                        <label>Countries Worldwide Shipping</label>
                        <input value={counts.count_countries}
                          class="form-control" onChange={(e) => { handleChangeUserData(e, "count_countries"); }} type="text" placeholder="Countries Worldwide Shipping" />
                      </div>
                      <div class="mb-2 col-md-6">
                        <label>App Users</label>
                        <input value={counts.count_users}
                          class="form-control" onChange={(e) => { handleChangeUserData(e, "count_users"); }} type="text" placeholder="App Users" />
                      </div>
                      <div class="mb-2 col-md-6">
                        <label>Card Users</label>
                        <input value={counts.count_cards}
                          class="form-control" onChange={(e) => { handleChangeUserData(e, "count_cards"); }} type="text" placeholder="Card Users" />
                      </div>
                      <div class="mb-2 col-md-6">
                        <label>Business Users</label>
                        <input value={counts.count_business}
                          class="form-control" onChange={(e) => { handleChangeUserData(e, "count_business"); }} type="text" placeholder="Business Users" />
                      </div>

                      <ToastContainer />
                    </form>
                  </div>

                  <div class="card-footer text-end">
                    <div class="">
                      <div class="form-group  float-end">
                        <button onClick={handleSaveChanges} disabled={!hasChanges} class="btn btn-print-invoice  btn-primary">Save Changes</button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>

              <div id="Greeting-settings" class="card">
                <form accept-charset="UTF-8">
                  <input type="hidden" name="_token" value="ziK14TnPDlOgZI0qPVUmgOr1HFP29RxurGxZp9qx" />                            <div class="card-header row d-flex justify-content-between">
                    <div class="col-auto">
                      <h5>Update Greeting Message</h5>
                    </div>
                  </div>
                  <form class="row">
                    <div class="mb-2 col-md-6">
                      <label>Message</label>
                      <div className="col">
                        <textarea className="form-control" placeholder="" id="floatingTextarea2" rows="5"
                          value={GreatingMsg} // Bind the state variable to the value of the textarea
                          onChange={handleGreatingMsgChange}
                        // Add onChange handler to update the state
                        />
                      </div>
                    </div>

                    <ToastContainer />
                  </form>
                  <div class="card-footer text-end">
                    <div class="">
                      <div class="form-group  float-end">
                        <button onClick={updateGtratingMsg} disabled={!hasChangesGreating} class="btn btn-print-invoice  btn-primary">Save Changes</button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div> */}

    </>
  );
}

export default Setting;
//1112
