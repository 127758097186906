import NewBusinessDetilsCreate from "./NewBusinessDetilsCreate";
import NewTestimonialsCreate from "./NewTestimonialsCreate";
import NewAppointmentsCreate from "./NewAppointmentsCreate";
import NewSocialmediaCreate from "./NewSocialmediaCreate";
import NewGallerycreate from "./NewGallerycreate";
import NewBusinessHour from "./NewBusinessHour";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
// import bannerimage from "../../../Images/placeholder-imagevmycard.jpg";
import bannerimage from "../../../Images/blackpic.jpg";
import avatar from "../../../Images/blackpic2.jpg";
import { useLocation, useParams } from "react-router";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { environmentVariables } from "../../../config/env.config";
import React, { useContext, useEffect, useRef, useState } from "react";
import { userContext } from "../../../context/userContext";
import ViewThem from "./ViewThem";
import { businessContext } from "../../../context/businessContext";
import { businessObjContext } from "../../../context/businessObjContext";
import CircularLoader from "../../Components/CircularLoader/CircularLoader";
import "./../Updatedcss/BusinessChangeBlockUpdatedStyle.css";
import { useFormik } from "formik";
import * as Yup from "yup";

import { Box, Modal, Slider, Button } from "@mui/material";
import AvatarEditor from "react-avatar-editor";
import { FcAddImage } from "react-icons/fc";
import { AiFillGithub } from "react-icons/ai";
import styled from "styled-components";


const ErrorStyle = styled.div`
color: #ff1717;
font-size: 12px;
`;

function Details({
  appointmentSlotDuration,
  setAppointmentSlotDuration,
  businessObj,
  mobilePreviewLoading,
  themeColor,
  themeNumber,
  activeTab,
  setActiveTab,
}) {
  const { userData, setUserData } = useContext(userContext);
  const { businessObjData } = useContext(businessObjContext);
  const { businessData, setBusinessData } = useContext(businessContext);
  const [countryName, setCountryName] = useState("");
  const fileInputRefBanner = useRef(null);
  const fileInputRefLogo = useRef(null);
  const baseUrl = environmentVariables?.apiUrl;

  const [title, setTitle] = useState("");
  const [designation, setDesignation] = useState("");
  const [subTitle, setSubTitle] = useState("");
  const [description, setDescription] = useState("");
  const [bannerImageUrl, setBannerImageUrl] = useState("");
  const [logoImageUrl, setLogoImageUrl] = useState("");
  const [slug, setSlug] = useState("");

  //contact info
  const [isEnabledContactInfo, setIsEnabledContactInfo] = useState(false);
  const [isEnabledContactForm, setIsEnabledContactForm] = useState(false);
  const [existingGalleryImages, setExistingGalleryImages] = useState([]);

  const [contactInfoPhone, setContactInfoPhone] = useState("");
  const [contactInfoEmail, setContactInfoEmail] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);
  const [contactInfoWebUrl, setContactInfoWebUrl] = useState("");
  const [contactInfoAddress, setContactInfoAddress] = useState("");
  const [galleryView, setGalleryView] = useState([]);
  const [galleryData, setGalleryData] = useState([]);
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
  const [businessId, setBusinessId] = useState("");
  const [logoData, setLogoData] = useState("");
  const [bannerData, setBannerData] = useState("");
  const [onState, setOnState] = useState({
    contactInfo: false,
    services: false,
    testimonials: false,
    socials: false,
    businessHours: false,
    gallery: false,
    appointment: false,
    contactForm: false,
  });
  //services array
  const [servicesArray, setServicesArray] = useState([]);
  const [isEnabledServices, setIsEnabledServices] = useState(false);
  // console.log(appointmentSlotDuration, "businessobjdatainnewbusinesshour");
  //testimonial arry
  const [testimonialArray, setTestimonialArray] = useState([]);
  const [isTestimonial, setIsTestimonial] = useState(false);

  //appointment array
  const [isAppointment, setIsAppointment] = useState(false);
  const [appointmentArray, setAppointmentArray] = useState([]);
  //social
  const [isSocial, setIsSocial] = useState(false);
  const [socialMediaData, setSocialMediaData] = useState([]);

  //business hours
  const [businessHourObject, setBusinessHourObject] = useState({});
  const [IsBusinessHourObject, setIsBusinessHourObject] = useState(false);

  //galary array
  const [gallaryObject, setGallaryObject] = useState({});
  const [isGallaryEnable, SetIsGallaryEnable] = useState(false);

  const [divStates, setDivStates] = useState({
    div1: false,
    div2: false,
    div3: false,
    div4: false,
    div5: false,
    div6: false,
    div7: false,
  });

  const toggleDiv = (divId) => {
    setDivStates({
      ...divStates,
      [divId]: !divStates[divId],
    });
  };
  const isBannerShow = (theme) => {
    const themeswithbanner = ["theme3", "theme6", "theme7", "theme8", "theme9", "theme10"];
    if (themeswithbanner.includes(theme)) {
      return true;
    } else {
      return false;
    }
  };
  const isLogoShow = (theme) => {
    const themeswithbanner = ["theme10"];
    if (!themeswithbanner.includes(theme)) {
      return true;
    } else {
      return false;
    }
  };
  useEffect(() => {
    // console.log(businessObj?.sub_title, "businessObj  231!@#");
    if (businessObj && businessObj?.id) {
      setBusinessId(businessObj?.id);
      setTitle(businessObj?.title || "");
      setDesignation(businessObj?.designation || "");
      setSubTitle(businessObj?.sub_title || "");
      setDescription(businessObj?.description || "");
      setBannerImageUrl(businessObj?.banner || "");
      setLogoImageUrl(businessObj?.logo || "");
      setSlug(businessObj?.slug || "");
    } else {
      setBusinessId("");
      setTitle("");
      setDesignation("");
      setSubTitle("");
      setDescription("");
      setBannerImageUrl("");
      setLogoImageUrl("");
      setSlug("");
    }

    let contactInfoData;
    if (
      businessObj &&
      businessObj?.contactInfo &&
      businessObj?.contactInfo?.business_id &&
      businessObj?.contactInfo?.content
    ) {
      contactInfoData = JSON.parse(businessObj?.contactInfo?.content);
      if (contactInfoData.length > 0) {
        contactInfoData = JSON.parse(contactInfoData);
      }
    }
    if (
      contactInfoData?.contactInfoAddress ||
      contactInfoData?.contactInfoPhone ||
      contactInfoData?.contactInfoWebUrl ||
      contactInfoData?.contactInfoEmail
    ) {
      setContactInfoPhone(contactInfoData?.contactInfoPhone);
      setContactInfoEmail(contactInfoData?.contactInfoEmail);
      setContactInfoWebUrl(contactInfoData?.contactInfoWebUrl);
      setContactInfoAddress(contactInfoData?.contactInfoAddress);
    } else {
      // setIsEnabledContactInfo(false);
      setContactInfoPhone("");
      setContactInfoEmail("");
      setContactInfoWebUrl("");
      setContactInfoAddress("");
    }
    if (
      businessObj &&
      businessObj?.gallaryObj &&
      businessObj?.gallaryObj?.is_enabled
    ) {
      SetIsGallaryEnable(true);
      setOnState({ ...onState, gallery: true });
      const galleryData = JSON.parse(businessObj?.gallaryObj?.content);
      setGallaryObject(galleryData);
      setExistingGalleryImages([...galleryData?.gallaryArray]);
    } else {
      SetIsGallaryEnable(false);
    }
    if (
      businessObj &&
      businessObj?.businessHourObj &&
      businessObj?.businessHourObj?.is_enabled
    ) {
      setIsBusinessHourObject(true);
      setOnState({ ...onState, businessHours: true });
    } else {
      setIsBusinessHourObject(false);
    }
    if (
      businessObj &&
      businessObj?.SocialMediaObj &&
      businessObj?.SocialMediaObj?.is_enabled
    ) {
      setIsSocial(true);
      setOnState({ ...onState, socials: true });
    } else {
      setIsSocial(false);
    }
    if (
      businessObj &&
      businessObj?.appointmentObj &&
      businessObj?.appointmentObj?.is_enabled
    ) {
      setIsAppointment(true);
      setOnState({ ...onState, appointment: true });
    } else {
      setIsAppointment(false);
    }
    if (
      businessObj &&
      businessObj?.testimonialObj &&
      businessObj?.testimonialObj?.is_enabled == 1
    ) {
      setIsTestimonial(true);
      setOnState({ ...onState, testimonials: true });
    } else {
      setIsTestimonial(false);
    }
    if (
      businessObj &&
      businessObj?.serviceObj &&
      businessObj?.serviceObj?.is_enabled
    ) {
      setIsEnabledServices(true);
      setOnState({
        ...onState,
        services: true,
      });
    } else {
      setIsEnabledServices(false);
    }
    if (
      businessObj &&
      businessObj?.contactInfo &&
      businessObj?.contactInfo?.is_enabled
    ) {
      setIsEnabledContactInfo(true);
      setOnState({
        ...onState,
        contactInfo: true,
      });
    } else {
      setIsEnabledContactInfo(false);
    }
    if (businessObj && businessObj?.is_contact_form_enabled) {
      setIsEnabledContactForm(true);
    } else {
      setIsEnabledContactInfo(false);
    }
    if (businessObj?.newGalleryImages) {
      setGalleryData(businessObj?.newGalleryImages);
    }

    formik.setValues({
      ...formik.values,
      contactInfoPhone: contactInfoData?.contactInfoPhone || "",
      contactInfoEmail: contactInfoData?.contactInfoEmail || "",
      subTitle: businessObj?.sub_title || "",
      designation: businessObj?.designation || "",
      description: businessObj?.description || "",
    });
  }, [businessObj]);

  useEffect(() => {
    let stateObj = {};
    if (businessObj?.testimonialObj?.is_enabled) {
      stateObj["testimonials"] = true;
    }
    if (businessObj?.serviceObj?.is_enabled) {
      stateObj["services"] = true;
    }
    if (businessObj?.contactInfo?.is_enabled) {
      stateObj["contactInfo"] = true;
    }
    if (businessObj?.gallaryObj?.is_enabled) {
      stateObj["gallery"] = true;
    }
    if (businessObj?.businessHourObj?.is_enabled) {
      stateObj["businessHours"] = true;
    }
    if (businessObj?.SocialMediaObj?.is_enabled) {
      stateObj["socials"] = true;
    }
    if (businessObj?.appointmentObj?.is_enabled) {
      stateObj["appointment"] = true;
    }
    if (businessObj?.is_contact_form_enabled) {
      stateObj["contactForm"] = true;
    }
    setOnState(stateObj);
  }, [businessObj]);

  const handleBannerChange = (e) => {
    const file = e.target.files[0];
    setBannerData(e.target.files[0]);
    // Use the FileReader API to display the selected image
    const reader = new FileReader();
    reader.onload = () => {
      setBannerImageUrl(reader.result);  //Imediate image for showing
    };
    reader.readAsDataURL(file);
  };

  const handleLogoChange = (e) => {
    // setLogoData(e.target.files[0]);
    setSrc(URL.createObjectURL(e.target.files[0]));   //Zoom Image for view
    setModalOpen(true);
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      setLogoImageUrl(reader.result);   //set image on Circle Imediate
    };
    reader.readAsDataURL(file);
  };

  const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  const validationSchema = Yup.object().shape({
    contactInfoPhone: Yup.string().min(10, "Enter valid Mobile Number"),
    contactInfoEmail: Yup.string().email("Enter valid Email"),
    subTitle: Yup.string().matches(
      /^[a-zA-Z]+([a-zA-Z' -]*[a-zA-Z])*$/,
      "Only alphabets are allowed"
    ),
    designation: Yup.string()
      .matches(
        /^[a-zA-Z]+([a-zA-Z' -]*[a-zA-Z])*$/,
        "Only alphabets are allowed"
      )
      .min(2, "Minimum 2 characters")
      .max(40, "Maximum 40 characters"),
    description: Yup.string()
      .min(10, "Minimum 10 characters")
      .max( 400, "Maximum 400 characters"),
  });

  const handleSubmit = async (e) => {
    // try {

    // e.preventDefault();
    // if (contactInfoEmail != "") {
    //   if (!emailPattern.test(contactInfoEmail)) {
    //     toast.error("Kindly provide valid eEmail", {
    //       position: toast.POSITION.TOP_RIGHT,
    //       autoClose: 1000,
    //     });
    //     setIsSubmit(false);
    //     return;
    //   }
    // }

    if (!formik.isValid) {
      if (formik?.errors?.subTitle) {
        toast.error(formik?.errors?.subTitle, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      } else if (formik?.errors?.designation) {
        toast.error(formik?.errors?.designation, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      } else if (formik?.errors?.description) {
        toast.error(formik?.errors?.description, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      } else if (formik?.errors?.contactInfoEmail) {
        toast.error(formik?.errors?.contactInfoEmail, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      } else if (formik?.errors?.contactInfoPhone) {
        toast.error(formik?.errors?.contactInfoPhone, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      }

      return;
    }
    setIsSubmit(true);
    const formData = new FormData();
    formData.append("banner", bannerData);
    formData.append("logo", logoData);

    formData.append("businessId", businessId);
    formData.append("title", title);
    formData.append("designation", formik.values.designation);
    formData.append("sub_title", formik.values.subTitle);
    formData.append("description", formik.values.description);
    formData.append("slug", slug);
    let formCount = 0;
    formData.append(
      "is_enabledContactInfo",
      isEnabledContactInfo == true ? 1 : 0
    );
    formData.append(
      "is_contact_form_enabled",
      isEnabledContactForm == true ? 1 : 0
    );
    let content = {
      contactInfoPhone: formik.values.contactInfoPhone,
      contactInfoEmail: formik.values.contactInfoEmail,
      contactInfoWebUrl,
      contactInfoAddress,
    };
    if (isEnabledContactInfo == true) {
      formData.append("content", JSON.stringify(content));
    }
    // console.log(servicesArray, "s23ervicesArray business");
    formData.append("is_services", isEnabledServices ? 1 : 0);
    if (servicesArray && servicesArray.length) {
      servicesArray.forEach((file, index) => {
        if (file?.banner && file?.banner["0"].name?.length) {
          formData.append(`servicesArray`, file?.banner["0"]);
        } else if (file && file.image) {
          formData.append(`servicesImages[${index}]`, file?.image);
        }
        formData.append(`serviceDescription[${index}]`, file?.description);
        formData.append(`serviceName[${index}]`, file?.name);
        formData.append(`serviceButtonLink[${index}]`, file?.buttonLink);
        formData.append(`serviceButtonName[${index}]`, file?.buttonName);
      });
    }

    //testimonial array
    // console.log("testimonialArray1232", testimonialArray);

    formData.append("is_testimonial", isTestimonial ? 1 : 0);
    if (testimonialArray && testimonialArray.length) {
      testimonialArray.forEach((file, index) => {
        if (
          file &&
          file.image &&
          file.image["0"] &&
          file.image["0"].name?.length
        ) {
          formData.append(`testimonialArray`, file?.image["0"]);
        } else {
          formData.append(`testimonialImageArray[${index}]`, file?.image);
        }
        formData.append(`testimonialDescription[${index}]`, file?.description);
        formData.append(`testimonialName[${index}]`, file?.name);
        formData.append(`testimonialFormCount[${index}]`, index);
      });
    }
    // console.log(testimonialArray, "21testimon ray");

    formData.append("is_business_hours_enabled", IsBusinessHourObject ? 1 : 0);
    formData.append("businesshoursOBject", JSON.stringify(businessHourObject));

    //appointment array
    formData.append("appointmentContent", JSON.stringify(appointmentArray));
    formData.append("is_appointment", isAppointment ? 1 : 0);

    //social media
    formData.append("is_social", isSocial ? 1 : 0);
    formData.append("isSocalData", JSON.stringify(socialMediaData));

    // gallary
    formData.append("isGallaryEnable", isGallaryEnable ? 1 : 0);
    // formData.append(`gallary`, gallaryObject?.gallary);
    galleryData &&
      galleryData.length &&
      galleryData.map((val, index) => {
        formData.append("gallary", val);
      });
    existingGalleryImages.forEach((val) => {
      formData.append("gallaryArray", val);
    });
    formData.append("gallary_video", gallaryObject?.gallary_video);
    formData.append("gallaryImage", gallaryObject?.gallaryImage);
    formData.append("gallaryVideoLink", gallaryObject?.videoLink);
    formData.append("country", countryName);
    axios
      .post(`${baseUrl}api/v1/business/updateDetails`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          _token: userData?.token,
        },
      })
      .then((res) => {
        if (res?.data?.success) {
          toast.success(res?.data?.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          setActiveTab("tab3");
          setIsSubmit(false);
        }
      })
      .catch((err) => {
        console.log(err, "error occurred");
        toast.error(
          err?.response?.data?.message?.message || err?.response?.data?.message,
          {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          }
        );
        setIsSubmit(false);
      });
    // } catch (err) {
    //   console.log(err);
    //   toast.error(err, {
    //     position: toast.POSITION.TOP_RIGHT,
    //   });
    //   return;
    // }
  };

  const formik = useFormik({
    initialValues: {
      contactInfoPhone: "",
      contactInfoEmail: "",
      subTitle: businessObj?.sub_title || "",
      designation: businessObj?.designation || "",
      description: businessObj?.description || "",
    },
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
  });

  const handleSubmitWithoutTabChange = async (e) => {
    // try {
    setIsLoadingSubmit(true);
    // e.preventDefault();
    if (contactInfoEmail != "") {
      if (!emailPattern.test(contactInfoEmail)) {
        toast.error("Kindly provide valid eEmail", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        setIsLoadingSubmit(false);
        return;
      }
    }

    const formData = new FormData();
    formData.append("banner", bannerData);
    formData.append("logo", logoData);

    formData.append("businessId", businessId);
    formData.append("title", title);
    formData.append("designation", formik.values.designation);
    formData.append("sub_title", formik.values.subTitle);
    formData.append("description", formik.values.description);
    formData.append("slug", slug);
    let formCount = 0;
    formData.append(
      "is_enabledContactInfo",
      isEnabledContactInfo == true ? 1 : 0
    );
    let content = {
      contactInfoPhone: formik.values.contactInfoPhone,
      contactInfoEmail: formik.values.contactInfoEmail,
      contactInfoWebUrl,
      contactInfoAddress,
    };
    if (isEnabledContactInfo == true) {
      formData.append("content", JSON.stringify(content));
    }

    formData.append(
      "is_contact_form_enabled",
      isEnabledContactForm == true ? 1 : 0
    );
    // console.log(servicesArray, "s23ervicesArray business");
    formData.append("is_services", isEnabledServices ? 1 : 0);
    if (servicesArray && servicesArray.length) {
      servicesArray.forEach((file, index) => {
        if (file?.banner && file?.banner["0"].name?.length) {
          formData.append(`servicesArray`, file?.banner["0"]);
        } else if (file && file.image) {
          formData.append(`servicesImages[${index}]`, file?.image);
        }
        formData.append(`serviceDescription[${index}]`, file?.description);
        formData.append(`serviceName[${index}]`, file?.name);
        formData.append(`serviceButtonLink[${index}]`, file?.buttonLink);
        formData.append(`serviceButtonName[${index}]`, file?.buttonName);
      });
    }

    //testimonial array
    // console.log("testimonialArray1232", testimonialArray);

    formData.append("is_testimonial", isTestimonial ? 1 : 0);
    if (testimonialArray && testimonialArray.length) {
      testimonialArray.forEach((file, index) => {
        if (
          file &&
          file.image &&
          file.image["0"] &&
          file.image["0"].name?.length
        ) {
          formData.append(`testimonialArray`, file?.image["0"]);
        } else {
          formData.append(`testimonialImageArray[${index}]`, file?.image);
        }
        formData.append(`testimonialDescription[${index}]`, file?.description);
        formData.append(`testimonialName[${index}]`, file?.name);
        formData.append(`testimonialFormCount[${index}]`, index);
      });
    }
    // console.log(testimonialArray, "21testimon ray");

    formData.append("is_business_hours_enabled", IsBusinessHourObject ? 1 : 0);
    formData.append("businesshoursOBject", JSON.stringify(businessHourObject));

    //appointment array
    formData.append("appointmentContent", JSON.stringify(appointmentArray));
    formData.append("is_appointment", isAppointment ? 1 : 0);

    //social media
    formData.append("is_social", isSocial ? 1 : 0);
    formData.append("isSocalData", JSON.stringify(socialMediaData));

    // gallary
    formData.append("isGallaryEnable", isGallaryEnable ? 1 : 0);
    // formData.append(`gallary`, gallaryObject?.gallary);
    galleryData &&
      galleryData.length &&
      galleryData.map((val, index) => {
        formData.append("gallary", val);
      });
    existingGalleryImages.forEach((val) => {
      formData.append("gallaryArray", val);
    });
    formData.append("gallary_video", gallaryObject?.gallary_video);
    formData.append("gallaryImage", gallaryObject?.gallaryImage);
    formData.append("gallaryVideoLink", gallaryObject?.videoLink);

    axios
      .post(`${baseUrl}api/v1/business/updateDetails`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          _token: userData?.token,
        },
      })
      .then((res) => {
        if (res?.data?.success) {
          toast.success(res?.data?.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          window.open(
            `/${businessObj?.slug ? businessObj?.slug : ""}`,
            "_blank"
          );
          setIsLoadingSubmit(false);
        }
      })
      .catch((err) => {
        console.log(err, "error occurred");
        toast.error(err?.response?.data?.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        setIsLoadingSubmit(false);
      });
    // } catch (err) {
    //   console.log(err);
    //   toast.error(err, {
    //     position: toast.POSITION.TOP_RIGHT,
    //   });
    //   return;
    // }
  };
  // console.log(appointmentArray, "appointment array of user object ");
  // console.log(isAppointment, onState.appointment, "appointment state");

  const handleImageClickBanner = () => {
    fileInputRefBanner.current.click();
  };

  const handleImageClickLogo = () => {
    fileInputRefLogo.current.click();
  };

  // const [activeTab, setActiveTab] = useState("tab1");

  const handlePreviousClick = () => {
    if (activeTab === "tab2") {
      setActiveTab("tab1");
    } else if (activeTab === "tab3") {
      setActiveTab("tab2");
    }
  };

  const handleChangeCompanyName = (e) => {
    const alphanumericWithSpaceRegex = /^[a-zA-Z0-9\s]+$/;
    if (
      e.target.value === "" ||
      alphanumericWithSpaceRegex.test(e.target.value)
    ) {
      setTitle(e.target.value);
    }
  };

  const [src, setSrc] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [slideValue, setSlideValue] = useState(10);
  const cropRef = useRef(null);
  const inputRef = useRef(null);

  const handleSave = async () => {
    const dataUrl = cropRef.current.getImage().toDataURL();
    setLogoImageUrl(dataUrl);
    setModalOpen(false);

    // Convert data URL to Blob
    const byteString = atob(dataUrl.split(',')[1]);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([ab], { type: 'image/png' }); // Adjust the MIME type accordingly
    // Create a File object from the Blob
    const file = new File([blob], 'filename.png', { type: 'image/png' }); // Adjust the file name and type accordingly
    setLogoData(file);
  };
  const boxStyle = {};
  const modalStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  return (
    <>
      <div className="ChangeBlock_Sequenceview_Wrapper">
        <div className="row ChangeBlock_Sequenceview_Container">
          <div className="col-12 col-lg-8 ChangeBlock_Container ChangeblockContainerpd">
            <div className="tile">
              <form onSubmit={formik.handleSubmit} className="row">
                {/* <div className="row justify-content-end px-0 mb-4">
                 
                  <div className="col-12 col-lg-3 Changeblock_saveandpreview_buttonWrapper">
                    {isSubmit ? (
                      <CircularLoader size={40} />
                    ) : (
                      <button
                        type="button"
                        className="btn btn-cu mb-4 w-100 save-cta Changeblock_saveandpreview_button"
                        onClick={(e) => handleSubmit(e)}
                      >
                        {"Save & Next"}
                      </button>
                    )}
                  </div>
                </div> */}
                {/* <div class="mb-3 col-md-12">
                <div className="sec-gap">
                  <h3 className="templatheading">Your Page Code</h3>
                  <div class="input-group mb-1">
                    <span class="input-group-text" id="basic-addon3">
                      {`https://vmycards.com/users/${businessObj?.slug}`}
                    </span>
                    <input
                      value={businessObj?.slug}
                      type="text"
                      class="form-control"
                      disabled={true}
                    />
                  </div>
                  <small className="etidspan">
                    *minimum 5 characters required.
                  </small>
                </div>
              </div> */}
                <div className="d-flex justify-content-between mb-4 col-md-12 AddDetailsBanner_logoWrapper">
                  {isBannerShow(businessObj?.theme_color) ? (
                    <div class="mb-4 col-md-8">
                      <div className="boxbanner DetailsSelectBanner_wrapper">
                        <label className="form-label">Select Banner:</label>
                        <div className="bannerbox">
                          <img
                            src={
                              bannerImageUrl
                                ? bannerImageUrl?.includes("base64")
                                  ? bannerImageUrl
                                  : bannerImageUrl == "null"
                                    ? ""
                                    : `${baseUrl}uploads/${bannerImageUrl}`
                                : bannerimage
                              // : ""
                            }
                            className="mx-auto d-block img-fluid"
                            onClick={(e) => handleImageClickBanner(e)}
                          ></img>
                          {/* <p>
                    <strong>Recommanded Size : (500 x 300)</strong>
                  </p> */}
                        </div>
                        <input
                          className="form-control d-none"
                          type="file"
                          name="banner" // Set the name to match the Multer field name
                          onChange={handleBannerChange}
                          ref={fileInputRefBanner}
                        />
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                  {isLogoShow(businessObj?.theme_color) ? (
                    <div className="mb-3 col-md-4">
                      <div className="boxbanner detailsdptextlogowrapper">
                        <label className="form-label detailsdptext">
                          Select Logo (Size : 150 x 150)
                        </label>
                        <div className="bannerbox2 AdddetailsdpContainer">
                          <img
                            src={
                              logoImageUrl
                                ? logoImageUrl?.includes("base64")
                                  ? logoImageUrl
                                  : logoImageUrl == "null"
                                    ? ""
                                    : `${baseUrl}uploads/${logoImageUrl}`
                                : avatar
                              // : ""
                              //  logoImageUrl
                            }
                            className="mx-auto d-block"
                            onClick={handleImageClickLogo}
                          />
                        </div>
                        {/* <p>
                      <strong>Size : (150 x 150)</strong>
                    </p> */}
                        <input
                          className="form-control d-none"
                          type="file"
                          name="logo" // Set the name to match the Multer field name
                          onChange={handleLogoChange}
                          ref={fileInputRefLogo}
                          accept=".png, .jpg, .jpeg"
                        />
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>

                <div className="AddDetailsNameDestiCom_Wrapper">
                  <div className="mb-3 col-md-4 AddDetailswidContainer">
                    <label className="form-label">Name:</label>
                    <input
                      // value={subTitle}
                      className="form-control"
                      type="text"
                      placeholder="Name"
                      // onChange={(e) => setSubTitle(e.target.value)}
                      {...formik.getFieldProps("subTitle")}
                      onChange={formik.handleChange}
                    />
                    {formik.touched.subTitle && formik.errors.subTitle && (
                      <ErrorStyle className="error">{formik.errors.subTitle}</ErrorStyle>
                    )}
                  </div>

                  <div className="mb-3 col-md-4 AddDetailswidContainer AddDetailsMarContainer">
                    <label className="form-label">Designation:</label>
                    <input
                      // value={designation}
                      {...formik.getFieldProps("designation")}
                      className="form-control"
                      type="text"
                      placeholder="Designation"
                    // onChange={(e) => setDesignation(e.target.value)}
                    />
                    {formik.touched.designation &&
                      formik.errors.designation && (
                        <ErrorStyle className="error">{formik.errors.designation}</ErrorStyle>
                      )}
                  </div>

                  <div className="mb-3 col-md-4 AddDetailswidContainer">
                    <label className="form-label">Company Name:</label>
                    <input
                      value={title}
                      className="form-control"
                      type="text"
                      placeholder="Company Name"
                      // onChange={(e) => setTitle(e.target.value)}
                      onChange={(e) => handleChangeCompanyName(e)}
                    />
                  </div>
                </div>

                <div className="mb-3 col-md-12">
                  <label className="form-label">Description:</label>
                  <textarea
                    // value={description}
                    {...formik.getFieldProps("description")}
                    className="form-control"
                    rows="4"
                    placeholder="Enter Description"
                  // onChange={(e) => setDescription(e.target.value)}
                  >
                    {/* Hi, I'm a graphic designer ! */}
                  </textarea>
                  {formik.touched.description && formik.errors.description && (
                    <ErrorStyle className="error">{formik.errors.description}</ErrorStyle>
                  )}
                </div>
                {/* <div class="mb-3 col-md-12 ">
                <div class="form-group">
                  <label class="form-label">Personalized link:</label>
                  <div class="input-group">
                    <input
                      type="text"
                      className="form-control"
                      readonly=""
                      value=" https://vmycards.com/freedom-5"
                    />
                    <input
                      className="input-group-text text-start"
                      placeholder="Enter Slug"
                      name="slug"
                      type="text"
                      onChange={(e) => setSlug(e.target.value)}
                      value={slug}
                    />
                  </div>
                </div>
              </div> */}
                <label className="form-label" style={{ fontSize: "17px" }}>
                  Settings:
                </label>
                {/* <h5 style={{fontWeight: "400"}} className="settings">Settings:</h5> */}

                <div className="accordion" id="accordionExample">
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingOne">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                        aria-expanded="false"
                        aria-controls="collapseOne"
                        onClick={(e) => {
                          // setIsEnabledContactInfo(!isEnabledContactInfo);
                          // setOnState({
                          //   ...onState,
                          //   contactInfo: !onState.contactInfo,
                          // });
                        }}
                      >
                        <div className="d-flex">
                          <h5 style={{ fontWeight: "400" }} border-0>
                            Contact Info
                          </h5>
                        </div>
                      </button>

                      <div className="form-check form-switch">
                        <label
                          className="form-check-label"
                        // for="flexSwitchCheckDefault"
                        >
                          On/Off
                        </label>
                        <input
                          onClick={(e) => {
                            setIsEnabledContactInfo(!isEnabledContactInfo);
                            setOnState({
                              ...onState,
                              contactInfo: !onState.contactInfo,
                            });
                          }}
                          className="form-check-input"
                          type="checkbox"
                          role="switch"
                          id="flexSwitchCheckDefault"
                          onChange={() => toggleDiv("div1")}
                          checked={onState?.contactInfo}
                        />
                      </div>
                    </h2>
                    <div
                      id="collapseOne"
                      className="accordion-collapse collapse "
                      aria-labelledby="headingOne"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body AddDetailsContactInfoInnerForm">
                        {/* {divStates.div1 && ( */}
                        <div className="row MobileDetailsWrapper">
                          <div className="mb-3 col-md-6 MobilePhoneFieldWrapper">
                            <label className="form-label">Phone Number:</label>
                            {/* <input
                            value={contactInfoPhone}
                            type="number"
                            className="form-control"
                            onChange={(e) =>
                              setContactInfoPhone(e.target.value)
                            }
                            placeholder="Phone Number"
                          /> */}
                            <PhoneInput
                              country={"in"}
                              enableSearch={true}
                              value={formik.values.contactInfoPhone}
                              onChange={(value, country) => {
                                if (country.dialCode) {
                                  formik.setFieldValue(
                                    "contactInfoPhone",
                                    `+${country.dialCode}-${value.substring(
                                      country.dialCode.length
                                    )}`
                                  );
                                  console.log(
                                    value,
                                    country,
                                    "phonenumberinput"
                                  );
                                  setCountryName(country.name);
                                } else {
                                  formik.setFieldValue(
                                    "contactInfoPhone",
                                    value
                                  );
                                }
                              }}
                              onBlur={formik.handleBlur("contactInfoPhone")}
                              // {...formik.getFieldProps('contactInfoPhone')}
                              className="DetailsInfoPhoneInput"
                            />
                            {formik.touched.contactInfoPhone &&
                              formik.errors.contactInfoPhone && (
                                <ErrorStyle className="error">
                                  {formik.errors.contactInfoPhone}
                                </ErrorStyle>
                              )}
                          </div>
                          <div className="mb-3 col-md-6 MobilePhoneFieldWrapper">
                            <label className="form-label">Email:</label>
                            <input
                              {...formik.getFieldProps("contactInfoEmail")}
                              // value={contactInfoEmail}
                              // value={formik.values.contactInfoEmail}
                              className="form-control"
                              type="text"
                              placeholder="Email"
                            // onChange={(e) =>
                            //   setContactInfoEmail(e.target.value)
                            // }
                            // onChange={formik.handleChange('contactInfoEmail')}
                            />
                            {formik.touched.contactInfoEmail &&
                              formik.errors.contactInfoEmail && (
                                <ErrorStyle className="error">
                                  {formik.errors.contactInfoEmail}
                                </ErrorStyle>
                              )}
                          </div>
                          {/* <div className="mb-3 col-md-12">
                          <label className="form-label">Web Url:</label>
                          <input
                            value={contactInfoWebUrl}
                            className="form-control"
                            type="text"
                            placeholder="web url"
                            onChange={(e) =>
                              setContactInfoWebUrl(e.target.value)
                            }
                          />
                        </div> */}
                          <div className="mb-3 col-md-12">
                            <label className="form-label">Address:</label>
                            <textarea
                              value={contactInfoAddress}
                              className="form-control"
                              rows="3"
                              placeholder="Address"
                              onChange={(e) =>
                                setContactInfoAddress(e.target.value)
                              }
                            ></textarea>
                          </div>
                        </div>
                        {/* )} */}
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item ContactFormPadIssue">
                    <h2 className="accordion-header">
                      <div className="d-flex border-0">
                        <h5 style={{ fontWeight: "400" }} border-0>
                          Contact Form
                        </h5>
                      </div>

                      <div className="form-check form-switch">
                        <label
                          className="form-check-label"
                        // for="flexSwitchCheckDefault"
                        >
                          On/Off
                        </label>
                        <input
                          onClick={(e) => {
                            setIsEnabledContactForm(!isEnabledContactForm);
                            setOnState({
                              ...onState,
                              contactForm: !onState.contactForm,
                            });
                          }}
                          className="form-check-input"
                          type="checkbox"
                          role="switch"
                          id="flexSwitchCheckDefault"
                          // onChange={() => toggleDiv("div1")}
                          checked={onState?.contactForm}
                        />
                      </div>
                    </h2>
                    <div
                      id="collapseOne"
                      className="accordion-collapse collapse "
                      aria-labelledby="headingOne"
                      data-bs-parent="#accordionExample"
                    ></div>
                  </div>

                  {/* ///SERVICES-----------------services  */}
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingTwo">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTwo"
                        aria-expanded="false"
                        aria-controls="collapseTwo"
                        onClick={(e) => {
                          // setIsEnabledServices(!isEnabledServices);
                          // setOnState({
                          //   ...onState,
                          //   services: !onState.services,
                          // });
                        }}
                      >
                        <div className="d-flex">
                          <h5 style={{ fontWeight: "400" }}>Services</h5>
                        </div>
                      </button>
                      <div className="form-check form-switch">
                        <label
                          className="form-check-label"
                        // for="flexSwitchCheckDefault"
                        >
                          On/Off
                        </label>
                        <input
                          className="form-check-input"
                          type="checkbox"
                          role="switch"
                          id="flexSwitchCheckDefault"
                          checked={onState?.services}
                          onClick={(e) => {
                            setIsEnabledServices(!isEnabledServices);
                            setOnState({
                              ...onState,
                              services: !onState.services,
                            });
                          }}
                          onChange={() => toggleDiv("div2")}
                        />
                      </div>
                    </h2>
                    <div
                      id="collapseTwo"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingTwo"
                      data-bs-parent="#accordionExample"
                    >
                      <div
                        className="accordion-body"
                        id="AddnewServicesformwrapper"
                      >
                        {/* {divStates.div2 && ( */}
                        <div className="row">
                          <NewBusinessDetilsCreate
                            setServicesArray={setServicesArray}
                            businessObj={businessObj}
                          />
                        </div>
                        {/* )} */}
                      </div>
                    </div>
                  </div>

                  {/* =========testimonial============== */}
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingThree">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseThree"
                        aria-expanded="false"
                        aria-controls="collapseThree"
                        onClick={() => {
                          // setOnState({
                          //   ...onState,
                          //   testimonials: !onState.testimonials,
                          // });
                        }}
                      >
                        <div className="d-flex">
                          <h5 style={{ fontWeight: "400" }}>Testimonials</h5>
                        </div>
                      </button>
                      <div className="form-check form-switch">
                        <label
                          className="form-check-label"
                        // for="flexSwitchCheckDefault"
                        >
                          On/Off
                        </label>
                        <input
                          className="form-check-input"
                          type="checkbox"
                          role="switch"
                          id="flexSwitchCheckDefault"
                          checked={onState?.testimonials}
                          onClick={() => {
                            setIsTestimonial(!isTestimonial);
                            setOnState({
                              ...onState,
                              testimonials: !onState.testimonials,
                            });
                          }}
                          onChange={() => toggleDiv("div3")}
                        />
                      </div>
                    </h2>
                    <div
                      id="collapseThree"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingThree"
                      data-bs-parent="#accordionExample"
                    >
                      <div
                        className="accordion-body"
                        id="AddnewServicesformwrapper"
                      >
                        <div className="row">
                          <NewTestimonialsCreate
                            setTestimonialArray={setTestimonialArray}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* =========social=================== */}
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingFive">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#five"
                        aria-expanded="false"
                        aria-controls="collapseThree"
                        onClick={() => {
                          // setIsSocial(!isSocial);
                          // setOnState({
                          //   ...onState,
                          //   socials: !onState.socials,
                          // });
                        }}
                      >
                        <div className="d-flex">
                          <h5 style={{ fontWeight: "400" }}>Social</h5>
                        </div>
                      </button>
                      <div className="form-check form-switch">
                        <label
                          className="form-check-label"
                        // for="flexSwitchCheckDefault"
                        >
                          On/Off
                        </label>
                        <input
                          className="form-check-input"
                          type="checkbox"
                          role="switch"
                          id="flexSwitchCheckDefault"
                          checked={onState?.socials}
                          onChange={() => toggleDiv("div5")}
                          onClick={() => {
                            setIsSocial(!isSocial);
                            setOnState({
                              ...onState,
                              socials: !onState.socials,
                            });
                          }}
                        />
                      </div>
                    </h2>
                    <div
                      id="five"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingFive"
                      data-bs-parent="#accordionExample"
                    >
                      <div
                        className="accordion-body"
                        id="AddnewServicesformwrapper"
                        style={{ padding: "15px 0" }}
                      >
                        {/* {divStates.div5 && ( */}
                        <div
                          className="row"
                          style={{ justifyContent: "center" }}
                        >
                          <NewSocialmediaCreate
                            setSocialMediaData={setSocialMediaData}
                            socialMediaData={socialMediaData}
                          />
                        </div>
                        {/* )} */}
                      </div>
                    </div>
                  </div>
                  {/* =====business hour===== */}
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingsix">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#six"
                        aria-expanded="false"
                        aria-controls="collapseThree"
                        onClick={(e) => {
                          // setIsBusinessHourObject(!IsBusinessHourObject);
                          // setOnState({
                          //   ...onState,
                          //   businessHours: !onState.businessHours,
                          // });
                        }}
                      >
                        <div className="d-flex">
                          <h5 style={{ fontWeight: "400" }}>Business Hours</h5>
                        </div>
                      </button>
                      <div className="form-check form-switch">
                        <label
                          className="form-check-label"
                        // for="flexSwitchCheckDefault"
                        >
                          On/Off
                        </label>
                        <input
                          className="form-check-input"
                          type="checkbox"
                          role="switch"
                          id="flexSwitchCheckDefault"
                          checked={onState?.businessHours}
                          onChange={() => toggleDiv("div6")}
                          onClick={(e) => {
                            setIsBusinessHourObject(!IsBusinessHourObject);
                            setOnState({
                              ...onState,
                              businessHours: !onState.businessHours,
                            });
                          }}
                        />
                      </div>
                    </h2>
                    <div
                      id="six"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingsix"
                      data-bs-parent="#accordionExample"
                    >
                      <div
                        className="accordion-body"
                        style={{ padding: "10px 0" }}
                      >
                        {/* {divStates.div6 && ( */}
                        <div className="row" style={{ padding: "10px 0" }}>
                          <NewBusinessHour
                            setAppointmentArray={setAppointmentArray}
                            appointmentSlotDuration={appointmentSlotDuration}
                            setAppointmentSlotDuration={
                              setAppointmentSlotDuration
                            }
                            setBusinessHourObject={setBusinessHourObject}
                          />
                        </div>
                        {/* )} */}
                      </div>
                    </div>
                  </div>

                  {/* =============appointments============ */}
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingFour">
                      {/* <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#four"
                      aria-expanded="false"
                      aria-controls="collapseThree"
                    > */}
                      <div className="d-flex">
                        <h5 style={{ fontWeight: "400" }}>Appointments</h5>
                        <div class="form-check form-switch">
                          <label
                            class="form-check-label"
                          // for="flexSwitchCheckDefault"
                          >
                            On/Off
                          </label>
                          <input
                            class="form-check-input"
                            type="checkbox"
                            role="switch"
                            id="flexSwitchCheckDefault"
                            checked={onState?.appointment}
                            onChange={() => toggleDiv("div4")}
                            onClick={() => {
                              setIsAppointment(!isAppointment);
                              setOnState({
                                ...onState,
                                appointment: !onState?.appointment,
                              });
                            }}
                          />
                        </div>
                      </div>
                      {/* </button> */}
                    </h2>
                    {/* <div
                    id="four"
                    class="accordion-collapse collapse"
                    aria-labelledby="headingFour"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body">
                      {divStates.div4 && (
                      <div className="row">
                        <NewAppointmentsCreate
                          businessHourObject={businessHourObject}
                          appointmentSlotDuration={appointmentSlotDuration}
                          businessObj={businessObj}
                          appointmentArray={appointmentArray}
                          setAppointmentArray={setAppointmentArray}
                        />
                      </div>
                      )}
                    </div>
                  </div> */}
                  </div>
                  {/* ======gallary=========== */}
                  <div className="accordion-item">
                    <div className="accordion-header" id="headingseven">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#seven"
                        aria-expanded="false"
                        aria-controls="collapseThree"
                        onClick={(e) => {
                          // SetIsGallaryEnable(!isGallaryEnable);
                          // setOnState({
                          //   ...onState,
                          //   gallery: !onState.gallery,
                          // });
                        }}
                      >
                        <div className="d-flex">
                          <h5 style={{ fontWeight: "400" }}>Gallery</h5>
                        </div>
                      </button>
                      <div className="form-check form-switch">
                        <label
                          className="form-check-label"
                        // for="flexSwitchCheckDefault"
                        >
                          On/Off
                        </label>
                        <input
                          className="form-check-input"
                          type="checkbox"
                          role="switch"
                          id="flexSwitchCheckDefault"
                          checked={onState?.gallery}
                          onClick={(e) => {
                            SetIsGallaryEnable(!isGallaryEnable);
                            setOnState({
                              ...onState,
                              gallery: !onState.gallery,
                            });
                          }}
                          onChange={() => toggleDiv("div7")}
                        />
                      </div>
                    </div>
                    <div
                      id="seven"
                      className={`accordion-collapse collapse`}
                      aria-labelledby="headingseven"
                      data-bs-parent="#accordionExample"
                    >
                      <div
                        className="accordion-body"
                        id="AddnewGallaryformwrapper"
                      >
                        <div className="row">
                          <NewGallerycreate
                            businessId={businessId}
                            existingGalleryImages={existingGalleryImages}
                            setExistingGalleryImages={setExistingGalleryImages}
                            gallaryObject={gallaryObject}
                            galleryData={galleryData}
                            setGalleryData={setGalleryData}
                            galleryView={galleryView}
                            setGalleryView={setGalleryView}
                            setGallaryObject={setGallaryObject}
                            isGallaryEnable={isGallaryEnable}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row Adddetails_saveprev_ButtonWrapper">
                  <div className="Changeblock_saveandpreview_buttonWrapper Changeblock_saveandpreview_buttonWrappertwo">
                    <button
                      className="btn btn-cu save-cta Changeblock_saveandpreview_button "
                      id="changeblockprenxtbtn"
                      type="button"
                      onClick={handlePreviousClick}
                    >
                      Previous
                    </button>
                    {/* </div>
                  <div className="Changeblock_saveandpreview_buttonWrapper"> */}
                    {isSubmit ? (
                      <CircularLoader size={40} />
                    ) : (
                      <button
                        type="button"
                        className="btn btn-cu save-cta Changeblock_saveandpreview_button"
                        id="changeblockprenxtbtn"
                        onClick={(e) => handleSubmit(e)}
                      >
                        Save & Next
                      </button>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>

          <div className="ChangeBlockView_Container">
            <ViewThem
              mobilePreviewLoading={mobilePreviewLoading}
              isLoadingSubmit={isLoadingSubmit}
              handleSubmit={handleSubmitWithoutTabChange}
              activeTab={activeTab}
              existingGalleryImages={existingGalleryImages}
              galleryView={galleryView}
              businessObj={businessObj}
              title={title}
              themeColor={themeColor}
              themeNumber={themeNumber}
              designation={formik.values.designation}
              subTitle={formik.values.subTitle}
              description={formik.values.description}
              bannerImageUrl={bannerImageUrl}
              logoImageUrl={logoImageUrl}
              contactInfo={{
                contactInfoAddress,
                contactInfoEmail: formik.values.contactInfoEmail,
                contactInfoPhone: formik.values.contactInfoPhone,
                contactInfoWebUrl,
              }}
              businessHourObject={businessHourObject}
              socialMediaData={socialMediaData}
              servicesArray={servicesArray}
              testimonialArray={testimonialArray}
              gallaryObject={gallaryObject}
              galleryData={galleryData}
              onState={onState}
              appointmentArray={appointmentArray}
            />
          </div>
        </div>
      </div>
      <Modal sx={modalStyle} open={modalOpen}>
        <Box sx={boxStyle}>
          <AvatarEditor
            ref={cropRef}
            image={src}
            style={{ width: "100%", height: "100%" }}
            border={50}
            borderRadius={150}
            color={[0, 0, 0, 0.72]}
            scale={slideValue / 10}
            rotate={0}
          />
          <Slider
            min={10}
            max={50}
            sx={{
              margin: "0 auto",
              width: "80%",
              color: "cyan",
            }}
            size="medium"
            defaultValue={slideValue}
            value={slideValue}
            onChange={(e) => setSlideValue(e.target.value)}
          />
          <Box
            sx={{
              display: "flex",
              padding: "10px",
              border: "3px solid white",
              background: "black",
            }}
          >
            <Button
              size="small"
              sx={{
                marginRight: "10px",
                color: "white",
                borderColor: "white",
              }}
              variant="outlined"
              onClick={(e) => setModalOpen(false)}
            >
              cancel
            </Button>
            <Button
              sx={{ background: "#5596e6" }}
              size="small"
              variant="contained"
              onClick={handleSave}
            >
              Save
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
}

export default Details;
