import React, { useState, useEffect, useRef } from "react";
import { environmentVariables } from "../../../config/env.config";
import testimonialdefault from "../../../Images/testimonialdefault.png";
import avatarImage from "../../../Images/avatar.png";
import "./../Updatedcss/BusinessChangeBlockUpdatedStyle.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import styled from "styled-components";



const FormikErrorStyle = styled.div`
font-size:11px;
color: #ff3333;
`;


const testimonialForm = ({
  formCount,
  testimonialData,
  setIsAddTestimonialDisable,
  setTestimonialData,
  index,
  removeForm,
}) => {
  const fileInputRef = useRef();
  const openFileInput = () => {
    fileInputRef.current.click();
  };
  const baseUrl = environmentVariables?.apiUrl;
  const [image, setImage] = useState(null);
  const [addBtnDisable, setAddBtnDisable] = useState(false);

  const [data, setData] = useState({
    formCount: formCount,
    image: null,
    name: "",
    description: "",
    imageView: null,
    isImageUpload: true,
  });

  const getImage = (data) => {
    // data["imageView"]
    //   ? data["imageView"]
    //   : data["image"]
    //   ? `${baseUrl}uploads/${data["image"]}`
    //   : avatarImage;

    if (data["imageView"]) {
      return data["imageView"];
    } else {
      if (data["image"]) {
        if (data["image"] != "null") {
          return `${baseUrl}uploads/${data["image"]}`;
        } else {
          return testimonialdefault;
        }
      } else {
        return avatarImage;
      }
    }
  };

  const validationSchema = Yup.object({
    name: Yup.string()
      .matches(/^[a-zA-Z' -]+$/, "Only alphabets are allowed")
      .required("name is required")
      .min(2, "Minimum 2 characters")
      .max(45, "Maximum 45 Characters"),
    description: Yup.string()
      .matches(/^[a-zA-Z' -]+$/, "Only accept alphabets")
      .min(10, "Minimum 10 characters")
      .max(400, "Maximum 400 characters"),
  });

  const formik = useFormik({
    initialValues: {
      name: data.name || "",
      description: data.description || "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleAddService(values);
    },
  });

  useEffect(() => {
    if (formik.values.name && formik.values.description) {
      handleAddService(formik.values);
    }
  }, [formik.values]);

  const handleAddService = (values) => {
    setAddBtnDisable(true);
    // console.log(testimonialData,"testimonialData!@#",data,"@!#",formCount)
    setIsAddTestimonialDisable(false);
    const newTestimonialData = testimonialData.map((val, index) => {
      if (val.formCount == formCount) {
        val.name = values?.name;
        val.description = data?.description;
        val.image = image;
        val.imageView = data?.imageView;
        val.isImageUpload = data?.isImageUpload;
        return val;
      }
      return val;
    });
    // const newTestimonialData = testimonialData.map((val,index) => {
    //   if (index ==data?.formCount) {
    //     val.name = data?.name;
    //     val.description = data?.description;
    //     val.image = image||data?.image;
    //     val.imageView = data?.image;
    //     return val;
    //   }
    //   return val;
    // });
    // console.log(newTestimonialData, "newTestimonialData@!#");
    setTestimonialData(newTestimonialData);
  };

  const handleChangeImage = (e) => {
    setImage(e.target.files);
    if (e.target.files.length > 0) {
      const reader = new FileReader();
      reader.onload = () => {
        // setImage(reader.result);
        setData({ ...data, imageView: reader.result });
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };
  useEffect(() => {
    // console.log( testimonialData, "234testimonialData23")
    let currentForm = testimonialData[index];
    // console.log(currentForm,"currentForm21")
    // currentForm=currentForm[0]
    setData({
      name: currentForm?.name,
      description: currentForm?.description,
      image: currentForm?.image,
      imageView: currentForm?.imageView,
      formCount: index,
      isImageUpload: currentForm?.isImageUpload,
    });
    // if (currentForm.length > 0) {
    // console.log(currentForm)
    // }
  }, [testimonialData]);

  return (
    <div
      key={`form-${formCount}`}
      className="col-12"
      id="AddNewStyleFormfieldpad"
    >
      <div className="serviceboxedittesti MobileviewServices">
        <i
          className="fa-solid fa-circle-xmark crossedit"
          onClick={() => removeForm(index)}
        >
          {" "}
        </i>

        <div className="boxbanner">
          {/* <label className="form-label">Banner:</label> */}
          <div className="bannerboxedittesti ">
            <img
              style={{
                cursor:
                  data["isImageUpload"] && !addBtnDisable
                    ? "pointer"
                    : "inherit",
              }}
              onClick={() => {
                data["isImageUpload"] && !addBtnDisable
                  ? openFileInput()
                  : null;
              }}
              src={getImage(data)}
              className="mx-auto d-block img-fluid"
            />
            <i
              style={{
                display:
                  data["isImageUpload"] && !addBtnDisable ? "block" : "none",
              }}
              onClick={() => {
                data["isImageUpload"] && !addBtnDisable
                  ? openFileInput()
                  : null;
              }}
              id="uploadicon"
              className="fa fa-solid fa-upload"
            ></i>
          </div>
          {data["isImageUpload"] && !addBtnDisable && (
            <input
              ref={fileInputRef}
              style={{ display: "none" }}
              onChange={(e) => {
                handleChangeImage(e);
              }}
              className="form-control"
              type="file"
              placeholder="Title"
              accept=".png, .jpg, .jpeg"
            />
          )}
        </div>
        <div className="AddDetailsTextimonialphnview">
          <div className="" style={{ fontSize: "16px", fontWeight: "500" }}>
            {/* <label className="form-label">Name</label> */}
            {data["isImageUpload"] && !addBtnDisable ? (
              <div>
                <input
                  // onChange={(e) => setData({ ...data, name: e.target.value })}
                  {...formik.getFieldProps("name")}
                  className="form-control"
                  type="text"
                  placeholder="Name"
                  // value={data["name"] == "undefined" ? "" : data["name"]}
                />
                {formik.touched.name && formik.errors.name ? (
                  <FormikErrorStyle>{formik.errors.name}</FormikErrorStyle>
                ) : null}
              </div>
            ) : (
              <div>{data["name"] == "undefined" ? "" : data["name"]}</div>
            )}
          </div>
          <div
            className="Textimonialminmaxheight"
            style={{ fontSize: "14px", fontWeight: "400", paddingTop: "5px" }}
          >
            {/* <label className="form-label">Description:</label> */}

            {data["isImageUpload"] && !addBtnDisable ? (
              <textarea
                className="form-control "
                rows="4"
                placeholder="Description"
                onChange={(e) =>
                  setData({ ...data, description: e.target.value })
                }
                value={
                  data["description"] == "undefined" ? "" : data["description"]
                }
              ></textarea>
            ) : data["description"] == "undefined" ? (
              ""
            ) : (
              data["description"]
            )}
          </div>
          {data["isImageUpload"] && !addBtnDisable ? (
            <button
              type="button"
              // disabled={addBtnDisable}
              // onClick={handleAddService}
              disabled={!formik.isValid || !formik.dirty || !data.description
                // || !data["imageView"]
              }
              onClick={() => {
                formik.handleSubmit();
                handleAddService({
                  name: data.name,
                  description: data.description,
                  image: image,
                  imageView: data.imageView,
                  isImageUpload: data.isImageUpload,
                });
              }}
              className="btn btn-cu editbutton editButtonTextimonial"
            >
              Add
            </button>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};

export default testimonialForm;
